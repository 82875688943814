import React, {useState} from "react";
import {Text, View, TouchableOpacity, ImageBackground, ActivityIndicator} from "react-native";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import API from "../../helpers/Axios";
import FieldSetTextInput from "../../components/FieldSetTextInput";

const ForgotPasswordScreen = ({navigation}) => {
	const [email, setEmail] = useState({
		value: '',
		error: false,
		errorString: null
	});

	const [isSubmitting, setIsSubmitting] = useState( false);

	const onSubmit = () => {
		if(isSubmitting) {
			return
		}

		API.post('forgot-password', {
			email: email.value,
		})
			.then(res => {
				setIsSubmitting(false);
				navigation.navigate('ResetPasswordScreen', {email: email.value})
			}).catch (error => {
				setIsSubmitting(false)
				setEmail({
					value: email.value,
					error: error.response.data.errors['email'] !== undefined,
					errorString: error.response.data.errors['email'] !== undefined ? error.response.data.errors['email'][0] : 'Error'
				});
		});
	}

	return (
		<ImageBackground
			source={{uri: '/assets/splash/1.jpeg'}}
			style={{
				position: 'absolute',
				flex: 1,
				width: '100%',
				height: '100vh',
				margin: 0,
				padding: 0,
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: '#1D1F1E',
			}}
		>
			<View
				style={{
					position: 'absolute',
					backgroundColor: 'rgba(29, 31, 30, 0.4)',
					top: 0,
					right: 0,
					left: 0,
					bottom: 0
				}}
			/>
			<View
				style={{
					maxWidth: 450
				}}
			>
				<View
					style={{
						marginBottom: 16,
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between'
					}}
				>
					<TouchableOpacity
						onPress={() => navigation.goBack()}
					>
						<Text
							style={{
								color: 'white'
							}}
						>Back</Text>
					</TouchableOpacity>
				</View>
				<View
					style={{
						padding: 48,
						backgroundColor: 'white',
						borderRadius: 8,
					}}
				>
					<Text
						style={{
							color: '#3DBA71',
							fontSize: 34,
							marginBottom: 24,
							fontWeight: '600',
						}}
					>Reset your password</Text>
					<Text
						style={{
							fontSize: 20,
							marginBottom: 24,
							color: '#6D6E6F'
						}}
					>Enter the email address associated with your account and we'll send you a code to reset your password.</Text>
					<View
						style={{
							marginTop: 12
						}}
					>
						<View>
							<FieldSetTextInput
								placeholder={'Email'}
								value={email.value}
								error={email.error}
								errorString={email.errorString}
								onChangeText={text => {
									setEmail({
										value: text,
										error: false,
										errorString: null
									});
								}}
							/>
						</View>
					</View>
					<TouchableOpacity
						onPress={() =>  {
							setIsSubmitting(true);
							onSubmit();
						}}
						style={{
							backgroundColor: '#3DBA71',
							alignItems: 'center',
							justifyContent: 'center',
							borderRadius: 4,
							height: 48,
							marginTop: 40
						}}
					>
						{isSubmitting && <ActivityIndicator color={'white'}/>}
						{!isSubmitting && <Text
							style={{
								color: 'white',
								fontSize: 16,
								fontWeight: '600'
							}}
						>Continue</Text>}
					</TouchableOpacity>
				</View>
			</View>
		</ImageBackground>
	)
}

ForgotPasswordScreen.propTypes = {
	navigation: PropTypes.object,
};

export default connect(({auth}) => ({auth}))(ForgotPasswordScreen);