import React from 'react';
import {Text, View} from 'react-native';

const StudioStatus = (props) => {
  const { status } = props;

  return (<View>
    {status === 0 && (
      <View
        style={{
          height: 26,
          borderRadius: 13,
          backgroundColor: '#EBE8E7',
          justifyContent: 'center',
          alignItems: 'center',
          width: 'fit-content',
          alignSelf: 'flex-start',
          paddingHorizontal: 8,
          flexDirection: 'row'
        }}
      >
        <View
          style={{
            width: 6,
            height: 6,
            borderRadius: 3,
            backgroundColor: '#6D6E6F',
            marginRight: 8
          }}
        />
        <Text
          style={{
            fontWeight: '600',
            fontSize: 12,
            color: '#595959'
          }}
        >{'Inactive'}</Text>
      </View>
    )}
    {status === 1 && (
      <View
        style={{
          height: 26,
          borderRadius: 13,
          backgroundColor: '#C2F3D7',
          justifyContent: 'center',
          alignItems: 'center',
          width: 'fit-content',
          alignSelf: 'flex-start',
          paddingHorizontal: 8,
          flexDirection: 'row'
        }}
      >
        <View
          style={{
            width: 6,
            height: 6,
            borderRadius: 3,
            backgroundColor: '#58B17D',
            marginRight: 8
          }}
        />
        <Text
          style={{
            fontWeight: '600',
            fontSize: 12,
            color: '#096730'
          }}
        >{'Active'}</Text>
      </View>
    )}
  </View>);
};

export default StudioStatus;