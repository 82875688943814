import React, {useMemo} from "react";
import {
	Text,
	View,
	TextInput, ScrollView, StyleSheet, Image
} from "react-native";
import PropTypes from "prop-types";
import {connect} from "react-redux";

function HelpScreen ({navigation, route}) {
	return (
		<View
			style={{
				flex: 1,
				padding: 40
			}}
		>
			<iframe
					style={{
						border: 0,
						flex: 1,
						height: '100%'
					}}
				src='https://stugo.customerly.help/'></iframe>
		</View>
	);
}

const styles = StyleSheet.create({
	tableHeader: {
		fontWeight: '600',
		textAlign: 'left',
		alignSelf: 'flex-start'
	},
	tableCell: {
		borderBottom: '1px solid',
		borderBottomColor: 'gray',
		paddingLeft: 16,
		paddingRight: 16
	},
});

HelpScreen.propTypes = {
	navigation: PropTypes.object,
};

export default connect(({auth}) => ({auth}))(HelpScreen);