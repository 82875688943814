import React from 'react';
import {Text, View} from 'react-native';

const BookingStatus = (props) => {
    const { status } = props;

    return (<View>
        {status === 0 && (
            <View
                style={{
                    height: 26,
                    borderRadius: 13,
                    backgroundColor: '#EBE8E7',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 'fit-content',
                    alignSelf: 'flex-start',
                    paddingHorizontal: 8
                }}
            >
                <Text
                    style={{
                        fontWeight: '600',
                        fontSize: 12,
                    }}
                >{'Pending confirmation'}</Text>
            </View>
        )}
        {status === 1 && (
            <View
                style={{
                    height: 26,
                    borderRadius: 13,
                    backgroundColor: '#E55A5A',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 'fit-content',
                    alignSelf: 'flex-start',
                    paddingHorizontal: 8
                }}
            >
                <Text
                    style={{
                        fontWeight: '600',
                        fontSize: 12,
                        color: 'white',
                    }}
                >Booking canceled</Text>
            </View>
        )}
        {status === 2 && (
            <View
                style={{
                    height: 26,
                    borderRadius: 13,
                    backgroundColor: '#3DBA71',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 'fit-content',
                    alignSelf: 'flex-start',
                    paddingHorizontal: 8
                }}
            >
                <Text
                    style={{
                        fontWeight: '600',
                        fontSize: 12,
                        color: 'white',
                    }}
                >{'Booking confirmed'}</Text>
            </View>
        )}
        {status === 3 && (
          <View
            style={{
                height: 26,
                borderRadius: 13,
                borderColor: '#3DBA71',
                borderWidth: 2,
                justifyContent: 'center',
                alignItems: 'center',
                width: 'fit-content',
                alignSelf: 'flex-start',
                paddingHorizontal: 8
            }}
          >
              <Text
                style={{
                    fontWeight: '600',
                    fontSize: 12,
                }}
              >{'Booking Complete'}</Text>
          </View>
        )}
    </View>);
};

export default BookingStatus;