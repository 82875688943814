const types = {
    NEW_MESSAGE_WAS_RECEIVED: "NEW_MESSAGE_WAS_RECEIVED",
};

const initialState = {
    messageWasReceived: 0,
    content: null
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.NEW_MESSAGE_WAS_RECEIVED:
            return {
                ...state,
                messageWasReceived: state.messageWasReceived + 1,
                content: action.content
            };
        default:
            return state;
    }
};
