import React, {useEffect, useRef, useState} from 'react';
import {View, Text, ActivityIndicator, Modal, ScrollView, Image, TextInput} from "react-native";
import RoundTextInput from "../../components/RoundTextInput";
import {Switch, TouchableOpacity} from "react-native-web";
import API from "../../helpers/Axios";
import RoundPicker from "../../components/RoundPicker";
import {toast} from "react-toastify";
import {Popover} from "react-tiny-popover";
import moment from "moment";
import {useNavigation, useRoute} from "@react-navigation/native";
import StudioStatus from "../../components/StudioStatus";
import {confirmAlert} from "react-confirm-alert";

const EditStudioScreen = ({ setIsVisible, item, setItem, getStudios }) => {
	const textInput = useRef();
	const [isActive, setIsActive] = useState(item.active);
	const [categories, setCategories] = useState([]);
	const [isApproveLoading, setIsApproveLoading] = useState(false);
	const [isRejectLoading, setIsRejectLoading] = useState(false);
	const [currentRoom, setCurrentRoom] = useState(item.rooms.length > 0 ? item.rooms[0] : []);
	const [messages, setMessages] = useState([]);
	const [currentConversation, setCurrentConversation] = useState(null);
	const [messageString, setMessageString] = useState('');

	const sendMessage = () => {
		if(!messageString.length) {
			return
		}
		const data = new FormData();
		const lid = (+ new Date());
		data.append('conversation_id', currentConversation.id);
		data.append('lid', lid);
		data.append('message', messageString.trim());
		data.append('type', 'text');

		const message = {
			"lid": lid,
			"body": messageString.trim(),
			"is_sender": 1,
			"type": "text"
		}

		setMessages([...messages, message]);
		setMessageString('');
		API.post('chat/send-message', data)
			.then(res => {
				let message = res.data;
				console.log(message);
				textInput.current.focus();
			}).catch (error => {
			console.log(error)
		});
	}

	const [studioType, setStudioType] = useState({
		value: item.category_id,
		error: false,
		errorString: 'Please select studio type'
	});

	useEffect(() => {
		API.post('categories')
			.then(res => {
				setCategories(res.data)
			}).catch (error => {
		});
	}, []);

	return (
		<View
			style={{
				flex: 1,
			}}
		>
			<View
				style={{
					justifyContent: 'center',
					alignItems: 'center',
					borderBottomWidth: 1,
					borderColor: '#E4E1E0',
					padding: 16,
					height: 64
				}}
			>
				<Text
					style={{
						fontWeight: '600',
						fontSize: 24,
						color: '#096730'
					}}
				>Studios</Text>
				<TouchableOpacity
					onPress={() => setIsVisible(false)}
					style={{
						position: 'absolute',
						right: 16,
					}}
				>
					<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M18.707 5.293a1 1 0 0 1 0 1.414l-12 12a1 1 0 0 1-1.414-1.414l12-12a1 1 0 0 1 1.414 0Z"
							fill="#6D6E6F"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M5.293 5.293a1 1 0 0 1 1.414 0l12 12a1 1 0 0 1-1.414 1.414l-12-12a1 1 0 0 1 0-1.414Z"
							fill="#6D6E6F"
						/>
					</svg>
				</TouchableOpacity>
			</View>
			<ScrollView
				style={{
					padding: 32,
					maxHeight: 'calc(100vh - 300px)',
				}}
			>
				<View
					style={{
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between'
					}}
				>
					<Text
						style={{
							fontWeight: '600',
							fontSize: 18,
							color: '#096730'
						}}
					>{item.name}</Text>
					<View
						style={{
							flexDirection: 'row'
						}}
					>
						<Text><strong>Location</strong> <Text style={{color: '#595959'}}>{item.address}</Text></Text>
					</View>
				</View>
				<View
					style={{
						flexDirection: 'row',
						marginTop: 32,
						justifyContent: 'space-between'
					}}
				>
					<View
						style={{
							marginRight: 24,
						}}
					>
						<Text
							style={{
								fontWeight: '600',
								marginBottom: 4
							}}
						>Business Incorporation</Text>
						<Text
							style={{
								color: '#595959',
								fontSize: 16
							}}
						>{item.user && item.user.name}</Text>
					</View>
					<View
						style={{
							marginRight: 24,
						}}
					>
						<Text
							style={{
								fontWeight: '600',
								marginBottom: 4
							}}
						>Studio Type</Text>
						{item.category && (
							<Text
								style={{
									color: '#595959',
									fontSize: 16
								}}
							>{item.category.name}</Text>
						)}
					</View>
					<View
						style={{
							marginRight: 24,
						}}
					>
						<Text
							style={{
								fontWeight: '600',
								marginBottom: 4
							}}
						>Account type</Text>
						<Text
							style={{
								color: '#595959',
								fontSize: 16
							}}
						>Business</Text>
					</View>
					<View
						style={{
							marginRight: 24,
						}}
					>
						<Text
							style={{
								fontWeight: '600',
								marginBottom: 4
							}}
						>Added in</Text>
						<Text
							style={{
								color: '#595959',
								fontSize: 16
							}}
						>{moment.utc(item.created_at).local().format("MMM D, YYYY")}</Text>
					</View>
				</View>
				<View
					style={{
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						marginTop: 16
					}}
				>
					<View
						style={{
							flexDirection: 'row'
						}}
					>
						<Text
							style={{
								fontWeight: '600',
								marginBottom: 4
							}}
						>Tax ID</Text>
						<Text
							style={{
								color: '#595959',
								marginLeft: 8
							}}
						>0018190002-133</Text>
					</View>
					{!item.approved && (
						<StudioStatus status={item.approved} />
					)}
					{item.approved > 0 && (
						<View
							style={{
								flexDirection: 'row',
								alignItems: 'center'
							}}
						>
							<Text
								style={{
									fontWeight: '600',
									marginRight: 8
								}}
							>Status: Active</Text>
							<Switch
								onValueChange={() => {
									API.post('admin/toggle-studio', {
										id: item.id
									}).then(res => {
										setIsActive(!isActive)
									}).catch (error => {
										setIsRejectLoading(false);
										console.log(error);
									});
								}}
								value={isActive}
							/>
						</View>
					)}
				</View>
				<View
					style={{
						marginTop: 32,
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<Text
						style={{
							fontWeight: '600',
							fontSize: 18,
							color: '#096730'
						}}
					>Studio Pictures</Text>
					<View
						style={{

						}}
					>
						<svg width={21} height={21} fill="none" xmlns="http://www.w3.org/2000/svg">
							<rect x={0.5} y={0.287} width={20} height={20} rx={3} fill="#58B17D" />
							<path
								d="M4.793 11.009c-.893-1.037.457-2.622 1.35-1.585l2.741 3.167 5.966-6.963c.893-1.042 2.251.543 1.358 1.585L9.567 14.96c-.373.435-.978.437-1.353.002l-3.42-3.953Z"
								fill="#fff"
							/>
						</svg>
					</View>
				</View>
				{item.images.length === 0 && (
					<View
						style={{
							alignItems: 'center',
							justifyContent: 'center',
							paddingVertical: 32
						}}
					>
						<Text
							style={{
								fontSize: 24,
								color: '#595959'
							}}
						>No Images</Text>
					</View>
				)}
				{item.images.length > 0 && (
					<ScrollView
						horizontal={true}
						style={{
							marginTop: 32,
							paddingBottom: 32
						}}
					>
						{item.images.map((item, index) => (
							<View
								key={index}
								style={{
									width: 280,
									aspectRatio: 1.777,
									borderRadius: 4,
									marginRight: 24,
									borderWidth: 1,
									borderColor: '#f1f0ef',
									overflow: 'hidden'
								}}
							>
								<Image
									style={{
										flex: 1
									}}
									source={{
										uri: item['sm'],
									}}
								/>
							</View>
						))}
					</ScrollView>
				)}
				<View
					style={{
						marginTop: 32,
						borderBottomWidth: 1,
						borderColor: '#E4E1E0',
						paddingBottom: 32
					}}
				>
					<Text
						style={{
							fontWeight: '600',
							fontSize: 18,
							marginBottom: 24,
							color: '#096730'
						}}
					>Studio Information</Text>
					<Text
						style={{
							fontWeight: '600',
							marginBottom: 16,
							fontSize: 16
						}}
					>Description</Text>
					<Text
						style={{
							color: '#595959',
							fontSize: 16
						}}
					>{item.description}</Text>
				</View>

				<View
					style={{
						marginTop: 24
					}}
				>
					<Text
						style={{
							color: '#096730',
							fontSize: 24,
							fontWeight: '600',
							textAlign: 'center'
						}}
					>Rooms</Text>
					{item.rooms.length > 0 && (
						<View
							style={{
								marginTop: 24
							}}
						>
							<View
								style={{
									flexDirection: 'row'
								}}
							>
								{item.rooms.map((item, index) => (
									<TouchableOpacity
										key={index}
										onPress={() => {
											setCurrentRoom(item);
										}}
										style={{
											borderBottomWidth: 2,
											borderColor: currentRoom.id === item.id ? '#096730' : 'white',
											alignSelf: 'flex-start',
											paddingHorizontal: 16
										}}
									>
										<Text
											style={{
												fontWeight: '600',
												fontSize: 16,
												color: currentRoom.id === item.id ? 'black' : '#096730',
												lineHeight: 40
											}}
										>{item.name}</Text>
									</TouchableOpacity>
								))}
							</View>





							<View
								style={{
									marginTop: 48,
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'space-between'
								}}
							>
								<Text
									style={{
										fontWeight: '600',
										fontSize: 18
									}}
								>{currentRoom.name}</Text>
								<Text
									style={{
										color: '#595959'
									}}
								><strong>Price per hour: </strong> ${currentRoom.per_session_price}</Text>
							</View>
							<View
								style={{
									flexDirection: 'row',
									marginTop: 32
								}}
							>
								<View
									style={{
										marginRight: 24
									}}
								>
									<Text
										style={{
											fontWeight: '600',
											marginBottom: 4
										}}
									>Name</Text>
									<Text>{currentRoom.name}</Text>
								</View>
								<View
									style={{
										marginRight: 24,
									}}
								>
									<Text
										style={{
											fontWeight: '600',
											marginBottom: 4
										}}
									>Date added</Text>
									<Text>{moment(currentRoom.created_at).format("MMM D, YYYY")}</Text>
								</View>
								<View>
									<Text
										style={{
											fontWeight: '600',
											marginBottom: 4
										}}
									>Price per hour</Text>
									<Text>${currentRoom.per_session_price}</Text>
								</View>
							</View>
							<View
								style={{
									marginTop: 32,
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'space-between',
									borderTopWidth: 1,
									borderColor: '#E4E1E0',
									paddingTop: 32
								}}
							>
								<Text
									style={{
										fontWeight: '600',
										fontSize: 18
									}}
								>Room Pictures</Text>
								<View
									style={{

									}}
								>
									<svg width={21} height={21} fill="none" xmlns="http://www.w3.org/2000/svg">
										<rect x={0.5} y={0.287} width={20} height={20} rx={3} fill="#58B17D" />
										<path
											d="M4.793 11.009c-.893-1.037.457-2.622 1.35-1.585l2.741 3.167 5.966-6.963c.893-1.042 2.251.543 1.358 1.585L9.567 14.96c-.373.435-.978.437-1.353.002l-3.42-3.953Z"
											fill="#fff"
										/>
									</svg>
								</View>
							</View>
							{currentRoom.images.length === 0 && (
								<View
									style={{
										alignItems: 'center',
										justifyContent: 'center',
										paddingVertical: 32
									}}
								>
									<Text
										style={{
											fontSize: 24,
											color: '#595959'
										}}
									>No Images</Text>
								</View>
							)}
							{currentRoom.images.length > 0 && (
								<ScrollView
									horizontal={true}
									style={{
										marginTop: 32,
									}}
								>
									{currentRoom.images.map((item, index) => (
										<View
											key={index}
											style={{
												width: 280,
												aspectRatio: 1.777,
												borderRadius: 4,
												marginRight: 24,
												borderWidth: 1,
												borderColor: '#f1f0ef',
												overflow: 'hidden'
											}}
										>
											<Image
												style={{
													flex: 1
												}}
												source={{
													uri: item['sm'],
												}}
											/>
										</View>
									))}
								</ScrollView>
							)}
							<View
								style={{
									marginTop: 32,
									borderTopWidth: 1,
									borderBottomWidth: 1,
									borderColor: '#E4E1E0',
									paddingVertical: 32
								}}
							>
								<Text
									style={{
										fontWeight: '600',
										fontSize: 18,
										marginBottom: 24,
									}}
								>Room Information</Text>
								<Text
									style={{
										fontWeight: '600',
										marginBottom: 16,
										fontSize: 16
									}}
								>Description</Text>
								<Text
									style={{
										color: '#595959',
										fontSize: 16
									}}
								>{currentRoom.description}</Text>
							</View>
							<View
								style={{
									marginTop: 32,
									borderBottomWidth: 1,
									borderColor: '#E4E1E0',
									paddingBottom: 32
								}}
							>
								<View
									style={{
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'space-between',
									}}
								>
									<Text
										style={{
											fontWeight: '600',
											fontSize: 18
										}}
									>Rules</Text>
								</View>
								<View
									style={{
										marginTop: 16,
									}}
								>
									{currentRoom.rules.map((item, index) => (
										<Text
											style={{
												lineHeight: 28,
												color: '#595959',
												fontSize: 16
											}}
										>{item.name}</Text>
									))}
								</View>
							</View>
							<View
								style={{
									marginTop: 32,
									borderBottomWidth: 1,
									borderColor: '#E4E1E0',
									paddingBottom: 32
								}}
							>
								<View
									style={{
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'space-between',
									}}
								>
									<Text
										style={{
											fontWeight: '600',
											fontSize: 18
										}}
									>Equipments</Text>
								</View>
								<View
									style={{
										marginTop: 16,
									}}
								>
									{currentRoom.equipments.map((item, index) => (
										<View
											key={index}
											style={{
												justifyContent: 'space-between',
												flexDirection: 'row',
												alignItems: 'center'
											}}
										>
											<Text
												style={{
													lineHeight: 28,
													color: '#595959',
													fontSize: 16
												}}
											>{item.name}</Text>
											<View
												style={{
													flex: 1,
													borderBottomWidth: 2,
													marginHorizontal: 8,
													height: 10,
													borderStyle: 'dotted',
													borderColor: '#595959'
												}}
											/>
											<Text
												style={{
													lineHeight: 28,
													fontWeight: '600'
												}}
											>{item.cost ? `$${item.cost}/hr` : 'Free'}</Text>
										</View>
									))}
								</View>
							</View>
							<View
								style={{
									marginTop: 32,
									borderBottomWidth: 1,
									borderColor: '#E4E1E0',
									paddingBottom: 32
								}}
							>
								<View
									style={{
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'space-between',
									}}
								>
									<Text
										style={{
											fontWeight: '600',
											fontSize: 18
										}}
									>Amenities</Text>
								</View>
								<View
									style={{
										marginTop: 16,
									}}
								>
									{currentRoom.amenities.map((item, index) => (
										<View
											key={index}
											style={{
												justifyContent: 'space-between',
												flexDirection: 'row',
												alignItems: 'center'
											}}
										>
											<Text
												style={{
													lineHeight: 28,
													color: '#595959',
													fontSize: 16
												}}
											>{item.name}</Text>
											<View
												style={{
													flex: 1,
													borderBottomWidth: 2,
													marginHorizontal: 8,
													height: 10,
													borderStyle: 'dotted',
													borderColor: '#595959'
												}}
											/>
											<Text
												style={{
													lineHeight: 28,
													fontWeight: '600'
												}}
											>{item.cost ? `$${item.cost}/hr` : 'Free'}</Text>
										</View>
									))}
								</View>
							</View>
						</View>
					)}
				</View>
				{!item.approved && (
					<View
						style={{
							flexDirection: 'row',
							justifyContent: 'flex-end',
							marginTop: 32
						}}
					>
						<TouchableOpacity
							onPress={() => {
								setIsRejectLoading(true);
								API.post('admin/reject-studio', {
									id: item.id
								}).then(res => {
									let newData = item;
									newData.approved = res.data.approved;
									setItem(newData);
									setIsRejectLoading(false);
									setIsVisible(false);
								}).catch (error => {
									setIsRejectLoading(false);
									console.log(error);
								});
							}}
							style={{
								fontWeight: '600',
								fontSize: 16,
								paddingHorizontal: 32,
								height: 48,
								borderRadius: 8,
								backgroundColor: '#FBE8E8',
								justifyContent: 'center',
								alignItems: 'center',
								flexDirection: 'row'
							}}
						>
							<Text
								style={{
									fontWeight: '600',
									fontSize: 16,
									color: '#A42323'
								}}
							>Reject Studio</Text>
							{isRejectLoading && <ActivityIndicator color={'#A42323'} style={{marginLeft: 8}}/>}
						</TouchableOpacity>
						<TouchableOpacity
							onPress={() => {
								setIsApproveLoading(true);
								API.post('admin/approve-studio', {
									id: item.id
								}).then(res => {
									let newData = item;
									newData.approved = res.data.approved;
									setItem(newData);
									setIsApproveLoading(false);
									setIsVisible(false);
								}).catch (error => {
									setIsApproveLoading(false);
									console.log(error);
								});
							}}
							style={{
								paddingHorizontal: 32,
								marginLeft: 16,
								fontWeight: '600',
								fontSize: 16,
								height: 48,
								borderRadius: 8,
								backgroundColor: '#19994F',
								justifyContent: 'center',
								alignItems: 'center',
								flexDirection: 'row'
							}}
						>
							<Text
								style={{
									fontWeight: '600',
									fontSize: 16,
									color: 'white'
								}}
							>Approve Studio</Text>
							{isApproveLoading && <ActivityIndicator color={'white'} style={{marginLeft: 8}}/>}
						</TouchableOpacity>
					</View>
				)}
				<View
					style={{
						marginVertical: 32
					}}
				>
					<Text
						style={{

						}}
					><strong>Send message to ${item.user && item.user.name}</strong></Text>
					<Text
						style={{
							marginTop: 8,
							color: '#6D6E6F'
						}}
					>Write here any reason why the studio is being rejected.</Text>
					<View
						style={{
							marginTop: 16,
							backgroundColor: '#f8f8f8' ,
							paddingTop: 16,
							paddingLeft: 16,
							paddingRight: 16,
							borderWidth: 1,
							borderColor: '#E4E1E0',
							overflow: 'hidden',
							borderRadius: 8,
							paddingBottom:8,
						}}>
						<TextInput
							ref={textInput}
							placeholder={`Send message to ${item.user && item.user.name}`}
							style={{
								borderColor: '#AFABAC',
								flex: 1,
								outline: 0,
								fontSize: 16
							}}
							value={messageString}
							onChangeText={text => {
								setMessageString(text)
							}}
							multiline={true}
							numberOfLines={4}
							blurOnSubmit={true}
							onSubmitEditing={() => sendMessage()}
						/>
						<View
							style={{
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'space-between'
							}}
						>
							<TouchableOpacity
								style={{
									backgroundColor: 'white',
									height: 26,
									borderRadius: 13,
									paddingHorizontal: 8,
									flexDirection: 'row',
									alignItems: 'center'
								}}
							>
								<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M10.52 1.592c-.53 0-1.04.21-1.415.586L2.978 8.305a3.336 3.336 0 1 0 4.717 4.717l6.127-6.127a.667.667 0 1 1 .943.943l-6.127 6.127a4.669 4.669 0 1 1-6.603-6.603l6.127-6.127a3.335 3.335 0 1 1 4.716 4.716l-6.133 6.127a2 2 0 0 1-2.83-2.83l5.66-5.653a.667.667 0 1 1 .943.943l-5.66 5.653a.668.668 0 0 0 .944.944l6.133-6.126a2.002 2.002 0 0 0-1.415-3.417Z"
										fill="#6D6E6F"
									/>
								</svg>
								<Text
									style={{
										marginLeft: 8,
										fontSize: 12,
										color: '#595959'
									}}
								>Attach file - PDF, JPG, PNG</Text>
							</TouchableOpacity>
							<TouchableOpacity
								onPress={() => sendMessage()}
								style={{
									flexDirection: 'row',
									alignItems: 'center',
								}}
							>
								<Text
									style={{
										marginRight: 8,
										color: '#096730',
										fontWeight: '600'
									}}
								>Send message</Text>
								<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M15.138.862c.26.26.26.682 0 .943L7.805 9.138a.667.667 0 1 1-.943-.943L14.195.862c.26-.26.683-.26.943 0Z"
										fill="#096730"
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M15.138.862c.181.181.243.45.158.692l-4.667 13.333a.667.667 0 0 1-1.238.05L6.828 9.172 1.063 6.609a.667.667 0 0 1 .05-1.238L14.447.704a.667.667 0 0 1 .691.158ZM3.141 6.074l4.463 1.983c.151.067.272.188.339.339l1.983 4.463L13.58 2.42 3.14 6.074Z"
										fill="#096730"
									/>
								</svg>
							</TouchableOpacity>
						</View>
					</View>
				</View>
				<TouchableOpacity
					onPress={() => {
						confirmAlert({
							title: 'Confirm to delete',
							message: 'Are you sure to do this.',
							buttons: [
								{
									label: 'No',
								},
								{
									label: 'Yes',
									className: 'confirm-delete',
									onClick: () => {
										API.post('admin/delete-studio', {
											id: item.id,
										}).then(res => {
											getStudios();
											setIsVisible(false);
										}).catch (error => {
											console.log(error)
										});
									}
								},
							]
						});
					}}
					style={{
						alignSelf: 'flex-start',
						height: 48,
						backgroundColor: '#E55A5A',
						borderRadius: 8,
						alignItems: 'center',
						justifyContent: 'center',
						flexDirection: 'row',
						paddingHorizontal: 16
					}}
				>
					<Text
						style={{
							fontWeight: '600',
							fontSize: 16,
							color: 'white',
						}}
					>Remove this studio</Text>
				</TouchableOpacity>
			</ScrollView>
		</View>
	)
}
export default EditStudioScreen;