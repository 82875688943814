import React, {useEffect, useState} from "react";
import {
	TouchableOpacity,
	ImageBackground,
	StyleSheet,
	Text,
	View,
	Image, ScrollView, Switch, TextInput, ActivityIndicator,
} from "react-native";
import _ from 'lodash';
import {connect} from "react-redux";
import {useTheme} from "@react-navigation/native";
import FieldSetTextInput from "../../components/FieldSetTextInput";
import FieldSetPicker from "../../components/FieldSetPicker";
import API from "../../helpers/Axios";
import {toast} from "react-toastify";
import {SortableContainer, SortableElement, sortableHandle,  arrayMove} from 'react-sortable-hoc';
import '../../styles/sortable.css';
import SortableImages from "../../components/SortableImages";
import FieldSetAddressTextInput from "../../components/FieldSetAddressTextInput";
import '../../styles/place.css';

const InitializationScreen = ({navigation, auth}) => {
	const {colors, dark} = useTheme();
	const [isSubmitting, setIsSubmitting] = useState( false);
	const [lng, setLng] = useState( null);
	const [lat, setLat] = useState( null);

	const [studioName, setStudioName] = useState({
		value: '',
		error: false,
		errorString: 'Please enter studio name'
	});
	const [studioAddress, setStudioAddress] = useState({
		value: '',
		error: false,
		errorString: 'Please enter studio address'
	});

	const [studioType, setStudioType] = useState({
		value: null,
		error: false,
		errorString: 'Please select studio type'
	});

	const [accountType, setAccountType] = useState({
		value: null,
		error: false,
		errorString: 'Please select account type'
	});

	const [description, setDescription] = useState({
		value: '',
		error: false,
		errorString: 'Please enter studio description'
	});

	const [businessName, setBusinessName] = useState({
		value: '',
		error: false,
		errorString: null
	});

	const [taxId, setTaxId] = useState({
		value: '',
		error: false,
		errorString: null
	});

	const [roomName, setRoomName] = useState({
		value: '',
		error: false,
		errorString: null
	});
	const [roomDescription, setRoomDescription] = useState({
		value: '',
		error: false,
		errorString: null
	});
	const [roomPrice, setRoomPrice] = useState({
		value: '',
		error: false,
		errorString: null
	});

	const [stepIndex, setStepIndex] = useState(0);
	const [studioArtworkFiles, setStudioArtworkFiles] = useState([]);
	const [roomArtworkFiles, setRoomArtworkFiles] = useState([]);
	const [categories, setCategories] = useState([]);

	const [roomRules, setRoomRules] = useState([
		{
			title: 'Suitable for children (2-12 years)'
		},
		{
			title: 'Pet friendly'
		},
		{
			title: 'Smoking allowed'
		},
		{
			title: 'Alcohol drinking allowed'
		}
	]);

	const [roomEquipments, setRoomEquipments] = useState([
		{
			title: 'Speakers',
			cost: 0
		},
		{
			title: 'Computer',
			cost: 0
		},
		{
			title: 'Work Station',
			cost: 0
		}
	]);

	const [roomAmenities, setRoomAmenities] = useState([
		{
			title: 'Free wifi',
			cost: 0
		},
		{
			title: 'Free parking',
			cost: 0
		},
		{
			title: 'Kitchen area',
			cost: 0
		}
	]);

	const [additionRule, setAdditionRule] = useState('');
	const [additionEquipment, setAdditionEquipment] = useState('');
	const [additionEquipmentPrice, setAdditionEquipmentPrice] = useState('');
	const [additionAmenity, setAdditionAmenity] = useState('');
	const [additionAmenityPrice, setAdditionAmenityPrice] = useState('');

	const studioArtworksSelectedHandler = (e) => {
		let array = [];
		for (let i = 0; i < e.target.files.length; i++) {
			array.push(e.target.files[i]);
		}
		setStudioArtworkFiles(array)
	}

	const roomArtworksSelectedHandler = (e) => {
		let array = [];
		for (let i = 0; i < e.target.files.length; i++) {
			array.push(e.target.files[i]);
		}
		setRoomArtworkFiles(array)
	}

	useEffect(() => {
		API.post('categories')
			.then(res => {
				setCategories(res.data)
			}).catch (error => {
		});
	}, []);
	
	return (
		<ScrollView
			style={{
				padding: 40
			}}
		>
			<View
				style={{
					maxWidth: 1200,
					width: '100%',
				}}
			>
				<Text
					style={{
						fontSize: 34,
						fontWeight: '600'
					}}
				>Let's create your Studio!</Text>
				<Text
					style={{
						color: '#6D6E6F',
						fontSize: 20,
						marginTop: 16
					}}
				>Here we will finish filling out the information you need create new studio!</Text>

				<View
					style={{
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
						backgroundColor: 'white',
						borderRadius: 8,
						marginTop: 24,
						shadowColor: "#000",
						shadowOffset: {
							width: 0,
							height: 2,
						},
						shadowOpacity: 0.05,
						shadowRadius: 3.84,
						elevation: 5,
					}}
				>
					<View
						style={{
							flexDirection: 'row',
							paddingVertical: 24,
							paddingHorizontal: 24,
							alignItems: 'center'
						}}
					>
						<Image
							source={require('../../assets/icons/common/circle-checked.png')}
							resizeMode={'cover'}
							style={{
								width: 24,
								height: 24,
								marginRight: 16
							}}
						/>
						<Text
							style={{
								fontSize: 24,
								fontWeight: '600',
								color: '#6D6E6F'
							}}
						>Studio Setup</Text>
					</View>
					<View
						style={{
							height: 40,
							width: '20%',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<View
							style={{
								height: 2,
								width: '50%',
								backgroundColor: '#AFABAC'
							}}
						/>
					</View>
					<View
						style={{
							flexDirection: 'row',
							paddingVertical: 24,
							paddingHorizontal: 24,
							alignItems: 'center'
						}}
					>
						{stepIndex === 2 && (
							<Image
								source={require('../../assets/icons/common/circle.png')}
								resizeMode={'cover'}
								style={{
									width: 24,
									height: 24,
									marginRight: 16
								}}
							/>
						)}
						{stepIndex > 2 && (
							<Image
								source={require('../../assets/icons/common/circle-checked.png')}
								resizeMode={'cover'}
								style={{
									width: 24,
									height: 24,
									marginRight: 16
								}}
							/>
						)}
						<Text
							style={{
								fontSize: 24,
								fontWeight: '600',
								color: stepIndex > 2 ? '#6D6E6F' : '#D2D2D2'
							}}
						>Create Room</Text>
					</View>
				</View>
				<View
					style={{
						marginTop: 24,
						flexDirection: 'row'
					}}
				>
					<View
						style={{
							width: '70%',
							backgroundColor: 'white',
							borderRadius: 8,
							shadowColor: "#000",
							shadowOffset: {
								width: 0,
								height: 2,
							},
							shadowOpacity: 0.05,
							shadowRadius: 3.84,
							elevation: 5,
							padding: 24
						}}
					>
						{stepIndex === 0 && (
							<>
								<Text
									style={{
										fontSize: 24,
										marginBottom: 24
									}}
								>Studio Basic Info</Text>
								<View
									style={{
										flex: 1
									}}
								>
									<FieldSetTextInput
										name={'Studio Name'}
										placeholder={'Enter your studio name'}
										value={studioName.value}
										error={studioName.error}
										errorString={studioName.errorString}
										onChangeText={text => {
											setStudioName({
												value: text,
												error: false,
												errorString: studioName.errorString
											});
										}}
									/>
								</View>
								<View
									style={{
										marginTop: 40
									}}
								>
									<FieldSetTextInput
										placeholder={'Description'}
										value={description.value}
										error={description.error}
										errorString={description.errorString}
										onChangeText={text => {
											setDescription({
												value: text,
												error: false,
												errorString: description.errorString
											});
										}}
										multiline={true}
										numberOfLines={3}
									/>
									<View
										style={{
											alignItems: 'flex-end',
											marginTop: 8
										}}
									>
										<View
											style={{
												flexDirection: 'row'
											}}
										>
											<Text
												style={{
													color: '#4A535E'
												}}
											>{description.value.length}</Text>
											<Text
												style={{
													color: '#4A535E'
												}}
											>/300</Text>
										</View>
									</View>
								</View>
								<View
									style={{
										marginTop: 40
									}}
								>
									<FieldSetAddressTextInput
										placeholder={'Address'}
										value={studioAddress.value}
										error={studioAddress.error}
										errorString={studioAddress.errorString}
										onChangeText={text => {
											setStudioAddress({
												value: text,
												error: false,
												errorString: studioAddress.errorString
											});
										}}
										onPlaceChange={data => {
											setStudioAddress({
												value: data.formatted_address,
												error: false,
												errorString: studioAddress.errorString
											});
											setLng(data.geometry.location.lng());
											setLat(data.geometry.location.lat())
										}}
									/>
								</View>
								<View
									style={{
										marginTop: 40,
										flexDirection: 'row',
										justifyContent: 'space-between',
										flex: 1,
									}}
								>
									<View
										style={{
											marginRight: 12,
											flex: 1,
										}}
									>
										<FieldSetPicker
											data={categories}
											placeholder={'Studio type'}
											value={studioType.value}
											error={studioType.error}
											errorString={studioType.errorString}
											onValueChange={value => {
												setStudioType({
													value: value,
													error: false,
													errorString: studioType.errorString
												});
											}}
										/>
									</View>
									<View
										style={{
											marginLeft: 12,
											flex: 1
										}}
									>
										<FieldSetPicker
											data={[
												{id: 1, name: 'Personal'},
												{id: 1, name: 'Business'}
											]}
											placeholder={'Account type'}
											value={accountType.value}
											error={accountType.error}
											errorString={accountType.errorString}
											onValueChange={value => {
												setAccountType({
													value: value,
													error: false,
													errorString: accountType.errorString
												});
											}}
										/>
									</View>
								</View>
								<View
									style={{
										marginTop: 40,
										flexDirection: 'row',
										justifyContent: 'space-between',
										flex: 1,
									}}
								>
									<View
										style={{
											marginRight: 12,
											flex: 1
										}}
									>
										<FieldSetTextInput
											placeholder={'Business Incorporation'}
											value={businessName.value}
											error={businessName.error}
											errorString={businessName.errorString}
											onChangeText={text => {
												setBusinessName({
													value: text,
													error: false,
													errorString: businessName.errorString
												});
											}}
										/>
									</View>
									<View
										style={{
											marginLeft: 12,
											flex: 1
										}}
									>
										<FieldSetTextInput
											placeholder={'TAX-ID'}
											value={taxId.value}
											error={taxId.error}
											errorString={taxId.errorString}
											onChangeText={text => {
												setTaxId({
													value: text,
													error: false,
													errorString: taxId.errorString
												});
											}}
										/>
									</View>
								</View>
								<View
									style={{
										backgroundColor: '#F5F4F3',
										padding: 8,
										marginTop: 40
									}}
								>
									<Text
										style={{
											color: '#6D6E6F'
										}}
									>Your basic profile information is already complete. You can complete your aditional info now or later under “Profiles” in Settings.</Text>
								</View>
								<View
									style={{
										marginTop: 64,
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'space-between'
									}}
								>
									<View/>
									<TouchableOpacity
										onPress={() => {
											if(isSubmitting) {
												return
											}
											setIsSubmitting(true);
											API.post('owner/studio-validate', {
												name: studioName.value,
												description: description.value,
												address: studioAddress.value,
												category_id: studioType.value,
												account_type: accountType.value,
												business_name: businessName.value,
												tax_id: taxId.value,
												lng: lng,
												lat: lat
											})
												.then(res => {
													setIsSubmitting(false);
													setStepIndex(2);
												}).catch (error => {
												setStudioName({
													value: studioName.value,
													error: error.response.data.errors['name'] !== undefined,
													errorString: error.response.data.errors['name'] !== undefined ? error.response.data.errors['name'][0] : 'Error'
												});
												setDescription({
													value: description.value,
													error: error.response.data.errors['description'] !== undefined,
													errorString: error.response.data.errors['description'] !== undefined ? error.response.data.errors['description'][0] : 'Error'
												});
												setStudioAddress({
													value: studioAddress.value,
													error: error.response.data.errors['address'] !== undefined,
													errorString: error.response.data.errors['address'] !== undefined ? error.response.data.errors['address'][0] : 'Error'
												});
												setStudioType({
													value: accountType.value,
													error: error.response.data.errors['category_id'] !== undefined,
													errorString: error.response.data.errors['category_id'] !== undefined ? error.response.data.errors['category_id'][0] : 'Error'
												});
												setAccountType({
													value: accountType.value,
													error: error.response.data.errors['account_type'] !== undefined,
													errorString: error.response.data.errors['account_type'] !== undefined ? error.response.data.errors['account_type'][0] : 'Error'
												});
												setBusinessName({
													value: businessName.value,
													error: error.response.data.errors['business_name'] !== undefined,
													errorString: error.response.data.errors['business_name'] !== undefined ? error.response.data.errors['business_name'][0] : 'Error'
												});
												setTaxId({
													value: taxId.value,
													error: error.response.data.errors['tax_id'] !== undefined,
													errorString: error.response.data.errors['tax_id'] !== undefined ? error.response.data.errors['tax_id'][0] : 'Error'
												});
												setIsSubmitting(false)
											});
										}}
										style={{
											backgroundColor: '#3DBA71',
											height: 48,
											justifyContent: 'center',
											alignItems: 'center',
											flexDirection: 'row',
											borderRadius: 6,
											paddingHorizontal: 24,
										}}
									>
										<Text
											style={{
												color: 'white',
												fontSize: 16,
												fontWeight: '600',
												marginRight: 16
											}}
										>Next</Text>
										{isSubmitting && <ActivityIndicator color={'white'}/>}
										{!isSubmitting && <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="m9 18 6-6-6-6"
												stroke="#fff"
												strokeWidth={2}
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>}
									</TouchableOpacity>
								</View>
							</>
						)}
						{stepIndex === 2 && (
							<>
								<Text
									style={{
										fontSize: 24,
										marginBottom: 24
									}}
								>Studio Photos</Text>
								<Text
									style={{
										color: '#6D6E6F',
										lineHeight: 20
									}}
								>Take photos with your phone or a camera. Upload at least one photo to publish your Studio. You can drag and reorder them however you like. You can always edit, remove or add new later.</Text>
								{studioArtworkFiles.length > 0 && (
									<View
										style={{
											marginTop: 40
										}}
									>
										<SortableImages
											items={studioArtworkFiles}
											onSortEnd={({oldIndex, newIndex}) => {
												let items = studioArtworkFiles;
												items = arrayMove(items, oldIndex, newIndex)
												setStudioArtworkFiles(items);
											}}
										/>
									</View>
								)}
								{studioArtworkFiles.length === 0 && (
									<View
										style={{
											marginTop: 40,
											flex: 1,
											borderWidth: 1,
											borderColor: '#D2D2D2'
										}}
									>
										<View
											style={{
												flexDirection: 'row',
												padding: 4
											}}
										>
											{[{}, {}, {}].map((item, index) => (
												<View
													key={index}
													style={{
														width: '33.33%',
														aspectRatio: 1.77,
														padding: 4,
														opacity: .2
													}}
												>
													<View
														style={{
															flex: 1,
															backgroundColor: '#97DEB4',
														}}
													/>
												</View>
											))}
										</View>
										<View
											style={{
												marginTop: -8,
												flexDirection: 'row',
												padding: 4
											}}
										>
											{[{}, {}, {}].map((item, index) => (
												<View
													key={index}
													style={{
														width: '33.33%',
														aspectRatio: 1.77,
														padding: 4,
														opacity: .2
													}}
												>
													<View
														style={{
															flex: 1,
															backgroundColor: '#97DEB4',
														}}
													/>
												</View>
											))}
										</View>
										<View
											style={{
												position: 'absolute',
												top: 0,
												left: 0,
												right: 0,
												bottom: 0,
												alignItems: 'center',
												justifyContent: 'center'
											}}
										>
											<View
												style={{
													backgroundColor: '#3DBA71',
													height: 40,
													justifyContent: 'center',
													alignItems: 'center',
													flexDirection: 'row',
													borderRadius: 6,
													paddingHorizontal: 24
												}}
											>
												<Text
													style={{
														color: 'white',
														fontWeight: '500'
													}}
												>Upload your photos and video</Text>
												<input
													style={{
														position: 'absolute',
														top: 0,
														left: 0,
														right: 0,
														bottom: 0,
														opacity: 0,
														cursor: 'pointer'
													}}
													type="file"
													onChange={studioArtworksSelectedHandler}
													accept="image/*"
													multiple
												/>
												<Text
													style={{
														position: 'absolute',
														bottom: -24,
														lineHeight: 20,
														color: '#484848'
													}}
												>Or drag them in here</Text>
											</View>
										</View>
									</View>
								)}
								<View
									style={{
										backgroundColor: '#F5F4F3',
										padding: 8,
										marginTop: 40
									}}
								>
									<Text
										style={{
											color: '#6D6E6F'
										}}
									>You can upload up to X photos and one video.</Text>
								</View>
								<View
									style={{
										marginTop: 64,
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'space-between'
									}}
								>
									<TouchableOpacity
										onPress={() => setStepIndex(0)}
										style={{
											borderWidth: 1,
											borderColor: '#6D6E6F',
											height: 48,
											justifyContent: 'center',
											alignItems: 'center',
											flexDirection: 'row',
											borderRadius: 6,
											paddingHorizontal: 24
										}}
									>
										<Text
											style={{
												color: '#6D6E6F',
												fontWeight: '500'
											}}
										>Previous</Text>
									</TouchableOpacity>
									<TouchableOpacity
										onPress={() => {
											if(studioArtworkFiles.length) {
												if(isSubmitting) {
													return
												}
												setIsSubmitting(true);
												const data = new FormData();

												for (let i = 0; i < studioArtworkFiles.length; i++) {
													data.append('studio_artworks[]', studioArtworkFiles[i])
												}
												API.post('owner/images-validate', data)
													.then(res => {
														setIsSubmitting(false);
														setStepIndex(3)
													}).catch (error => {
													let files = studioArtworkFiles;
													let removeIndex = []
													Object.keys(error.response.data.errors).forEach(function(key) {
														removeIndex.push(parseInt(key.replace( /^\D+/g, '')));
													});
													_.pullAt(files, removeIndex);
													toast.error('Some of your images was not mark our required and have been removed automatically, please upload others.');
													setIsSubmitting(false)
												});
											} else {
												toast.error('Please upload images');
											}
										}}
										style={{
											backgroundColor: '#3DBA71',
											height: 48,
											justifyContent: 'center',
											alignItems: 'center',
											flexDirection: 'row',
											borderRadius: 6,
											paddingHorizontal: 24
										}}
									>
										<Text
											style={{
												color: 'white',
												fontWeight: '500'
											}}
										>Next</Text>
										{isSubmitting && <ActivityIndicator style={{marginLeft: 8}} color={'white'}/>}
										{!isSubmitting && <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="m9 18 6-6-6-6"
												stroke="#fff"
												strokeWidth={2}
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>}
									</TouchableOpacity>
								</View>
							</>
						)}
						{stepIndex === 3 && (
							<>
								<Text
									style={{
										fontSize: 24,
										marginBottom: 24,
										fontWeight: '500'
									}}
								>Room info and rules</Text>
								<View
									style={{
										borderTopWidth: 1,
										borderColor: '#CDCDCD',
										paddingTop: 24
									}}
								>
									<Text
										style={{
											fontSize: 24,
											marginBottom: 40,
											color: '#6D6E6F',
											fontWeight: '500'
										}}
									>Room info</Text>
									<View
										style={{
											flexDirection: 'row',
											justifyContent: 'space-between',
										}}
									>
										<View
											style={{
												marginRight: 12,
												width: '70%'
											}}
										>
											<FieldSetTextInput
												placeholder={'Room name'}
												value={roomName.value}
												error={roomName.error}
												errorString={roomName.errorString}
												onChangeText={text => {
													setRoomName({
														value: text,
														error: false,
														errorString: roomName.errorString
													});
												}}
											/>
										</View>
										<View
											style={{
												flex: 1,
												marginLeft: 12,
											}}
										>
											<FieldSetTextInput
												maxLength={9}
												pattern="[+-]?\d+(?:[.,]\d+)?"
												placeholder={'Price per hour'}
												value={roomPrice.value}
												error={roomPrice.error}
												errorString={roomPrice.errorString}
												onChangeText={text => {
													let val = text;
													val = val.replace(/([^0-9.]+)/, "");
													val = val.replace(/^(0|\.)/, "");
													const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);
													const value = match[1] + match[2];
													setRoomPrice({
														value: value,
														error: false,
														errorString: roomPrice.errorString
													});
												}}
											/>
										</View>
									</View>
									<View
										style={{
											marginTop: 40
										}}
									>
										<FieldSetTextInput
											placeholder={'Description'}
											value={roomDescription.value}
											error={roomDescription.error}
											errorString={roomDescription.errorString}
											onChangeText={text => {
												setRoomDescription({
													value: text,
													error: false,
													errorString: roomDescription.errorString
												});
											}}
											multiline={true}
											numberOfLines={4}
										/>
										<View
											style={{
												alignItems: 'flex-end',
												marginTop: 8
											}}
										>
											<View
												style={{
													flexDirection: 'row'
												}}
											>
												<Text
													style={{
														color: '#4A535E'
													}}
												>{roomDescription.value.length}</Text>
												<Text
													style={{
														color: '#4A535E'
													}}
												>/300</Text>
											</View>
										</View>
									</View>
									<Text
										style={{
											fontSize: 24,
											marginTop: 40,
											color: '#6D6E6F',
											fontWeight: '500',
											marginBottom: 24
										}}
									>Room rules</Text>
									{roomRules.map((item, index) => (
										<View
											key={index}
											style={{
												flexDirection: 'row',
												alignItems: 'center',
												justifyContent: 'space-between',
												paddingVertical: 8
											}}
										>
											<Text
												style={{
													color: '#6D6E6F',
													fontWeight: '600'
												}}
											>{item.title}</Text>
											<TouchableOpacity
												onPress={() => {
													let rules = roomRules;
													rules.splice(index, 1);
													setRoomRules(prev => ([...rules]));
												}}
											>
												<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M18.707 5.293a1 1 0 0 1 0 1.414l-12 12a1 1 0 0 1-1.414-1.414l12-12a1 1 0 0 1 1.414 0Z"
														fill="#4F4F4F"
													/>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M5.293 5.293a1 1 0 0 1 1.414 0l12 12a1 1 0 0 1-1.414 1.414l-12-12a1 1 0 0 1 0-1.414Z"
														fill="#4F4F4F"
													/>
												</svg>
											</TouchableOpacity>
										</View>
									))}
								</View>
								<View
									style={{
										height: 1,
										backgroundColor: '#CDCDCD',
										marginVertical: 40
									}}
								/>
								<View
									style={{
										width: '70%'
									}}
								>
									<Text
										style={{
											fontSize: 20,
											color: '#6D6E6F',
											fontWeight: '500'
										}}
									>Additional rules</Text>
									<View
										style={{
											flexDirection: 'row',
											borderRadius: 8,
											borderWidth: 1,
											borderColor: '#D2D2D2',
											marginTop: 16,
											height: 64,
										}}
									>
										<View
											style={{
												flex: 1,
												flexDirection: 'row',
												justifyContent: 'space-between'
											}}
										>
											<TextInput
												placeholder={'Add additional rule'}
												style={{
													flex: 1,
													outline: 0,
													paddingHorizontal: 16,
													fontSize: 16
												}}
												value={additionRule}
												onChangeText={(text) => setAdditionRule(text)}
											/>
										</View>
										<TouchableOpacity
											onPress={() => {
												if(additionRule) {
													let item = {
														title: additionRule
													}
													let rules = roomRules;
													rules.push(item);
													setRoomRules(prev => ([...rules]));
													setAdditionRule('');
												}
											}}
											style={{
												backgroundColor: 'rgba(61,186,113,0.2)',
												justifyContent: 'center',
												alignItems: 'center',
												paddingHorizontal: 24
											}}
										>
											<Text
												style={{
													color: '#178545',
													fontWeight: '600'
												}}
											>Add</Text>
										</TouchableOpacity>
									</View>
									<View
										style={{
											backgroundColor: '#F5F4F3',
											padding: 8,
											marginTop: 16
										}}
									>
										<Text
											style={{
												color: '#6D6E6F'
											}}
										>Additional rules are pending of approval.</Text>
									</View>
								</View>
								<View
									style={{
										height: 1,
										backgroundColor: '#CDCDCD',
										marginVertical: 40
									}}
								/>
								<Text
									style={{
										fontSize: 24,
										color: '#6D6E6F',
										fontWeight: '500',
										marginBottom: 24
									}}
								>Equipments</Text>
								{roomEquipments.map((item, index) => (
									<View
										key={index}
										style={{
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: 'space-between',
											paddingVertical: 8
										}}
									>
										<View
											style={{
												flexDirection: 'row',
												alignItems: 'center',
												flex: 1
											}}
										>
											<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M20.707 5.293a1 1 0 0 1 0 1.414l-11 11a1 1 0 0 1-1.414 0l-5-5a1 1 0 1 1 1.414-1.414L9 15.586 19.293 5.293a1 1 0 0 1 1.414 0Z"
													fill="#3DBA71"
												/>
											</svg>
											<Text
												style={{
													marginLeft: 16,
													color: '#6D6E6F',
													fontWeight: '600'
												}}
											>{item.title}</Text>
										</View>
										<View
											style={{
												flexDirection: 'row',
												alignItems: 'center'
											}}
										>
											<Text
												style={{
													color: '#178545',
													fontWeight: '500',
													marginRight: 16,
												}}
											>{item.cost ? `$ ${item.cost}` : 'Free'}</Text>
											<TouchableOpacity
												onPress={() => {
													let equipments = roomEquipments;
													equipments.splice(index, 1);
													setRoomEquipments(prev => ([...equipments]));
												}}
											>
												<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M18.707 5.293a1 1 0 0 1 0 1.414l-12 12a1 1 0 0 1-1.414-1.414l12-12a1 1 0 0 1 1.414 0Z"
														fill="#4F4F4F"
													/>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M5.293 5.293a1 1 0 0 1 1.414 0l12 12a1 1 0 0 1-1.414 1.414l-12-12a1 1 0 0 1 0-1.414Z"
														fill="#4F4F4F"
													/>
												</svg>
											</TouchableOpacity>
										</View>
									</View>
								))}
								<View
									style={{
										width: '70%',
										marginTop: 40
									}}
								>
									<Text
										style={{
											fontSize: 20,
											color: '#6D6E6F',
											fontWeight: '500'
										}}
									>Add Equipment</Text>
									<View
										style={{
											flexDirection: 'row',
											borderRadius: 8,
											borderWidth: 1,
											borderColor: '#D2D2D2',
											marginTop: 16,
											height: 64,
										}}
									>
										<View
											style={{
												flex: 1,
												flexDirection: 'row',
												justifyContent: 'space-between'
											}}
										>
											<TextInput
												placeholder={'Add additional equipment'}
												style={{
													flex: 1,
													outline: 0,
													paddingHorizontal: 16,
													fontSize: 16
												}}
												value={additionEquipment}
												onChangeText={text => setAdditionEquipment(text)}
											/>
											<TextInput
												placeholder={'Additional cost: $ 0.00 '}
												style={{
													borderLeftWidth: 1,
													borderColor: '#AFABAC',
													flex: 1,
													outline: 0,
													paddingHorizontal: 16,
													fontSize: 16
												}}
												value={additionEquipmentPrice}
												onChangeText={text => {
													let val = text;
													val = val.replace(/([^0-9.]+)/, "");
													val = val.replace(/^(0|\.)/, "");
													const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);
													const value = match[1] + match[2];
													setAdditionEquipmentPrice(value);
												}}

											/>
										</View>
										<TouchableOpacity
											onPress={() => {
												if(additionEquipment && additionEquipmentPrice) {
													let item = {
														title: additionEquipment,
														cost: additionEquipmentPrice
													}
													let equipments = roomEquipments;
													equipments.push(item);
													setRoomEquipments(prev => ([...equipments]));
													setAdditionEquipment('');
													setAdditionEquipmentPrice('');
												}
											}}
											style={{
												backgroundColor: 'rgba(61,186,113,0.2)',
												justifyContent: 'center',
												alignItems: 'center',
												paddingHorizontal: 24
											}}
										>
											<Text
												style={{
													color: '#178545',
													fontWeight: '600'
												}}
											>Add</Text>
										</TouchableOpacity>
									</View>
									<View
										style={{
											backgroundColor: '#F5F4F3',
											padding: 8,
											marginTop: 16
										}}
									>
										<Text
											style={{
												color: '#6D6E6F'
											}}
										>Please note that Equipment can be free or charged per hour.</Text>
									</View>
								</View>
								<View
									style={{
										height: 1,
										backgroundColor: '#CDCDCD',
										marginVertical: 40
									}}
								/>
								<Text
									style={{
										fontSize: 24,
										color: '#6D6E6F',
										fontWeight: '500',
										marginBottom: 24
									}}
								>Amenities</Text>
								{roomAmenities.map((item, index) => (
									<View
										key={index}
										style={{
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: 'space-between',
											paddingVertical: 8
										}}
									>
										<View
											style={{
												flexDirection: 'row',
												alignItems: 'center',
												flex: 1
											}}
										>
											<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M20.707 5.293a1 1 0 0 1 0 1.414l-11 11a1 1 0 0 1-1.414 0l-5-5a1 1 0 1 1 1.414-1.414L9 15.586 19.293 5.293a1 1 0 0 1 1.414 0Z"
													fill="#3DBA71"
												/>
											</svg>
											<Text
												style={{
													marginLeft: 16,
													color: '#6D6E6F',
													fontWeight: '600'
												}}
											>{item.title}</Text>
										</View>
										<View
											style={{
												flexDirection: 'row',
												alignItems: 'center'
											}}
										>
											<Text
												style={{
													color: '#178545',
													fontWeight: '500',
													marginRight: 16
												}}
											>{item.cost ? `$ ${item.cost}` : 'Free'}</Text>
											<TouchableOpacity
												onPress={() => {
													let amenities = roomAmenities;
													amenities.splice(index, 1);
													setRoomAmenities(prev => ([...amenities]));
												}}
											>
												<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M18.707 5.293a1 1 0 0 1 0 1.414l-12 12a1 1 0 0 1-1.414-1.414l12-12a1 1 0 0 1 1.414 0Z"
														fill="#4F4F4F"
													/>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M5.293 5.293a1 1 0 0 1 1.414 0l12 12a1 1 0 0 1-1.414 1.414l-12-12a1 1 0 0 1 0-1.414Z"
														fill="#4F4F4F"
													/>
												</svg>
											</TouchableOpacity>
										</View>
									</View>
								))}
								<View
									style={{
										width: '70%',
										marginTop: 40
									}}
								>
									<Text
										style={{
											fontSize: 20,
											color: '#6D6E6F',
											fontWeight: '500'
										}}
									>Add Amenity</Text>
									<View
										style={{
											flexDirection: 'row',
											borderRadius: 8,
											borderWidth: 1,
											borderColor: '#D2D2D2',
											marginTop: 16,
											height: 64,
										}}
									>
										<View
											style={{
												flex: 1,
												flexDirection: 'row',
												justifyContent: 'space-between'
											}}
										>
											<TextInput
												placeholder={'Add additional amenity'}
												style={{
													flex: 1,
													outline: 0,
													paddingHorizontal: 16,
													fontSize: 16
												}}
												value={additionAmenity}
												onChangeText={text => setAdditionAmenity(text)}
											/>
											<TextInput
												placeholder={'Additional cost: $ 0.00 '}
												style={{
													borderLeftWidth: 1,
													borderColor: '#AFABAC',
													flex: 1,
													outline: 0,
													paddingHorizontal: 16,
													fontSize: 16
												}}
												value={additionAmenityPrice}
												onChangeText={text => {
													let val = text;
													val = val.replace(/([^0-9.]+)/, "");
													val = val.replace(/^(0|\.)/, "");
													const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);
													const value = match[1] + match[2];
													setAdditionAmenityPrice(value);
												}}

											/>
										</View>
										<TouchableOpacity
											onPress={() => {
												if(additionAmenity && additionAmenityPrice) {
													let item = {
														title: additionAmenity,
														cost: additionAmenityPrice
													}
													let amenities = roomAmenities;
													amenities.push(item);
													setRoomAmenities(prev => ([...amenities]));
													setAdditionAmenity('');
													setAdditionAmenityPrice('');
												}
											}}
											style={{
												backgroundColor: 'rgba(61,186,113,0.2)',
												justifyContent: 'center',
												alignItems: 'center',
												paddingHorizontal: 24
											}}
										>
											<Text
												style={{
													color: '#178545',
													fontWeight: '600'
												}}
											>Add</Text>
										</TouchableOpacity>
									</View>
									<View
										style={{
											backgroundColor: '#F5F4F3',
											padding: 8,
											marginTop: 16
										}}
									>
										<Text
											style={{
												color: '#6D6E6F'
											}}
										>Please note that amenities can be free or charged per hour.</Text>
									</View>
								</View>
								<View
									style={{
										marginTop: 64,
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'space-between',
									}}
								>
									<TouchableOpacity
										onPress={() => setStepIndex(2)}
										style={{
											borderWidth: 1,
											borderColor: '#6D6E6F',
											height: 48,
											justifyContent: 'center',
											alignItems: 'center',
											flexDirection: 'row',
											borderRadius: 6,
											paddingHorizontal: 24
										}}
									>
										<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="m15 18-6-6 6-6"
												stroke="#6D6E6F"
												strokeWidth={2}
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
										<Text
											style={{
												color: '#6D6E6F',
												fontWeight: '500',
												marginLeft: 16
											}}
										>Previous</Text>
									</TouchableOpacity>
									<TouchableOpacity
										onPress={() => {
											if(isSubmitting) {
												return
											}
											setIsSubmitting(true);
											API.post('owner/room-validate', {
												name: roomName.value,
												description: roomDescription.value,
												price: roomPrice.value,
												rules: roomRules,
												equipments: roomEquipments,
												amenities: roomAmenities,
											})
												.then(res => {
													setIsSubmitting(false);
													setStepIndex(4);
												}).catch (error => {
												setRoomName({
													value: roomName.value,
													error: error.response.data.errors['name'] !== undefined,
													errorString: error.response.data.errors['name'] !== undefined ? error.response.data.errors['name'][0] : 'Error'
												});
												setRoomDescription({
													value: roomDescription.value,
													error: error.response.data.errors['description'] !== undefined,
													errorString: error.response.data.errors['description'] !== undefined ? error.response.data.errors['description'][0] : 'Error'
												});
												setRoomPrice({
													value: roomPrice.value,
													error: error.response.data.errors['price'] !== undefined,
													errorString: error.response.data.errors['price'] !== undefined ? error.response.data.errors['price'][0] : 'Error'
												});

												setIsSubmitting(false)
											});
										}}
										style={{
											backgroundColor: '#3DBA71',
											height: 48,
											justifyContent: 'center',
											alignItems: 'center',
											flexDirection: 'row',
											borderRadius: 6,
											paddingHorizontal: 24
										}}
									>
										<Text
											style={{
												color: 'white',
												fontWeight: '500',
												marginRight: 16
											}}
										>Next</Text>
										{isSubmitting && <ActivityIndicator color={'white'}/>}
										{!isSubmitting && <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="m9 18 6-6-6-6"
												stroke="#fff"
												strokeWidth={2}
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>}
                  </TouchableOpacity>
								</View>
							</>
						)}
						{stepIndex === 4 && (
							<>
								<Text
									style={{
										fontSize: 24,
										marginBottom: 24
									}}
								>Room Photos</Text>
								<Text
									style={{
										color: '#6D6E6F',
										lineHeight: 20
									}}
								>Take photos with your phone or a camera. Upload at least one photo to publish your Studio's room. You can drag and reorder them however you like. You can always edit, remove or add new later.</Text>
								{roomArtworkFiles.length > 0 && (
									<View
										style={{
											marginTop: 40
										}}
									>
										<SortableImages
											items={roomArtworkFiles}
											onSortEnd={({oldIndex, newIndex}) => {
												let items = roomArtworkFiles;
												items = arrayMove(items, oldIndex, newIndex)
												setRoomArtworkFiles(items);
											}}
										/>
									</View>
								)}
								{roomArtworkFiles.length === 0 && (
									<View
										style={{
											marginTop: 40,
											flex: 1,
											borderWidth: 1,
											borderColor: '#D2D2D2'
										}}
									>
										<View
											style={{
												flexDirection: 'row',
												padding: 4
											}}
										>
											{[{}, {}, {}].map((item, index) => (
												<View
													key={index}
													style={{
														width: '33.33%',
														aspectRatio: 1.77,
														padding: 4,
														opacity: .2
													}}
												>
													<View
														style={{
															flex: 1,
															backgroundColor: '#97DEB4',
														}}
													/>
												</View>
											))}
										</View>
										<View
											style={{
												marginTop: -8,
												flexDirection: 'row',
												padding: 4
											}}
										>
											{[{}, {}, {}].map((item, index) => (
												<View
													key={index}
													style={{
														width: '33.33%',
														aspectRatio: 1.77,
														padding: 4,
														opacity: .2
													}}
												>
													<View
														style={{
															flex: 1,
															backgroundColor: '#97DEB4',
														}}
													/>
												</View>
											))}
										</View>
										<View
											style={{
												position: 'absolute',
												top: 0,
												left: 0,
												right: 0,
												bottom: 0,
												alignItems: 'center',
												justifyContent: 'center'
											}}
										>
											<View
												style={{
													backgroundColor: '#3DBA71',
													height: 40,
													justifyContent: 'center',
													alignItems: 'center',
													flexDirection: 'row',
													borderRadius: 6,
													paddingHorizontal: 24
												}}
											>
												<Text
													style={{
														color: 'white',
														fontWeight: '500'
													}}
												>Upload your photos and video</Text>
												<input
													style={{
														position: 'absolute',
														top: 0,
														left: 0,
														right: 0,
														bottom: 0,
														opacity: 0,
														cursor: 'pointer'
													}}
													type="file"
													onChange={roomArtworksSelectedHandler}
													accept="image/*"
													multiple
												/>
												<Text
													style={{
														position: 'absolute',
														bottom: -24,
														lineHeight: 20,
														color: '#484848'
													}}
												>Or drag them in here</Text>
											</View>
										</View>
									</View>
								)}
								<View
									style={{
										backgroundColor: '#F5F4F3',
										padding: 8,
										marginTop: 40
									}}
								>
									<Text
										style={{
											color: '#6D6E6F'
										}}
									>You can upload up to X photos and one video.</Text>
								</View>
								<View
									style={{
										marginTop: 64,
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'space-between'
									}}
								>
									<TouchableOpacity
										onPress={() => setStepIndex(3)}
										style={{
											borderWidth: 1,
											borderColor: '#6D6E6F',
											height: 48,
											justifyContent: 'center',
											alignItems: 'center',
											flexDirection: 'row',
											borderRadius: 6,
											paddingHorizontal: 24
										}}
									>
										<Text
											style={{
												color: '#6D6E6F',
												fontWeight: '500'
											}}
										>Previous</Text>
									</TouchableOpacity>
									<TouchableOpacity
										onPress={() => {
											if(roomArtworkFiles.length) {
												if(isSubmitting) {
													return
												}
												setIsSubmitting(true);
												const data = new FormData();
												data.append('name', studioName.value);
												data.append('description', description.value);
												data.append('address', studioAddress.value);
												data.append('category_id', studioType.value);
												data.append('room_name', roomName.value);
												data.append('room_price', roomPrice.value);
												data.append('room_description', roomDescription.value);

												for (let i = 0; i < roomRules.length; i++) {
													data.append('rules[]', JSON.stringify(roomRules[i]));
												}

												for (let i = 0; i < roomAmenities.length; i++) {
													data.append('amenities[]', JSON.stringify(roomAmenities[i]));
												}

												for (let i = 0; i < roomEquipments.length; i++) {
													data.append('equipments[]', JSON.stringify(roomEquipments[i]));
												}

												for (let i = 0; i < roomArtworkFiles.length; i++) {
													data.append('room_artworks[]', roomArtworkFiles[i])
												}

												for (let i = 0; i < studioArtworkFiles.length; i++) {
													data.append('studio_artworks[]', studioArtworkFiles[i])
												}

												API.post('owner/create-studio', data)
													.then(res => {
														navigation.navigate('SettingsScreen');
													}).catch (error => {

													let files = roomArtworkFiles;
													let removeIndex = []
													Object.keys(error.response.data.errors).forEach(function(key) {
														if(key.includes('room_artworks')) {
															removeIndex.push(parseInt(key.replace( /^\D+/g, '')));
														}
													});
													_.pullAt(files, removeIndex);
													toast.error('Some of your images was not mark our required and have been removed automatically, please upload others.');
													setIsSubmitting(false);
												});
											} else {
												toast.error('Please upload images');
											}
										}}
										style={{
											backgroundColor: '#3DBA71',
											height: 48,
											justifyContent: 'center',
											alignItems: 'center',
											flexDirection: 'row',
											borderRadius: 6,
											paddingHorizontal: 24
										}}
									>
										<Text
											style={{
												color: 'white',
												fontWeight: '500'
											}}
										>I'm Finish</Text>
										{isSubmitting && <ActivityIndicator color={'white'}/>}
									</TouchableOpacity>
								</View>
							</>
						)}
					</View>
					<View
						style={{
							flex: 1,
							marginLeft: 16,
							backgroundColor: 'white',
							borderRadius: 8,
							shadowColor: "#000",
							shadowOffset: {
								width: 0,
								height: 2,
							},
							shadowOpacity: 0.05,
							shadowRadius: 3.84,
							elevation: 5,
							padding: 16
						}}
					>
						<Text>Stugo tips</Text>
						<Text>Be short and concise on your description. Try to catch your client’s attention with good and helpful info about what your studio provides.</Text>
					</View>
				</View>
			</View>
		</ScrollView>
	);
}

export default connect(({auth}) => ({auth}))(InitializationScreen);
