import React  from "react";
import {
	TouchableOpacity,
	ImageBackground,
	StyleSheet,
	Text,
	View,
	StatusBar,
} from "react-native";
import PropTypes from "prop-types";
import AppStyles from "../../AppStyles";
import {connect} from "react-redux";
import { SvgXml } from "react-native-svg";
import {SafeAreaView} from "react-native-safe-area-context";
import Icon from "react-native-vector-icons/dist/Feather";
import {useTheme} from "@react-navigation/native";

const AccountTypeScreen = (props) => {
	const {colors, dark} = useTheme()
	console.log(props.auth);
	return (
		<View
			style={{
				position: 'absolute',
				flex: 1,
				width: '100%',
				height: '100vh',
				margin: 0,
				padding: 0,
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: '#1D1F1E'
			}}
		>

			<ImageBackground
				source={require('../../assets/images/account_type_bg.jpeg')}
				resizeMode="cover"
				style={{
					...StyleSheet.absoluteFill
				}}
			/>


			<View
				style={{
					maxWidth: 500
				}}
			>
				<View
					style={{
						marginBottom: 16
					}}
				>
					<TouchableOpacity
						onPress={() => props.navigation.goBack()}
						style={{
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M14.8625 3.725L13.3791 2.25L5.13745 10.5L13.3875 18.75L14.8625 17.275L8.08745 10.5L14.8625 3.725Z" fill="white"/>
						</svg>
						<Text
							style={{
								marginLeft: 8,
								color: colors.whiteColor
							}}
						>Back</Text>
					</TouchableOpacity>
				</View>
				<View
					style={{
						backgroundColor: dark ? '#1D1F1E' : '#f3f3f3',
						padding: 24,
						borderRadius: 8,
						justifyContent: 'center',
					}}
				>
					<Text
						style={{
							color: '#3DBA71',
							fontSize: 34,
							marginBottom: 24,
							fontWeight: '600',
						}}
					>Join Us!</Text>
					<Text
						style={{
							fontSize: 20,
							marginBottom: 24,
							color: '#6D6E6F'
						}}
					>To begin on Stugo, tell us what type of account you’d be opening.</Text>
					<TouchableOpacity
						onPress={() => props.navigation.navigate("UserInfoScreen")}
						style={{
							flexDirection: 'row',
							paddingTop: 24,
							paddingBottom: 24,
							paddingLeft: 16,
							paddingRight: 16,
							borderWidth: 1,
							borderColor: dark ? '#424244' : 'transparent',
							borderRadius: 6,
							backgroundColor: dark ? 'transparent' : 'white'
						}}
					>
						<View
							style={{
								width: 52,
								height: 52,
								borderRadius: 26,
								justifyContent: 'center',
								alignItems: 'center',
								marginRight: 24,
								backgroundColor: dark ? '#353739' : '#F4F2F1',
							}}
						>
							<svg width={24} height={25} fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="m19 9.21-5.333-4.148a2.666 2.666 0 0 0-3.274 0L5.059 9.21a2.665 2.665 0 0 0-1.029 2.105v7.2a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-7.2c0-.823-.38-1.6-1.03-2.105Z"
									stroke="#212429"
									strokeWidth={1.5}
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M16 15.5c-2.21 1.333-5.792 1.333-8 0"
									stroke="#212429"
									strokeWidth={1.5}
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</View>
						<View
							style={{
								flex: 1,
							}}
						>
							<Text
								style={{
									color: colors.mainTextColor,
									fontWeight: '700',
									fontSize: 16,
									marginBottom: 8
								}}
							>Add my studio to StuGo</Text>
							<Text
								style={{
									color: colors.mainSubtextColor,
								}}
							>Description of the label content to give the user a bit of context.</Text>
						</View>
					</TouchableOpacity>
					<Text
						style={{
							color: colors.mainSubtextColor,
							marginTop: 8,
							marginBottom: 8,
							textAlign: 'center',
							fontWeight: '600'
						}}
					>or</Text>
					<TouchableOpacity
						onPress={() => props.navigation.navigate("DownloadAppScreen")}
						style={{
							flexDirection: 'row',
							paddingTop: 24,
							paddingBottom: 24,
							paddingLeft: 16,
							paddingRight: 16,
							borderWidth: 1,
							borderColor: dark ? '#5DA283' : 'transparent',
							borderRadius: 6,
							backgroundColor: dark ? 'transparent' : 'white'
						}}
					>
						<View
							style={{
								width: 52,
								height: 52,
								borderRadius: 26,
								backgroundColor: dark ? '#3DBA71' : '#F4F2F1',
								justifyContent: 'center',
								alignItems: 'center',
								marginRight: 24
							}}
						>
							<svg width={24} height={25} fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M15 5.5v2M15 11.5v2M15 17.5v2M5 5.5h14a2 2 0 0 1 2 2v3a2 2 0 0 0 0 4v3a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-3a2 2 0 1 0 0-4v-3a2 2 0 0 1 2-2"
									stroke="#212429"
									strokeWidth={1.5}
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</View>
						<View
							style={{
								flex: 1,
							}}
						>
							<Text
								style={{
									color: colors.mainTextColor,
									fontWeight: '700',
									fontSize: 16,
									marginBottom: 8
								}}
							>I want to book a studio</Text>
							<Text
								style={{
									color: colors.mainSubtextColor,
								}}
							>Description of the label content to give the user a bit of context.</Text>
						</View>
					</TouchableOpacity>
				</View>
			</View>
		</View>
	);
}

export default connect(({auth}) => ({auth}))(AccountTypeScreen);
