import React, {useState} from "react";
import {Text, View, TouchableOpacity, ImageBackground, ActivityIndicator} from "react-native";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import API from "../../helpers/Axios";
import FieldSetTextInput from "../../components/FieldSetTextInput";
import AsyncStorage from "@react-native-async-storage/async-storage";

const ResetPasswordScreen = ({navigation, route, dispatch}) => {
	const [code, setCode] = useState({
		value: '',
		error: false,
		errorString: null
	});

	const [isSubmitting, setIsSubmitting] = useState( false);

	const onSubmit = () => {
		if(isSubmitting) {
			return
		}

		API.post('verify-forgot-code', {
			email: route.params?.email,
			code: code.value,
		})
			.then(res => {
				setIsSubmitting(false);
				navigation.replace("SetNewPasswordScreen", {email: route.params?.email, code: code.value});
			}).catch (error => {
				setIsSubmitting(false)
				setCode({
					value: code.value,
					error: error.response.data.errors['code'] !== undefined,
					errorString: error.response.data.errors['code'] !== undefined ? error.response.data.errors['code'][0] : 'Error'
				});
		});
	}

	return (
		<ImageBackground
			source={{uri: '/assets/splash/1.jpeg'}}
			style={{
				position: 'absolute',
				flex: 1,
				width: '100%',
				height: '100vh',
				margin: 0,
				padding: 0,
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: '#1D1F1E',
			}}
		>
			<View
				style={{
					position: 'absolute',
					backgroundColor: 'rgba(29, 31, 30, 0.4)',
					top: 0,
					right: 0,
					left: 0,
					bottom: 0
				}}
			/>
			<View
				style={{
					maxWidth: 450
				}}
			>
				<View
					style={{
						marginBottom: 16,
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between'
					}}
				>
					<TouchableOpacity
						onPress={() => navigation.goBack()}
					>
						<Text
							style={{
								color: 'white'
							}}
						>Back</Text>
					</TouchableOpacity>
				</View>
				<View
					style={{
						padding: 48,
						backgroundColor: 'white',
						borderRadius: 8,
					}}
				>
					<Text
						style={{
							color: '#3DBA71',
							fontSize: 34,
							marginBottom: 24,
							fontWeight: '600',
						}}
					>Enter code to reset password</Text>
					<Text
						style={{
							fontSize: 20,
							marginBottom: 24,
							color: '#6D6E6F'
						}}
					>We have emailed you a code to to reset your password, please check your mail box.</Text>
					<View
						style={{
							marginTop: 12
						}}
					>
						<View>
							<FieldSetTextInput
								placeholder={'Code'}
								value={code.value}
								error={code.error}
								errorString={code.errorString}
								onChangeText={text => {
									setCode({
										value: text,
										error: false,
										errorString: null
									});
								}}
							/>
						</View>
					</View>
					<TouchableOpacity
						onPress={() =>  {
							setIsSubmitting(true);
							onSubmit();
						}}
						style={{
							backgroundColor: '#3DBA71',
							alignItems: 'center',
							justifyContent: 'center',
							borderRadius: 4,
							height: 48,
							marginTop: 40
						}}
					>
						{isSubmitting && <ActivityIndicator color={'white'}/>}
						{!isSubmitting && <Text
							style={{
								color: 'white',
								fontSize: 16,
								fontWeight: '600'
							}}
						>Continue</Text>}
					</TouchableOpacity>
				</View>
			</View>
		</ImageBackground>
	)
}

ResetPasswordScreen.propTypes = {
	navigation: PropTypes.object,
};

export default connect(({auth}) => ({auth}))(ResetPasswordScreen);