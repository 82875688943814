import React, {useEffect, useMemo, useState} from "react";
import {
	Text,
	View,
	Modal, ScrollView, ImageBackground, Image, ActivityIndicator, Button, TextInput, StyleSheet
} from "react-native";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import API from "../../helpers/Axios";
import {TouchableOpacity} from "react-native-web";
import moment from 'moment';
import useModal from '../../helpers/useModal';
import EditStudioScreen from '../../screens/modals/EditStudioScreen';
import EditPaymentScreen from '../../screens/modals/EditPaymentScreen';
import EditPicturesScreen from "../modals/EditPicturesScreen";
import {toast} from "react-toastify";
import {Popover} from "react-tiny-popover";
import {countriesArray} from "../../helpers/CountryCode";
import AppStyles from "../../AppStyles";
import StudioStatus from "../../components/StudioStatus";
import UserStatus from "../../components/UserStatus";

function ReportsScreen ({navigation, route}) {
	const [tabIndex, setTabIndex] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const [isDisableLoading, setIsDisableLoading] = useState(false);
	const [studios, setStudios] = useState([]);
	const [studiosData, setStudiosData] = useState(null);
	const [isStudiosLoading, setIsStudiosLoading] = useState(true);

	const [users, setUsers] = useState([]);
	const [usersData, setUsersData] = useState(null);
	const [isUsersLoading, setUsersIsLoading] = useState(true);

	const [currentStudio, setCurrentStudio] = useState(null);
	const [menuDisplay, setMenuDisplay] = useState( false);

	const [isVisible, setIsVisible] = useState(false);
	const [isPaymentVisible, setIsPaymentVisible] = useState(false);
	const [isImageEditorVisible, setIsImageEditorVisible] = useState(false);
	const [isDisableItemVisible, setIsDisableItemVisible] = useState(false);
	const [isActive, setIsActive] = useState(null);
	const [isDeleteItemVisible, setIsDeleteItemVisible] = useState(false);
	const [isDeleteLoading, setIsDeleteLoading] = useState(false);

	useEffect(() => {
		if(currentStudio) {
			setIsActive(currentStudio.active);
		}
	}, [currentStudio]);

	const deleteStudio = (id) => {
		if(isDeleteLoading) {
			return;
		}
		setIsDeleteLoading(true);
		API.post('owner/delete-studio', {
			id: id
		}).then(res => {
			setIsDeleteLoading(false);
			setIsDeleteItemVisible(false);
			let data = studios;
			data = data.filter(x => {
				return x.id !== id;
			});
			setStudios(data);
			if(data.length) {
				setCurrentStudio(data[0]);
			} else {
				navigation.navigate('NewStudioScreen')
			}
		}).catch (error => {
			console.log(error);
		});
	}

	const getUsers = (url = `admin/users`) => {
		API.post(url)
			.then(res => {
				setUsers(res.data.data);
				setUsersData(res.data);
				setUsersIsLoading(false);
			}).catch (error => {
			console.log(error);
		});
	}

	const getStudios = (url = `admin/studios`) => {
		API.post(url)
			.then(res => {
				setStudiosData(res.data);
				setIsStudiosLoading(false);
			}).catch (error => {
			console.log(error);
		});
	}

	useEffect(() => {
		getUsers();
		getStudios();

	}, []);

	return (
		<View
			style={{
				flex: 1
			}}
		>
			<Modal onRequestClose={() => setIsDeleteItemVisible(false)} transparent visible={isDeleteItemVisible}>
				{currentStudio && (
					<View
						style={{
							backgroundColor: 'white',
							borderColor: '#eee',
							borderRadius: 8,
							borderWidth: 1,
							margin: 'auto',
							shadowColor: "#000",
							shadowOffset: {
								width: 0,
								height: 2,
							},
							shadowOpacity: 0.05,
							shadowRadius: 3.84,
							elevation: 5,
							padding: 24,
							maxWidth: 550
						}}
					>
						<Text
							style={{
								textAlign: 'center',
								fontSize: 24,
								fontWeight: '600',
								marginBottom: 24
							}}
						>Are you sue want to delete this studio</Text>
						<Text
							style={{
								color: '#595959',
								fontSize: 16,
								lineHeight: 22,
								textAlign: 'center'
							}}
						>Are you sure you want to permanently delete this studio? You can not under this action.</Text>
						<View
							style={{
								marginTop: 24,
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'space-between'
							}}
						>
							<TouchableOpacity
								onPress={() => {
									setMenuDisplay(false);
									setIsDeleteItemVisible(false);
									setIsDeleteLoading(false);
								}}
								style={{
									height: 48,
									borderWidth: 1,
									borderColor: '#E4E1E0',
									borderRadius: 8,
									alignItems: 'center',
									justifyContent: 'center',
									flex: 1,
									marginRight: 8
								}}
							>
								<Text
									style={{
										fontWeight: '600',
										fontSize: 16
									}}
								>Cancel</Text>
							</TouchableOpacity>
							<TouchableOpacity
								onPress={() => {
									deleteStudio(currentStudio.id);
								}}
								style={{
									height: 48,
									backgroundColor: '#E55A5A',
									borderRadius: 8,
									alignItems: 'center',
									justifyContent: 'center',
									flex: 1,
									marginLeft: 8,
									flexDirection: 'row'
								}}
							>
								<Text
									style={{
										fontWeight: '600',
										fontSize: 16,
										color: 'white',
									}}
								>Delete studio</Text>
								{isDeleteLoading && <ActivityIndicator color={'white'} style={{marginLeft: 8}}/>}
							</TouchableOpacity>
						</View>
					</View>
				)}
			</Modal>
			<Modal onRequestClose={() => setIsVisible(false)} transparent visible={isVisible}>
				<View
					style={{
						backgroundColor: 'white',
						borderColor: '#eee',
						borderRadius: 8,
						borderWidth: 1,
						margin: 'auto',
						minWidth: 780,
						shadowColor: "#000",
						shadowOffset: {
							width: 0,
							height: 2,
						},
						shadowOpacity: 0.05,
						shadowRadius: 3.84,
						elevation: 5,
					}}
				>
					<EditStudioScreen
						setIsVisible={setIsVisible}
						item={currentStudio}
						setCurrentStudio={setCurrentStudio}
					/>
				</View>
			</Modal>
			<Modal onRequestClose={() => setIsPaymentVisible(false)} transparent visible={isPaymentVisible}>
				<View
					style={{
						backgroundColor: 'white',
						borderColor: '#eee',
						borderRadius: 8,
						borderWidth: 1,
						margin: 'auto',
						minWidth: 780,
						shadowColor: "#000",
						shadowOffset: {
							width: 0,
							height: 2,
						},
						shadowOpacity: 0.05,
						shadowRadius: 3.84,
						elevation: 5,
					}}
				>
					<EditPaymentScreen
						setIsVisible={setIsPaymentVisible}
					/>
				</View>
			</Modal>
			<Modal onRequestClose={() => setIsImageEditorVisible(false)} transparent visible={isImageEditorVisible}>
				<View
					style={{
						backgroundColor: 'white',
						borderColor: '#eee',
						borderRadius: 8,
						borderWidth: 1,
						margin: 'auto',
						minWidth: 780,
						shadowColor: "#000",
						shadowOffset: {
							width: 0,
							height: 2,
						},
						shadowOpacity: 0.05,
						shadowRadius: 3.84,
						elevation: 5,
					}}
				>
					<EditPicturesScreen
						item={currentStudio}
						setIsVisible={setIsImageEditorVisible}
						type={'studio'}
					/>
				</View>
			</Modal>
			<ScrollView
				style={{
					flex: 1,
					padding: 40
				}}
			>
				<Text
					style={{
						fontSize: 48,
						fontWeight: '600'
					}}
				>Issue reports </Text>
				<Text
					style={{
						marginTop: 16,
						fontSize: 20,
						color: '#595959'
					}}
				>Overview of the reports that happened on the platform.</Text>

			</ScrollView>
		</View>
	);
}

const styles = StyleSheet.create({
	tableHeader: {
		fontWeight: '600',
		textAlign: 'left',
		alignSelf: 'flex-start'
	},
	tableCell: {
		borderBottom: '1px solid',
		borderBottomColor: 'gray',
		paddingLeft: 16,
		paddingRight: 16
	},
});

ReportsScreen.propTypes = {
	navigation: PropTypes.object,
};

export default connect(({auth}) => ({auth}))(ReportsScreen);
