import React, {useEffect, useState} from "react";
import {
	Text,
	View,
	ScrollView,
	StyleSheet,
	Image,
	ActivityIndicator,
	Modal,
	TouchableOpacity,
} from "react-native";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import API from "../../helpers/Axios";
import '../../styles/calendar.css';
import moment from "moment";
import AppStyles from "../../AppStyles";
import StudioStatus from "../../components/StudioStatus";
import StudioDataScreen from "../modals/StudioDataScreen";
import BookingDetailsScreen from "../modals/BookingDetailsScreen";

const PayoutsScreen = ({ navigation }) =>  {
	const [currentBooking, setCurrentBooking] = useState(null);
	const [isDetailsVisible, setIsDetailsVisible] = useState(false);
	const [payoutsData, setPayoutsData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	const getStudios = (url = `admin/payouts`) => {
		API.post(url).then(res => {
			setIsLoading(false);
			setPayoutsData(res.data);
		}).catch (error => {
			console.log(error);
		});
	}

	useEffect(() => {
		getStudios();
	}, []);

	return isLoading ? (<ActivityIndicator style={{flex: 1, justifyContent: 'center', alignItems: 'center', marginVertical: 64}} />) : (
		<View
			style={{
				flex: 1
			}}
		>
			<Modal onRequestClose={() => setIsDetailsVisible(false)} transparent visible={isDetailsVisible}>
				<View
					style={{
						backgroundColor: 'white',
						borderColor: '#eee',
						borderRadius: 8,
						borderWidth: 1,
						margin: 'auto',
						width: 900,
						shadowColor: "#000",
						shadowOffset: {
							width: 0,
							height: 2,
						},
						shadowOpacity: 0.05,
						shadowRadius: 3.84,
						elevation: 5,
					}}
				>
					<BookingDetailsScreen
						item={currentBooking}
						setIsVisible={setIsDetailsVisible}
						type={'studio'}
					/>
				</View>
			</Modal>
			<ScrollView
				style={{
					flex: 1,
					padding: 40
				}}
			>
				<Text
					style={{
						fontSize: 48,
						fontWeight: '600'
					}}
				>Payouts</Text>
				<Text
					style={{
						marginTop: 16,
						fontSize: 20,
						color: '#595959'
					}}
				>Overview about upcoming payouts.</Text>
				<View
					style={{
						marginTop: 40
					}}
				>
					<View
						style={{
							backgroundColor: 'white',
							shadowColor: "#000",
							shadowOffset: {
								width: 0,
								height: 2,
							},
							shadowOpacity: 0.05,
							shadowRadius: 3.84,
							elevation: 5,
							borderRadius: 8
						}}
					>
						<View
							style={{
							}}
						>
							<View
								style={{
									borderBottomWidth: 2,
									borderColor: '#096730',
									alignSelf: 'flex-start',
									paddingHorizontal: 16
								}}
							>
								<Text
									style={{
										fontWeight: '600',
										fontSize: 16,
										color: '#096730',
										lineHeight: 40
									}}
								>All Studios</Text>
							</View>
						</View>
						<table
							style={{
								flex: 1,
								width: '100%',
								borderCollapse: 'collapse'
							}}
						>
							<tr
								style={{
									backgroundColor: '#F8F8F8',
									height: 48
								}}
							>
								<th></th>
								<th
									style={{
										textAlign: 'left',
										paddingLeft: 16,
										paddingRight: 16
									}}
								>
									<Text
										style={styles.tableHeader}
									>Studio Name</Text>
								</th>
								<th
									style={{
										textAlign: 'left',
										paddingLeft: 16,
										paddingRight: 16
									}}
								>
									<Text
										style={styles.tableHeader}
									>Amount</Text>
								</th>
								<th
									style={{
										textAlign: 'left',
										paddingLeft: 16,
										paddingRight: 16
									}}
								>
									<Text
										style={styles.tableHeader}
									>Created at</Text>
								</th>
								<th
									style={{
										textAlign: 'left',
										paddingLeft: 16,
										paddingRight: 16
									}}
								>
									<Text
										style={styles.tableHeader}
									>Issued at</Text>
								</th>
								<th
									style={{
										textAlign: 'left',
										paddingLeft: 16,
										paddingRight: 16
									}}
								>
									<Text
										style={styles.tableHeader}
									>Status</Text>
								</th>
							</tr>
							{payoutsData && payoutsData.data.map((item, index) => (
								<tr
									key={index}
									style={{
										height: 64,
										cursor: 'pointer'
									}}
									onClick={() => {
										setCurrentBooking(item);
										setIsDetailsVisible(true);
									}}
								>
									<td
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Image
											style={{
												borderRadius: 16,
												marginRight: 16,
												width: 32,
												height: 32,
											}}
											source={{
												uri: item.user.artwork_url
											}}
										/>
									</td>
									<td
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<View>
											<Text
												style={{
													color: '#909090',
													marginTop: 4,
													fontWeight: '600'
												}}
											>{item.booking.studio.name}</Text>
											<Text

											>{item.user && item.user.email}</Text>
										</View>
									</td>
									<td
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Text
											style={{
												fontWeight: '600'
											}}
										>${item.amount}</Text>
									</td>
									<td
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Text>{moment.utc(item.created_at).local().format("MMM D, YYYY")}</Text>
									</td>
									<td
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Text>{moment.utc(item.issued_at).local().format("MMM D, YYYY hh:mm a")}</Text>
									</td>
									<td
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										{item.status === 0 && (
											<View
												style={{
													height: 26,
													borderRadius: 13,
													backgroundColor: '#EBE8E7',
													justifyContent: 'center',
													alignItems: 'center',
													width: 'fit-content',
													alignSelf: 'flex-start',
													paddingHorizontal: 8
												}}
											>
												<Text
													style={{
														fontWeight: '600',
														fontSize: 12,
													}}
												>{'Processing'}</Text>
											</View>
										)}
										{item.status === 1 && (
											<View
												style={{
													height: 26,
													borderRadius: 13,
													backgroundColor: '#3DBA71',
													justifyContent: 'center',
													alignItems: 'center',
													width: 'fit-content',
													alignSelf: 'flex-start',
													paddingHorizontal: 8
												}}
											>
												<Text
													style={{
														fontWeight: '600',
														fontSize: 12,
														color: 'white',
													}}
												>{'Paid'}</Text>
											</View>
										)}
									</td>
								</tr>
							))}
						</table>
						{payoutsData && (
							<View
								style={{
									backgroundColor: '#F8F8F8',
									height: 48,
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'flex-end',
									paddingHorizontal: 24
								}}
							>
								<View
									style={{
										marginRight: 40,
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'space-between',
									}}
								>
									<Text
										style={{
											fontWeight: '600',
											marginRight: 8
										}}
									>Rows per page: {payoutsData.per_page}</Text>
									<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M12 14 8 9h8l-4 5Z" fill="#6D6E6F" />
									</svg>
								</View>
								<Text
									style={{
										fontWeight: '600'
									}}
								>{payoutsData.from}- {payoutsData.to} </Text>
								<View
									style={{
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'space-between',
										width: 80,
										marginLeft: 40
									}}
								>
									<TouchableOpacity
										onPress={() => {
											if(!payoutsData.prev_page_url) {
												return;
											}
											setIsLoading(true);
											getStudios(payoutsData.prev_page_url);
										}}
									>
										<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M14.8 8.106c.628-.593-.367-1.533-.995-.89L9.196 11.52a.66.66 0 0 0 0 .94l4.609 4.352c.628.593 1.623-.346.995-.94l-4.085-3.857L14.8 8.106Z"
												fill={payoutsData.prev_page_url ? '#6D6E6F' : '#ccc'}
											/>
										</svg>
									</TouchableOpacity>
									{isLoading && (
										<ActivityIndicator color={'#096730'} />
									)}
									<TouchableOpacity
										onPress={() => {
											if(!payoutsData.next_page_url) {
												return;
											}
											setIsLoading(true);
											getStudios(payoutsData.next_page_url);
										}}
									>
										<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M9.2 8.106c-.628-.593.367-1.533.995-.89l4.609 4.303a.66.66 0 0 1 0 .94l-4.609 4.352c-.628.593-1.623-.346-.995-.94l4.085-3.857L9.2 8.106Z"
												fill={payoutsData.next_page_url ? '#6D6E6F' : '#ccc'}
											/>
										</svg>
									</TouchableOpacity>
								</View>
							</View>
						)}
					</View>
				</View>
			</ScrollView>
		</View>
	);
}

const styles = StyleSheet.create({
	tableHeader: {
		fontWeight: '600',
		textAlign: 'left',
		alignSelf: 'flex-start'
	},
	tableCell: {
		borderBottom: '1px solid',
		borderBottomColor: 'gray',
		paddingLeft: 16,
		paddingRight: 16
	},
});

PayoutsScreen.propTypes = {
	navigation: PropTypes.object,
};

export default connect(({auth}) => ({auth}))(PayoutsScreen);