import React, {useEffect, useState} from "react";
import {
	Text,
	View,
	ScrollView,
	StyleSheet,
	Image,
	ActivityIndicator,
	Modal,
	ImageBackground,
	TouchableOpacity,
	TouchableOpacityComponent,
	TextInput
} from "react-native";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import API from "../../helpers/Axios";
import Calendar from 'react-calendar';
import StarRatings from 'react-star-ratings';
import '../../styles/calendar.css';
import moment from "moment";
import ReviewsScreen from "../modals/ReviewsScreen";
import { Calendar as BigCalendar, momentLocalizer  } from 'react-big-calendar'
import {TouchableHighlight} from "react-native-web";
import AppStyles from "../../AppStyles";
import StudioStatus from "../../components/StudioStatus";
import EditPicturesScreen from "../modals/EditPicturesScreen";
import EditStudioScreen from "../modals/EditStudioScreen";
import {Popover} from "react-tiny-popover";
const localizer = momentLocalizer(moment);

const DashboardScreen = ({navigation}) =>  {
	const [currentStudio, setCurrentStudio] = useState(null);
	const [isStudioEditorVisible, setIsStudioEditorVisible] = useState(false);
	const [studiosData, setStudiosData] = useState(null);
	const [isStudiosLoading, setIsStudiosLoading] = useState(true);
	const [menuDisplay, setMenuDisplay] = useState( false);
	const [categories, setCategories] = useState([]);

	useEffect(() => {
		API.post('categories')
			.then(res => {
				setCategories(res.data)
			}).catch (error => {
		});
	}, []);

	const getStudios = (url = `admin/studios`) => {
		API.post(url, {
			approved: 0
		}).then(res => {
				setStudiosData(res.data);
				setIsStudiosLoading(false);
			}).catch (error => {
			console.log(error);
		});
	}

	useEffect(() => {
		getStudios();
	}, []);

	return (
		<View
			style={{
				flex: 1
			}}
		>
			<Modal onRequestClose={() => setIsStudioEditorVisible(false)} transparent visible={isStudioEditorVisible}>
				<View
					style={{
						backgroundColor: 'white',
						borderColor: '#eee',
						borderRadius: 8,
						borderWidth: 1,
						margin: 'auto',
						width: 900,
						shadowColor: "#000",
						shadowOffset: {
							width: 0,
							height: 2,
						},
						shadowOpacity: 0.05,
						shadowRadius: 3.84,
						elevation: 5,
					}}
				>
					<EditStudioScreen
						item={currentStudio}
						setIsVisible={setIsStudioEditorVisible}
						setItem={setCurrentStudio}
					/>
				</View>
			</Modal>
			<ScrollView
				style={{
					flex: 1,
					padding: 40
				}}
			>
				<Text
					style={{
						fontSize: 48,
						fontWeight: '600'
					}}
				>Studios Pending Approval</Text>
				<Text
					style={{
						marginTop: 16,
						fontSize: 20,
						color: '#595959'
					}}
				>Look for things like blurry images, Spelling edits, price corrections, amenities and equipment errors.</Text>
				<View
					style={{
						marginTop: 40,
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}
				>
					<View
						style={{
							flexDirection: 'row',
							alignItems: 'center',
							backgroundColor: 'white',
							flex: 1,
							boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.08)',
							paddingVertical: 16,
							paddingHorizontal: 16,
							borderRadius: 8,
							marginRight: 16
						}}
					>
						<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M11.837 4.999a7 7 0 1 0 0 14 7 7 0 0 0 0-14Zm-9 7a9 9 0 1 1 18 0 9 9 0 0 1-18 0Z"
								fill="#8692A6"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M16.78 16.942a1 1 0 0 1 1.414 0l4.35 4.35a1 1 0 0 1-1.414 1.414l-4.35-4.35a1 1 0 0 1 0-1.414Z"
								fill="#8692A6"
							/>
						</svg>
						<TextInput
							placeholder={'Search for bookings, users or rooms'}
							style={{
								flex: 1,
								outline: 0,
								paddingHorizontal: 16,
								fontSize: 16
							}}
							value={''}
							//onChangeText={(text) => setAdditionRule(text)}
							//onSubmitEditing={() => onSubmit()}
						/>
					</View>
					<Popover
						isOpen={menuDisplay}
						positions={['bottom', 'right']}
						align={'end'}
						containerStyle={{
							zIndex: 5,
						}}
						padding={10}
						reposition={false}
						onClickOutside={() => setMenuDisplay(false)}
						content={({ position, nudgedLeft, nudgedTop }) => (
							<View
								style={{
									borderRadius: 8,
									width: 200,
									backgroundColor: 'white',
									shadowColor: "#000",
									shadowOffset: {
										width: 0,
										height: 2,
									},
									shadowOpacity: 0.25,
									shadowRadius: 3.84,
									elevation: 5,
									paddingHorizontal: 16,
									paddingVertical: 8
								}}
							>
								<TouchableOpacity
									style={{
										paddingVertical: 8,

									}}
								>
									<Text
										style={{
											color: '#595959',
										}}
									>Date</Text>
								</TouchableOpacity>
								<TouchableOpacity
									style={{
										paddingVertical: 8,

									}}
								>
									<Text
										style={{
											color: '#595959',
										}}
									>Name</Text>
								</TouchableOpacity>
								{categories.map((item, index) => (
									<TouchableOpacity
										key={index}
										style={{
											paddingVertical: 8,

										}}
									>
										<Text
											style={{
												color: '#595959',
											}}
										>{item.name}</Text>
									</TouchableOpacity>
								))}
							</View>
						)}
					>
						<TouchableOpacity
							onPress={() => {
								setMenuDisplay(!menuDisplay);
							}}
							style={{
								backgroundColor: 'white',
								padding: 16,
								borderRadius: 8,
								shadowColor: "#000",
								shadowOffset: {
									width: 0,
									height: 2,
								},
								shadowOpacity: 0.05,
								shadowRadius: 3.84,
								elevation: 5,
								flexDirection: 'row',
								justifyContent: 'space-between',
								width: 200
							}}
						>
							<View>
								<Text
									style={{
										fontWeight: '600',
										color: '#096730'
									}}
								>Sort by:</Text>
								<Text
									style={{
										fontWeight: '600',
										marginTop: 4
									}}
								>Date</Text>
							</View>
							<svg
								width={16} height={16}
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 492 492"
								style={{
									enableBackground: "new 0 0 492 492",
								}}
								xmlSpace="preserve"
							>
								<path d="M246.1 293.4 62 109.3c-5.1-5.1-11.8-7.9-19-7.9-7.2 0-14 2.8-19 7.9L7.9 125.5c-5.1 5.1-7.9 11.8-7.9 19 0 7.2 2.8 14 7.9 19L227 382.7c5.1 5.1 11.9 7.9 19.1 7.8 7.2 0 14-2.8 19.1-7.8l218.9-218.9c5.1-5.1 7.9-11.8 7.9-19 0-7.2-2.8-14-7.9-19L468 109.5c-10.5-10.5-27.6-10.5-38.1 0L246.1 293.4z" />
							</svg>
						</TouchableOpacity>
					</Popover>
				</View>
					<View
						style={{
							marginTop: 40
						}}
					>
						<View
							style={{
								backgroundColor: 'white',
								shadowColor: "#000",
								shadowOffset: {
									width: 0,
									height: 2,
								},
								shadowOpacity: 0.05,
								shadowRadius: 3.84,
								elevation: 5,
								borderRadius: 8
							}}
						>
							<View
								style={{
								}}
							>
								<View
									style={{
										borderBottomWidth: 2,
										borderColor: '#096730',
										alignSelf: 'flex-start',
										paddingHorizontal: 16
									}}
								>
									<Text
										style={{
											fontWeight: '600',
											fontSize: 16,
											color: '#096730',
											lineHeight: 40
										}}
									>All Studios</Text>
								</View>
							</View>
							<table
								style={{
									flex: 1,
									width: '100%',
									borderCollapse: 'collapse'
								}}
							>
								<tr
									style={{
										backgroundColor: '#F8F8F8',
										height: 48
									}}
								>
									<th></th>
									<th
										style={{
											textAlign: 'left',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Text
											style={styles.tableHeader}
										>Name</Text>
									</th>
									<th
										style={{
											textAlign: 'left',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Text
											style={styles.tableHeader}
										>Contact Number</Text>
									</th>
									<th
										style={{
											textAlign: 'left',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Text
											style={styles.tableHeader}
										>Date Added</Text>
									</th>
									<th
										style={{
											textAlign: 'left',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Text
											style={styles.tableHeader}
										>Status</Text>
									</th>
									<th
										style={{
											textAlign: 'left',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
									</th>
									<th
										style={{
											textAlign: 'left',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
									</th>
								</tr>
								{studiosData && studiosData.data.map((item, index) => (
									<tr
										key={index}
										style={{
											height: 64,
											cursor: 'pointer'
										}}
										onClick={() => {
											setCurrentStudio(item);
											setIsStudioEditorVisible(true);
										}}
									>
										<td
											style={{
												borderBottom: '1px solid',
												borderBottomColor: '#E4E1E0',
												paddingLeft: 16,
												paddingRight: 16
											}}
										>
											<Image
												style={{
													borderRadius: 16,
													marginRight: 16,
													width: 32,
													height: 32,
												}}
												source={{
													uri: item.artwork_url
												}}
											/>
										</td>
										<td
											style={{
												borderBottom: '1px solid',
												borderBottomColor: '#E4E1E0',
												paddingLeft: 16,
												paddingRight: 16
											}}
										>
											<View>
												<Text>{item.name}</Text>
												<Text
													style={{
														color: '#909090',
														marginTop: 4,
														fontWeight: '600'
													}}
												>{item.user && item.user.email}</Text>
											</View>
										</td>
										<td
											style={{
												borderBottom: '1px solid',
												borderBottomColor: '#E4E1E0',
												paddingLeft: 16,
												paddingRight: 16
											}}
										>
											<Text>{item.user && AppStyles.utils.formatPhoneNumber(item.user.phone_number)}</Text>
										</td>
										<td
											style={{
												borderBottom: '1px solid',
												borderBottomColor: '#E4E1E0',
												paddingLeft: 16,
												paddingRight: 16
											}}
										>
											<Text>{moment.utc(item.created_at).local().format("MMM D, YYYY")}</Text>
										</td>
										<td
											style={{
												borderBottom: '1px solid',
												borderBottomColor: '#E4E1E0',
												paddingLeft: 16,
												paddingRight: 16
											}}
										>
											<StudioStatus status={item.approved}/>
										</td>
										<td
											style={{
												borderBottom: '1px solid',
												borderBottomColor: '#E4E1E0',
												paddingLeft: 16,
												paddingRight: 16
											}}
										>
											<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
												<g clipPath="url(#a)" fill="#6D6E6F">
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M7.333 1.333A.667.667 0 0 0 6.666 2v5.588l1.702-.851a.667.667 0 0 1 .298-.07H14A.667.667 0 0 0 14.666 6V2A.667.667 0 0 0 14 1.333H7.333ZM5.919.586A2 2 0 0 1 7.333 0H14a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H8.824L6.298 9.263a.667.667 0 0 1-.965-.596V2A2 2 0 0 1 5.919.586Z"
													/>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M1.252 1.92a2 2 0 0 1 1.415-.587h1a.667.667 0 1 1 0 1.334h-1A.667.667 0 0 0 2 3.333V14a.667.667 0 0 0 .667.667H10a.667.667 0 0 0 .666-.667v-4A.667.667 0 0 1 12 10v4a2 2 0 0 1-2 2H2.667a2 2 0 0 1-2-2V3.333a2 2 0 0 1 .585-1.414Z"
													/>
													<circle cx={6.667} cy={12.667} r={0.667} />
												</g>
												<defs>
													<clipPath id="a">
														<path fill="#fff" d="M0 0h16v16H0z" />
													</clipPath>
												</defs>
											</svg>
										</td>
										<td
											style={{
												borderBottom: '1px solid',
												borderBottomColor: '#E4E1E0',
												paddingLeft: 16,
												paddingRight: 16
											}}
										>
											<Text
												style={{
													fontWeight: '600',
													color: '#595959'
												}}
											>View More</Text>
										</td>
									</tr>
								))}
							</table>
							{studiosData && (
								<View
									style={{
										backgroundColor: '#F8F8F8',
										height: 48,
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'flex-end',
										paddingHorizontal: 24
									}}
								>
									<View
										style={{
											marginRight: 40,
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
									>
										<Text
											style={{
												fontWeight: '600',
												marginRight: 8
											}}
										>Rows per page: {studiosData.per_page}</Text>
										<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M12 14 8 9h8l-4 5Z" fill="#6D6E6F" />
										</svg>
									</View>
									<Text
										style={{
											fontWeight: '600'
										}}
									>{studiosData.from}- {studiosData.to} </Text>
									<View
										style={{
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: 'space-between',
											width: 80,
											marginLeft: 40
										}}
									>
										<TouchableOpacity
											onPress={() => {
												if(!studiosData.prev_page_url) {
													return;
												}
												setIsStudiosLoading(true);
												getStudios(studiosData.prev_page_url);
											}}
										>
											<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
												<path
													d="M14.8 8.106c.628-.593-.367-1.533-.995-.89L9.196 11.52a.66.66 0 0 0 0 .94l4.609 4.352c.628.593 1.623-.346.995-.94l-4.085-3.857L14.8 8.106Z"
													fill={studiosData.prev_page_url ? '#6D6E6F' : '#ccc'}
												/>
											</svg>
										</TouchableOpacity>
										{isStudiosLoading && (
											<ActivityIndicator color={'#096730'} />
										)}
										<TouchableOpacity
											onPress={() => {
												if(!studiosData.next_page_url) {
													return;
												}
												setIsStudiosLoading(true);
												getStudios(studiosData.next_page_url);
											}}
										>
											<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
												<path
													d="M9.2 8.106c-.628-.593.367-1.533.995-.89l4.609 4.303a.66.66 0 0 1 0 .94l-4.609 4.352c-.628.593-1.623-.346-.995-.94l4.085-3.857L9.2 8.106Z"
													fill={studiosData.next_page_url ? '#6D6E6F' : '#ccc'}
												/>
											</svg>
										</TouchableOpacity>
									</View>
								</View>
							)}
						</View>
					</View>
			</ScrollView>
		</View>
	);
}

const styles = StyleSheet.create({
	tableHeader: {
		fontWeight: '600',
		textAlign: 'left',
		alignSelf: 'flex-start'
	},
	tableCell: {
		borderBottom: '1px solid',
		borderBottomColor: 'gray',
		paddingLeft: 16,
		paddingRight: 16
	},
});

DashboardScreen.propTypes = {
	navigation: PropTypes.object,
};

export default connect(({auth}) => ({auth}))(DashboardScreen);