import React, {useEffect, useState} from 'react';
import {View, Text, ActivityIndicator} from "react-native";
import RoundTextInput from "../../components/RoundTextInput";
import {TouchableOpacity} from "react-native-web";
import API from "../../helpers/Axios";

const EditRoomScreen = ({ setIsVisible, item, setCurrentRoom }) => {
	const [name, setName] = useState(item.name);
	const [price, setPrice] = useState(item.per_session_price);
	const [description, setDescription] = useState(item.description);
	const [isLoading, setIsLoading] = useState(false);

	return (
		<View
			style={{
				flex: 1,
			}}
		>
			<View
				style={{
					flex: 1,
					justifyContent: 'center',
					alignItems: 'center',
					borderBottomWidth: 1,
					borderColor: '#E4E1E0',
					padding: 16
				}}
			>
				<Text
					style={{
						fontWeight: '500',
						fontSize: 20
					}}
				>Edit Room Information</Text>
				<TouchableOpacity
					onPress={() => setIsVisible(false)}
					style={{
						position: 'absolute',
						right: 16,
					}}
				>
					<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M18.707 5.293a1 1 0 0 1 0 1.414l-12 12a1 1 0 0 1-1.414-1.414l12-12a1 1 0 0 1 1.414 0Z"
							fill="#6D6E6F"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M5.293 5.293a1 1 0 0 1 1.414 0l12 12a1 1 0 0 1-1.414 1.414l-12-12a1 1 0 0 1 0-1.414Z"
							fill="#6D6E6F"
						/>
					</svg>
				</TouchableOpacity>
			</View>
			<View
				style={{
					padding: 32
				}}
			>
				<View
					style={{
						flexDirection: 'row'
					}}
				>
					<View
						style={{
							width: '60%'
						}}
					>
						<RoundTextInput
							placeholder={'Room Name'}
							value={name}
							error={false}
							onChangeText={text => setName(text)}
						/>
					</View>
					<View
						style={{
							marginLeft: 16,
							flex: 1
						}}
					>
						<RoundTextInput
							placeholder={'Price per hour'}
							value={price}
							error={false}
							onChangeText={text => setPrice(text)}
						/>
					</View>
				</View>
				<View
					style={{
						marginTop: 16
					}}
				>
					<RoundTextInput
						placeholder={'Description'}
						value={description}
						error={false}
						onChangeText={text => setDescription(text)}
						multiline={true}
						numberOfLines={5}
						maxLength={300}
					/>
				</View>
				<View
					style={{
						alignItems: 'flex-end',
						marginTop: 8
					}}
				>
					<View
						style={{
							flexDirection: 'row'
						}}
					>
						<Text
							style={{
								color: '#4A535E'
							}}
						>{description.length}</Text>
						<Text
							style={{
								color: '#4A535E'
							}}
						>/300</Text>
					</View>
				</View>
				<View
					style={{
						flexDirection: 'row',
						justifyContent: 'flex-end',
						marginTop: 32
					}}
				>
					<TouchableOpacity
						onPress={() => setIsVisible(false)}
						style={{
							width: 132,
							fontWeight: '600',
							fontSize: 16,
							paddingHorizontal: 32,
							borderWidth: 1,
							height: 48,
							borderRadius: 8,
							borderColor: '#E4E1E0',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<Text
							style={{
								fontWeight: '600',
								fontSize: 16
							}}
						>Cancel</Text>
					</TouchableOpacity>
					<TouchableOpacity
						onPress={() => {
							setIsLoading(true);
							const data = new FormData();
							data.append('id', item.id);
							data.append('name', name);
							data.append('price', price);
							data.append('description', description);

							API.post('owner/save-room', data).then(res => {
								let newData = item;
								newData.name = res.data.name;
								newData.description = res.data.description;
								newData.per_session_price = res.data.per_session_price;
								setCurrentRoom(newData);
								setIsLoading(false);
								setIsVisible(false);
							}).catch (error => {
								console.log(error);
							});
						}}
						style={{
							width: 132,
							marginLeft: 16,
							fontWeight: '600',
							fontSize: 16,
							height: 48,
							borderRadius: 8,
							backgroundColor: '#19994F',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'row'
						}}
					>
						<Text
							style={{
								fontWeight: '600',
								fontSize: 16,
								color: 'white',
								marginRight: 8
							}}
						>Save</Text>
						{isLoading && <ActivityIndicator color={'white'}/>}
					</TouchableOpacity>
				</View>
			</View>
		</View>
	)
}
export default EditRoomScreen;