import React, {useEffect, useMemo, useState} from "react";
import {
	Text,
	View,
	Modal, ScrollView, ImageBackground, Image, ActivityIndicator, Button, TextInput, StyleSheet
} from "react-native";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import API from "../../helpers/Axios";
import {TouchableOpacity} from "react-native-web";
import moment from 'moment';
import '../../styles/modal.css';
import useModal from '../../helpers/useModal';
import EditStudioScreen from '../../screens/modals/EditStudioScreen';
import EditPaymentScreen from '../../screens/modals/EditPaymentScreen';
import EditPicturesScreen from "../modals/EditPicturesScreen";
import {toast} from "react-toastify";
import {Popover} from "react-tiny-popover";
import {countriesArray} from "../../helpers/CountryCode";
import AppStyles from "../../AppStyles";
import StudioStatus from "../../components/StudioStatus";
import NotificationStatus from "../../components/NotificationStatus";
import SendNotificationScreen from "../modals/SendNotificationScreen";

function NotificationsScreen ({navigation, route}) {
	const [isLoading, setIsLoading] = useState(true);
	const [isDisableLoading, setIsDisableLoading] = useState(false);
	const [studios, setStudios] = useState([]);
	const [currentStudio, setCurrentStudio] = useState(null);
	const [menuDisplay, setMenuDisplay] = useState( false);

	const [isVisible, setIsVisible] = useState(false);
	const [isPaymentVisible, setIsPaymentVisible] = useState(false);
	const [isImageEditorVisible, setIsImageEditorVisible] = useState(false);
	const [isDisableItemVisible, setIsDisableItemVisible] = useState(false);
	const [isActive, setIsActive] = useState(null);
	const [isDeleteItemVisible, setIsDeleteItemVisible] = useState(false);
	const [isDeleteLoading, setIsDeleteLoading] = useState(false);

	const [isStudioEditorVisible, setIsStudioEditorVisible] = useState(false);
	const [studiosData, setStudiosData] = useState(null);
	const [isStudiosLoading, setIsStudiosLoading] = useState(true);
	const [categories, setCategories] = useState([]);

	const getStudios = (offset = 0) => {
		API.post(`admin/onesignal-notifications`, {
			offset: offset
		}).then(res => {
			setStudiosData(res.data);
			setIsLoading(false);
			setIsStudiosLoading(false);
		}).catch (error => {
			console.log(error);
		});
	}

	useEffect(() => {
		getStudios();
	}, []);

	return isLoading ? (<ActivityIndicator style={{flex: 1, justifyContent: 'center', alignItems: 'center'}} />) : (
		<View
			style={{
				flex: 1
			}}
		>
			<Modal onRequestClose={() => setIsVisible(false)} transparent visible={isVisible}>
				<View
					style={{
						backgroundColor: 'white',
						borderColor: '#eee',
						borderRadius: 8,
						borderWidth: 1,
						margin: 'auto',
						width: 780,
						shadowColor: "#000",
						shadowOffset: {
							width: 0,
							height: 2,
						},
						shadowOpacity: 0.05,
						shadowRadius: 3.84,
						elevation: 5,
					}}
				>
					<SendNotificationScreen
						setIsVisible={setIsVisible}
					/>
				</View>
			</Modal>
			<ScrollView
				style={{
					flex: 1,
					padding: 40
				}}
			>
				<Text
					style={{
						fontSize: 48,
						fontWeight: '600'
					}}
				>Notifications</Text>
				<Text
					style={{
						marginTop: 16,
						fontSize: 20,
						color: '#595959'
					}}
				>Send messages to Stugo users and studios</Text>
				<ImageBackground
					source={{uri: '/assets/splash/1.jpeg'}}
					style={{
						marginTop: 32,
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
						borderRadius: 8,
						overflow: 'hidden',
						padding: 8
					}}
				>
					<View
						style={{
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<View
							style={{
								width: 36,
								height: 36,
								borderRadius: 12,
								backgroundColor: '#EDFDF4',
								justifyContent: 'center',
								alignItems: 'center'
							}}
						>
							<svg width={26} height={26} fill="none" xmlns="http://www.w3.org/2000/svg">
								<circle
									cx={19.502}
									cy={6.497}
									r={3.501}
									stroke="#58B17D"
									strokeWidth={1.5}
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M20.003 13.734v3.824a4.446 4.446 0 0 1-4.447 4.446H8.442a4.446 4.446 0 0 1-4.446-4.446v-7.114a4.446 4.446 0 0 1 4.446-4.447h3.824"
									stroke="#58B17D"
									strokeWidth={1.5}
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</View>
						<Text
							style={{
								fontSize: 24,
								fontWeight: '600',
								color: 'white',
								marginLeft: 16
							}}
						>Push notifications</Text>
					</View>
					<TouchableOpacity
						onPress={() => setIsVisible(true)}
						style={{
							height: 36,
							backgroundColor: '#C2F3D7',
							justifyContent: 'center',
							paddingHorizontal: 16,
							borderRadius: 6
						}}
					>
						<Text
							style={{
								color: '#096730',
								fontWeight: '600',
								fontSize: 16
							}}
						>Send new push notification</Text>
					</TouchableOpacity>
				</ImageBackground>
				<View
					style={{
						marginVertical: 32,
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}
				>
					<View
						style={{
							flexDirection: 'row',
							alignItems: 'center',
							backgroundColor: 'white',
							flex: 1,
							boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.08)',
							paddingVertical: 16,
							paddingHorizontal: 16,
							borderRadius: 8,
							marginRight: 16
						}}
					>
						<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M11.837 4.999a7 7 0 1 0 0 14 7 7 0 0 0 0-14Zm-9 7a9 9 0 1 1 18 0 9 9 0 0 1-18 0Z"
								fill="#8692A6"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M16.78 16.942a1 1 0 0 1 1.414 0l4.35 4.35a1 1 0 0 1-1.414 1.414l-4.35-4.35a1 1 0 0 1 0-1.414Z"
								fill="#8692A6"
							/>
						</svg>
						<TextInput
							placeholder={'Search for title, date or studio'}
							style={{
								flex: 1,
								outline: 0,
								paddingHorizontal: 16,
								fontSize: 16
							}}
							value={''}
							//onChangeText={(text) => setAdditionRule(text)}
							//onSubmitEditing={() => onSubmit()}
						/>
					</View>
					<Popover
						isOpen={menuDisplay}
						positions={['bottom', 'right']}
						align={'end'}
						containerStyle={{
							zIndex: 5,
						}}
						padding={10}
						reposition={false}
						onClickOutside={() => setMenuDisplay(false)}
						content={({ position, nudgedLeft, nudgedTop }) => (
							<View
								style={{
									borderRadius: 8,
									width: 240,
									backgroundColor: 'white',
									shadowColor: "#000",
									shadowOffset: {
										width: 0,
										height: 2,
									},
									shadowOpacity: 0.25,
									shadowRadius: 3.84,
									elevation: 5,
									paddingHorizontal: 16
								}}
							>

							</View>
						)}
					>
						<TouchableOpacity
							onPress={() => {
								setMenuDisplay(!menuDisplay)
							}}
							style={{
								backgroundColor: 'white',
								padding: 16,
								borderRadius: 8,
								shadowColor: "#000",
								shadowOffset: {
									width: 0,
									height: 2,
								},
								shadowOpacity: 0.05,
								shadowRadius: 3.84,
								elevation: 5,
								flexDirection: 'row',
								justifyContent: 'space-between',
								width: 240
							}}
						>
							<View>
								<Text
									style={{
										fontWeight: '600',
										color: '#096730'
									}}
								>Sort by:</Text>
								<Text
									style={{
										fontWeight: '600',
										marginTop: 4
									}}
								>Title</Text>
							</View>
							<svg
								width={16} height={16}
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 492 492"
								style={{
									enableBackground: "new 0 0 492 492",
								}}
								xmlSpace="preserve"
							>
								<path d="M246.1 293.4 62 109.3c-5.1-5.1-11.8-7.9-19-7.9-7.2 0-14 2.8-19 7.9L7.9 125.5c-5.1 5.1-7.9 11.8-7.9 19 0 7.2 2.8 14 7.9 19L227 382.7c5.1 5.1 11.9 7.9 19.1 7.8 7.2 0 14-2.8 19.1-7.8l218.9-218.9c5.1-5.1 7.9-11.8 7.9-19 0-7.2-2.8-14-7.9-19L468 109.5c-10.5-10.5-27.6-10.5-38.1 0L246.1 293.4z" />
							</svg>
						</TouchableOpacity>
					</Popover>
				</View>
				<View
					style={{
						backgroundColor: 'white',
						shadowColor: "#000",
						shadowOffset: {
							width: 0,
							height: 2,
						},
						shadowOpacity: 0.05,
						shadowRadius: 3.84,
						elevation: 5,
						borderRadius: 8
					}}
				>
				<View
					style={{
					}}
				>
					<View
						style={{
							borderBottomWidth: 2,
							borderColor: '#096730',
							alignSelf: 'flex-start',
							paddingHorizontal: 16
						}}
					>
						<Text
							style={{
								fontWeight: '600',
								fontSize: 16,
								color: '#096730',
								lineHeight: 40
							}}
						>All Notifications</Text>
					</View>
				</View>
				<table
					style={{
						flex: 1,
						width: '100%',
						borderCollapse: 'collapse'
					}}
				>
					<tr
						style={{
							backgroundColor: '#F8F8F8',
							height: 48
						}}
					>
						<th
							style={{
								textAlign: 'left',
								paddingLeft: 16,
								paddingRight: 16
							}}
						>
							<Text
								style={styles.tableHeader}
							>Title</Text>
						</th>
						<th
							style={{
								textAlign: 'left',
								paddingLeft: 16,
								paddingRight: 16
							}}
						>
							<Text
								style={styles.tableHeader}
							>Send to</Text>
						</th>
						<th
							style={{
								textAlign: 'left',
								paddingLeft: 16,
								paddingRight: 16
							}}
						>
							<Text
								style={styles.tableHeader}
							>Created at</Text>
						</th>
						<th
							style={{
								textAlign: 'left',
								paddingLeft: 16,
								paddingRight: 16
							}}
						>
							<Text
								style={styles.tableHeader}
							>Send at</Text>
						</th>
						<th
							style={{
								textAlign: 'left',
								paddingLeft: 16,
								paddingRight: 16
							}}
						>
							<Text
								style={styles.tableHeader}
							>Status</Text>
						</th>
						<th
							style={{
								textAlign: 'left',
								paddingLeft: 16,
								paddingRight: 16
							}}
						>
						</th>
					</tr>
					{studiosData && studiosData.notifications.map((item, index) => (
						<tr
							key={index}
							style={{
								height: 64,
								cursor: 'pointer'
							}}
							onClick={() => {
								setCurrentStudio(item);
								setIsStudioEditorVisible(true);
							}}
						>
							<td
								style={{
									borderBottom: '1px solid',
									borderBottomColor: '#E4E1E0',
									paddingLeft: 16,
									paddingRight: 16
								}}
							>
								<Text
									style={{
										fontWeight: '600'
									}}
								>{item.contents.en}</Text>
							</td>
							<td
								style={{
									borderBottom: '1px solid',
									borderBottomColor: '#E4E1E0',
									paddingLeft: 16,
									paddingRight: 16
								}}
							>
								<Text>{item.included_segments[0]}</Text>
							</td>
							<td
								style={{
									borderBottom: '1px solid',
									borderBottomColor: '#E4E1E0',
									paddingLeft: 16,
									paddingRight: 16
								}}
							>
								<Text>{moment.utc(moment(item.queued_at*1000)).local().format("MMM D, YYYY hh:mm a")}</Text>
							</td>
							<td
								style={{
									borderBottom: '1px solid',
									borderBottomColor: '#E4E1E0',
									paddingLeft: 16,
									paddingRight: 16
								}}
							>
								<Text>{moment.utc(moment(item.send_after*1000)).local().format("MMM D, YYYY hh:mm a")}</Text>
							</td>
							<td
								style={{
									borderBottom: '1px solid',
									borderBottomColor: '#E4E1E0',
									paddingLeft: 16,
									paddingRight: 16
								}}
							>
								<NotificationStatus status={item.send_after <= item.queued_at ? 1 : 0}/>
							</td>
							<td
								style={{
									borderBottom: '1px solid',
									borderBottomColor: '#E4E1E0',
									paddingLeft: 16,
									paddingRight: 16
								}}
							>
								<Text
									style={{
										fontWeight: '600',
										color: '#595959'
									}}
								>View More</Text>
							</td>
						</tr>
					))}
				</table>
				{studiosData && (
					<View
						style={{
							backgroundColor: '#F8F8F8',
							height: 48,
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'flex-end',
							paddingHorizontal: 24
						}}
					>
						<View
							style={{
								marginRight: 40,
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<Text
								style={{
									fontWeight: '600',
									marginRight: 8
								}}
							>Rows per page: {studiosData.limit}</Text>
							<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M12 14 8 9h8l-4 5Z" fill="#6D6E6F" />
							</svg>
						</View>
						<Text
							style={{
								fontWeight: '600'
							}}
						>{studiosData.offset+1} - {studiosData.offset + studiosData.limit < studiosData.total_count ? (studiosData.offset + studiosData.limit) : studiosData.total_count} </Text>
						<View
							style={{
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'space-between',
								width: 80,
								marginLeft: 40
							}}
						>
							<TouchableOpacity
								onPress={() => {
									if(studiosData.offset - studiosData.limit >= studiosData.total) {
										return;
									}
									setIsStudiosLoading(true);
									getStudios(studiosData.offset - studiosData.limit);
								}}
							>
								<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M14.8 8.106c.628-.593-.367-1.533-.995-.89L9.196 11.52a.66.66 0 0 0 0 .94l4.609 4.352c.628.593 1.623-.346.995-.94l-4.085-3.857L14.8 8.106Z"
										fill={studiosData.prev_page_url ? '#6D6E6F' : '#ccc'}
									/>
								</svg>
							</TouchableOpacity>
							{isStudiosLoading && (
								<ActivityIndicator color={'#096730'} />
							)}
							<TouchableOpacity
								onPress={() => {
									if(studiosData.offset + studiosData.limit >= studiosData.total) {
										return;
									}
									setIsStudiosLoading(true);
									getStudios(studiosData.offset + studiosData.limit);
								}}
							>
								<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M9.2 8.106c-.628-.593.367-1.533.995-.89l4.609 4.303a.66.66 0 0 1 0 .94l-4.609 4.352c-.628.593-1.623-.346-.995-.94l4.085-3.857L9.2 8.106Z"
										fill={studiosData.next_page_url ? '#6D6E6F' : '#ccc'}
									/>
								</svg>
							</TouchableOpacity>
						</View>
					</View>
				)}
				</View>
			</ScrollView>
		</View>
	);
}


const styles = StyleSheet.create({
	tableHeader: {
		fontWeight: '600',
		textAlign: 'left',
		alignSelf: 'flex-start'
	},
	tableCell: {
		borderBottom: '1px solid',
		borderBottomColor: 'gray',
		paddingLeft: 16,
		paddingRight: 16
	},
});

NotificationsScreen.propTypes = {
	navigation: PropTypes.object,
};

export default connect(({auth}) => ({auth}))(NotificationsScreen);
