import React, {useEffect, useRef, useState} from 'react';
import {View, Text, TextInput} from "react-native";
import {Switch, TouchableOpacity} from "react-native-web";
import API from "../helpers/Axios";

const SendMessageContainer = ({ item }) => {
	const textInput = useRef();
	const [messages, setMessages] = useState([]);
	const [currentConversation, setCurrentConversation] = useState(null);
	const [messageString, setMessageString] = useState('');

	const sendMessage = () => {
		if(!messageString.length) {
			return
		}
		const data = new FormData();
		const lid = (+ new Date());
		data.append('conversation_id', currentConversation.id);
		data.append('lid', lid);
		data.append('message', messageString.trim());
		data.append('type', 'text');

		const message = {
			"lid": lid,
			"body": messageString.trim(),
			"is_sender": 1,
			"type": "text"
		}

		setMessages([...messages, message]);
		setMessageString('');
		API.post('chat/send-message', data)
			.then(res => {
				let message = res.data;
				console.log(message);
				textInput.current.focus();
			}).catch (error => {
			console.log(error)
		});
	}

	return (
		<View
			style={{
				marginTop: 32
			}}
		>
			<Text
				style={{

				}}
			><strong>Send message to {item && item.name}</strong></Text>
			<Text
				style={{
					marginTop: 8,
					color: '#6D6E6F'
				}}
			>Write here any reason why the studio is being rejected.</Text>
			<View
				style={{
					marginTop: 16,
					backgroundColor: '#f8f8f8' ,
					paddingTop: 16,
					paddingLeft: 16,
					paddingRight: 16,
					borderWidth: 1,
					borderColor: '#E4E1E0',
					overflow: 'hidden',
					borderRadius: 8,
					paddingBottom:8,
				}}>
				<TextInput
					ref={textInput}
					placeholder={`Send message to ${item && item.name}`}
					style={{
						borderColor: '#AFABAC',
						flex: 1,
						outline: 0,
						fontSize: 16
					}}
					value={messageString}
					onChangeText={text => {
						setMessageString(text)
					}}
					multiline={true}
					numberOfLines={4}
					blurOnSubmit={true}
					onSubmitEditing={() => sendMessage()}
				/>
				<View
					style={{
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between'
					}}
				>
					<TouchableOpacity
						style={{
							backgroundColor: 'white',
							height: 26,
							borderRadius: 13,
							paddingHorizontal: 8,
							flexDirection: 'row',
							alignItems: 'center'
						}}
					>
						<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M10.52 1.592c-.53 0-1.04.21-1.415.586L2.978 8.305a3.336 3.336 0 1 0 4.717 4.717l6.127-6.127a.667.667 0 1 1 .943.943l-6.127 6.127a4.669 4.669 0 1 1-6.603-6.603l6.127-6.127a3.335 3.335 0 1 1 4.716 4.716l-6.133 6.127a2 2 0 0 1-2.83-2.83l5.66-5.653a.667.667 0 1 1 .943.943l-5.66 5.653a.668.668 0 0 0 .944.944l6.133-6.126a2.002 2.002 0 0 0-1.415-3.417Z"
								fill="#6D6E6F"
							/>
						</svg>
						<Text
							style={{
								marginLeft: 8,
								fontSize: 12,
								color: '#595959'
							}}
						>Attach file - PDF, JPG, PNG</Text>
					</TouchableOpacity>
					<TouchableOpacity
						onPress={() => sendMessage()}
						style={{
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<Text
							style={{
								marginRight: 8,
								color: '#096730',
								fontWeight: '600'
							}}
						>Send message</Text>
						<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M15.138.862c.26.26.26.682 0 .943L7.805 9.138a.667.667 0 1 1-.943-.943L14.195.862c.26-.26.683-.26.943 0Z"
								fill="#096730"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M15.138.862c.181.181.243.45.158.692l-4.667 13.333a.667.667 0 0 1-1.238.05L6.828 9.172 1.063 6.609a.667.667 0 0 1 .05-1.238L14.447.704a.667.667 0 0 1 .691.158ZM3.141 6.074l4.463 1.983c.151.067.272.188.339.339l1.983 4.463L13.58 2.42 3.14 6.074Z"
								fill="#096730"
							/>
						</svg>
					</TouchableOpacity>
				</View>
			</View>
		</View>
	)
}
export default SendMessageContainer;