import React, {useEffect, useMemo, useState} from "react";
import {
	Text,
	View,
	TextInput, ScrollView, StyleSheet, Image, Modal, ActivityIndicator
} from "react-native";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import API from "../../helpers/Axios";
import {TouchableOpacity} from "react-native-web";
import moment from 'moment';
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import '../../styles/calendar.css';

import { Calendar, momentLocalizer  } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import useModal from "../../helpers/useModal";
import BookingDetailsScreen from "../modals/BookingDetailsScreen";
import EditStudioScreen from "../modals/EditStudioScreen";
import BookingStatus from "../../components/BookingStatus";
const localizer = momentLocalizer(moment);


function BookingsScreen ({navigation, route}) {
	const [studios, setStudios] = useState([]);
	const [bookings, setBookings] = useState([]);
	const {isShowing, toggleModal} = useModal();
	const [booking, setBooking] = useState(null);
	const [isVisible, setIsVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [currentStudio, setCurrentStudio] = useState(null);
	const [currentRoom, setCurrentRoom] = useState({id: 0});
	const [allRoomBookings, setAllRoomBookings] = useState([]);
  const [isBookingLoading, setIsBookingLoading] = useState(false);
	const [currentDate, setCurrentDate] = useState(new Date());
	const [events, setEvents] = useState([]);

	useEffect(() => {
		if(currentStudio) {
			setIsBookingLoading(true);
			API.get(`owner/bookings/studio/${currentStudio.id}`)
				.then(res => {
					setBookings(res.data.data);
					setIsBookingLoading(false);
					setCurrentRoom({id: 0});
				}).catch (error => {
				console.log(error);
			});
		}
	}, [currentStudio]);

	useEffect(() => {
		API.get('owner/bookings')
			.then(res => {
				setStudios(res.data.studios);
				setCurrentStudio(res.data.studios[0]);
				getStudioEvents(res.data.studios[0].id);
				setIsLoading(false);
			}).catch (error => {
			console.log(error);
		});
	}, []);

	const getStudioEvents = (studio_id) => {
		API.post('owner/get-studio-events', {
			studio_id: studio_id
		}).then(res => {
			let events = [];
			res.data.forEach((item) => {
				events.push({
					id: item.id,
					title: `Room: ${item.room.name}, by: ${item.user.name}`,
					start: moment.utc(item.start_time).local().toDate(),
					end: moment.utc(item.end_time).local().toDate(),
				});
			});
			setEvents(events);
		}).catch (error => {
			console.log(error);
		});
	}

	const getBookingsRoom = (roomId) => {
		setIsBookingLoading(true);
		API.get(`owner/bookings/room/${roomId}`)
			.then(res => {
				setBookings(res.data.data)
				setIsBookingLoading(false);
			}).catch (error => {
			console.log(error);
		});
	}

	return isLoading ? (<ActivityIndicator style={{flex: 1, justifyContent: 'center', alignItems: 'center'}} />) : (
		<View
			style={{
				flex: 1
			}}
		>
			<Modal onRequestClose={() => setIsVisible(false)} transparent visible={isVisible}>
				<View
					style={{
						backgroundColor: 'white',
						borderColor: '#eee',
						borderRadius: 8,
						borderWidth: 1,
						margin: 'auto',
						minWidth: 780,
						shadowColor: "#000",
						shadowOffset: {
							width: 0,
							height: 2,
						},
						shadowOpacity: 0.05,
						shadowRadius: 3.84,
						elevation: 5,
					}}
				>
					<BookingDetailsScreen
						setIsVisible={setIsVisible}
						item={booking}
					/>
				</View>
			</Modal>
			<ScrollView
				style={{
					flex: 1,
					padding: 40
				}}
			>
				<Text
					style={{
						fontSize: 48,
						fontWeight: '600'
					}}
				>Bookings</Text>
				<Text
					style={{
						marginTop: 16,
						fontSize: 20,
						color: '#595959'
					}}
				>View information about your reservations</Text>
				<ScrollView
					horizontal={true}
					style={{
						marginVertical: 40,
						flexDirection: 'row'
					}}
				>
					{studios.map((item, index) => (
						<TouchableOpacity
							key={index}
							onPress={() => {
								setCurrentStudio(item);
								getStudioEvents(item.id);
							}}
							style={{
								width: 364,
								flexDirection: 'row',
								borderWidth: item.id === currentStudio.id ? 2 : 1,
								borderColor: item.id === currentStudio.id ? '#6EB28B' : '#E4E1E0',
								padding: 8,
								borderRadius: 8,
								backgroundColor: 'white',
								marginRight: 24
							}}
						>
							<View
								style={{
									width: 128,
									aspectRatio: 1.777,
									borderRadius: 4,
									marginRight: 16,
									overflow: 'hidden'
								}}
							>
								<Image
									style={{
										flex: 1
									}}
									source={{
										uri: item.artwork_url,
									}}
								/>
							</View>
							<View
								style={{
									flex: 1,
									justifyContent: 'center'
								}}
							>
								<Text
									style={{
										fontWeight: '600',
										color: '#096730',
										fontSize: 18
									}}
								>{item.name}</Text>
								<Text
									style={{
										fontWeight: '600',
										marginTop: 4,
										color: '#595959'
									}}
									numberOfLines={2}
								>{item.address}</Text>
							</View>
						</TouchableOpacity>
					))}
				</ScrollView>
				<View
					style={{
						marginBottom: 16,
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center'
					}}
				>
					<Text
						style={{
							fontSize: 20,
							fontWeight: '500',
						}}
					>Last bookings</Text>
				</View>
				<View
					style={{
						backgroundColor: 'white',
						shadowColor: "#000",
						shadowOffset: {
							width: 0,
							height: 2,
						},
						shadowOpacity: 0.05,
						shadowRadius: 3.84,
						elevation: 5,
						borderRadius: 8
					}}
				>
					<View
						style={{
							flexDirection: 'row'
						}}
					>
						<TouchableOpacity
							onPress={() => {
								setIsBookingLoading(true);
								setCurrentRoom({id: 0});
								API.get(`owner/bookings/studio/${currentStudio.id}`)
									.then(res => {
										setBookings(res.data.data);
										setIsBookingLoading(false);
									}).catch (error => {
									console.log(error);
								});
							}}
							style={{
								borderBottomWidth: 2,
								borderColor: currentRoom.id === 0 ? '#096730' : 'white',
								alignSelf: 'flex-start',
								paddingHorizontal: 16
							}}
						>
							<Text
								style={{
									fontWeight: '600',
									fontSize: 16,
									color: '#096730',
									lineHeight: 40
								}}
							>All rooms</Text>
						</TouchableOpacity>
						{currentStudio.rooms.map((item, index) => (
							<TouchableOpacity
								key={index}
								onPress={() => {
									setCurrentRoom(item);
									getBookingsRoom(item.id);
								}}
								style={{
									borderBottomWidth: 2,
									borderColor: currentRoom.id === item.id ? '#096730' : 'white',
									alignSelf: 'flex-start',
									paddingHorizontal: 16
								}}
							>
								<Text
									style={{
										fontWeight: '600',
										fontSize: 16,
										color: currentRoom.id === item.id ? 'black' : '#096730',
										lineHeight: 40
									}}
								>{item.name}</Text>
							</TouchableOpacity>
						))}
					</View>
					{isBookingLoading && (
						<View
							style={{
								height: 180,
								justifyContent: 'center',
								alignItems: 'center'
							}}
						>
							<ActivityIndicator color={'#096730'} />
						</View>
					)}
					{!isBookingLoading && (
					<table
						style={{
							flex: 1,
							width: '100%',
							borderCollapse: 'collapse'
						}}
					>
						<tr
							style={{
								backgroundColor: '#F8F8F8',
								height: 48
							}}
						>
							<th
								style={{
									textAlign: 'left',
									paddingLeft: 16,
									paddingRight: 16
								}}
							>
								<Text
									style={styles.tableHeader}
								>Name</Text>
							</th>
							<th
								style={{
									textAlign: 'left',
									paddingLeft: 16,
									paddingRight: 16
								}}
							>
								<Text
									style={styles.tableHeader}
								>Room</Text>
							</th>
							<th
								style={{
									textAlign: 'left',
									paddingLeft: 16,
									paddingRight: 16
								}}
							>
								<Text
									style={styles.tableHeader}
								>Booking date</Text>
							</th>
							<th
								style={{
									textAlign: 'left',
									paddingLeft: 16,
									paddingRight: 16
								}}
							>
								<Text
									style={styles.tableHeader}
								>Amount</Text>
							</th>
							<th
								style={{
									textAlign: 'left',
									paddingLeft: 16,
									paddingRight: 16
								}}
							>
								<Text
									style={styles.tableHeader}
								>Status</Text>
							</th>
							<th></th>
							<th></th>
						</tr>
						{bookings.map((item, index) => (
							(item.user && item.room) && (
								<tr
									key={index}
									style={{
										height: 64,
										cursor: 'pointer'
									}}
									onClick={() => {
										setBooking(item);
										setIsVisible(true);
									}}
								>
									<td
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<View
											style={{
												flexDirection: 'row',
												alignItems: 'center'
											}}
										>
											<Image
												style={{
													borderRadius: 16,
													marginRight: 16,
													width: 32,
													height: 32,
												}}
												source={{
													uri: item.user.artwork_url
												}}
											/>
											<View>
												<Text>{item.user.name}</Text>
												<Text
													style={{
														color: '#909090',
														marginTop: 4,
														fontWeight: '600'
													}}
												>{item.user.email}</Text>
											</View>
										</View>
									</td>
									<td
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Text>{item.room.name}</Text>
									</td>
									<td
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Text>{moment.utc(item.start_time).local().format("MMM D, YYYY h:mm A")}</Text>
									</td>
									<td
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Text
											style={{
												fontWeight: '600'
											}}
										>${item.cost}</Text>
									</td>
									<td
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<BookingStatus status={item.status}/>
									</td>
									<td
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
											<g clipPath="url(#a)" fill="#6D6E6F">
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M7.333 1.333A.667.667 0 0 0 6.667 2v5.588l1.702-.851a.667.667 0 0 1 .298-.07H14A.666.666 0 0 0 14.667 6V2A.666.666 0 0 0 14 1.333H7.333ZM5.92.586A2 2 0 0 1 7.333 0H14a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H8.824L6.298 9.263a.667.667 0 0 1-.965-.596V2A2 2 0 0 1 5.92.586Z"
												/>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M1.252 1.919a2 2 0 0 1 1.415-.586h1a.667.667 0 0 1 0 1.334h-1A.667.667 0 0 0 2 3.333V14a.667.667 0 0 0 .667.667H10a.667.667 0 0 0 .667-.667v-4A.667.667 0 0 1 12 10v4a2 2 0 0 1-2 2H2.667a2 2 0 0 1-2-2V3.333a2 2 0 0 1 .585-1.414Z"
												/>
												<circle cx={6.667} cy={12.667} r={0.667} />
											</g>
											<defs>
												<clipPath id="a">
													<path fill="#fff" d="M0 0h16v16H0z" />
												</clipPath>
											</defs>
										</svg>
									</td>
									<td
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Text
											style={{
												fontWeight: '600'
											}}
										>View More</Text>
									</td>
								</tr>
							)
						))}
					</table>
					)}
				</View>
				<View
					style={{
						marginTop: 40,
						marginBottom: 16,
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center'
					}}
				>
					<Text
						style={{
							fontSize: 20,
							fontWeight: '500',
						}}
					>Calendar view</Text>
				</View>
				<View
					style={{
						aspectRatio: 1.777,
						backgroundColor: 'white',
						borderRadius: 8,
					}}
				>
					<Calendar
						key={currentStudio.id}
						events={events}
						resizable
						defaultDate={currentDate}
						localizer={localizer}
						defaultView="week"
						components={{
							week: {
								header: ({ date, localizer }) => (
									<View
										style={{
											paddingTop: 8,
											paddingLeft: 8,
											paddingBottom: 16
										}}
									>
										<Text
											style={{
												textTransform: 'uppercase',
												fontWeight: '600',
												color: '#71717A',
												alignSelf: 'flex-start'
											}}
										>{localizer.format(date, 'ddd')}</Text>
										<Text
											style={{
												marginTop: 8,
												fontSize: 24,
												fontWeight: '500',
												alignSelf: 'flex-start'
											}}
										>{localizer.format(date, 'D')}</Text>
									</View>
								)
							}
						}}
					/>
				</View>
			</ScrollView>
		</View>
	);

}


const styles = StyleSheet.create({
	tableHeader: {
		fontWeight: '600',
		textAlign: 'left',
		alignSelf: 'flex-start'
	},
	tableCell: {
		borderBottom: '1px solid',
		borderBottomColor: 'gray',
		paddingLeft: 16,
		paddingRight: 16
	},
});

BookingsScreen.propTypes = {
	navigation: PropTypes.object,
};

export default connect(({auth}) => ({auth}))(BookingsScreen);