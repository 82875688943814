import React, {useState} from 'react'
import {View, Picker, Text, TextInput} from 'react-native'

const FieldSetPicker = ({ data = [], value = '', placeholder = 'Placeholder', name, error = false, errorString = 'Error', onValueChange, onPress, style, textStyle, ...props}) => {
  if(name === undefined) {
    name = placeholder;
  }


  const localOnValueChange = (value, index) => {
    onValueChange(value);
  }
  
  return (
    <View>
      <View
        style={{
          borderWidth: (value > 0) ? 2 : 1,
          borderColor: error ? '#F09696' : ((value > 0) ? '#3DBA71' : '#8692A6'),
          borderRadius: 8,
          backgroundColor: 'white',
          paddingLeft: 16,
          paddingRight: 16
        }}
      >
        {(value > 0) && (
          <View
            style={{
              position: 'absolute',
              left: 12,
              top: -9,
              height: 18,
              paddingLeft: 8,
              paddingRight: 8,
              backgroundColor: 'white'
            }}
          >
            <Text
              style={{
                lineHeight: 18,
                fontWeight: '500',
                color: error ? '#B96363' : ((value > 0) ? '#3DBA71' : '#8692A6')
              }}
            >{name}</Text>
          </View>
        )}
        <Picker
          onValueChange={localOnValueChange}
          style={{
            border: 0,
            outline: 0,
            height: 55,
            opacity: (value > 0) ? 1 : .55
          }}
        >
          <Picker.Item
            label={placeholder}
            value={0}
          />
          {data.map((item, index) => (
            <Picker.Item
              key={index}
              label={item.name}
              value={item.id}
            />
          ))}
        </Picker>
      </View>
      {error && <Text
        style={{
          color: '#B96363',
          marginTop: 4,
          marginLeft: 16
        }}
      >{errorString}</Text>}
    </View>

  )
}

export default FieldSetPicker
