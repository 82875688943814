import React, {useEffect, useRef, useState} from 'react';
import {View, Text, TextInput, ActivityIndicator, Image, ImageBackground, StyleSheet} from "react-native";
import {ScrollView, Switch, TouchableOpacity} from "react-native-web";
import DatePicker from "react-datepicker";
import FormTextInput from "../../components/FormTextInput";
import API from "../../helpers/Axios";
import AppStyles from "../../AppStyles";
import moment from "moment";
import UserStatus from "../../components/UserStatus";
import BookingStatus from "../../components/BookingStatus";
import StyleDict from "../../AppStyles";
import {useTheme} from "@react-navigation/native";
import {confirmAlert} from "react-confirm-alert";
import {connect} from "react-redux";

function Modal ({ setIsVisible, item, getUsers }) {
	const {colors, dark} = useTheme();

	const textInput = useRef();
	const [messages, setMessages] = useState([]);
	const [currentConversation, setCurrentConversation] = useState(null);
	const [messageString, setMessageString] = useState('');
	const [isActive, setIsActive] = useState(!item.banned);
	const [bookingDetailsDisplay, setBookingDetailsDisplay] = useState([]);

	const sendMessage = () => {
		if(!messageString.length) {
			return
		}
		const data = new FormData();
		const lid = (+ new Date());
		data.append('conversation_id', currentConversation.id);
		data.append('lid', lid);
		data.append('message', messageString.trim());
		data.append('type', 'text');

		const message = {
			"lid": lid,
			"body": messageString.trim(),
			"is_sender": 1,
			"type": "text"
		}

		setMessages([...messages, message]);
		setMessageString('');
		API.post('chat/send-message', data)
			.then(res => {
				let message = res.data;
				console.log(message);
				textInput.current.focus();
			}).catch (error => {
			console.log(error)
		});
	}

	const [title, setTitle] = useState({
		value: '',
		error: false,
		errorString: null
	});

	const [body, setBody] = useState({
		value: '',
		error: false,
		errorString: null
	});

	const [url, setUrl] = useState({
		value: '',
		error: false,
		errorString: null
	});

	return (
		<View
			style={{
				flex: 1,
			}}
		>
			<View
				style={{
					flex: 1,
					justifyContent: 'center',
					alignItems: 'center',
					borderBottomWidth: 1,
					borderColor: '#E4E1E0',
					padding: 16
				}}
			>
				<Text
					style={{
						fontWeight: '500',
						fontSize: 20
					}}
				>Users</Text>
				<TouchableOpacity
					onPress={() => setIsVisible(false)}
					style={{
						position: 'absolute',
						right: 16,
					}}
				>
					<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M18.707 5.293a1 1 0 0 1 0 1.414l-12 12a1 1 0 0 1-1.414-1.414l12-12a1 1 0 0 1 1.414 0Z"
							fill="#6D6E6F"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M5.293 5.293a1 1 0 0 1 1.414 0l12 12a1 1 0 0 1-1.414 1.414l-12-12a1 1 0 0 1 0-1.414Z"
							fill="#6D6E6F"
						/>
					</svg>
				</TouchableOpacity>
			</View>
			<ScrollView
				style={{
					maxHeight: 'calc(100vh - 180px)'
				}}
			>
				<View
					style={{
						padding: 32
					}}
				>
					<View
						style={{
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center'
						}}
					>
						<Text
							style={{
								fontSize: 16,
								fontWeight: '600'
							}}
						>{item.name}</Text>
						<View
							style={{
								flexDirection: 'row',
								alignItems: 'center'
							}}
						>
							<Text
								style={{
									fontSize: 16,
									fontWeight: '600'
								}}
							>Contact Number: </Text>
							<Text>{item.phone && item.phone.phone_number}</Text>
						</View>
					</View>
					<View
						style={{
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
							marginTop: 8
						}}
					>
						<Text
							style={{
								fontSize: 16,
								fontWeight: '600',
								color: '#595959'
							}}
						>{item.email}</Text>
						<View
							style={{
								flexDirection: 'row',
								alignItems: 'center'
							}}
						>
							<View
								style={{
									flexDirection: 'row',
									alignItems: 'center'
								}}
							>
								<Text
									style={{
										fontWeight: '600',
										marginRight: 8
									}}
								>Status: {isActive ? 'Active' : 'Disabled'}</Text>
								<Switch
									onValueChange={() => {
										API.post('admin/toggle-user', {
											id: item.id
										}).then(res => {
											setIsActive(!isActive)
										}).catch (error => {
											console.log(error);
										});
									}}
									value={isActive}
								/>
							</View>
						</View>
					</View>
					{!!item.booked.length && (
						<View
							style={{
								borderRadius: 8,
								overflow: 'hidden',
								borderWidth: 1,
								borderColor: '#E4E1E0',
								marginTop: 24
							}}
						>
							<View
								style={{
									backgroundColor: '#F8F8F8'
								}}
							>
								<View
									style={{
										alignSelf: 'center',
									}}
								>
									<Text
										style={{
											fontWeight: '600',
											fontSize: 16,
											lineHeight: 40
										}}
									>History Bookings</Text>
								</View>
							</View>
							<table
								style={{
									flex: 1,
									width: '100%',
									borderCollapse: 'collapse'
								}}
							>
								<tr
									style={{
										backgroundColor: '#F8F8F8',
										height: 48
									}}
								>
									<th
										style={{
											textAlign: 'left',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Text
											style={styles.tableHeader}
										>Studio</Text>
									</th>
									<th
										style={{
											textAlign: 'left',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Text
											style={styles.tableHeader}
										>Booking date</Text>
									</th>
									<th
										style={{
											textAlign: 'left',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Text
											style={styles.tableHeader}
										>Amount</Text>
									</th>
									<th
										style={{
											textAlign: 'left',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Text
											style={styles.tableHeader}
										>Status</Text>
									</th>
									<th
										style={{
											textAlign: 'left',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
									</th>
									<th
										style={{
											textAlign: 'left',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
									</th>
								</tr>
								{item.booked.map((booking, index) => (
									<>
										<tr
											key={index}
											style={{
												height: 64,
												cursor: 'pointer'
											}}
											onClick={() => {
												const update = {};
												update['indicator_' + booking.id] = true;
												setBookingDetailsDisplay(update)
											}}
										>
											<td
												style={{
													borderBottomColor: '#E4E1E0',
													paddingLeft: 16,
													paddingRight: 16
												}}
											>
												<View
													style={{
														flexDirection: 'row',
														alignItems: 'center'
													}}
												>
													<Image
														style={{
															borderRadius: 16,
															marginRight: 16,
															width: 32,
															height: 32,
														}}
														source={{
															uri: booking.studio.artwork_url
														}}
													/>
													<View>
														<Text
															style={{
																fontSize: 16
															}}
														>{booking.studio.name}</Text>
														<Text
															style={{
																color: '#909090',
																marginTop: 4,
																fontWeight: '600'
															}}
														>{booking.email}</Text>
													</View>
												</View>
											</td>
											<td
												style={{
													borderBottomColor: '#E4E1E0',
													paddingLeft: 16,
													paddingRight: 16
												}}
											>
												<Text
													style={{
														color: '#909090',
													}}
												>{moment.utc(booking.created_at).local().format("MMM D, YYYY")}</Text>
											</td>
											<td
												style={{
													borderBottomColor: '#E4E1E0',
													paddingLeft: 16,
													paddingRight: 16
												}}
											>
												<Text
													style={{
														color: '#909090',
														marginTop: 4,
														fontWeight: '600'
													}}
												>${booking.cost}</Text>
											</td>
											<td
												style={{
													borderBottomColor: '#E4E1E0',
													paddingLeft: 16,
													paddingRight: 16
												}}
											>
												<BookingStatus status={booking.status}/>
											</td>
											<td
												style={{
													borderBottomColor: '#E4E1E0',
													paddingLeft: 16,
													paddingRight: 16
												}}
											>
												<Text
													style={{
														fontWeight: '600',
														color: '#595959'
													}}
												>More</Text>
											</td>
										</tr>
										{bookingDetailsDisplay['indicator_' + booking.id] && (
											<tr>
												<td
													colSpan="7"
												>
													<View
														style={{
															backgroundColor: '#F8F8F8',
															paddingHorizontal: 8,
															height: 36,
															justifyContent: 'space-between',
															alignItems: 'center',
															flexDirection: 'row',
														}}
													>
														<Text
															style={{
																fontWeight: '600',
																fontSize: 16
															}}
														>Booking information</Text>
														<TouchableOpacity
															onPress={() => { setBookingDetailsDisplay([])}}
														>
															<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M15.625 4.375L4.375 15.625" stroke="#6D6E6F" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
																<path d="M15.625 15.625L4.375 4.375" stroke="#6D6E6F" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
															</svg>
														</TouchableOpacity>
													</View>
													<View
														style={{
															flexDirection: 'row',
															justifyContent: 'space-between',
															paddingVertical: 16
														}}
													>
														<View
															style={{
																flex: 1,
																paddingLeft: 16,
															}}
														>
															<Text
																style={{
																	fontWeight: '600',
																	fontSize: 20
																}}
															>Date & Time</Text>
															<View
																style={{
																	flexDirection: 'row',
																	marginTop: 16
																}}
															>
																<View
																	style={{
																		width: '50%'
																	}}
																>
																	<Text
																		style={{
																			color: '#3DBA71',
																			fontWeight: '700',
																			fontSize: 12
																		}}
																	>Session starts at</Text>
																	<Text
																		style={{
																			marginTop: 8,
																			fontWeight: '600',
																			color: colors.mainTextColor
																		}}
																	>{moment.utc(booking.start_time).local().format("hh:mm A")}</Text>
																</View>
																<View
																	style={{
																		width: '50%'
																	}}
																>
																	<Text
																		style={{
																			color: '#3DBA71',
																			fontWeight: '700',
																			fontSize: 12
																		}}
																	>Booking date</Text>
																	<Text
																		style={{
																			marginTop: 8,
																			fontWeight: '600',
																			color: colors.mainTextColor
																		}}
																	>{moment(booking.start_time).format('DD MMM Y')}</Text>
																</View>
															</View>
															<View
																style={{
																	flexDirection: 'row',
																	marginTop: 16
																}}
															>
																<View
																	style={{
																		width: '50%'
																	}}
																>
																	<Text
																		style={{
																			color: '#3DBA71',
																			fontWeight: '700',
																			fontSize: 12
																		}}
																	>Session duration</Text>
																	<Text
																		style={{
																			marginTop: 8,
																			fontWeight: '600',
																			color: colors.mainTextColor
																		}}
																	>{StyleDict.utils.diffTime(booking.start_time, booking.end_time)} Hours</Text>
																</View>
																<View
																	style={{
																		width: '50%'
																	}}
																>
																	<Text
																		style={{
																			color: '#3DBA71',
																			fontWeight: '700',
																			fontSize: 12
																		}}
																	>Session ends at</Text>
																	<Text
																		style={{
																			marginTop: 8,
																			fontWeight: '600',
																			color: colors.mainTextColor
																		}}
																	>{moment.utc(booking.end_time).local().format("hh:mm A")}</Text>
																</View>
															</View>
														</View>
														<View
															style={{
																flex: 1,
																paddingRight: 16,

															}}
														>
															<Text
																style={{
																	fontWeight: '600',
																	fontSize: 20
																}}
															>Extra equipment & amenities</Text>
															<View
																style={{
																	marginBottom: 24
																}}
															>
																{booking.booking_amenities.map((item, index) => item.amenity && (
																	<View
																		key={index}
																		style={{
																			flexDirection: 'row',
																			marginTop: 16,
																			justifyContent: 'space-between',
																			borderBottomWidth: 1,
																			borderBottomColor: dark ? '#313233' : '#E4E1E0',
																			paddingBottom: 16
																		}}
																	>
																		<Text
																			style={{
																				fontWeight: '600',
																				fontSize: 14,
																				color: colors.mainSubtextColor
																			}}
																		>{item.amenity.name} ({item.quantity} hours)</Text>
																		{item.cost === 0 && (
																			<Text
																				style={{
																					fontWeight: '600',
																					color: colors.mainTextColor
																				}}
																			>Free</Text>
																		)}
																		{item.cost > 0 && (
																			<Text
																				style={{
																					fontWeight: '600',
																					color: colors.mainTextColor
																				}}
																			>+ ${item.cost}</Text>
																		)}
																	</View>
																))}
																{booking.booking_equipments.map((item, index) => item.equipment && (
																	<View
																		key={index}
																		style={{
																			flexDirection: 'row',
																			marginTop: 16,
																			justifyContent: 'space-between',
																			borderBottomWidth: 1,
																			borderBottomColor: dark ? '#313233' : '#E4E1E0',
																			paddingBottom: 16
																		}}
																	>
																		<Text
																			style={{
																				fontWeight: '600',
																				fontSize: 14,
																				color: colors.mainSubtextColor
																			}}
																		>{item.equipment.name} ({item.quantity} hours)</Text>
																		{item.cost === 0 && (
																			<Text
																				style={{
																					fontWeight: '600',
																					color: colors.mainTextColor
																				}}
																			>Free</Text>
																		)}
																		{item.cost > 0 && (
																			<Text
																				style={{
																					fontWeight: '600',
																					color: colors.mainTextColor
																				}}
																			>+ ${item.cost}</Text>
																		)}
																	</View>
																))}
															</View>

															{item && (() => {
																const baseCost = StyleDict.utils.diffTimeToHours(moment(booking.start_time).toDate(), moment(booking.end_time).toDate()) * booking.room.per_session_price;
																let amenitiesCost = 0;
																booking.booking_amenities.forEach(item => {
																	amenitiesCost = amenitiesCost + item.cost * item.quantity
																});
																let equipmentsCost = 0;
																booking.booking_equipments.forEach(item => {
																	equipmentsCost = equipmentsCost + item.cost * item.quantity
																});
																const subTotal = baseCost + amenitiesCost + equipmentsCost;
																const serviceFee = subTotal * 0.03;
																const totalCost = baseCost + amenitiesCost + equipmentsCost + serviceFee;

																return (
																	<>
																		<View
																			style={{
																				justifyContent: 'space-between',
																				flexDirection: 'row',
																				marginBottom: 8
																			}}
																		>
																			<Text
																				style={{
																					color: colors.mainTextColor
																				}}
																			>Subtotal</Text>
																			<Text
																				style={{
																					color: colors.mainTextColor
																				}}
																			>$ {(booking.cost - booking.total_service_fee).toFixed(2)}</Text>
																		</View>
																		<View
																			style={{
																				justifyContent: 'space-between',
																				flexDirection: 'row',
																				marginBottom: 8
																			}}
																		>
																			<Text
																				style={{
																					color: colors.mainTextColor
																				}}
																			>Service fee</Text>
																			<Text
																				style={{
																					color: colors.mainTextColor
																				}}
																			>$ {booking.total_service_fee}</Text>
																		</View>
																		<View
																			style={{
																				justifyContent: 'space-between',
																				flexDirection: 'row',
																				marginTop: 4
																			}}
																		>
																			<Text
																				style={{
																					color: colors.mainTextColor,
																					fontWeight: '700'
																				}}
																			>Total</Text>
																			<Text
																				style={{
																					color: colors.mainTextColor,
																					fontWeight: '700'
																				}}
																			>$ {booking.cost}</Text>
																		</View>
																	</>
																)
															})()}
														</View>
													</View>
												</td>
											</tr>
										)}
									</>
								))}
							</table>
						</View>
					)}
					<View
						style={{
							marginVertical: 32
						}}
					>
						<Text
							style={{

							}}
						><strong>Send message to {item.name}</strong></Text>
						<Text
							style={{
								marginTop: 8,
								color: '#6D6E6F'
							}}
						>Talk to the user about any problem about the Stugo</Text>
						<View
							style={{
								marginTop: 16,
								backgroundColor: '#f8f8f8' ,
								paddingTop: 16,
								paddingLeft: 16,
								paddingRight: 16,
								borderWidth: 1,
								borderColor: '#E4E1E0',
								overflow: 'hidden',
								borderRadius: 8,
								paddingBottom:8,
							}}>
							<TextInput
								ref={textInput}
								placeholder={`Send message to ${item.name}`}
								style={{
									borderColor: '#AFABAC',
									flex: 1,
									outline: 0,
									fontSize: 16
								}}
								value={messageString}
								onChangeText={text => {
									setMessageString(text)
								}}
								multiline={true}
								numberOfLines={4}
								blurOnSubmit={true}
								onSubmitEditing={() => sendMessage()}
							/>
							<View
								style={{
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'space-between'
								}}
							>
								<TouchableOpacity
									style={{
										backgroundColor: 'white',
										height: 26,
										borderRadius: 13,
										paddingHorizontal: 8,
										flexDirection: 'row',
										alignItems: 'center'
									}}
								>
									<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M10.52 1.592c-.53 0-1.04.21-1.415.586L2.978 8.305a3.336 3.336 0 1 0 4.717 4.717l6.127-6.127a.667.667 0 1 1 .943.943l-6.127 6.127a4.669 4.669 0 1 1-6.603-6.603l6.127-6.127a3.335 3.335 0 1 1 4.716 4.716l-6.133 6.127a2 2 0 0 1-2.83-2.83l5.66-5.653a.667.667 0 1 1 .943.943l-5.66 5.653a.668.668 0 0 0 .944.944l6.133-6.126a2.002 2.002 0 0 0-1.415-3.417Z"
											fill="#6D6E6F"
										/>
									</svg>
									<Text
										style={{
											marginLeft: 8,
											fontSize: 12,
											color: '#595959'
										}}
									>Attach file - PDF, JPG, PNG</Text>
								</TouchableOpacity>
								<TouchableOpacity
									onPress={() => sendMessage()}
									style={{
										flexDirection: 'row',
										alignItems: 'center',
									}}
								>
									<Text
										style={{
											marginRight: 8,
											color: '#096730',
											fontWeight: '600'
										}}
									>Send message</Text>
									<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M15.138.862c.26.26.26.682 0 .943L7.805 9.138a.667.667 0 1 1-.943-.943L14.195.862c.26-.26.683-.26.943 0Z"
											fill="#096730"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M15.138.862c.181.181.243.45.158.692l-4.667 13.333a.667.667 0 0 1-1.238.05L6.828 9.172 1.063 6.609a.667.667 0 0 1 .05-1.238L14.447.704a.667.667 0 0 1 .691.158ZM3.141 6.074l4.463 1.983c.151.067.272.188.339.339l1.983 4.463L13.58 2.42 3.14 6.074Z"
											fill="#096730"
										/>
									</svg>
								</TouchableOpacity>
							</View>
						</View>
					</View>
					{(!item.group || (item.group && item.group.role_id !== 1)) && (
						<TouchableOpacity
							onPress={() => {
								confirmAlert({
									title: 'Confirm to delete',
									message: 'Are you sure to do this.',
									buttons: [
										{
											label: 'No',
										},
										{
											label: 'Yes',
											className: 'confirm-delete',
											onClick: () => {
												API.post('admin/deleteUser', {
													id: item.id,
												}).then(res => {
													getUsers();
													setIsVisible(false);
												}).catch (error => {
													console.log(error)
												});
											}
										},
									]
								});
							}}
							style={{
								alignSelf: 'flex-start',
								height: 48,
								backgroundColor: '#E55A5A',
								borderRadius: 8,
								alignItems: 'center',
								justifyContent: 'center',
								marginLeft: 8,
								flexDirection: 'row',
								paddingHorizontal: 16
							}}
						>
							<Text
								style={{
									fontWeight: '600',
									fontSize: 16,
									color: 'white',
								}}
							>Remove this user</Text>
						</TouchableOpacity>
					)}
				</View>
			</ScrollView>
		</View>
	)
}

const styles = StyleSheet.create({
	tableHeader: {
		fontWeight: '600',
		textAlign: 'left',
		alignSelf: 'flex-start'
	},
	tableCell: {
		borderBottom: '1px solid',
		borderBottomColor: 'gray',
		paddingLeft: 16,
		paddingRight: 16
	},
});

export default connect(({auth}) => ({auth}))(Modal);