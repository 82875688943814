import React, {useEffect, useState} from 'react';
import {View, Text, TextInput, ActivityIndicator, Image, ImageBackground} from "react-native";
import {TouchableOpacity} from "react-native-web";
import DatePicker from "react-datepicker";
import FormTextInput from "../../components/FormTextInput";
import API from "../../helpers/Axios";
import {toast} from "react-toastify";

function Modal ({ setIsVisible, item }) {
	const [isLoading, setIsLoading] = useState(false);
	const [schedule, setSchedule] = useState(false);
	const [startDate, setStartDate] = useState(new Date());

	const [title, setTitle] = useState({
		value: '',
		error: false,
		errorString: null
	});

	const [body, setBody] = useState({
		value: '',
		error: false,
		errorString: null
	});

	const [url, setUrl] = useState({
		value: '',
		error: false,
		errorString: null
	});

	const sendNotif = () => {
		if(!title.value.length || isLoading) {
			return
		}
		setIsLoading(true);
		const data = new FormData();
		const lid = (+ new Date());
		data.append('title', title.value);
		data.append('url', url.value);
		data.append('message', body.value.trim());
		data.append('schedule', schedule);
		data.append('start_date', startDate);

		API.post('admin/send-notifications', data)
			.then(res => {
				setIsLoading(false);
				setIsVisible(false);
				toast.success('Notification sent!')
			}).catch (error => {
			console.log(error)
		});
	}


	return (
		<View
			style={{
				flex: 1,
			}}
		>
			<View
				style={{
					flex: 1,
					justifyContent: 'center',
					alignItems: 'center',
					borderBottomWidth: 1,
					borderColor: '#E4E1E0',
					padding: 16
				}}
			>
				<Text
					style={{
						fontWeight: '500',
						fontSize: 20
					}}
				>Send push notification</Text>
				<TouchableOpacity
					onPress={() => setIsVisible(false)}
					style={{
						position: 'absolute',
						right: 16,
					}}
				>
					<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M18.707 5.293a1 1 0 0 1 0 1.414l-12 12a1 1 0 0 1-1.414-1.414l12-12a1 1 0 0 1 1.414 0Z"
							fill="#6D6E6F"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M5.293 5.293a1 1 0 0 1 1.414 0l12 12a1 1 0 0 1-1.414 1.414l-12-12a1 1 0 0 1 0-1.414Z"
							fill="#6D6E6F"
						/>
					</svg>
				</TouchableOpacity>
			</View>
			<View
				style={{
					padding: 32
				}}
			>
				<View
					style={{
						flexDirection: 'row',
					}}
				>
					<View
						style={{
							width: '40%',
							paddingRight: 12
						}}
					>
						<FormTextInput
							name={'Title'}
							placeholder={'Add push notification title'}
							value={title.value}
							error={title.error}
							errorString={title.errorString}
							maxLength={30}
							onChangeText={text => {
								setTitle({
									value: text,
									error: false,
									errorString: null
								});
							}}
						/>
						<FormTextInput
							name={'Studio/Room URL (optional)'}
							placeholder={'Set page to open upon clicking it'}
							value={url.value}
							error={url.error}
							errorString={url.errorString}
							maxLength={30}
							onChangeText={text => {
								setUrl({
									value: text,
									error: false,
									errorString: null
								});
							}}
						/>
						<FormTextInput
							name={'Message'}
							placeholder={'Add message body'}
							value={body.value}
							error={body.error}
							errorString={body.errorString}
							multiline={true}
							numberOfLines={4}
							maxLength={380}
							onChangeText={text => {
								setBody({
									value: text,
									error: false,
									errorString: null
								});
							}}
						/>
						<View
							style={{
								flexDirection: 'row',
								justifyContent: 'flex-end',
								marginTop: -12
							}}
						>
							<Text
								style={{
									color: '#1A1A1A',
									fontSize: 12
								}}
							>{body.value.length}/380</Text>
						</View>
						<TouchableOpacity
							onPress={() => {
								setSchedule(!schedule)
							}}
							style={{
								marginTop: 24,
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'space-between'
							}}
						>
							<Text
								style={{
									marginRight: 8,
									color: '#096730',
									fontWeight: '600'
								}}
							>Schedule notification</Text>
							{!schedule && (
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect x="0.75" y="0.75" width="18.5" height="18.5" rx="2.25" stroke="#CCCCCC" stroke-width="1.5"/>
								</svg>
							)}
							{!!schedule && (
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect width="20" height="20" rx="3" fill="#58B17D"/>
									<path d="M4.29306 10.7212C3.39997 9.68471 4.75038 8.09982 5.64331 9.13634L8.3843 12.3037L14.3502 5.34088C15.2433 4.29852 16.601 5.88322 15.7079 6.92558L9.06654 14.6727C8.69361 15.1081 8.08874 15.1094 7.71441 14.6749L4.29306 10.7212Z" fill="white"/>
								</svg>
							)}
						</TouchableOpacity>
						{!!schedule && (
							<View
								style={{
									flexDirection: 'row',
									marginHorizontal: -4,
									paddingTop: 8,
								}}
							>
								<View
									style={{
										width: '50%',
										paddingHorizontal: 4
									}}
								>
									<View
										style={{
											height: 48,
											borderWidth: 1,
											borderRadius: 8,
											borderColor: '#6D6E6F',
											backgroundColor: '#F8F8F8',
											alignItems: 'center',
											flexDirection: 'row',
											justifyContent: 'space-between',
											paddingHorizontal: 4
										}}
									>
										<DatePicker
											selected={startDate}
											onChange={(date) => setStartDate(date)}
										/>
										<svg width="24" height="24" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M16.1631 2.00146V6.00146" stroke="#6D6E6F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M8.16309 2.00146V6.00146" stroke="#6D6E6F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M3.16309 9.00146H21.1631" stroke="#6D6E6F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
											<path fill-rule="evenodd" clip-rule="evenodd" d="M19.1631 4.00146H5.16309C4.05809 4.00146 3.16309 4.89646 3.16309 6.00146V19.0015C3.16309 20.1065 4.05809 21.0015 5.16309 21.0015H19.1631C20.2681 21.0015 21.1631 20.1065 21.1631 19.0015V6.00146C21.1631 4.89646 20.2681 4.00146 19.1631 4.00146Z" stroke="#6D6E6F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M7.17601 12.7305C7.03801 12.7305 6.92601 12.8425 6.92701 12.9805C6.92701 13.1185 7.03901 13.2305 7.17701 13.2305C7.31501 13.2305 7.42701 13.1185 7.42701 12.9805C7.42701 12.8425 7.31501 12.7305 7.17601 12.7305" stroke="#6D6E6F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M12.176 12.7305C12.038 12.7305 11.926 12.8425 11.927 12.9805C11.927 13.1185 12.039 13.2305 12.177 13.2305C12.315 13.2305 12.427 13.1185 12.427 12.9805C12.427 12.8425 12.315 12.7305 12.176 12.7305" stroke="#6D6E6F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M17.176 12.7305C17.038 12.7305 16.926 12.8425 16.927 12.9805C16.927 13.1185 17.039 13.2305 17.177 13.2305C17.315 13.2305 17.427 13.1185 17.427 12.9805C17.427 12.8425 17.315 12.7305 17.176 12.7305" stroke="#6D6E6F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M7.17601 16.7305C7.03801 16.7305 6.92601 16.8425 6.92701 16.9805C6.92701 17.1185 7.03901 17.2305 7.17701 17.2305C7.31501 17.2305 7.42701 17.1185 7.42701 16.9805C7.42701 16.8425 7.31501 16.7305 7.17601 16.7305" stroke="#6D6E6F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M12.176 16.7305C12.038 16.7305 11.926 16.8425 11.927 16.9805C11.927 17.1185 12.039 17.2305 12.177 17.2305C12.315 17.2305 12.427 17.1185 12.427 16.9805C12.427 16.8425 12.315 16.7305 12.176 16.7305" stroke="#6D6E6F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
										</svg>
									</View>
								</View>
								<View
									style={{
										width: '50%',
										paddingHorizontal: 4
									}}
								>
									<View
										style={{
											height: 48,
											borderWidth: 1,
											borderRadius: 8,
											borderColor: '#6D6E6F',
											backgroundColor: '#F8F8F8',
											alignItems: 'center',
											flexDirection: 'row',
											justifyContent: 'space-between',
											paddingHorizontal: 4
										}}
									>
										<DatePicker
											showTimeSelect={true}
											showTimeSelectOnly={true}
											selected={startDate}
											timeIntervals={15}
											timeCaption="Time"
											dateFormat="h:mm aa"
											onChange={(date) => setStartDate(date)}
										/>
										<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M16.2296 12.1208H11.5776" stroke="#6D6E6F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
											<circle cx="12.18" cy="11.5182" r="9.00375" stroke="#6D6E6F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M11.5776 12.1207V6.51611" stroke="#6D6E6F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
										</svg>
									</View>
								</View>
							</View>
						)}
					</View>
					<View
						style={{
							width: '50%',
							paddingLeft: 24
						}}
					>
						<Text
							style={{
								fontWeight: '500',
								fontSize: 14,
								color: '#096730',
							}}
						>Message preview</Text>
						<ImageBackground
							source={{uri: '/assets/dashboard/notif.jpeg'}}
							style={{
								marginVertical: 16,
								flex: 1,
								padding: 16,
								borderRadius: 8,
								overflow: 'hidden',
								alignItems: 'center',
								justifyContent: 'center'
							}}
						>
							<View
								style={{
									flexDirection: 'row',
									padding: 16,
									backgroundColor: 'rgba(37, 37, 37, 0.5)',
									borderRadius: 8,
									width: '100%',
									maxWidth: 390,
									justifyContent: 'space-between'
								}}
							>
								<View
									style={{
										flex: 1,
										flexDirection: 'row'
									}}
								>
									<svg width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M29.6626 0.903564H6.68334C3.45391 0.903564 0.835938 3.45609 0.835938 6.60479V29.0096C0.835938 32.1583 3.45391 34.7108 6.68334 34.7108H29.6626C32.8921 34.7108 35.51 32.1583 35.51 29.0096V6.60479C35.51 3.45609 32.8921 0.903564 29.6626 0.903564Z" fill="#212529"/>
										<path d="M16.9454 25.378C18.7928 24.9419 20.9052 23.6437 21.365 22.3973C21.2603 22.6225 21.0611 22.87 20.6825 23.1161C20.0715 23.5133 19.2987 23.7147 18.3857 23.7147C17.6464 23.7274 16.9118 23.5989 16.2233 23.3364C15.5318 23.0451 14.8991 22.636 14.3548 22.1282C14.035 21.8419 13.6153 21.6852 13.1812 21.69C12.9368 21.6896 12.695 21.7384 12.4709 21.8332C12.2467 21.928 12.045 22.0668 11.8783 22.241C11.5382 22.5697 11.3454 23.0163 11.3418 23.4832C11.3451 23.9531 11.5302 24.4044 11.8602 24.7469C12.9605 25.2418 14.8896 25.8631 16.9454 25.378Z" fill="#2DC02E" stroke="white" stroke-width="0.058987"/>
										<path d="M23.0956 9.76819C22.3348 9.48033 20.4421 9.26809 18.494 10.2435C16.7295 11.1269 15.415 12.7388 15.616 13.0875C15.7644 12.8604 15.9491 12.6578 16.1629 12.4874C16.7077 12.1192 17.4801 11.9325 18.4587 11.9325C19.1343 11.944 19.8018 12.0778 20.4273 12.327C21.1079 12.5857 21.64 12.8982 22.0086 13.2557L22.0362 13.281C22.3191 13.5201 22.683 13.648 23.0573 13.6401C23.2996 13.6417 23.5397 13.5947 23.7626 13.502C23.9856 13.4093 24.1865 13.2729 24.3531 13.1012C24.6888 12.7754 24.8782 12.3328 24.8794 11.8709C24.8794 11.3086 24.6127 10.7956 24.0865 10.346C24.0268 10.29 23.9634 10.236 23.9006 10.1823C23.6931 10.0448 23.4731 9.92631 23.2434 9.82821C23.2434 9.82821 23.1674 9.7954 23.0956 9.76819Z" fill="#2DC02E" stroke="white" stroke-width="0.058987"/>
										<path d="M24.4189 18.8096C23.8767 18.0234 23.118 17.4022 22.2301 17.0174C21.3646 16.6254 20.2449 16.2817 18.9096 15.9979C17.6909 15.7237 16.7741 15.3929 16.1849 15.0144C15.7144 14.7126 15.4953 14.3029 15.4953 13.7248C15.4895 13.4482 15.5698 13.1764 15.7257 12.9452C16.1398 12.0492 16.7632 11.1268 18.5206 10.2435C20.4603 9.26845 22.3449 9.48029 23.1024 9.76815C23.1742 9.79546 23.2495 9.82817 23.2495 9.82817C23.4783 9.9263 23.6973 10.0448 23.9038 10.1822C23.2358 9.62846 22.4679 9.20078 21.6394 8.92107C20.6276 8.56735 19.5601 8.38909 18.4853 8.39436C17.3294 8.37852 16.1826 8.59628 15.1173 9.0339C14.1411 9.43082 13.2989 10.0871 12.6866 10.928C12.0866 11.7786 11.7738 12.7905 11.7917 13.8226C11.7917 15.3949 12.3361 16.651 13.4098 17.5563C14.4185 18.4065 15.8541 19.0218 17.6681 19.3824C19.0306 19.6725 20.0442 20.0306 20.6804 20.4465C20.9288 20.5964 21.1328 20.8068 21.2722 21.0571C21.4115 21.3073 21.4815 21.5887 21.4751 21.8734C21.4777 22.054 21.4399 22.2329 21.3643 22.3977C20.9043 23.644 18.7922 24.9422 16.9447 25.3783C14.8891 25.8634 12.96 25.2421 11.8597 24.7475C11.932 24.8261 12.0095 24.9001 12.0915 24.9691C12.9306 25.7256 13.911 26.3178 14.9794 26.7135V26.7135C15.983 27.0715 17.1456 27.253 18.4349 27.253C19.5866 27.2637 20.7276 27.0367 21.783 26.587C22.771 26.1738 23.6279 25.5108 24.265 24.6665C24.8875 23.826 25.2165 22.8126 25.2035 21.7759C25.2038 20.5792 24.9398 19.5813 24.4189 18.8096Z" fill="white"/>
									</svg>
									<View
										style={{
											marginLeft: 8
										}}
									>
										<Text
											style={{
												color: 'white',
												fontWeight: '600',
												fontSize: 16
											}}
										>{title.value || 'Add push notification title'}</Text>
										<Text
											style={{
												marginTop: 2,
												color: 'white',
												fontSize: 14
											}}
										>{body.value || 'Add message body'}</Text>
									</View>
								</View>
								<Text
									style={{
										fontSize: 13,
										color: 'rgba(235, 235, 245, 0.6)'
									}}
								>now</Text>
							</View>
							<View
								style={{
									marginTop: 24,
									padding: 16,
									backgroundColor: 'white',
									borderRadius: 8,
									width: '100%',
									maxWidth: 390,
									justifyContent: 'space-between'
								}}
							>
								<View
									style={{
										flexDirection: 'row',
										alignItems: 'center',
									}}
								>
									<svg width="16" height="16" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M29.6626 0.903564H6.68334C3.45391 0.903564 0.835938 3.45609 0.835938 6.60479V29.0096C0.835938 32.1583 3.45391 34.7108 6.68334 34.7108H29.6626C32.8921 34.7108 35.51 32.1583 35.51 29.0096V6.60479C35.51 3.45609 32.8921 0.903564 29.6626 0.903564Z" fill="#212529"/>
										<path d="M16.9454 25.378C18.7928 24.9419 20.9052 23.6437 21.365 22.3973C21.2603 22.6225 21.0611 22.87 20.6825 23.1161C20.0715 23.5133 19.2987 23.7147 18.3857 23.7147C17.6464 23.7274 16.9118 23.5989 16.2233 23.3364C15.5318 23.0451 14.8991 22.636 14.3548 22.1282C14.035 21.8419 13.6153 21.6852 13.1812 21.69C12.9368 21.6896 12.695 21.7384 12.4709 21.8332C12.2467 21.928 12.045 22.0668 11.8783 22.241C11.5382 22.5697 11.3454 23.0163 11.3418 23.4832C11.3451 23.9531 11.5302 24.4044 11.8602 24.7469C12.9605 25.2418 14.8896 25.8631 16.9454 25.378Z" fill="#2DC02E" stroke="white" stroke-width="0.058987"/>
										<path d="M23.0956 9.76819C22.3348 9.48033 20.4421 9.26809 18.494 10.2435C16.7295 11.1269 15.415 12.7388 15.616 13.0875C15.7644 12.8604 15.9491 12.6578 16.1629 12.4874C16.7077 12.1192 17.4801 11.9325 18.4587 11.9325C19.1343 11.944 19.8018 12.0778 20.4273 12.327C21.1079 12.5857 21.64 12.8982 22.0086 13.2557L22.0362 13.281C22.3191 13.5201 22.683 13.648 23.0573 13.6401C23.2996 13.6417 23.5397 13.5947 23.7626 13.502C23.9856 13.4093 24.1865 13.2729 24.3531 13.1012C24.6888 12.7754 24.8782 12.3328 24.8794 11.8709C24.8794 11.3086 24.6127 10.7956 24.0865 10.346C24.0268 10.29 23.9634 10.236 23.9006 10.1823C23.6931 10.0448 23.4731 9.92631 23.2434 9.82821C23.2434 9.82821 23.1674 9.7954 23.0956 9.76819Z" fill="#2DC02E" stroke="white" stroke-width="0.058987"/>
										<path d="M24.4189 18.8096C23.8767 18.0234 23.118 17.4022 22.2301 17.0174C21.3646 16.6254 20.2449 16.2817 18.9096 15.9979C17.6909 15.7237 16.7741 15.3929 16.1849 15.0144C15.7144 14.7126 15.4953 14.3029 15.4953 13.7248C15.4895 13.4482 15.5698 13.1764 15.7257 12.9452C16.1398 12.0492 16.7632 11.1268 18.5206 10.2435C20.4603 9.26845 22.3449 9.48029 23.1024 9.76815C23.1742 9.79546 23.2495 9.82817 23.2495 9.82817C23.4783 9.9263 23.6973 10.0448 23.9038 10.1822C23.2358 9.62846 22.4679 9.20078 21.6394 8.92107C20.6276 8.56735 19.5601 8.38909 18.4853 8.39436C17.3294 8.37852 16.1826 8.59628 15.1173 9.0339C14.1411 9.43082 13.2989 10.0871 12.6866 10.928C12.0866 11.7786 11.7738 12.7905 11.7917 13.8226C11.7917 15.3949 12.3361 16.651 13.4098 17.5563C14.4185 18.4065 15.8541 19.0218 17.6681 19.3824C19.0306 19.6725 20.0442 20.0306 20.6804 20.4465C20.9288 20.5964 21.1328 20.8068 21.2722 21.0571C21.4115 21.3073 21.4815 21.5887 21.4751 21.8734C21.4777 22.054 21.4399 22.2329 21.3643 22.3977C20.9043 23.644 18.7922 24.9422 16.9447 25.3783C14.8891 25.8634 12.96 25.2421 11.8597 24.7475C11.932 24.8261 12.0095 24.9001 12.0915 24.9691C12.9306 25.7256 13.911 26.3178 14.9794 26.7135V26.7135C15.983 27.0715 17.1456 27.253 18.4349 27.253C19.5866 27.2637 20.7276 27.0367 21.783 26.587C22.771 26.1738 23.6279 25.5108 24.265 24.6665C24.8875 23.826 25.2165 22.8126 25.2035 21.7759C25.2038 20.5792 24.9398 19.5813 24.4189 18.8096Z" fill="white"/>
									</svg>
									<Text
										style={{
											marginHorizontal: 8
										}}
									>Stugo</Text>
									<svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
										<circle cx="2" cy="1.51813" r="1.5" fill="#212121"/>
									</svg>
									<Text
										style={{
											marginHorizontal: 8
										}}
									>now</Text>
								</View>
								<View
									style={{
										marginTop: 8
									}}
								>
									<Text
										style={{
											fontWeight: '500',
											fontSize: 15
										}}
									>{title.value || 'Add push notification title'}</Text>
									<Text
										style={{
											marginTop: 4,
											fontSize: 14
										}}
									>{body.value || 'Add message body'}</Text>
								</View>
								<View
									style={{
										marginTop: 8,
										flexDirection: 'row'
									}}
								>
									<Text
										style={{
											fontWeight: '500',
											color: '#757575',
											marginRight: 24
										}}
									>Save Story</Text>
									<Text
										style={{
											fontWeight: '500',
											color: '#757575'
										}}
									>Share</Text>
								</View>
							</View>
						</ImageBackground>
					</View>
				</View>
				<View
					style={{
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'flex-end',
					}}
				>
					<TouchableOpacity
						onPress={() => {
							sendNotif();
						}}
						style={{
							paddingHorizontal: 24,
							marginLeft: 16,
							fontWeight: '600',
							fontSize: 16,
							height: 48,
							borderRadius: 8,
							backgroundColor: '#19994F',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'row'
						}}
					>
						<Text
							style={{
								fontWeight: '600',
								fontSize: 16,
								color: 'white',
								marginRight: 8
							}}
						>Send notification</Text>
						{isLoading && <ActivityIndicator color={'white'}/>}
					</TouchableOpacity>
				</View>
			</View>
		</View>
	)
}
export default Modal;