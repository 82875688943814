import React, {useEffect, useState} from 'react';
import {View, Text, ActivityIndicator, Modal} from "react-native";
import {TouchableOpacity} from "react-native-web";
import API from "../../helpers/Axios";
import {Views} from "react-big-calendar";
import moment from "moment";

const EditPaymentScreen = ({ setIsVisible, item }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [isChargesEnabled, setIsChargesEnabled] = useState(false);
	const [isConnecting, setIsConnecting] = useState(false);
	const [connectedData, setConnectedData] = useState(false);
	const [isDisableItemVisible, setIsDisableItemVisible] = useState(false);
	const [isDisconnectLoading, setIsDisconnectLoading] = useState(false);
	const [tabIndex, setTabIndex] = useState(0);
	const [statements, setStatements] = useState([]);

	const checkIsChargeAble = () => {
		API.get(`stripe/check/connected-account`)
			.then(res => {
				setIsLoading(false);
				setConnectedData(res.data);
				setIsChargesEnabled(res.data.charges_enabled);
				setIsConnecting(false);
			}).catch (error => {
			console.log(error);
		});
	}

	useEffect(() => {
		checkIsChargeAble();
		API.post(`owner/statement`)
			.then(res => {
				setStatements(res.data.data);
			}).catch (error => {
			console.log(error);
		});
		window.addEventListener('message', function(e){
			setIsLoading(true);
			checkIsChargeAble();
		});
	}, []);

	return (
		<View
			style={{
				flex: 1,
			}}
		>
			<Modal onRequestClose={() => setIsDisableItemVisible(false)} transparent visible={isDisableItemVisible}>
				<View
					style={{
						backgroundColor: 'white',
						borderColor: '#eee',
						borderRadius: 8,
						borderWidth: 1,
						margin: 'auto',
						shadowColor: "#000",
						shadowOffset: {
							width: 0,
							height: 2,
						},
						shadowOpacity: 0.05,
						shadowRadius: 3.84,
						elevation: 5,
						padding: 24,
						maxWidth: 550
					}}
				>
					<Text
						style={{
							textAlign: 'center',
							fontSize: 24,
							fontWeight: '600',
							marginBottom: 24
						}}
					>Disconnect from your Stripe account</Text>
					<Text
						style={{
							color: '#595959',
							fontSize: 16,
							lineHeight: 22,
							textAlign: 'center'
						}}
					>Are you sure you want to disconnect from your Stripe account? Your payout will be putted on pause until the Stripe is connected again.</Text>
					<View
						style={{
							marginTop: 24,
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'space-between'
						}}
					>
						<TouchableOpacity
							onPress={() => {
								setIsDisableItemVisible(false);
								setIsDisconnectLoading(false);
							}}
							style={{
								height: 48,
								borderWidth: 1,
								borderColor: '#E4E1E0',
								borderRadius: 8,
								alignItems: 'center',
								justifyContent: 'center',
								flex: 1,
								marginRight: 8
							}}
						>
							<Text
								style={{
									fontWeight: '600',
									fontSize: 16
								}}
							>Cancel</Text>
						</TouchableOpacity>
						<TouchableOpacity
							onPress={() => {
								if(isDisconnectLoading) {
									return;
								}
								setIsDisconnectLoading(true);
								API.post(`stripe/disconnect-account`)
									.then(res => {
										setIsDisconnectLoading(false);
										setIsDisableItemVisible(false);
										setIsChargesEnabled(false);
										setIsConnecting(false);
										setConnectedData(null);
									}).catch (error => {
									console.log(error.response);
								});
							}}
							style={{
								height: 48,
								backgroundColor: '#E55A5A',
								borderRadius: 8,
								alignItems: 'center',
								justifyContent: 'center',
								flex: 1,
								marginLeft: 8,
								flexDirection: 'row'
							}}
						>
							<Text
								style={{
									fontWeight: '600',
									fontSize: 16,
									color: 'white',
								}}
							>Disconnect</Text>
							{isDisconnectLoading && <ActivityIndicator color={'white'} style={{marginLeft: 8}}/>}
						</TouchableOpacity>
					</View>
				</View>
			</Modal>
			<View
				style={{
					flex: 1,
					alignItems: 'center',
					borderBottomWidth: 1,
					borderColor: '#E4E1E0',
					flexDirection: 'row'
				}}
			>
				<TouchableOpacity
					onPress={() => setTabIndex(0)}
					style={{
						height: 50,
						alignItems: 'center',
						justifyContent: 'center',
						paddingHorizontal: 24,
						borderBottomWidth: 3,
						borderColor: tabIndex === 0 ? '#096730' : 'white'
					}}
				>
					<Text
						style={{
							fontWeight: '500',
							fontSize: 20
						}}
					>Stripe</Text>
				</TouchableOpacity>
				<TouchableOpacity
					onPress={() => setTabIndex(1)}
					style={{
						height: 50,
						alignItems: 'center',
						justifyContent: 'center',
						paddingHorizontal: 24,
						borderBottomWidth: 3,
						borderColor: tabIndex === 1 ? '#096730' : 'white'
					}}
				>
					<Text
						style={{
							fontWeight: '500',
							fontSize: 20
						}}
					>Payouts</Text>
				</TouchableOpacity>
				<TouchableOpacity
					onPress={() => setIsVisible(false)}
					style={{
						position: 'absolute',
						right: 16,
					}}
				>
					<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M18.707 5.293a1 1 0 0 1 0 1.414l-12 12a1 1 0 0 1-1.414-1.414l12-12a1 1 0 0 1 1.414 0Z"
							fill="#6D6E6F"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M5.293 5.293a1 1 0 0 1 1.414 0l12 12a1 1 0 0 1-1.414 1.414l-12-12a1 1 0 0 1 0-1.414Z"
							fill="#6D6E6F"
						/>
					</svg>
				</TouchableOpacity>
			</View>
			{tabIndex === 0 && (
				<>
					<Text
						style={{
							textAlign: 'center',
							fontSize: 16,
							marginVertical: 16
						}}
					>Connect Your Stripe Account To Get Paid</Text>
					<View
						style={{
							padding: 32,
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						{isLoading && <ActivityIndicator color={'black'}/>}
						{!isLoading && (
							<>
								{isChargesEnabled && (
									<>
										<View
											style={{
												flexDirection: 'row',
												justifyContent: 'center',
												alignItems: 'center',
											}}
										>
											<View
												style={{
													backgroundColor: '#edfdf4',
													paddingHorizontal: 32,
													height: 50,
													borderRadius: 8,
													justifyContent: 'center',
													flexDirection: 'row',
													alignItems: 'center'
												}}
											>
												<Text
													style={{
														fontWeight: '600',
														fontSize: 16,
														color: '#096730',
													}}
												>{connectedData.email}</Text>
											</View>
											<TouchableOpacity
												onPress={() => setIsDisableItemVisible(true)}
												style={{
													backgroundColor: '#f24c4c',
													paddingHorizontal: 32,
													height: 50,
													borderRadius: 8,
													justifyContent: 'center',
													flexDirection: 'row',
													alignItems: 'center',
													marginLeft: 16
												}}
											>
												<Text
													style={{
														fontWeight: '600',
														fontSize: 16,
														color: 'white',
													}}
												>Disconnect</Text>
											</TouchableOpacity>
										</View>
										<View
											style={{
												marginTop: 24,
												paddingVertical: 16,
												maxWidth: 732,
												paddingHorizontal: 16,
												backgroundColor: '#f5f4f3',
												borderRadius: 8,
											}}
										>
											<Text
												style={{
													color: '#6d6e6f'
												}}
											>In order to change your studios payout account, simply disconnect the stripe account and reconnect to the correct stripe account you prefer to use.</Text>
										</View>
									</>
								)}
								{!isChargesEnabled && (
									<>
										<Text
											style={{
												fontSize: 16,
												textAlign: 'center',
												marginBottom: 24
											}}
										>You need to provide more information to Stripe to enable payments and payouts on your account.</Text>
										<TouchableOpacity
											onPress={() => {
												if(! isConnecting) {
													setIsConnecting(true);
													API.post(`stripe/create/account-link`, {
														'refresh_url': window.location.origin + '/stripe-refresh.html',
														'return_url' : window.location.origin + '/stripe-return.html',
													}).then(res => {
														window.open(res.data.url, "_blank")
													}).catch (error => {
														console.log(error);
													});
												}
											}}
											style={{
												backgroundColor: '#1e5538',
												paddingHorizontal: 32,
												height: 50,
												borderRadius: 8,
												justifyContent: 'center',
												flexDirection: 'row',
												alignItems: 'center'
											}}
										>
											<Text
												style={{
													fontWeight: '600',
													fontSize: 16,
													color: 'white',
													marginRight: 8
												}}
											>Connect Your Stripe Account</Text>
											{isConnecting && <ActivityIndicator color={'white'}/>}
										</TouchableOpacity>
									</>
								)}
							</>
						)}
					</View>
				</>
			)}
			{tabIndex === 1 && (
				<View
					style={{
						marginVertical: 16,
						paddingHorizontal: 16,
						font: '14px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
						borderTopLeftRadius: 6,
						borderTopRightRadius: 6,
						overflow: 'hidden'
					}}
				>
					<table cellspacing="0" cellpadding="0">
						<thead>
						<tr
							style={{
								color: 'white',
								lineHeight: '40px',
							}}
						>
							<th
								style={{
									textAlign: 'left',
									paddingLeft: 8,
									backgroundColor: '#096730',
									'border-top-left-radius': '4px'
								}}
							>Date</th>
							<th
								style={{
									textAlign: 'left',
									backgroundColor: '#096730',
								}}
							>Type</th>
							<th
								style={{
									textAlign: 'left',
									backgroundColor: '#096730',
								}}
							>Detail</th>
							<th
								style={{
									textAlign: 'left',
									backgroundColor: '#096730',
								}}
							>Amount</th>
							<th
								style={{
									textAlign: 'left',
									backgroundColor: '#096730',
									'border-top-right-radius': '4px'
								}}
							>Status</th>
						</tr>
						</thead>
						<tbody>
						{statements.map((item) => (
							<tr
								style={{
									lineHeight: '40px',
									backgroundColor: 'whitesmoke'
								}}
							>
								<td
									style={{
										'border-bottom': '1px solid #d4d4d4',
										paddingLeft: 8
									}}
								>{moment(item.issued_at).format('DD MMM Y hh:mm A')}</td>
								<td
									style={{
										'border-bottom': '1px solid #d4d4d4'
									}}
								>
									<span>Payout</span>
								</td>
								<td
									style={{
										'border-bottom': '1px solid #d4d4d4'
									}}
								>Via Stripe</td>
								<td
									style={{
										'border-bottom': '1px solid #d4d4d4',
										color: '#f24c4c',
										fontWeight: '600',
									}}
								>$-{item.amount}</td>
								<td
									style={{
										'border-bottom': '1px solid #d4d4d4',
									}}
								>
									{item.status === 0 && (
										<View
											style={{
												height: 26,
												borderRadius: 13,
												backgroundColor: '#EBE8E7',
												justifyContent: 'center',
												alignItems: 'center',
												width: 'fit-content',
												alignSelf: 'flex-start',
												paddingHorizontal: 8
											}}
										>
											<Text
												style={{
													fontWeight: '600',
													fontSize: 12,
												}}
											>{'Processing'}</Text>
										</View>
									)}
									{item.status === 1 && (
										<View
											style={{
												height: 26,
												borderRadius: 13,
												backgroundColor: '#3DBA71',
												justifyContent: 'center',
												alignItems: 'center',
												width: 'fit-content',
												alignSelf: 'flex-start',
												paddingHorizontal: 8
											}}
										>
											<Text
												style={{
													fontWeight: '600',
													fontSize: 12,
													color: 'white',
												}}
											>{'Paid'}</Text>
										</View>
									)}
								</td>
							</tr>
						))}
						</tbody>
					</table>
				</View>
			)}
		</View>
	)
}
export default EditPaymentScreen;
