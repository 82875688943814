import { AppRegistry } from "react-native";
import App from "./App";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import 'react-confirm-alert/src/react-confirm-alert.css';

AppRegistry.registerComponent("App", () => App);

AppRegistry.runApplication("App", {
  rootTag: document.getElementById("root")
});
