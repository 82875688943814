import React, {useEffect, useState} from 'react';
import {View, Text, TextInput, ActivityIndicator} from "react-native";
import RoundTextInput from "../../components/RoundTextInput";
import {TouchableOpacity} from "react-native-web";
import API from "../../helpers/Axios";

const Modal = ({ setIsVisible, item, setCurrentRoomEquipments }) => {
	const [additionEquipment, setAdditionEquipment] = useState('');
	const [additionEquipmentPrice, setAdditionEquipmentPrice] = useState('');
	const [roomEquipments, setRoomEquipments] = useState(item.equipments);
	const [isLoading, setIsLoading] = useState(false);

	return (
		<View
			style={{
				flex: 1,
			}}
		>
			<View
				style={{
					flex: 1,
					justifyContent: 'center',
					alignItems: 'center',
					borderBottomWidth: 1,
					borderColor: '#E4E1E0',
					padding: 16
				}}
			>
				<Text
					style={{
						fontWeight: '500',
						fontSize: 20
					}}
				>Edit Equipments</Text>
				<TouchableOpacity
					onPress={() => setIsVisible(false)}
					style={{
						position: 'absolute',
						right: 16,
					}}
				>
					<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M18.707 5.293a1 1 0 0 1 0 1.414l-12 12a1 1 0 0 1-1.414-1.414l12-12a1 1 0 0 1 1.414 0Z"
							fill="#6D6E6F"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M5.293 5.293a1 1 0 0 1 1.414 0l12 12a1 1 0 0 1-1.414 1.414l-12-12a1 1 0 0 1 0-1.414Z"
							fill="#6D6E6F"
						/>
					</svg>
				</TouchableOpacity>
			</View>
			<View
				style={{
					padding: 32
				}}
			>
				<View
					style={{
						marginTop: 16,
						flexDirection: 'row',
						flexWrap: 'wrap'
					}}
				>
					{roomEquipments.map((item, index) => (
						<View
							key={index}
							style={{
								backgroundColor: '#EBE8E7',
								paddingHorizontal: 12,
								height: 32,
								borderRadius: 16,
								justifyContent: 'center',
								marginRight: 16,
								marginBottom: 16,
								flexDirection: 'row',
								alignItems: 'center',
							}}
						>
							<Text
								style={{
									fontWeight: '600',
									marginRight: 8
								}}
							>{item.name} - {item.cost ? `$${item.cost}/hr`: 'Free'}</Text>
							<TouchableOpacity
								onPress={() => {
									roomEquipments.splice(index, 1);
									setRoomEquipments(prev => ([...roomEquipments]));
								}}
							>
								<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M9.887 6.113 6.113 9.886M9.887 9.886 6.113 6.113"
										stroke="#1E1F21"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										clipRule="evenodd"
										d="M8 14v0a6 6 0 0 1-6-6v0a6 6 0 0 1 6-6v0a6 6 0 0 1 6 6v0a6 6 0 0 1-6 6Z"
										stroke="#1E1F21"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</TouchableOpacity>
						</View>
					))}
				</View>
				<View
					style={{
						maxWidth: '70%',
					}}
				>
					<Text
						style={{
							fontSize: 20,
							color: '#6D6E6F',
							fontWeight: '500'
						}}
					>Add Equipment</Text>
					<View
						style={{
							flexDirection: 'row',
							borderRadius: 8,
							borderWidth: 1,
							borderColor: '#D2D2D2',
							marginTop: 16,
							height: 64,
						}}
					>
						<View
							style={{
								flex: 1,
								flexDirection: 'row',
								justifyContent: 'space-between'
							}}
						>
							<TextInput
								placeholder={'Add additional equipment'}
								style={{
									flex: 1,
									outline: 0,
									paddingHorizontal: 16,
									fontSize: 16
								}}
								value={additionEquipment}
								onChangeText={text => setAdditionEquipment(text)}
							/>
							<TextInput
								placeholder={'Additional cost: $ 0.00 '}
								style={{
									borderLeftWidth: 1,
									borderColor: '#AFABAC',
									flex: 1,
									outline: 0,
									paddingHorizontal: 16,
									fontSize: 16
								}}
								value={additionEquipmentPrice}
								onChangeText={text => {
									let val = text;
									val = val.replace(/([^0-9.]+)/, "");
									val = val.replace(/^(0|\.)/, "");
									const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);
									const value = match[1] + match[2];
									setAdditionEquipmentPrice(value);
								}}

							/>
						</View>
						<TouchableOpacity
							onPress={() => {
								if(additionEquipment) {
									let item = {
										name: additionEquipment,
										cost: additionEquipmentPrice ? additionEquipmentPrice : 0
									}
									let equipments = roomEquipments;
									equipments.push(item);
									setRoomEquipments(prev => ([...equipments]));
									setAdditionEquipment('');
									setAdditionEquipmentPrice('');
								}
							}}
							style={{
								backgroundColor: 'rgba(61,186,113,0.2)',
								justifyContent: 'center',
								alignItems: 'center',
								paddingHorizontal: 24
							}}
						>
							<Text
								style={{
									color: '#178545',
									fontWeight: '600'
								}}
							>Add</Text>
						</TouchableOpacity>
					</View>
					<View
						style={{
							backgroundColor: '#F5F4F3',
							padding: 8,
							marginTop: 16
						}}
					>
						<Text
							style={{
								color: '#6D6E6F'
							}}
						>Please note that Equipment can be free or charged per hour.</Text>
					</View>
				</View>
				<View
					style={{
						flexDirection: 'row',
						justifyContent: 'flex-end',
						marginTop: 32
					}}
				>
					<TouchableOpacity
						onPress={() => setIsVisible(false)}
						style={{
							width: 132,
							fontWeight: '600',
							fontSize: 16,
							paddingHorizontal: 32,
							borderWidth: 1,
							height: 48,
							borderRadius: 8,
							borderColor: '#E4E1E0',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<Text
							style={{
								fontWeight: '600',
								fontSize: 16
							}}
						>Cancel</Text>
					</TouchableOpacity>
					<TouchableOpacity
						onPress={() => {
							setIsLoading(true);
							const data = new FormData();
							data.append('id', item.id);
							for (let i = 0; i < roomEquipments.length; i++) {
								data.append('equipments[]', JSON.stringify(roomEquipments[i]));
							}
							API.post('owner/save-room-equipments', data).then(res => {
								setRoomEquipments(res.data.equipments);
								setCurrentRoomEquipments(res.data.equipments);
								setIsLoading(false);
								setIsVisible(false);
							}).catch (error => {
								console.log(error);
							});
						}}
						style={{
							width: 132,
							marginLeft: 16,
							fontWeight: '600',
							fontSize: 16,
							height: 48,
							borderRadius: 8,
							backgroundColor: '#19994F',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'row'
						}}
					>
						<Text
							style={{
								fontWeight: '600',
								fontSize: 16,
								color: 'white',
								marginRight: 8
							}}
						>Save</Text>
						{isLoading && <ActivityIndicator color={'white'}/>}
					</TouchableOpacity>
				</View>
			</View>
		</View>
	)
}
export default Modal;