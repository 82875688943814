import { combineReducers } from 'redux';
import auth from './auth';
import coordinate from './coordinate';
import display from './display';
import message from './message';
import common from './common';

export default combineReducers({
    auth, coordinate, display, message, common
});
