import React, {useState} from "react";
import {Text, View, TouchableOpacity, ImageBackground, ActivityIndicator} from "react-native";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import API from "../../helpers/Axios";
import FieldSetTextInput from "../../components/FieldSetTextInput";
import AsyncStorage from "@react-native-async-storage/async-storage";

const SetNewPasswordScreen = ({navigation, route, dispatch}) => {
	const [password, setPassword] = useState({
		value: '',
		error: false,
		errorString: null
	});
	const [passwordConfirm, setPasswordConfirm] = useState({
		value: '',
		error: false,
		errorString: null
	});

	const [isSubmitting, setIsSubmitting] = useState( false);

	const onSubmit = () => {
		if(isSubmitting) {
			return
		}

		API.post('set-new-password', {
			email: route.params?.email,
			code: route.params?.code,
			password: password.value,
			password_confirmation: passwordConfirm.value
		})
			.then(res => {
				setIsSubmitting(false);
				AsyncStorage.setItem('access_token', res.data.access_token);
				dispatch({type: 'TOGGLE_AUTH', user: res.data.user});
				dispatch({type: 'UPDATE_ACCESS_TOKEN', accessToken: res.data.access_token});
				navigation.replace("TabsStack");
			}).catch (error => {
			setIsSubmitting(false)
			setPassword({
				value: password.value,
				error: error.response.data.errors['password'] !== undefined,
				errorString: error.response.data.errors['password'] !== undefined ? error.response.data.errors['password'][0] : 'Error'
			});
			setPasswordConfirm({
				value: passwordConfirm.value,
				error: error.response.data.errors['comfirm_password'] !== undefined,
				errorString: error.response.data.errors['comfirm_password'] !== undefined ? error.response.data.errors['comfirm_password'][0] : 'Error'
			});
		});
	}

	return (
		<ImageBackground
			source={{uri: '/assets/splash/1.jpeg'}}
			style={{
				position: 'absolute',
				flex: 1,
				width: '100%',
				height: '100vh',
				margin: 0,
				padding: 0,
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: '#1D1F1E',
			}}
		>
			<View
				style={{
					position: 'absolute',
					backgroundColor: 'rgba(29, 31, 30, 0.4)',
					top: 0,
					right: 0,
					left: 0,
					bottom: 0
				}}
			/>
			<View
				style={{
					maxWidth: 450
				}}
			>
				<View
					style={{
						marginBottom: 16,
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between'
					}}
				>
					<TouchableOpacity
						onPress={() => navigation.goBack()}
					>
						<Text
							style={{
								color: 'white'
							}}
						>Back</Text>
					</TouchableOpacity>
				</View>
				<View
					style={{
						padding: 48,
						backgroundColor: 'white',
						borderRadius: 8,
					}}
				>
					<Text
						style={{
							color: '#3DBA71',
							fontSize: 34,
							marginBottom: 24,
							fontWeight: '600',
						}}
					>Set your new password</Text>
					<Text
						style={{
							fontSize: 20,
							marginBottom: 24,
							color: '#6D6E6F'
						}}
					>You now can set your new password, make it secured!</Text>
					<View
						style={{
							marginTop: 12
						}}
					>
						<View>
							<FieldSetTextInput
								placeholder={'Password'}
								value={password.value}
								error={password.error}
								errorString={password.errorString}
								onChangeText={text => {
									setPassword({
										value: text,
										error: false,
										errorString: password.errorString
									});
								}}
								secureTextEntry={true}
							/>
						</View>
						<Text
							style={{
								marginTop: 8,
								color: '#AFABAC'
							}}
						>Password needs 8 characters, both uppercase and lowercase letters and at least one symbol.</Text>
						<View
							style={{
								marginTop: 24
							}}
						>
							<FieldSetTextInput
								placeholder={'Confirm password'}
								value={passwordConfirm.value}
								error={password.error}
								errorString={null}
								onChangeText={text => {
									setPasswordConfirm({
										value: text,
										error: false,
										errorString: null
									});
								}}
								secureTextEntry={true}
							/>
						</View>
					</View>
					<TouchableOpacity
						onPress={() =>  {
							setIsSubmitting(true);
							onSubmit();
						}}
						style={{
							backgroundColor: '#3DBA71',
							alignItems: 'center',
							justifyContent: 'center',
							borderRadius: 4,
							height: 48,
							marginTop: 40
						}}
					>
						{isSubmitting && <ActivityIndicator color={'white'}/>}
						{!isSubmitting && <Text
							style={{
								color: 'white',
								fontSize: 16,
								fontWeight: '600'
							}}
						>Continue</Text>}
					</TouchableOpacity>
				</View>
			</View>
		</ImageBackground>
	)
}

SetNewPasswordScreen.propTypes = {
	navigation: PropTypes.object,
};

export default connect(({auth}) => ({auth}))(SetNewPasswordScreen);