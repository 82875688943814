import React, {useState} from 'react'
import {View, StyleSheet, Text, TextInput} from 'react-native'

const FormTextInput = ({ value = '', placeholder = 'Placeholder', name, error = false, errorString = 'Error', onChangeText, onPress, style, textStyle, ...props}) => {
  if(name === undefined) {
    name = placeholder;
  }
  return (
    <View
      style={{
        marginBottom: 16
      }}
    >
      <View
        style={{
          borderRadius: 8,
          backgroundColor: 'white',
          minHeight: 60,
          justifyContent: 'center'
        }}
      >
        <Text
          style={{
            fontWeight: '500',
            fontSize: 14,
            color: error ? '#B96363' : (value.length > 0 ? '#096730' : '#096730'),
          }}
        >{name}</Text>
        <TextInput
          style={{
            backgroundColor: value.length > 0 ? '#F8F8F8' : 'white',
            borderRadius: 8,
            paddingHorizontal: 16,
            paddingVertical: 12,
            borderWidth: 1,
            borderColor: error ? '#F09696' : (value.length > 0 ? '#AFABAC' : '#E4E1E0'),
            marginTop: 8,
            fontWeight: '400',
            fontSize: 16,
            color: 'black',
            outline: 'none'
          }}
          placeholder={placeholder}
          onChangeText={onChangeText}
          placeholderTextColor={'#a9a9a9'}
          autoCapitalize="words"
          underlineColorAndroid="rgba(0,0,0,0)"
          value={value}
          {...props}
        />
      </View>
      {error && <Text
        style={{
          color: '#B96363',
          marginTop: 4,
          marginLeft: 16
        }}
      >{errorString}</Text>}
    </View>

  )
}

export default FormTextInput
