import React, {useEffect, useMemo, useState} from "react";
import {
  Text,
  View,
  TouchableOpacity
} from "react-native";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {useNavigation, useRoute} from "@react-navigation/native";

function DashboardSideBar () {
  const navigation = useNavigation();
  const route = useRoute();
  const [routeName, setRouteName] = useState(route.name);

  useEffect(() => {
    setTimeout(() => {
      setRouteName(window.location.href.substring(window.location.href.lastIndexOf('/') + 1))
    }, 100)
  }, [window.location.href.substring(window.location.href.lastIndexOf('/') + 1)]);

  return (
    <View
      style={{
        backgroundColor: 'white',
        padding: 24,
      }}
    >
      {[
        {
          title: 'Dashboard',
          screen: 'DashboardScreen',
          icon: routeName === 'DashboardScreen' ? <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12 15a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM14 11l2-2"
              stroke="#31A762"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.778 21a10.101 10.101 0 0 1-3.384-5.097 10.189 10.189 0 0 1 .164-6.134A10.088 10.088 0 0 1 6.21 4.865 9.935 9.935 0 0 1 12 3c2.075 0 4.098.652 5.79 1.865a10.088 10.088 0 0 1 3.652 4.904c.683 1.978.74 4.122.164 6.134A10.101 10.101 0 0 1 18.222 21H5.778Z"
              stroke="#31A762"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg> : <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12 15a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM14 11l2-2"
              stroke="#595959"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.778 21a10.101 10.101 0 0 1-3.384-5.097 10.189 10.189 0 0 1 .164-6.134A10.088 10.088 0 0 1 6.21 4.865 9.935 9.935 0 0 1 12 3c2.075 0 4.098.652 5.79 1.865a10.088 10.088 0 0 1 3.652 4.904c.683 1.978.74 4.122.164 6.134A10.101 10.101 0 0 1 18.222 21H5.778Z"
              stroke="#595959"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        },
        {
          title: 'Studio approval',
          screen: 'StudiosScreen',
          icon: <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.571 4H6.286c-.606 0-1.188.237-1.617.659C4.241 5.081 4 5.653 4 6.25v13.5c0 .597.24 1.169.67 1.591.428.422 1.01.659 1.616.659h11.428c.607 0 1.188-.237 1.617-.659.428-.422.669-.994.669-1.591V6.25c0-.597-.24-1.169-.67-1.591A2.304 2.304 0 0 0 17.715 4H15.43"
              stroke={routeName === 'StudiosScreen' ? '#31A762': "#6D6E6F"}
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13 2h-2a2 2 0 1 0 0 4h2a2 2 0 1 0 0-4ZM9 14.5l2 2.5 4-5"
              stroke={routeName === 'StudiosScreen' ? '#31A762': "#6D6E6F"}
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        },
        {
          title: 'Notifications',
          screen: 'NotificationsScreen',
          icon: <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
              id="b"
              style={{
                maskType: "alpha",
              }}
              maskUnits="userSpaceOnUse"
              x={2}
              y={1}
              width={20}
              height={22}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 16v2h20v-2a2 2 0 0 1-2-2V9A8 8 0 1 0 4 9v5a2 2 0 0 1-2 2Zm16-2V9A6 6 0 0 0 6 9v5c0 .729-.195 1.412-.535 2h13.07A3.982 3.982 0 0 1 18 14Zm-3.405 7.502a3 3 0 0 1-5.19 0L8.534 20h6.932l-.871 1.502Z"
                fill="#fff"
              />
            </mask>
            <g mask="url(#b)">
              <path fill={routeName === 'NotificationsScreen' ? '#31A762': "#6D6E6F"} d="M0 0h24v24H0z" />
            </g>
          </svg>
        },
        {
          title: 'Client database',
          screen: 'ClientsScreen',
          icon:  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
              id="c"
              style={{
                maskType: "alpha",
              }}
              maskUnits="userSpaceOnUse"
              x={0}
              y={3}
              width={24}
              height={18}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.105 11.553c.13-.259.371-.688.722-1.232.582-.9 1.267-1.799 2.057-2.641C6.187 5.223 8.898 3.727 12 3.727c3.101 0 5.813 1.496 8.116 3.953.79.842 1.475 1.741 2.056 2.641.352.544.593.973.722 1.232l.224.447-.224.447a13.9 13.9 0 0 1-.722 1.232c-.58.9-1.267 1.799-2.056 2.641-2.303 2.457-5.015 3.953-8.116 3.953-3.102 0-5.813-1.496-8.116-3.953a18.72 18.72 0 0 1-2.057-2.641 13.892 13.892 0 0 1-.722-1.232L.882 12l.223-.447Zm2.403 1.04c.52.806 1.133 1.61 1.835 2.36 1.959 2.089 4.19 3.32 6.657 3.32 2.467 0 4.698-1.231 6.657-3.32a16.736 16.736 0 0 0 2.2-2.953 16.736 16.736 0 0 0-2.201-2.952c-1.958-2.09-4.19-3.32-6.656-3.32-2.467 0-4.698 1.23-6.657 3.32A16.738 16.738 0 0 0 3.142 12c.107.184.23.383.366.594ZM12 16a4 4 0 1 1 0-8 4 4 0 0 1 0 8Zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
                fill="#fff"
              />
            </mask>
            <g mask="url(#c)">
              <path fill={routeName === 'ClientsScreen' ? '#31A762': "#6D6E6F"} d="M0 0h24v24H0z" />
            </g>
          </svg>
        },
        {
          title: 'Revenue overview',
          screen: 'RevenueScreen',
          icon: <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
              id="a"
              style={{
                maskType: "alpha",
              }}
              maskUnits="userSpaceOnUse"
              x={1}
              y={1}
              width={22}
              height={22}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 12c0 6.075 4.925 11 11 11s11-4.925 11-11S18.075 1 12 1 1 5.925 1 12Zm20 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-8-5h1.917v2h-4.375c-.58 0-1.042.452-1.042 1s.462 1 1.042 1h2.916c1.676 0 3.042 1.338 3.042 3s-1.366 3-3.042 3H13v2h-2v-2H8.5v-2h4.958c.58 0 1.042-.452 1.042-1s-.462-1-1.042-1h-2.916C8.866 13 7.5 11.662 7.5 10s1.366-3 3.042-3H11V5h2v2Z"
                fill="#fff"
              />
            </mask>
            <g mask="url(#a)">
              <path fill={routeName === 'RevenueScreen' ? '#31A762': "#6D6E6F"} d="M0 0h24v24H0z" />
            </g>
          </svg>
        },
        {
          title: 'Issue reports',
          screen: 'ReportsScreen',
          icon: <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12 7.375v3.375m0 3.375v.011M3 22V7.375A3.375 3.375 0 0 1 6.375 4h11.25A3.375 3.375 0 0 1 21 7.375v6.75a3.375 3.375 0 0 1-3.375 3.375H7.5L3 22Z"
              stroke={routeName === 'ReportsScreen' ? '#31A762': "#6D6E6F"}
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        },
        {
          title: 'Data',
          screen: 'DataScreen',
          icon: <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="m4 5 .003.015a.296.296 0 0 0 .032.066c.043.07.13.174.291.301.33.26.87.539 1.626.79C7.454 6.674 9.592 7 12 7s4.546-.327 6.048-.827c.756-.252 1.296-.53 1.626-.79.162-.128.248-.232.29-.302a.293.293 0 0 0 .033-.066L20 5l-.003-.015a.293.293 0 0 0-.032-.066 1.235 1.235 0 0 0-.291-.301c-.33-.26-.87-.539-1.626-.79C16.546 3.326 14.408 3 12 3s-4.546.327-6.048.827c-.756.252-1.296.53-1.626.79a1.236 1.236 0 0 0-.29.302.296.296 0 0 0-.033.066L4 5Zm1.32-3.07C7.075 1.345 9.437 1 12 1c2.563 0 4.925.345 6.68.93.873.29 1.651.66 2.232 1.117C21.482 3.496 22 4.148 22 5c0 .852-.519 1.504-1.088 1.953-.581.458-1.36.826-2.232 1.117-1.755.585-4.117.93-6.68.93-2.563 0-4.925-.345-6.68-.93-.873-.29-1.651-.66-2.232-1.117C2.518 6.504 2 5.852 2 5c0-.852.519-1.504 1.088-1.953.581-.458 1.36-.826 2.232-1.117ZM3 11a1 1 0 0 1 1 1c0 .002 0 .007.003.018a.298.298 0 0 0 .033.067c.042.07.127.173.288.3.328.26.864.537 1.618.789 1.497.5 3.635.826 6.058.826s4.561-.326 6.058-.826c.754-.252 1.29-.53 1.618-.789.16-.127.246-.23.288-.3a.298.298 0 0 0 .033-.067A.061.061 0 0 0 20 12a1 1 0 1 1 2 0c0 .85-.513 1.502-1.082 1.953-.579.459-1.355.827-2.226 1.118-1.753.585-4.115.929-6.692.929s-4.939-.344-6.692-.929c-.87-.29-1.647-.66-2.226-1.118C2.513 13.502 2 12.849 2 12a1 1 0 0 1 1-1Z"
              fill={routeName === 'DataScreen' ? '#31A762': "#6D6E6F"}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3 4a1 1 0 0 1 1 1v14c0 .002 0 .007.003.018a.298.298 0 0 0 .033.067c.042.07.127.173.288.3.328.26.864.537 1.618.789 1.497.5 3.635.826 6.058.826s4.561-.326 6.058-.826c.754-.252 1.29-.53 1.618-.789.16-.127.246-.23.288-.3a.298.298 0 0 0 .033-.067A.061.061 0 0 0 20 19V5a1 1 0 0 1 2 0v14c0 .85-.513 1.502-1.082 1.953-.579.459-1.355.827-2.226 1.118-1.753.585-4.115.929-6.692.929s-4.939-.344-6.692-.929c-.87-.29-1.647-.66-2.226-1.118C2.513 20.502 2 19.849 2 19V5a1 1 0 0 1 1-1Z"
              fill={routeName === 'DataScreen' ? '#31A762': "#6D6E6F"}
            />
          </svg>
        }
      ].map((item, index) => (
        <TouchableOpacity
          key={index}
          onPress={() => {
            setRouteName(item.screen);
            navigation.navigate(item.screen);
          }}
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 16
          }}
        >
          {item.icon}
          <Text
            style={{
              fontWeight: '500',
              marginLeft: 16,
              color: routeName === item.screen ? 'green' : 'black'
            }}
          >{item.title}</Text>
        </TouchableOpacity>
      ))}
    </View>

  );
}

DashboardSideBar.propTypes = {
  navigation: PropTypes.object,
};

export default connect(({auth}) => ({auth}))(DashboardSideBar);
