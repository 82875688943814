import React, {useEffect, useState} from 'react';
import {View, Text, TextInput, ActivityIndicator, Image} from "react-native";
import {TouchableOpacity} from "react-native-web";
import moment from "moment";
import StyleDict from "../../AppStyles";
import {useTheme} from "@react-navigation/native";
import API from "../../helpers/Axios";

function Modal ({ setIsVisible, item }) {
	const [reviews, setReviews] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		API.get(`owner/studio-reviews/${item.id}`)
			.then(res => {
				setReviews(res.data);
				setIsLoading(false);
			}).catch (error => {
			console.log(error);
		});
	}, []);

	return isLoading ? (<ActivityIndicator style={{flex: 1, justifyContent: 'center', alignItems: 'center', marginVertical: 64}} />) : (
		<View
			style={{
				flex: 1,
			}}
		>
			<View
				style={{
					flex: 1,
					justifyContent: 'center',
					alignItems: 'center',
					borderBottomWidth: 1,
					borderColor: '#E4E1E0',
					padding: 16
				}}
			>
				<Text
					style={{
						fontWeight: '500',
						fontSize: 20
					}}
				>{item.name} - Reviews</Text>
				<TouchableOpacity
					onPress={() => setIsVisible(false)}
					style={{
						position: 'absolute',
						right: 16,
					}}
				>
					<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M18.707 5.293a1 1 0 0 1 0 1.414l-12 12a1 1 0 0 1-1.414-1.414l12-12a1 1 0 0 1 1.414 0Z"
							fill="#6D6E6F"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M5.293 5.293a1 1 0 0 1 1.414 0l12 12a1 1 0 0 1-1.414 1.414l-12-12a1 1 0 0 1 0-1.414Z"
							fill="#6D6E6F"
						/>
					</svg>
				</TouchableOpacity>
			</View>
			<View
				style={{
					padding: 32
				}}
			>
				<View
					style={{
						flexDirection: 'row',
						alignItems: 'center'
					}}
				>
					<Text
						style={{
							fontSize: 16
						}}
					><Text style={{fontWeight: '600'}}>{reviews.average} stars</Text> ({reviews.total} reviews)</Text>
				</View>
				{reviews.data.map((item, index) => (
					<View
						key={index}
						style={{
							marginTop: 24
						}}
					>

						<View
							style={{
								flexDirection: 'row',
								alignItems: 'center',
								marginBottom: 16
							}}
						>
							<Image
								style={{
									width: 36,
									height: 36,
									borderRadius: 18,
									backgroundColor: 'gray',
									marginRight: 8
								}}
								source={{
									uri: item.user.artwork_url
								}}
							/>
							<View>
								<Text
									style={{
										fontWeight: '600',
									}}
								>{item.user.name}</Text>
								<Text
									style={{
										color: '#6D6E6F'
									}}
								>{moment(item.created_at).format('MMM D, YYYY h:mm A')}</Text>
							</View>
						</View>
						<Text
							style={{
								color: '#6D6E6F'
							}}
						>{item.comments}</Text>
					</View>
				))}
			</View>
		</View>
	)
}
export default Modal;