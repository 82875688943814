import { Appearance } from 'react-native'

const types = {
	TOGGLE_DARK_MODE: "TOGGLE_DARK_MODE",
	TOGGLE_SYSTEM_PREFERENCE: "TOGGLE_SYSTEM_PREFERENCE",
};

const initialState = {
	darkMode: Appearance.getColorScheme() === 'dark',
	systemPreference: false
};

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case types.TOGGLE_DARK_MODE:
			return {
				...state,
				darkMode: ! state.darkMode
			};
		case types.TOGGLE_SYSTEM_PREFERENCE:
			return {
				...state,
				systemPreference: ! state.systemPreference
			};
		default:
			return state;
	}
};
