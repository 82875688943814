import React, {useEffect, useMemo, useState} from "react";
import {
	Text,
	View,
	ScrollView,
	ActivityIndicator,
	Image,
	Modal
} from "react-native";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import API from "../../helpers/Axios";
import {TouchableOpacity} from "react-native-web";
import moment from 'moment';
import EditRoomScreen from "../modals/EditRoomScreen";
import EditRoomRulesScreen from "../modals/EditRoomRulesScreen";
import EditRoomEquipmentsScreen from "../modals/EditRoomEquipmentsScreen";
import EditRoomAmenitiesScreen from "../modals/EditRoomAmenitiesScreen";
import EditPicturesScreen from "../modals/EditPicturesScreen";
import {Popover} from "react-tiny-popover";

function RoomsScreen ({navigation, route}) {
	const [studios, setStudios] = useState([]);

	const [currentStudio, setCurrentStudio] = useState(null);
	const [currentRoom, setCurrentRoom] = useState(null);
	const [currentRoomRules, setCurrentRoomRules] = useState([]);
	const [currentRoomAmenities, setCurrentRoomAmenities] = useState([]);
	const [currentRoomEquipments, setCurrentRoomEquipments] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [menuDisplay, setMenuDisplay] = useState( false);
	const [isRoomEditVisible, setIsRoomEditIsVisible] = useState(false);
	const [roomOptionsMenu, setRoomOptionsMenu] = useState( false);
	const [isRoomRulesEditVisible, setIsRoomRulesEditIsVisible] = useState(false);
	const [isRoomEquipmentsEditVisible, setIsRoomEquipmentsEditIsVisible] = useState(false);
	const [isRoomAmenitiesEditVisible, setIsRoomAmenitiesEditIsVisible] = useState(false);
	const [isImageEditorVisible, setIsImageEditorVisible] = useState(false);
	const [isActive, setIsActive] = useState(null);
	const [isDisableItemVisible, setIsDisableItemVisible] = useState(false);
	const [isDisableLoading, setIsDisableLoading] = useState(false);
	const [isDeleteItemVisible, setIsDeleteItemVisible] = useState(false);
	const [isDeleteLoading, setIsDeleteLoading] = useState(false);

	const deleteRoom = (id) => {
		if(isDeleteLoading) {
			return;
		}
		setIsDeleteLoading(true);
		API.post('owner/delete-room', {
			id: id
		}).then(res => {
			setIsDeleteLoading(false);
			setIsDeleteItemVisible(false);
			let studio = currentStudio;
			let rooms = studio.rooms;
			rooms = rooms.filter(x => {
				return x.id !== id;
			});
			studio.rooms = rooms;
			setCurrentStudio(studio);
			if(studio.rooms.length) {
				setCurrentRoom(studio.rooms[0]);
			} else {
				setCurrentRoom(null);
			}
		}).catch (error => {
			console.log(error);
		});
	}

	useEffect(() => {
		if(currentRoom) {
			setCurrentRoomRules(currentRoom.rules);
			setCurrentRoomAmenities(currentRoom.amenities);
			setCurrentRoomEquipments(currentRoom.equipments);
			setIsActive(currentRoom.active);
		}
	}, [currentRoom]);

	const loadRoom = () => {
		API.get('owner/rooms')
			.then(res => {
				setStudios(res.data);
				if(res.data.length) {
					if(route.params?.item && route.params?.item.id !== undefined) {
						setCurrentStudio(route.params?.item);
						if(route.params?.item.rooms.length) {
							setCurrentRoom(route.params?.item.rooms[0]);
						}
					} else {
						setCurrentStudio(res.data[0]);
						if(res.data[0].rooms.length) {
							setCurrentRoom(res.data[0].rooms[0]);
						}
					}
					setIsLoading(false);
				}
			}).catch (error => {
			console.log(error);
		});
	}

	useEffect(() => {
		loadRoom();
	}, []);


	return isLoading ? (<ActivityIndicator style={{flex: 1, justifyContent: 'center', alignItems: 'center'}} />) : (
		<View
			style={{
				flex: 1
			}}
		>
			<Modal onRequestClose={() => setIsDeleteItemVisible(false)} transparent visible={isDeleteItemVisible}>
				{currentRoom && (
					<View
						style={{
							backgroundColor: 'white',
							borderColor: '#eee',
							borderRadius: 8,
							borderWidth: 1,
							margin: 'auto',
							shadowColor: "#000",
							shadowOffset: {
								width: 0,
								height: 2,
							},
							shadowOpacity: 0.05,
							shadowRadius: 3.84,
							elevation: 5,
							padding: 24,
							maxWidth: 550
						}}
					>
						<Text
							style={{
								textAlign: 'center',
								fontSize: 24,
								fontWeight: '600',
								marginBottom: 24
							}}
						>Are you sue want to delete this room</Text>
						<Text
							style={{
								color: '#595959',
								fontSize: 16,
								lineHeight: 22,
								textAlign: 'center'
							}}
						>Are you sure you want to permanently delete this room? You can not under this action.</Text>
						<View
							style={{
								marginTop: 24,
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'space-between'
							}}
						>
							<TouchableOpacity
								onPress={() => {
									setMenuDisplay(false);
									setIsDeleteItemVisible(false);
									setIsDeleteLoading(false);
								}}
								style={{
									height: 48,
									borderWidth: 1,
									borderColor: '#E4E1E0',
									borderRadius: 8,
									alignItems: 'center',
									justifyContent: 'center',
									flex: 1,
									marginRight: 8
								}}
							>
								<Text
									style={{
										fontWeight: '600',
										fontSize: 16
									}}
								>Cancel</Text>
							</TouchableOpacity>
							<TouchableOpacity
								onPress={() => {
									deleteRoom(currentRoom.id);
								}}
								style={{
									height: 48,
									backgroundColor: '#E55A5A',
									borderRadius: 8,
									alignItems: 'center',
									justifyContent: 'center',
									flex: 1,
									marginLeft: 8,
									flexDirection: 'row'
								}}
							>
								<Text
									style={{
										fontWeight: '600',
										fontSize: 16,
										color: 'white',
									}}
								>Delete room</Text>
								{isDeleteLoading && <ActivityIndicator color={'white'} style={{marginLeft: 8}}/>}
							</TouchableOpacity>
						</View>
					</View>
				)}
			</Modal>
			<Modal onRequestClose={() => setIsDisableItemVisible(false)} transparent visible={isDisableItemVisible}>
				{currentRoom && (
					<View
						style={{
							backgroundColor: 'white',
							borderColor: '#eee',
							borderRadius: 8,
							borderWidth: 1,
							margin: 'auto',
							shadowColor: "#000",
							shadowOffset: {
								width: 0,
								height: 2,
							},
							shadowOpacity: 0.05,
							shadowRadius: 3.84,
							elevation: 5,
							padding: 24,
							maxWidth: 550
						}}
					>
						<Text
							style={{
								textAlign: 'center',
								fontSize: 24,
								fontWeight: '600',
								marginBottom: 24
							}}
						>Temporarily disable room</Text>
						<Text
							style={{
								color: '#595959',
								fontSize: 16,
								lineHeight: 22,
								textAlign: 'center'
							}}
						>Are you sure you want to temporarily deactivate {currentRoom.name}? Users won’t be able to book sessions until the studio is activated again.</Text>
						<View
							style={{
								marginTop: 24,
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'space-between'
							}}
						>
							<TouchableOpacity
								onPress={() => {
									setMenuDisplay(false);
									setIsDisableItemVisible(false);
									setIsDisableLoading(false);
								}}
								style={{
									height: 48,
									borderWidth: 1,
									borderColor: '#E4E1E0',
									borderRadius: 8,
									alignItems: 'center',
									justifyContent: 'center',
									flex: 1,
									marginRight: 8
								}}
							>
								<Text
									style={{
										fontWeight: '600',
										fontSize: 16
									}}
								>Cancel</Text>
							</TouchableOpacity>
							<TouchableOpacity
								onPress={() => {
									if(isDisableLoading) {
										return;
									}
									setIsDisableLoading(true);
									API.post(`owner/disable-room`, {
										id: currentRoom.id
									})
										.then(res => {
											setMenuDisplay(false);
											setIsDisableLoading(false);
											setIsDisableItemVisible(false);
											setIsActive(res.data.active);
											setRoomOptionsMenu(false);
											let newStudios = studios;
											let studioIndex = newStudios.findIndex(item => item.id === currentStudio.id);
											let index = newStudios[studioIndex].rooms.findIndex(item => item.id === currentRoom.id);
											newStudios[studioIndex].rooms[index].active = res.data.active;
											setStudios(newStudios);
										}).catch (error => {
										console.log(error.response);
									});
								}}
								style={{
									height: 48,
									backgroundColor: '#E55A5A',
									borderRadius: 8,
									alignItems: 'center',
									justifyContent: 'center',
									flex: 1,
									marginLeft: 8,
									flexDirection: 'row'
								}}
							>
								<Text
									style={{
										fontWeight: '600',
										fontSize: 16,
										color: 'white',
									}}
								>Deactivate room</Text>
								{isDisableLoading && <ActivityIndicator color={'white'} style={{marginLeft: 8}}/>}
							</TouchableOpacity>
						</View>
					</View>
				)}
			</Modal>
			<Modal onRequestClose={() => setIsImageEditorVisible(false)} transparent visible={isImageEditorVisible}>
				<View
					style={{
						backgroundColor: 'white',
						borderColor: '#eee',
						borderRadius: 8,
						borderWidth: 1,
						margin: 'auto',
						minWidth: 780,
						shadowColor: "#000",
						shadowOffset: {
							width: 0,
							height: 2,
						},
						shadowOpacity: 0.05,
						shadowRadius: 3.84,
						elevation: 5,
					}}
				>
					<EditPicturesScreen
						item={currentRoom}
						setIsVisible={setIsImageEditorVisible}
						type={'rooms'}
					/>
				</View>
			</Modal>
			<Modal onRequestClose={() => setIsRoomEditIsVisible(false)} transparent visible={isRoomEditVisible}>
				<View
					style={{
						backgroundColor: 'white',
						borderColor: '#eee',
						borderRadius: 8,
						borderWidth: 1,
						margin: 'auto',
						minWidth: 780,
						shadowColor: "#000",
						shadowOffset: {
							width: 0,
							height: 2,
						},
						shadowOpacity: 0.05,
						shadowRadius: 3.84,
						elevation: 5,
					}}
				>
					<EditRoomScreen
						setIsVisible={setIsRoomEditIsVisible}
						setCurrentRoom={setCurrentRoom}
						item={currentRoom}
					/>
				</View>
			</Modal>
			<Modal onRequestClose={() => setIsRoomRulesEditIsVisible(false)} transparent visible={isRoomRulesEditVisible}>
				<View
					style={{
						backgroundColor: 'white',
						borderColor: '#eee',
						borderRadius: 8,
						borderWidth: 1,
						margin: 'auto',
						minWidth: 780,
						maxWidth: 880,
						shadowColor: "#000",
						shadowOffset: {
							width: 0,
							height: 2,
						},
						shadowOpacity: 0.05,
						shadowRadius: 3.84,
						elevation: 5,
					}}
				>
					<EditRoomRulesScreen
						setIsVisible={setIsRoomRulesEditIsVisible}
						setCurrentRoom={setCurrentRoom}
						setCurrentRoomRules={setCurrentRoomRules}
						item={currentRoom}
					/>
				</View>
			</Modal>
			<Modal onRequestClose={() => setIsRoomEquipmentsEditIsVisible(false)} transparent visible={isRoomEquipmentsEditVisible}>
				<View
					style={{
						backgroundColor: 'white',
						borderColor: '#eee',
						borderRadius: 8,
						borderWidth: 1,
						margin: 'auto',
						minWidth: 780,
						maxWidth: 880,
						shadowColor: "#000",
						shadowOffset: {
							width: 0,
							height: 2,
						},
						shadowOpacity: 0.05,
						shadowRadius: 3.84,
						elevation: 5,
					}}
				>
					<EditRoomEquipmentsScreen
						setIsVisible={setIsRoomEquipmentsEditIsVisible}
						setCurrentRoomEquipments={setCurrentRoomEquipments}
						item={currentRoom}
					/>
				</View>
			</Modal>
			<Modal onRequestClose={() => setIsRoomAmenitiesEditIsVisible(false)} transparent visible={isRoomAmenitiesEditVisible}>
				<View
					style={{
						backgroundColor: 'white',
						borderColor: '#eee',
						borderRadius: 8,
						borderWidth: 1,
						margin: 'auto',
						minWidth: 780,
						maxWidth: 880,
						shadowColor: "#000",
						shadowOffset: {
							width: 0,
							height: 2,
						},
						shadowOpacity: 0.05,
						shadowRadius: 3.84,
						elevation: 5,
					}}
				>
					<EditRoomAmenitiesScreen
						setIsVisible={setIsRoomAmenitiesEditIsVisible}
						setCurrentRoomAmenities={setCurrentRoomAmenities}
						item={currentRoom}
					/>
				</View>
			</Modal>
			<ScrollView
				style={{
					flex: 1,
					padding: 40
				}}
			>
				<Text
					style={{
						fontSize: 48,
						fontWeight: '600'
					}}
				>Rooms</Text>
				<View
					style={{
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
						zIndex: 1
					}}
				>
					<Text
						style={{
							marginTop: 16,
							fontSize: 20,
							color: '#595959'
						}}
					>See information about your rooms.</Text>
					<View
						style={{
							display: 'inline-block'
						}}
					>
						<Popover
							isOpen={menuDisplay}
							positions={['bottom', 'right']}
							align={'end'}
							containerStyle={{
								zIndex: 5,
							}}
							padding={10} // adjust padding here!
							reposition={false} // prevents automatic readjustment of content position that keeps your popover content within its parent's bounds
							onClickOutside={() => setMenuDisplay(false)} // handle click events outside of the popover/target here!
							content={({ position, nudgedLeft, nudgedTop }) => ( // you can also provide a render function that injects some useful stuff!
								<View
									style={{
										borderRadius: 8,
										width: 240,
										backgroundColor: 'white',
										shadowColor: "#000",
										shadowOffset: {
											width: 0,
											height: 2,
										},
										shadowOpacity: 0.25,
										shadowRadius: 3.84,
										elevation: 5,
										paddingHorizontal: 16
									}}
								>
									{studios.map((item, index) => (
										<TouchableOpacity
											key={index}
											onPress={() => {
												setMenuDisplay(false)
												setCurrentStudio(item);
												if(item.rooms.length) {
													setCurrentRoom(item.rooms[0])
												}
											}}
											style={{
												flexDirection: 'row',
												alignItems: 'center',
												marginVertical: 16
											}}
										>
											<View
												style={{
													width: 64,
													aspectRatio: 1.777,
													borderRadius: 4,
													overflow: 'hidden'
												}}
											>
												<Image
													style={{
														flex: 1
													}}
													source={{
														uri: item.artwork_url,
													}}
												/>
											</View>
											<Text
												style={{
													marginLeft: 12,
													fontWeight: '600'
												}}
											>{item.name}</Text>
										</TouchableOpacity>
									))}
								</View>
							)}
						>
							<TouchableOpacity
								onPress={() => {
									setMenuDisplay(!menuDisplay)
								}}
								style={{
									backgroundColor: 'white',
									padding: 16,
									borderRadius: 8,
									shadowColor: "#000",
									shadowOffset: {
										width: 0,
										height: 2,
									},
									shadowOpacity: 0.05,
									shadowRadius: 3.84,
									elevation: 5,
									flexDirection: 'row',
									justifyContent: 'space-between',
									width: 240
								}}
							>
								<View>
									<Text
										style={{
											fontWeight: '600',
											color: '#096730'
										}}
									>Studio:</Text>
									<Text
										style={{
											fontWeight: '600',
											marginTop: 4
										}}
									>{currentStudio.name}</Text>
								</View>
								<svg
									width={16} height={16}
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 492 492"
									style={{
										enableBackground: "new 0 0 492 492",
									}}
									xmlSpace="preserve"
								>
									<path d="M246.1 293.4 62 109.3c-5.1-5.1-11.8-7.9-19-7.9-7.2 0-14 2.8-19 7.9L7.9 125.5c-5.1 5.1-7.9 11.8-7.9 19 0 7.2 2.8 14 7.9 19L227 382.7c5.1 5.1 11.9 7.9 19.1 7.8 7.2 0 14-2.8 19.1-7.8l218.9-218.9c5.1-5.1 7.9-11.8 7.9-19 0-7.2-2.8-14-7.9-19L468 109.5c-10.5-10.5-27.6-10.5-38.1 0L246.1 293.4z" />
								</svg>
							</TouchableOpacity>
						</Popover>
					</View>
				</View>
				<ScrollView
					horizontal={true}
					style={{
						flexDirection: 'row',
						marginVertical: 40
					}}
				>
					<TouchableOpacity
						onPress={() => navigation.navigate('NewRoomScreen', {item: currentStudio})}
						style={{
							backgroundColor: '#F4F2F1',
							justifyContent: 'center',
							alignItems: 'center',
							padding: 16,
							marginRight: 24,
							borderRadius: 8,
							borderWidth: 1,
							borderColor: '#D9D6D6',
							flexDirection: 'row',
						}}
					>
						<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M21 14V8a5 5 0 0 0-5-5H8a5 5 0 0 0-5 5v8a5 5 0 0 0 5 5h6"
								stroke="#323232"
								strokeWidth={1.5}
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M21 14v2a5 5 0 0 1-5 5h-2"
								stroke="#323232"
								strokeWidth={1.5}
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M15 21v-1a5 5 0 0 1 5-5h1M10 12h4M12 14v-4"
								stroke="#323232"
								strokeWidth={1.5}
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						<Text
							style={{
								marginLeft: 8,
								fontWeight: '600'
							}}
						>Add Room</Text>
					</TouchableOpacity>

					{currentStudio.rooms.map((item, index) => (
						<TouchableOpacity
							key={index}
							onPress={() => setCurrentRoom(item)}
							style={{
								width: 364,
								flexDirection: 'row',
								borderWidth: item.id === currentRoom.id ? 2 : 1,
								borderColor: item.id === currentRoom.id ? '#6EB28B' : '#E4E1E0',
								padding: 8,
								borderRadius: 8,
								backgroundColor: 'white',
								marginRight: 24,
							}}
						>
							<View
								style={{
									width: 128,
									aspectRatio: 1.777,
									borderRadius: 4,
									marginRight: 16,
									overflow: 'hidden'
								}}
							>
								<Image
									style={{
										flex: 1
									}}
									source={{
										uri: item.artwork_url,
									}}
								/>
							</View>
							<View
								style={{
									flex: 1,
									justifyContent: 'center'
								}}
							>
								<Text
									style={{
										fontWeight: '600',
										color: '#096730',
										fontSize: 18
									}}
								>{item.name}</Text>
								<Text
									style={{
										fontWeight: '600',
										marginTop: 4,
										color: '#595959'
									}}
									numberOfLines={2}
								>Gravity House Studios</Text>
							</View>
						</TouchableOpacity>
					))}
				</ScrollView>
				{currentStudio.rooms.length > 0 && (
					<>
						{currentRoom && (
							<View
								style={{
									backgroundColor: 'white',
									shadowColor: "#000",
									shadowOffset: {
										width: 0,
										height: 2,
									},
									shadowOpacity: 0.05,
									shadowRadius: 3.84,
									elevation: 5,
									borderRadius: 8,
									padding: 32
								}}
							>
								<View
									style={{
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'space-between',
										zIndex: 1
									}}
								>
									<Text
										style={{
											fontWeight: '600',
											fontSize: 20
										}}
									>Room details</Text>
									<View
										style={{
											flexDirection: 'row',
											alignItems: 'center',
										}}
									>
										<Text
											style={{
												fontWeight: '600',
											}}
										>Room status</Text>
										{isActive === 1 && (
											<View
												style={{
													backgroundColor: '#EDFDF4',
													paddingHorizontal: 12,
													height: 26,
													borderRadius: 13,
													justifyContent: 'center',
													marginLeft: 16
												}}
											>
												<Text
													style={{
														fontWeight: '600',
														color: '#096730'
													}}
												>Active</Text>
											</View>
										)}
										{isActive === 0 && (
											<View
												style={{
													backgroundColor: '#D2D2D2',
													paddingHorizontal: 12,
													height: 26,
													borderRadius: 13,
													justifyContent: 'center',
													marginLeft: 16
												}}
											>
												<Text
													style={{
														fontWeight: '600',
													}}
												>Deactivated</Text>
											</View>
										)}
										<View
											style={{
												display: 'inline-block'
											}}
										>
											<Popover
												isOpen={roomOptionsMenu}
												positions={['bottom', 'right']}
												align={'end'}
												containerStyle={{
													zIndex: 5,
												}}
												padding={10} // adjust padding here!
												reposition={false} // prevents automatic readjustment of content position that keeps your popover content within its parent's bounds
												onClickOutside={() => setRoomOptionsMenu(false)} // handle click events outside of the popover/target here!
												content={({ position, nudgedLeft, nudgedTop }) => ( // you can also provide a render function that injects some useful stuff!
													<View
														style={{
															borderRadius: 8,
															width: 240,
															backgroundColor: 'white',
															padding: 8,
															shadowColor: "#000",
															shadowOffset: {
																width: 0,
																height: 2,
															},
															shadowOpacity: 0.25,
															shadowRadius: 3.84,
															elevation: 5,
														}}
													>
														<TouchableOpacity
															onPress={() => {
																setRoomOptionsMenu(false);
																setIsRoomEditIsVisible(true);
															}}
															style={{
																flexDirection: 'row',
																paddingHorizontal: 8,
																height: 34,
																borderRadius: 4,
																alignItems: 'center'
															}}
														>
															<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
																<path
																	d="M14 8.711v3.937c0 .747-.6 1.352-1.342 1.352H3.342A1.347 1.347 0 0 1 2 12.648v-8.63c0-.746.6-1.351 1.342-1.351H8"
																	stroke="#323232"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="m5.219 11.448 2.487-.622a.671.671 0 0 0 .31-.175l5.593-5.594c.521-.52.521-1.364 0-1.885l-.114-.115a1.333 1.333 0 0 0-1.886 0L6.016 8.651a.668.668 0 0 0-.175.31l-.622 2.487M10.553 4.113l2 2"
																	stroke="#323232"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M7.697 10.828c.01-.07.022-.14.022-.214 0-.92-.746-1.667-1.667-1.667-.073 0-.143.013-.214.022"
																	stroke="#323232"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
															</svg>
															<Text
																style={{
																	marginLeft: 12,
																	fontWeight: '600'
																}}
															>Edit room details</Text>
														</TouchableOpacity>
														{isActive === 1 && (
															<TouchableOpacity
																onPress={() => setIsDisableItemVisible(true)}
																style={{
																	flexDirection: 'row',
																	paddingHorizontal: 8,
																	borderRadius: 4,
																	alignItems: 'center',
																	marginTop: 8
																}}
															>
																<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path
																		d="M8 12.667a4.63 4.63 0 0 1-1.67-.33M13.921 8.312C12.66 10.645 10.33 12.667 8 12.667M12.72 5.947c.46.54.87 1.128 1.201 1.742a.658.658 0 0 1 0 .623M3.333 12.667l9.334-9.334M6.515 9.485a2.1 2.1 0 0 1 2.97-2.97"
																		stroke="#323232"
																		strokeLinecap="round"
																		strokeLinejoin="round"
																	/>
																	<path
																		d="M11.363 4.637C10.33 3.84 9.165 3.333 8 3.333c-2.33 0-4.66 2.022-5.921 4.356a.658.658 0 0 0 0 .623c.63 1.166 1.527 2.254 2.558 3.051"
																		stroke="#323232"
																		strokeLinecap="round"
																		strokeLinejoin="round"
																	/>
																</svg>
																<View
																	style={{
																		flex: 1,
																		marginLeft: 12,
																	}}
																>
																	<Text
																		style={{
																			fontWeight: '600'
																		}}
																	>Temporarily disable room</Text>
																	<Text
																		style={{
																			marginTop: 4,
																			color: '#595959'
																		}}
																	>users won't be able to find your studio's rooms on the app.</Text>
																</View>
															</TouchableOpacity>
														)}
														{isActive === 0 && (
															<TouchableOpacity
																onPress={() => {
																	API.post(`owner/active-room`, {
																		id: currentRoom.id
																	}).then(res => {
																			setRoomOptionsMenu(false);
																			setIsActive(res.data.active);
																			let newStudios = studios;
																			let studioIndex = newStudios.findIndex(item => item.id === currentStudio.id);
																			let index = newStudios[studioIndex].rooms.findIndex(item => item.id === currentRoom.id);
																			newStudios[studioIndex].rooms[index].active = res.data.active;
																			setStudios(newStudios);
																		}).catch (error => {
																		console.log(error.response);
																	});
																}}
																style={{
																	flexDirection: 'row',
																	paddingHorizontal: 8,
																	borderRadius: 4,
																	alignItems: 'center',
																	marginTop: 8
																}}
															>
																<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path
																		clipRule="evenodd"
																		d="M3.118 12.467a.987.987 0 0 1 0-.935C5.01 8.033 8.505 5 12 5s6.99 3.033 8.882 6.533a.987.987 0 0 1 0 .935C18.99 15.967 15.495 19 12 19s-6.99-3.033-8.882-6.533Z"
																		stroke="#323232"
																		strokeLinecap="round"
																		strokeLinejoin="round"
																	/>
																	<path
																		d="M14.121 9.879A3 3 0 1 1 9.88 14.12 3 3 0 0 1 14.12 9.88"
																		stroke="#323232"
																		strokeLinecap="round"
																		strokeLinejoin="round"
																	/>
																</svg>
																<View
																	style={{
																		flex: 1,
																		marginLeft: 12,
																	}}
																>
																	<Text
																		style={{
																			fontWeight: '600'
																		}}
																	>Active room</Text>
																	<Text
																		style={{
																			marginTop: 4,
																			color: '#595959'
																		}}
																	>users will be able to find studio's rooms on the app.</Text>
																</View>
															</TouchableOpacity>
														)}
														<TouchableOpacity
															onPress={() => {
																setRoomOptionsMenu(false);
																setIsDeleteItemVisible(true)
															}}
															style={{
																flexDirection: 'row',
																paddingHorizontal: 8,
																height: 34,
																borderRadius: 4,
																alignItems: 'center',
																marginTop: 8
															}}
														>
															<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
																<path
																	clipRule="evenodd"
																	d="M10.362 14.002H5.638a1.5 1.5 0 0 1-1.496-1.385l-.644-8.369h9.004l-.644 8.369a1.5 1.5 0 0 1-1.496 1.385v0Z"
																	stroke="#6D6E6F"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M13.336 4.248H2.665"
																	stroke="#6D6E6F"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	clipRule="evenodd"
																	d="M6.124 1.998h3.752a.75.75 0 0 1 .75.75v1.5H5.374v-1.5a.75.75 0 0 1 .75-.75Z"
																	stroke="#6D6E6F"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M9.313 7.25V11M6.687 7.25V11"
																	stroke="#6D6E6F"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
															</svg>
															<Text
																style={{
																	marginLeft: 12,
																	fontWeight: '600'
																}}
															>Delete room</Text>
														</TouchableOpacity>
													</View>
												)}
											>
												<TouchableOpacity
													onPress={() => setRoomOptionsMenu(!roomOptionsMenu)}
													style={{
														marginLeft: 16,
													}}
												>
													<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
														<path
															d="M18.503 12a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0M12.5 12a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0M6.498 12a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0"
															stroke="#323232"
															strokeWidth={1.5}
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</svg>
												</TouchableOpacity>
											</Popover>
										</View>
									</View>
								</View>
								<View
									style={{
										marginTop: 48,
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'space-between'
									}}
								>
									<Text
										style={{
											fontWeight: '600',
											fontSize: 18
										}}
									>Basic info</Text>
									<TouchableOpacity
										onPress={() => {
											setRoomOptionsMenu(false);
											setIsRoomEditIsVisible(true);
										}}
										style={{
											borderColor: '#E4E1E0',
											borderWidth: 1,
											paddingHorizontal: 12,
											height: 32,
											borderRadius: 6,
											alignItems: 'center',
											flexDirection: 'row',
										}}
									>
										<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
											<g clipPath="url(#a)" fillRule="evenodd" clipRule="evenodd" fill="#595959">
												<path d="M2.667 3.333A.667.667 0 0 0 2 4v9.333a.666.666 0 0 0 .667.666H12a.666.666 0 0 0 .666-.666v-3.56a.667.667 0 0 1 1.334 0v3.56a2 2 0 0 1-2 2H2.666a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h3.56a.667.667 0 0 1 0 1.333h-3.56Z" />
												<path d="M11.528.861c.26-.26.683-.26.943 0l2.667 2.667c.26.26.26.682 0 .943L8.47 11.137a.667.667 0 0 1-.471.196H5.333a.667.667 0 0 1-.667-.667V7.999c0-.176.07-.346.196-.471L11.528.86ZM6 8.275V10h1.724l6-6L12 2.275l-6 6Z" />
											</g>
											<defs>
												<clipPath id="a">
													<path fill="#fff" d="M0 0h16v16H0z" />
												</clipPath>
											</defs>
										</svg>
										<Text
											style={{
												fontWeight: '600',
												color: '#595959',
												marginLeft: 8
											}}
										>Edit</Text>
									</TouchableOpacity>
								</View>
								<View
									style={{
										flexDirection: 'row',
										marginTop: 32
									}}
								>
									<View
										style={{
											marginRight: 24
										}}
									>
										<Text
											style={{
												fontWeight: '600',
												marginBottom: 4
											}}
										>Name</Text>
										<Text>{currentRoom.name}</Text>
									</View>
									<View
										style={{
											marginRight: 24,
										}}
									>
										<Text
											style={{
												fontWeight: '600',
												marginBottom: 4
											}}
										>Date added</Text>
										<Text>{moment(currentRoom.created_at).format("MMM D, YYYY")}</Text>
									</View>
									<View>
										<Text
											style={{
												fontWeight: '600',
												marginBottom: 4
											}}
										>Price per hour</Text>
										<Text>${currentRoom.per_session_price}</Text>
									</View>
								</View>
								<View
									style={{
										marginTop: 24,
										borderBottomWidth: 1,
										borderColor: '#E4E1E0',
										paddingBottom: 32
									}}
								>
									<Text
										style={{
											fontWeight: '600',
											marginBottom: 4
										}}
									>Description</Text>
									<Text
										style={{
											color: '#595959'
										}}
									>{currentRoom.description}</Text>
								</View>
								<View
									style={{
										marginTop: 32,
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'space-between',
									}}
								>
									<Text
										style={{
											fontWeight: '600',
											fontSize: 18
										}}
									>Pictures</Text>
									<TouchableOpacity
										onPress={() => setIsImageEditorVisible(true)}
										style={{
											borderColor: '#E4E1E0',
											borderWidth: 1,
											paddingHorizontal: 12,
											height: 32,
											borderRadius: 6,
											alignItems: 'center',
											flexDirection: 'row',
										}}
									>
										<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
											<g clipPath="url(#a)" fillRule="evenodd" clipRule="evenodd" fill="#595959">
												<path d="M2.667 3.333A.667.667 0 0 0 2 4v9.333a.666.666 0 0 0 .667.666H12a.666.666 0 0 0 .666-.666v-3.56a.667.667 0 0 1 1.334 0v3.56a2 2 0 0 1-2 2H2.666a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h3.56a.667.667 0 0 1 0 1.333h-3.56Z" />
												<path d="M11.528.861c.26-.26.683-.26.943 0l2.667 2.667c.26.26.26.682 0 .943L8.47 11.137a.667.667 0 0 1-.471.196H5.333a.667.667 0 0 1-.667-.667V7.999c0-.176.07-.346.196-.471L11.528.86ZM6 8.275V10h1.724l6-6L12 2.275l-6 6Z" />
											</g>
											<defs>
												<clipPath id="a">
													<path fill="#fff" d="M0 0h16v16H0z" />
												</clipPath>
											</defs>
										</svg>
										<Text
											style={{
												fontWeight: '600',
												color: '#595959',
												marginLeft: 8
											}}
										>Edit</Text>
									</TouchableOpacity>
								</View>
								<ScrollView
									horizontal={true}
									style={{
										marginTop: 32,
										borderBottomWidth: 1,
										borderColor: '#E4E1E0',
										paddingBottom: 32
									}}
								>
									{currentRoom.images.map((item, index) => (
										<View
											key={index}
											style={{
												width: 280,
												aspectRatio: 1.777,
												borderRadius: 4,
												marginRight: 24,
												borderWidth: 1,
												borderColor: '#f1f0ef',
												overflow: 'hidden'
											}}
										>
											<Image
												style={{
													flex: 1
												}}
												source={{
													uri: item['sm'],
												}}
											/>
										</View>
									))}
								</ScrollView>
								<View
									style={{
										marginTop: 32,
										borderBottomWidth: 1,
										borderColor: '#E4E1E0',
										paddingBottom: 32
									}}
								>
									<View
										style={{
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
									>
										<Text
											style={{
												fontWeight: '600',
												fontSize: 18
											}}
										>Rules</Text>
										<TouchableOpacity
											onPress={() => {
												setIsRoomRulesEditIsVisible(true);
											}}
											style={{
												borderColor: '#E4E1E0',
												borderWidth: 1,
												paddingHorizontal: 12,
												height: 32,
												borderRadius: 6,
												alignItems: 'center',
												flexDirection: 'row',
											}}
										>
											<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
												<g clipPath="url(#a)" fillRule="evenodd" clipRule="evenodd" fill="#595959">
													<path d="M2.667 3.333A.667.667 0 0 0 2 4v9.333a.666.666 0 0 0 .667.666H12a.666.666 0 0 0 .666-.666v-3.56a.667.667 0 0 1 1.334 0v3.56a2 2 0 0 1-2 2H2.666a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h3.56a.667.667 0 0 1 0 1.333h-3.56Z" />
													<path d="M11.528.861c.26-.26.683-.26.943 0l2.667 2.667c.26.26.26.682 0 .943L8.47 11.137a.667.667 0 0 1-.471.196H5.333a.667.667 0 0 1-.667-.667V7.999c0-.176.07-.346.196-.471L11.528.86ZM6 8.275V10h1.724l6-6L12 2.275l-6 6Z" />
												</g>
												<defs>
													<clipPath id="a">
														<path fill="#fff" d="M0 0h16v16H0z" />
													</clipPath>
												</defs>
											</svg>
											<Text
												style={{
													fontWeight: '600',
													color: '#595959',
													marginLeft: 8
												}}
											>Edit</Text>
										</TouchableOpacity>
									</View>
									<Text
										style={{
											marginTop: 32,
											fontWeight: '600',
										}}
									>Main rules</Text>
									<View
										style={{
											marginTop: 16,
											flexDirection: 'row',
											flexWrap: 'wrap'
										}}
									>
										{currentRoomRules.map((item, index) => (
											<View
												key={index}
												style={{
													backgroundColor: '#EBE8E7',
													paddingHorizontal: 12,
													height: 32,
													borderRadius: 16,
													justifyContent: 'center',
													marginRight: 16,
													marginBottom: 16
												}}
											>
												<Text
													style={{
														fontWeight: '600',
													}}
												>{item.name}</Text>
											</View>
										))}
									</View>
								</View>
								<View
									style={{
										marginTop: 32,
										borderBottomWidth: 1,
										borderColor: '#E4E1E0',
										paddingBottom: 32
									}}
								>
									<View
										style={{
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
									>
										<Text
											style={{
												fontWeight: '600',
												fontSize: 18
											}}
										>Equipments</Text>
										<TouchableOpacity
											onPress={() => {
												setIsRoomEquipmentsEditIsVisible(true);
											}}
											style={{
												borderColor: '#E4E1E0',
												borderWidth: 1,
												paddingHorizontal: 12,
												height: 32,
												borderRadius: 6,
												alignItems: 'center',
												flexDirection: 'row',
											}}
										>
											<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
												<g clipPath="url(#a)" fillRule="evenodd" clipRule="evenodd" fill="#595959">
													<path d="M2.667 3.333A.667.667 0 0 0 2 4v9.333a.666.666 0 0 0 .667.666H12a.666.666 0 0 0 .666-.666v-3.56a.667.667 0 0 1 1.334 0v3.56a2 2 0 0 1-2 2H2.666a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h3.56a.667.667 0 0 1 0 1.333h-3.56Z" />
													<path d="M11.528.861c.26-.26.683-.26.943 0l2.667 2.667c.26.26.26.682 0 .943L8.47 11.137a.667.667 0 0 1-.471.196H5.333a.667.667 0 0 1-.667-.667V7.999c0-.176.07-.346.196-.471L11.528.86ZM6 8.275V10h1.724l6-6L12 2.275l-6 6Z" />
												</g>
												<defs>
													<clipPath id="a">
														<path fill="#fff" d="M0 0h16v16H0z" />
													</clipPath>
												</defs>
											</svg>
											<Text
												style={{
													fontWeight: '600',
													color: '#595959',
													marginLeft: 8
												}}
											>Edit</Text>
										</TouchableOpacity>
									</View>
									<View
										style={{
											marginTop: 16,
											flexDirection: 'row',
											flexWrap: 'wrap'
										}}
									>
										{currentRoomEquipments.map((item, index) => (
											<View
												key={index}
												style={{
													backgroundColor: '#EBE8E7',
													paddingHorizontal: 12,
													height: 32,
													borderRadius: 16,
													justifyContent: 'center',
													marginRight: 16,
													marginBottom: 16
												}}
											>
												<Text
													style={{
														fontWeight: '600',
													}}
												>{item.name}{item.cost ? ` - $${item.cost}/hr` : ' - Free'}</Text>
											</View>
										))}
									</View>
								</View>
								<View
									style={{
										marginTop: 32,
										borderBottomWidth: 1,
										borderColor: '#E4E1E0',
										paddingBottom: 32
									}}
								>
									<View
										style={{
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
									>
										<Text
											style={{
												fontWeight: '600',
												fontSize: 18
											}}
										>Amenities</Text>
										<TouchableOpacity
											onPress={() => {
												setIsRoomAmenitiesEditIsVisible(true);
											}}
											style={{
												borderColor: '#E4E1E0',
												borderWidth: 1,
												paddingHorizontal: 12,
												height: 32,
												borderRadius: 6,
												alignItems: 'center',
												flexDirection: 'row',
											}}
										>
											<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
												<g clipPath="url(#a)" fillRule="evenodd" clipRule="evenodd" fill="#595959">
													<path d="M2.667 3.333A.667.667 0 0 0 2 4v9.333a.666.666 0 0 0 .667.666H12a.666.666 0 0 0 .666-.666v-3.56a.667.667 0 0 1 1.334 0v3.56a2 2 0 0 1-2 2H2.666a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h3.56a.667.667 0 0 1 0 1.333h-3.56Z" />
													<path d="M11.528.861c.26-.26.683-.26.943 0l2.667 2.667c.26.26.26.682 0 .943L8.47 11.137a.667.667 0 0 1-.471.196H5.333a.667.667 0 0 1-.667-.667V7.999c0-.176.07-.346.196-.471L11.528.86ZM6 8.275V10h1.724l6-6L12 2.275l-6 6Z" />
												</g>
												<defs>
													<clipPath id="a">
														<path fill="#fff" d="M0 0h16v16H0z" />
													</clipPath>
												</defs>
											</svg>
											<Text
												style={{
													fontWeight: '600',
													color: '#595959',
													marginLeft: 8
												}}
											>Edit</Text>
										</TouchableOpacity>
									</View>
									<View
										style={{
											marginTop: 16,
											flexDirection: 'row',
											flexWrap: 'wrap'
										}}
									>
										{currentRoomAmenities.map((item, index) => (
											<View
												key={index}
												style={{
													backgroundColor: '#EBE8E7',
													paddingHorizontal: 12,
													height: 32,
													borderRadius: 16,
													justifyContent: 'center',
													marginRight: 16,
													marginBottom: 16
												}}
											>
												<Text
													style={{
														fontWeight: '600',
													}}
												>{item.name}{item.cost ? ` - $${item.cost}/hr` : ' - Free'}</Text>
											</View>
										))}
									</View>
								</View>
							</View>
						)}
					</>
				)}

			</ScrollView>
		</View>
	);
}

RoomsScreen.propTypes = {
	navigation: PropTypes.object,
};

export default connect(({auth}) => ({auth}))(RoomsScreen);