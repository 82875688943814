import React, {useEffect, useState} from "react";
import {
	Text,
	View,
	ScrollView,
	StyleSheet,
	Image,
	ActivityIndicator,
	Modal,
	ImageBackground,
	TouchableOpacity,
	TouchableOpacityComponent,
	TextInput
} from "react-native";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import API from "../../helpers/Axios";
import Calendar from 'react-calendar';
import StarRatings from 'react-star-ratings';
import '../../styles/calendar.css';
import moment from "moment";
import AppStyles from "../../AppStyles";
import StudioStatus from "../../components/StudioStatus";
import StudioDataScreen from "../modals/StudioDataScreen";

const DataScreen = ({navigation}) =>  {
	const [currentStudio, setCurrentStudio] = useState(null);
	const [isDetailsVisible, setIsDetailsVisible] = useState(false);
	const [studiosData, setStudiosData] = useState(null);
	const [isStudiosLoading, setIsStudiosLoading] = useState(true);

	const getStudios = (url = `admin/studios`) => {
		API.post(url, {
			approved: 1
		}).then(res => {
				setStudiosData(res.data);
				setIsStudiosLoading(false);
			}).catch (error => {
			console.log(error);
		});
	}

	useEffect(() => {
		getStudios();
	}, []);

	return (
		<View
			style={{
				flex: 1
			}}
		>
			<Modal onRequestClose={() => setIsDetailsVisible(false)} transparent visible={isDetailsVisible}>
				<View
					style={{
						backgroundColor: 'white',
						borderColor: '#eee',
						borderRadius: 8,
						borderWidth: 1,
						margin: 'auto',
						width: 900,
						shadowColor: "#000",
						shadowOffset: {
							width: 0,
							height: 2,
						},
						shadowOpacity: 0.05,
						shadowRadius: 3.84,
						elevation: 5,
					}}
				>
					<StudioDataScreen
						item={currentStudio}
						setIsVisible={setIsDetailsVisible}
						type={'studio'}
					/>
				</View>
			</Modal>
			<ScrollView
				style={{
					flex: 1,
					padding: 40
				}}
			>
				<Text
					style={{
						fontSize: 48,
						fontWeight: '600'
					}}
				>Data Storage</Text>
				<Text
					style={{
						marginTop: 16,
						fontSize: 20,
						color: '#595959'
					}}
				>All about stugo data.</Text>
				<View
					style={{
						flexDirection: 'row',
						alignItems: 'center',
						marginHorizontal: -20,
						marginTop: 40
					}}
				>
					{[
						{
							title: 'Stored files amount',
							label: '5',
							value: '2.341',
							icon: (
								<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										clipRule="evenodd"
										d="M15.001 19.403v-8.54c0-.426-.169-.833-.469-1.133l-2.263-2.263c-.3-.3-.707-.47-1.132-.47h-5.54a1.6 1.6 0 0 0-1.6 1.602v10.804a1.6 1.6 0 0 0 1.6 1.6h7.804a1.6 1.6 0 0 0 1.6-1.6Z"
										stroke="#58B17D"
										strokeWidth={1.5}
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M15.001 17.002h3.402a1.6 1.6 0 0 0 1.6-1.6V6.86c0-.425-.169-.832-.469-1.133l-2.263-2.263c-.3-.3-.707-.469-1.132-.469h-5.54a1.6 1.6 0 0 0-1.6 1.6v2.402"
										stroke="#58B17D"
										strokeWidth={1.5}
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M20.003 6.998h-3.201a.8.8 0 0 1-.8-.8V2.996M15.001 11H11.8a.8.8 0 0 1-.8-.8V6.997"
										stroke="#58B17D"
										strokeWidth={1.5}
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							)
						},
						{
							title: 'Global storage size',
							label: '+15%',
							value: <Text>32.189.41<Text style={{fontSize: 14}}>GB</Text></Text>,
							icon: (
								<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										clipRule="evenodd"
										d="M15.001 19.403v-8.54c0-.426-.169-.833-.469-1.133l-2.263-2.263c-.3-.3-.707-.47-1.132-.47h-5.54a1.6 1.6 0 0 0-1.6 1.602v10.804a1.6 1.6 0 0 0 1.6 1.6h7.804a1.6 1.6 0 0 0 1.6-1.6Z"
										stroke="#58B17D"
										strokeWidth={1.5}
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M15.001 17.002h3.402a1.6 1.6 0 0 0 1.6-1.6V6.86c0-.425-.169-.832-.469-1.133l-2.263-2.263c-.3-.3-.707-.469-1.132-.469h-5.54a1.6 1.6 0 0 0-1.6 1.6v2.402"
										stroke="#58B17D"
										strokeWidth={1.5}
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M20.003 6.998h-3.201a.8.8 0 0 1-.8-.8V2.996M15.001 11H11.8a.8.8 0 0 1-.8-.8V6.997"
										stroke="#58B17D"
										strokeWidth={1.5}
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							)
						}
					].map((item, index) => (
						<View
							style={{
								width: '25%',
								paddingHorizontal: 20,
							}}
						>
							<View
								style={{
									flex: 1,
									padding: 16,
									borderRadius: 8,
									overflow: 'hidden',
									backgroundColor: '#1A1A1A',
									flexDirection: 'row',
									justifyContent: 'space-between',
									alignItems: 'center'
								}}
							>
								<View>
									<Text
										style={{
											fontSize: 14,
											fontWeight: '700',
											color: '#A0AEC0'
										}}
									>{item.title}</Text>
									<View
										style={{
											marginTop: 8
										}}
									>
										<Text
											style={{
												fontSize: 20,
												fontWeight: '700',
												color: 'white'
											}}
										>{item.value}</Text>
									</View>
								</View>
								<View
									style={{
										background: '#EDFDF4',
										width: 36,
										height: 36,
										borderRadius: 12,
										justifyContent: 'center',
										alignItems: 'center'
									}}
								>
									{item.icon}
								</View>
							</View>
						</View>
					))}
				</View>
				<Text
					style={{
						marginTop: 40,
						fontSize: 24,
						fontWeight: '600',
						color: '#096730'
					}}
				>Studio’s storage</Text>
				<View
					style={{
						marginTop: 40
					}}
				>
					<View
						style={{
							backgroundColor: 'white',
							shadowColor: "#000",
							shadowOffset: {
								width: 0,
								height: 2,
							},
							shadowOpacity: 0.05,
							shadowRadius: 3.84,
							elevation: 5,
							borderRadius: 8
						}}
					>
						<View
							style={{
							}}
						>
							<View
								style={{
									borderBottomWidth: 2,
									borderColor: '#096730',
									alignSelf: 'flex-start',
									paddingHorizontal: 16
								}}
							>
								<Text
									style={{
										fontWeight: '600',
										fontSize: 16,
										color: '#096730',
										lineHeight: 40
									}}
								>All Studios</Text>
							</View>
						</View>
						<table
							style={{
								flex: 1,
								width: '100%',
								borderCollapse: 'collapse'
							}}
						>
							<tr
								style={{
									backgroundColor: '#F8F8F8',
									height: 48
								}}
							>
								<th></th>
								<th
									style={{
										textAlign: 'left',
										paddingLeft: 16,
										paddingRight: 16
									}}
								>
									<Text
										style={styles.tableHeader}
									>Studio Name</Text>
								</th>
								<th
									style={{
										textAlign: 'left',
										paddingLeft: 16,
										paddingRight: 16
									}}
								>
									<Text
										style={styles.tableHeader}
									>Number of files</Text>
								</th>
								<th
									style={{
										textAlign: 'left',
										paddingLeft: 16,
										paddingRight: 16
									}}
								>
									<Text
										style={styles.tableHeader}
									>File types</Text>
								</th>
								<th
									style={{
										textAlign: 'left',
										paddingLeft: 16,
										paddingRight: 16
									}}
								>
									<Text
										style={styles.tableHeader}
									>Studio plan used</Text>
								</th>
							</tr>
							{studiosData && studiosData.data.map((item, index) => (
								<tr
									key={index}
									style={{
										height: 64,
										cursor: 'pointer'
									}}
									onClick={() => {
										setCurrentStudio(item);
										setIsDetailsVisible(true);
									}}
								>
									<td
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Image
											style={{
												borderRadius: 16,
												marginRight: 16,
												width: 32,
												height: 32,
											}}
											source={{
												uri: item.artwork_url
											}}
										/>
									</td>
									<td
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<View>
											<Text>{item.name}</Text>
											<Text
												style={{
													color: '#909090',
													marginTop: 4,
													fontWeight: '600'
												}}
											>{item.user && item.user.email}</Text>
										</View>
									</td>
									<td
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Text>{item.user && AppStyles.utils.formatPhoneNumber(item.user.phone_number)}</Text>
									</td>
									<td
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Text>{moment.utc(item.created_at).local().format("MMM D, YYYY")}</Text>
									</td>
									<td
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<StudioStatus status={item.approved}/>
									</td>
								</tr>
							))}
						</table>
						{studiosData && (
							<View
								style={{
									backgroundColor: '#F8F8F8',
									height: 48,
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'flex-end',
									paddingHorizontal: 24
								}}
							>
								<View
									style={{
										marginRight: 40,
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'space-between',
									}}
								>
									<Text
										style={{
											fontWeight: '600',
											marginRight: 8
										}}
									>Rows per page: {studiosData.per_page}</Text>
									<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M12 14 8 9h8l-4 5Z" fill="#6D6E6F" />
									</svg>
								</View>
								<Text
									style={{
										fontWeight: '600'
									}}
								>{studiosData.from}- {studiosData.to} </Text>
								<View
									style={{
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'space-between',
										width: 80,
										marginLeft: 40
									}}
								>
									<TouchableOpacity
										onPress={() => {
											if(!studiosData.prev_page_url) {
												return;
											}
											setIsStudiosLoading(true);
											getStudios(studiosData.prev_page_url);
										}}
									>
										<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M14.8 8.106c.628-.593-.367-1.533-.995-.89L9.196 11.52a.66.66 0 0 0 0 .94l4.609 4.352c.628.593 1.623-.346.995-.94l-4.085-3.857L14.8 8.106Z"
												fill={studiosData.prev_page_url ? '#6D6E6F' : '#ccc'}
											/>
										</svg>
									</TouchableOpacity>
									{isStudiosLoading && (
										<ActivityIndicator color={'#096730'} />
									)}
									<TouchableOpacity
										onPress={() => {
											if(!studiosData.next_page_url) {
												return;
											}
											setIsStudiosLoading(true);
											getStudios(studiosData.next_page_url);
										}}
									>
										<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M9.2 8.106c-.628-.593.367-1.533.995-.89l4.609 4.303a.66.66 0 0 1 0 .94l-4.609 4.352c-.628.593-1.623-.346-.995-.94l4.085-3.857L9.2 8.106Z"
												fill={studiosData.next_page_url ? '#6D6E6F' : '#ccc'}
											/>
										</svg>
									</TouchableOpacity>
								</View>
							</View>
						)}
					</View>
				</View>
			</ScrollView>
		</View>
	);
}

const styles = StyleSheet.create({
	tableHeader: {
		fontWeight: '600',
		textAlign: 'left',
		alignSelf: 'flex-start'
	},
	tableCell: {
		borderBottom: '1px solid',
		borderBottomColor: 'gray',
		paddingLeft: 16,
		paddingRight: 16
	},
});

DataScreen.propTypes = {
	navigation: PropTypes.object,
};

export default connect(({auth}) => ({auth}))(DataScreen);