import React, {useEffect, useState} from "react";
import {
	Text,
	View,
	ScrollView, StyleSheet, Image, ActivityIndicator, Modal, ImageBackground, TouchableOpacity, TouchableOpacityComponent
} from "react-native";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import API from "../../helpers/Axios";
import Calendar from 'react-calendar';
import StarRatings from 'react-star-ratings';
import '../../styles/calendar.css';
import moment from "moment";
import ReviewsScreen from "../modals/ReviewsScreen";
import { Calendar as BigCalendar, momentLocalizer  } from 'react-big-calendar'
import {TouchableHighlight} from "react-native-web";
import AppStyles from "../../AppStyles";
import StudioStatus from "../../components/StudioStatus";
import SendNotificationScreen from "../modals/SendNotificationScreen";
import UserDetailsScreen from "../modals/UserDetailsScreen";
import EditStudioScreen from "../modals/EditStudioScreen";
import StaffScreen from "../modals/StaffScreen";
import Responsive from "../../helpers/Reponsive";
const localizer = momentLocalizer(moment);

const DashboardScreen = ({navigation}) =>  {
	const { isMobile } = Responsive();
	const [isLoading, setIsLoading] = useState(true);
	const [studios, setStudios] = useState([]);
	const [bookings, setBookings] = useState([]);
	const [currentStudio, setCurrentStudio] = useState(null);
	const [currentDate, setCurrentDate] = useState(new Date());
	const [reviews, setReviews] = useState([]);
	const [isVisible, setIsVisible] = useState(false);
	const [isDataLoading, setIsDataLoading] = useState(true);
	const [rooms, setRooms] = useState([]);
	const [currentRoom, setCurrentRoom] = useState(null);
	const [menuDisplay, setMenuDisplay] = useState( false);
	const [conversations, setConversations] = useState([]);
	const [events, setEvents] = useState([]);
	const [studiosData, setStudiosData] = useState(null);
	const [isStudiosLoading, setIsStudiosLoading] = useState(true);
	const [isStudioEditorVisible, setIsStudioEditorVisible] = useState(false);
	const [isStaffVisible, setIsStaffVisible] = useState(false);

	const [users, setUsers] = useState([]);
	const [usersData, setUsersData] = useState(null);
	const [isUsersLoading, setUsersIsLoading] = useState(true);
	const [userDetailsVisible, setUserDetailsVisible] = useState(false);
	const [isDeleteLoading, setIsDeleteLoading] = useState(false);
	const [currentUser, setCurrentUser] = useState(null);
	const [topBookingStudios, setTopBookingStudios] = useState([]);
	const [stats, setStats] = useState(null);

	const getStudios = (url = `admin/studios`) => {
		API.post(url, {
			approved: 0,
			items_per_page: 4
		}).then(res => {
			setStudiosData(res.data);
			setIsStudiosLoading(false);
		}).catch (error => {
			console.log(error);
		});
	}

	const getUsers = (url = `admin/users`) => {
		API.post(url)
			.then(res => {
				setUsers(res.data.data);
				setUsersData(res.data);
				setUsersIsLoading(false);
			}).catch (error => {
			console.log(error);
		});
	}

	useEffect(() => {
		API.get('admin/dashboard')
			.then(res => {
				setTopBookingStudios(res.data.top_bookings_studios);
				setStats(res.data.stats);
				setIsLoading(false);
			}).catch (error => {
			console.log(error);
		});
		getStudios();
		getUsers();
	}, []);

	return isLoading ? (<ActivityIndicator style={{flex: 1, justifyContent: 'center', alignItems: 'center'}} />) : (
		<View
			style={{
				flex: 1
			}}
		>
			<Modal onRequestClose={() => setUserDetailsVisible(false)} transparent visible={userDetailsVisible}>
				<View
					style={{
						backgroundColor: 'white',
						borderColor: '#eee',
						borderRadius: 8,
						borderWidth: 1,
						margin: 'auto',
						minWidth: 780,
						shadowColor: "#000",
						shadowOffset: {
							width: 0,
							height: 2,
						},
						shadowOpacity: 0.05,
						shadowRadius: 3.84,
						elevation: 5,
					}}
				>
					<UserDetailsScreen
						setIsVisible={setUserDetailsVisible}
						item={currentUser}
						setCurrentUser={setCurrentUser}
					/>
				</View>
			</Modal>
			<Modal onRequestClose={() => setIsStaffVisible(false)} transparent visible={isStaffVisible}>
				<View
					style={{
						backgroundColor: 'white',
						borderColor: '#eee',
						borderRadius: 8,
						borderWidth: 1,
						margin: 'auto',
						minWidth: 780,
						shadowColor: "#000",
						shadowOffset: {
							width: 0,
							height: 2,
						},
						shadowOpacity: 0.05,
						shadowRadius: 3.84,
						elevation: 5,
					}}
				>
					<StaffScreen
						setIsVisible={setIsStaffVisible}
					/>
				</View>
			</Modal>
			<Modal onRequestClose={() => setIsVisible(false)} transparent visible={isVisible}>
				<View
					style={{
						backgroundColor: 'white',
						borderColor: '#eee',
						borderRadius: 8,
						borderWidth: 1,
						margin: 'auto',
						width: 780,
						shadowColor: "#000",
						shadowOffset: {
							width: 0,
							height: 2,
						},
						shadowOpacity: 0.05,
						shadowRadius: 3.84,
						elevation: 5,
					}}
				>
					<SendNotificationScreen
						setIsVisible={setIsVisible}
					/>
				</View>
			</Modal>
			<Modal onRequestClose={() => setIsStudioEditorVisible(false)} transparent visible={isStudioEditorVisible}>
				<View
					style={{
						backgroundColor: 'white',
						borderColor: '#eee',
						borderRadius: 8,
						borderWidth: 1,
						margin: 'auto',
						width: 900,
						shadowColor: "#000",
						shadowOffset: {
							width: 0,
							height: 2,
						},
						shadowOpacity: 0.05,
						shadowRadius: 3.84,
						elevation: 5,
					}}
				>
					<EditStudioScreen
						item={currentStudio}
						setIsVisible={setIsStudioEditorVisible}
						setItem={setCurrentStudio}
					/>
				</View>
			</Modal>
			<ScrollView
				style={{
					flex: 1,
					padding: isMobile ? 24 : 40
				}}
			>
				<Text
					style={{
						fontSize: 48,
						fontWeight: '600'
					}}
				>Dashboard</Text>
				<Text
					style={{
						marginTop: 16,
						fontSize: 20,
						color: '#595959'
					}}
				>Get an overview of everything that happens in Stugo!</Text>
				<View
					style={{
						marginTop: 40,
						flexDirection: isMobile ? 'column' : 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						marginHorizontal: -20,
					}}
				>
					{[
						{
							title: 'Push notifications',
							subTitle: 'Schedule or send notifications',
							buttonText: 'Send push notification',
							imageSource: '/assets/dashboard/phone.jpeg',
							action: () => {
								setIsVisible(true)
							},
							icon: <svg width={26} height={26} fill="none" xmlns="http://www.w3.org/2000/svg">
								<circle
									cx={19.502}
									cy={6.497}
									r={3.501}
									stroke="#58B17D"
									strokeWidth={1.5}
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M20.003 13.734v3.824a4.446 4.446 0 0 1-4.447 4.446H8.442a4.446 4.446 0 0 1-4.446-4.447v-7.114a4.446 4.446 0 0 1 4.446-4.446h3.824"
									stroke="#58B17D"
									strokeWidth={1.5}
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						},
						{
							title: 'Staff accounts',
							subTitle: 'Manage access accounts',
							buttonText: 'Add or manage accounts',
							imageSource: '/assets/dashboard/team.jpeg',
							action: () => {
								setIsStaffVisible(true)
							},
							icon:   <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M3 13V8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5h-5"
									stroke="#58B17D"
									strokeWidth={1.5}
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="m6.5 16 2 2-2 2M3.5 18h5"
									stroke="#58B17D"
									strokeWidth={1.5}
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<circle
									cx={12}
									cy={9.5}
									r={2.5}
									stroke="#58B17D"
									strokeWidth={1.5}
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M10 14.5h4.211c.993 0 1.892.59 2.289 1.5"
									stroke="#58B17D"
									strokeWidth={1.5}
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						},
						{
							title: 'Revenue',
							subTitle: 'Overview of payments',
							buttonText: 'See Revenue overview',
							imageSource: '/assets/dashboard/money.jpeg',
							action: () => {
								navigation.navigate('RevenueScreen')
							},
							icon: <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M6 15h1M17 7h1M10.232 12.768a2.5 2.5 0 0 1 3.536-3.536"
									stroke="#58B17D"
									strokeWidth={1.5}
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M8 18H6a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v4"
									stroke="#58B17D"
									strokeWidth={1.5}
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									clipRule="evenodd"
									d="M12.5 17h2a.5.5 0 0 1 .5.5V21h-2.5a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5ZM15.5 13h2a.5.5 0 0 1 .5.5V21h-3v-7.5a.5.5 0 0 1 .5-.5ZM18.5 15h2a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5H18v-5.5a.5.5 0 0 1 .5-.5Z"
									stroke="#58B17D"
									strokeWidth={1.5}
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						}
					].map((item, index) => (
						<View
							style={{
								flex: 1,
								width: '100%',
								paddingHorizontal: 20,
								marginBottom: isMobile ? 24 : 0
							}}
						>
							<ImageBackground
								source={{uri: item.imageSource}}
								style={{
									flex: 1,
									padding: 16,
									borderRadius: 8,
									overflow: 'hidden',
								}}
							>
								<View
									style={{
										position: 'absolute',
										top: 0,
										bottom: 0,
										left: 0,
										right: 0,
										backgroundColor: 'rgba(26,26,26,0.85)',
										zIndex: -1
									}}
								/>
								<View
									style={{
										width: 36,
										height: 36,
										borderRadius: 12,
										backgroundColor: '#EDFDF4',
										justifyContent: 'center',
										alignItems: 'center'
									}}
								>
									{item.icon}
								</View>
								<Text
									style={{
										marginTop: 24,
										fontSize: 16,
										fontWeight: '600',
										color: 'white'
									}}
								>{item.title}</Text>
								<Text
									style={{
										fontSize: 12,
										color: 'white'
									}}
								>{item.subTitle}</Text>
								<TouchableOpacity
									onPress={item.action}
									style={{
										height: 32,
										backgroundColor: '#C2F3D7',
										justifyContent: 'center',
										alignItems: 'center',
										marginTop: 16,
										borderRadius: 4
									}}
								>
									<Text
										style={{
											fontSize: 16,
											color: '#096730',
											fontWeight: '600'
										}}
									>{item.buttonText}</Text>
								</TouchableOpacity>
							</ImageBackground>
						</View>
					))}
				</View>
				<View
					style={{
						flexDirection: isMobile ? 'column' : 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						marginHorizontal: -20,
						marginTop: 40
					}}
				>
					{[
						{
							title: 'Studios Pending Approval',
							icon: <svg width={24} height={25} fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M12 13.62V9.88M11.999 16.625a.25.25 0 1 0 .002.5.25.25 0 0 0-.002-.5"
									stroke="#35BD6E"
									strokeWidth={1.5}
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									clipRule="evenodd"
									d="m14.029 4.639 7.659 13.404c.89 1.558-.235 3.497-2.03 3.497H4.342c-1.795 0-2.92-1.939-2.03-3.497l7.66-13.404c.897-1.571 3.16-1.571 4.058 0Z"
									stroke="#35BD6E"
									strokeWidth={1.5}
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						},
						{
							title: 'New studios added',
							icon: <svg width={25} height={24} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M14.335 7.99854V3.99687C14.3351 3.66885 14.496 3.36172 14.7656 3.17491C15.0353 2.9881 15.3794 2.94535 15.6865 3.06048L20.6886 4.93626C21.0794 5.08273 21.3381 5.45636 21.3379 5.87365V21.004" stroke="#35BD6E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path fillRule="evenodd" clipRule="evenodd" d="M9.53253 7.99854H15.1349C15.7979 7.99854 16.3354 8.53602 16.3354 9.19904V21.004H8.33203V9.19904C8.33203 8.53602 8.86951 7.99854 9.53253 7.99854Z" stroke="#35BD6E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M11.3336 7.99856V5.99773C11.3336 5.44522 10.8857 4.99731 10.3331 4.99731H6.33147C5.77896 4.99731 5.33105 5.44522 5.33105 5.99773V10.9998" stroke="#35BD6E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M8.33224 2.99634V4.99717" stroke="#35BD6E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M22.3384 21.0039H2.33008" stroke="#35BD6E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M10.833 13.7009H13.8343" stroke="#35BD6E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M10.833 10.9997H13.8343" stroke="#35BD6E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M10.833 16.4021H13.8343" stroke="#35BD6E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M3.33008 21.0039V12.0002C3.33008 11.4477 3.77798 10.9998 4.33049 10.9998H8.33216" stroke="#35BD6E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
							</svg>
						},
						{
							title: 'New users',
							icon: <svg width={25} height={24} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M20.8802 9.52343C21.6703 10.3135 21.6703 11.5944 20.8802 12.3844C20.0902 13.1744 18.8093 13.1744 18.0193 12.3844C17.2293 11.5944 17.2293 10.3135 18.0193 9.52343C18.8093 8.7334 20.0902 8.7334 20.8802 9.52343" stroke="#35BD6E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M14.2903 5.91236C15.5068 7.12884 15.5068 9.10115 14.2903 10.3176C13.0738 11.5341 11.1015 11.5341 9.88502 10.3176C8.66854 9.10116 8.66854 7.12885 9.88502 5.91236C11.1015 4.69588 13.0738 4.69588 14.2903 5.91236" stroke="#35BD6E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M6.1566 9.52343C6.94663 10.3135 6.94663 11.5944 6.1566 12.3844C5.36657 13.1744 4.08568 13.1744 3.29565 12.3844C2.50562 11.5944 2.50562 10.3135 3.29565 9.52343C4.08568 8.7334 5.36657 8.7334 6.1566 9.52343" stroke="#35BD6E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M23.0881 19.0001V17.9041C23.0881 16.5231 21.9691 15.4041 20.5881 15.4041H19.7871" stroke="#35BD6E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M1.08789 19.0001V17.9041C1.08789 16.5231 2.20689 15.4041 3.58789 15.4041H4.38889" stroke="#35BD6E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M17.427 18.9999V17.3989C17.427 15.4659 15.86 13.8989 13.927 13.8989H10.248C8.31505 13.8989 6.74805 15.4659 6.74805 17.3989V18.9999" stroke="#35BD6E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
							</svg>
						},
						{
							title: 'Disputes Open',
							icon: <svg width={25} height={24} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M11.9148 12.3942L7.91309 8.39258" stroke="#35BD6E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M11.6533 4.65112L15.655 8.65279" stroke="#35BD6E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M3.25586 21.0039H13.8463" stroke="#35BD6E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M4.15625 21.0039L4.70148 19.3713C4.97315 18.5556 5.73553 18.0046 6.59527 18.0027H10.5069C11.3692 18.0016 12.1352 18.5531 12.4077 19.3713L12.9489 21.0039" stroke="#35BD6E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path fillRule="evenodd" clipRule="evenodd" d="M9.15083 7.29825L6.94991 9.05398C6.54297 9.37945 6.29352 9.86252 6.26374 10.3827C6.23397 10.903 6.42669 11.4114 6.79385 11.7811L8.47055 13.4658C8.83469 13.8319 9.3375 14.0255 9.85309 13.9982C10.3687 13.9709 10.8482 13.7252 11.1717 13.3228L12.9484 11.1158C13.3688 10.5932 13.8452 10.1181 14.369 9.69925L16.5699 7.94352C16.9769 7.61806 17.2263 7.13499 17.2561 6.61475C17.2859 6.09452 17.0931 5.58615 16.726 5.21638L15.0523 3.53268C14.6882 3.16664 14.1853 2.97304 13.6697 3.00035C13.1542 3.02765 12.6746 3.27329 12.3512 3.67574L10.5724 5.88266C10.1519 6.40527 9.67521 6.87999 9.15083 7.29825Z" stroke="#35BD6E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M14.6371 9.48608L20.5636 14.5012C21.1276 14.9804 21.3785 15.7334 21.2146 16.4551C21.0508 17.1768 20.4993 17.7477 19.7837 17.9364C19.0681 18.1251 18.3069 17.9004 17.8084 17.3534L12.5723 11.587" stroke="#35BD6E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
							</svg>
						}
					].map((item, index) => (
						<View
							key={index}
							style={{
								flex: 1,
								width: '100%',
								marginBottom: isMobile ? 24 : 0,
								paddingHorizontal: 20,
							}}
						>
							<View
								style={{
									flex: 1,
									padding: 16,
									borderRadius: 8,
									overflow: 'hidden',
									backgroundColor: '#1A1A1A',
									flexDirection: 'row',
									justifyContent: 'space-between',
									alignItems: 'center'
								}}
							>
								<View>
									<Text
										style={{
											fontSize: 12,
											color: 'white',
											fontWeight: '600'
										}}
									>{item.title}</Text>
									<View
										style={{
											marginTop: 4
										}}
									>
										<Text
											style={{
												fontSize: 20,
												fontWeight: '700',
												color: 'white'
											}}
										>
											{index === 0 && stats.pending_approval}
											{index === 1 && stats.new_studios}
											{index === 2 && stats.new_users}
											{index === 3 && stats.reports}
										</Text>
									</View>

								</View>
								<View
									style={{
										width: 36,
										height: 36,
										borderRadius: 12,
										backgroundColor: '#EDFDF4',
										justifyContent: 'center',
										alignItems: 'center'
									}}
								>
									{item.icon}
								</View>
							</View>
						</View>
					))}
				</View>
				<>
					<View
						style={{
							marginTop: 24,
							marginBottom: 16,
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center'
						}}
					>
						<Text
							style={{
								fontSize: 20,
								fontWeight: '500',
							}}
						>Studios Pending Approval</Text>
						<TouchableOpacity
							onPress={() => navigation.navigate('StudiosScreen')}
							style={{
								flexDirection: 'row',
								alignItems: 'center',
								borderWidth: 1,
								borderColor: '#E4E1E0',
								paddingHorizontal: 16,
								paddingVertical: 8,
								borderRadius: 6
							}}
						>
							<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M3.333 4.667a.667.667 0 0 0-.666.667v7.333a.667.667 0 0 0 .666.667h7.334a.667.667 0 0 0 .666-.667v-4a.667.667 0 0 1 1.334 0v4a2 2 0 0 1-2 2H3.333a2 2 0 0 1-2-2V5.334a2 2 0 0 1 2-2h4a.667.667 0 0 1 0 1.333h-4ZM9.333 2c0-.368.299-.666.667-.666h4c.368 0 .667.298.667.667v4a.667.667 0 0 1-1.334 0V2.667H10a.667.667 0 0 1-.667-.666Z"
									fill="#6D6E6F"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M14.471 1.53c.26.26.26.682 0 .942L7.138 9.805a.667.667 0 0 1-.943-.942l7.334-7.334c.26-.26.682-.26.942 0Z"
									fill="#6D6E6F"
								/>
							</svg>
							<Text
								style={{
									fontWeight: '600',
									marginLeft: 8
								}}
							>See all</Text>
						</TouchableOpacity>
					</View>
					<View
						style={{
							backgroundColor: 'white',
							shadowColor: "#000",
							shadowOffset: {
								width: 0,
								height: 2,
							},
							shadowOpacity: 0.05,
							shadowRadius: 3.84,
							elevation: 5,
							borderRadius: 8
						}}
					>
						<View
							style={{
							}}
						>
							<View
								style={{
									borderBottomWidth: 2,
									borderColor: '#096730',
									alignSelf: 'flex-start',
									paddingHorizontal: 16
								}}
							>
								<Text
									style={{
										fontWeight: '600',
										fontSize: 16,
										color: '#096730',
										lineHeight: 40
									}}
								>All Studios</Text>
							</View>
						</View>
						<table
							style={{
								flex: 1,
								width: '100%',
								borderCollapse: 'collapse'
							}}
						>
							<tr
								style={{
									backgroundColor: '#F8F8F8',
									height: 48
								}}
							>
								<th></th>
								<th
									style={{
										textAlign: 'left',
										paddingLeft: 16,
										paddingRight: 16
									}}
								>
									<Text
										style={styles.tableHeader}
									>Name</Text>
								</th>
								<th
									style={{
										textAlign: 'left',
										paddingLeft: 16,
										paddingRight: 16
									}}
								>
									<Text
										style={styles.tableHeader}
									>Contact Number</Text>
								</th>
								<th
									style={{
										textAlign: 'left',
										paddingLeft: 16,
										paddingRight: 16
									}}
								>
									<Text
										style={styles.tableHeader}
									>Date Added</Text>
								</th>
								<th
									style={{
										textAlign: 'left',
										paddingLeft: 16,
										paddingRight: 16
									}}
								>
									<Text
										style={styles.tableHeader}
									>Status</Text>
								</th>
								<th
									style={{
										textAlign: 'left',
										paddingLeft: 16,
										paddingRight: 16
									}}
								>
								</th>
								<th
									style={{
										textAlign: 'left',
										paddingLeft: 16,
										paddingRight: 16
									}}
								>
								</th>
							</tr>
							{studiosData && studiosData.data.map((item, index) => (
								<tr
									key={index}
									style={{
										height: 64,
										cursor: 'pointer'
									}}
									onClick={() => {
										setCurrentStudio(item);
										setIsStudioEditorVisible(true);
									}}
								>
									<td
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Image
											style={{
												borderRadius: 16,
												marginRight: 16,
												width: 32,
												height: 32,
											}}
											source={{
												uri: item.artwork_url
											}}
										/>
									</td>
									<td
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<View>
											<Text>{item.name}</Text>
											<Text
												style={{
													color: '#909090',
													marginTop: 4,
													fontWeight: '600'
												}}
											>{item.user && item.user.email}</Text>
										</View>
									</td>
									<td
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Text>{item.user && AppStyles.utils.formatPhoneNumber(item.user.phone_number)}</Text>
									</td>
									<td
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Text>{moment.utc(item.created_at).local().format("MMM D, YYYY")}</Text>
									</td>
									<td
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<StudioStatus status={item.approved}/>
									</td>
									<td
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
											<g clipPath="url(#a)" fill="#6D6E6F">
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M7.333 1.333A.667.667 0 0 0 6.666 2v5.588l1.702-.851a.667.667 0 0 1 .298-.07H14A.667.667 0 0 0 14.666 6V2A.667.667 0 0 0 14 1.333H7.333ZM5.919.586A2 2 0 0 1 7.333 0H14a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H8.824L6.298 9.263a.667.667 0 0 1-.965-.596V2A2 2 0 0 1 5.919.586Z"
												/>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M1.252 1.92a2 2 0 0 1 1.415-.587h1a.667.667 0 1 1 0 1.334h-1A.667.667 0 0 0 2 3.333V14a.667.667 0 0 0 .667.667H10a.667.667 0 0 0 .666-.667v-4A.667.667 0 0 1 12 10v4a2 2 0 0 1-2 2H2.667a2 2 0 0 1-2-2V3.333a2 2 0 0 1 .585-1.414Z"
												/>
												<circle cx={6.667} cy={12.667} r={0.667} />
											</g>
											<defs>
												<clipPath id="a">
													<path fill="#fff" d="M0 0h16v16H0z" />
												</clipPath>
											</defs>
										</svg>
									</td>
									<td
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Text
											style={{
												fontWeight: '600',
												color: '#595959'
											}}
										>View More</Text>
									</td>
								</tr>
							))}
						</table>
						{studiosData && (
							<View
								style={{
									backgroundColor: '#F8F8F8',
									height: 48,
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'flex-end',
									paddingHorizontal: 24
								}}
							>
								<View
									style={{
										marginRight: 40,
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'space-between',
									}}
								>
									<Text
										style={{
											fontWeight: '600',
											marginRight: 8
										}}
									>Rows per page: {studiosData.per_page}</Text>
									<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M12 14 8 9h8l-4 5Z" fill="#6D6E6F" />
									</svg>
								</View>
								<Text
									style={{
										fontWeight: '600'
									}}
								>{studiosData.from}- {studiosData.to} </Text>
								<View
									style={{
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'space-between',
										width: 80,
										marginLeft: 40
									}}
								>
									<TouchableOpacity
										onPress={() => {
											if(!studiosData.prev_page_url) {
												return;
											}
											setIsStudiosLoading(true);
											getStudios(studiosData.prev_page_url);
										}}
									>
										<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M14.8 8.106c.628-.593-.367-1.533-.995-.89L9.196 11.52a.66.66 0 0 0 0 .94l4.609 4.352c.628.593 1.623-.346.995-.94l-4.085-3.857L14.8 8.106Z"
												fill={studiosData.prev_page_url ? '#6D6E6F' : '#ccc'}
											/>
										</svg>
									</TouchableOpacity>
									{isStudiosLoading && (
										<ActivityIndicator color={'#096730'} />
									)}
									<TouchableOpacity
										onPress={() => {
											if(!studiosData.next_page_url) {
												return;
											}
											setIsStudiosLoading(true);
											getStudios(studiosData.next_page_url);
										}}
									>
										<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M9.2 8.106c-.628-.593.367-1.533.995-.89l4.609 4.303a.66.66 0 0 1 0 .94l-4.609 4.352c-.628.593-1.623-.346-.995-.94l4.085-3.857L9.2 8.106Z"
												fill={studiosData.next_page_url ? '#6D6E6F' : '#ccc'}
											/>
										</svg>
									</TouchableOpacity>
								</View>
							</View>
						)}
					</View>
				</>
				<View
					style={{
						flexDirection: isMobile ? 'column' : 'row',
						justifyContent: 'space-between',
						marginHorizontal: -12,
						marginTop: 16
					}}
				>
					<View
						style={{
							paddingHorizontal: 12,
							flex: 1
						}}
					>
						<View
							style={{
								marginTop: 24,
								marginBottom: 16,
								flexDirection: 'row',
								justifyContent: 'space-between',
								alignItems: 'center'
							}}
						>
							<View>
								<Text
									style={{
										fontSize: 20,
										fontWeight: '500',
									}}
								>Top Users</Text>
								<Text
									style={{
										marginTop: 16,
										color: '#6D6E6F',
										fontSize: 16
									}}
								>The User that are moving the platform the most!</Text>
							</View>
							<TouchableOpacity
								onPress={() => navigation.navigate('ClientsScreen')}
								style={{
									flexDirection: 'row',
									alignItems: 'center',
									borderWidth: 1,
									borderColor: '#E4E1E0',
									paddingHorizontal: 16,
									paddingVertical: 8,
									borderRadius: 6
								}}
							>
								<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M3.333 4.667a.667.667 0 0 0-.666.667v7.333a.667.667 0 0 0 .666.667h7.334a.667.667 0 0 0 .666-.667v-4a.667.667 0 0 1 1.334 0v4a2 2 0 0 1-2 2H3.333a2 2 0 0 1-2-2V5.334a2 2 0 0 1 2-2h4a.667.667 0 0 1 0 1.333h-4ZM9.333 2c0-.368.299-.666.667-.666h4c.368 0 .667.298.667.667v4a.667.667 0 0 1-1.334 0V2.667H10a.667.667 0 0 1-.667-.666Z"
										fill="#6D6E6F"
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M14.471 1.53c.26.26.26.682 0 .942L7.138 9.805a.667.667 0 0 1-.943-.942l7.334-7.334c.26-.26.682-.26.942 0Z"
										fill="#6D6E6F"
									/>
								</svg>
								<Text
									style={{
										fontWeight: '600',
										marginLeft: 8
									}}
								>See all</Text>
							</TouchableOpacity>
						</View>
						<View
							style={{
								backgroundColor: 'white',
								shadowColor: "#000",
								shadowOffset: {
									width: 0,
									height: 2,
								},
								shadowOpacity: 0.05,
								shadowRadius: 3.84,
								elevation: 5,
								borderRadius: 8
							}}
						>
							<View
								style={{
								}}
							>
								<View
									style={{
										borderBottomWidth: 2,
										borderColor: '#096730',
										alignSelf: 'flex-start',
										paddingHorizontal: 16
									}}
								>
									<Text
										style={{
											fontWeight: '600',
											fontSize: 16,
											color: '#096730',
											lineHeight: 40
										}}
									>All Users</Text>
								</View>
							</View>
							{isUsersLoading && (
								<ActivityIndicator color={'#096730'} />
							)}
							{!isUsersLoading && (
								<table
									style={{
										flex: 1,
										width: '100%',
										borderCollapse: 'collapse'
									}}
								>
									<tr
										style={{
											backgroundColor: '#F8F8F8',
											height: 48
										}}
									>
										<th></th>
										<th
											style={{
												textAlign: 'left',
												paddingLeft: 16,
												paddingRight: 16
											}}
										>
											<Text
												style={styles.tableHeader}
											>Name</Text>
										</th>
										<th
											style={{
												textAlign: 'left',
												paddingLeft: 16,
												paddingRight: 16
											}}
										>
											<Text
												style={styles.tableHeader}
											>Date Added</Text>
										</th>
									</tr>
									{usersData && usersData.data.map((item, index) => index < 5 && (
										<tr
											key={index}
											style={{
												height: 64,
												cursor: 'pointer'
											}}
											onClick={() => {
												setCurrentUser(item);
												setUserDetailsVisible(true);
											}}
										>
											<td
												style={{
													borderBottom: '1px solid',
													borderBottomColor: '#E4E1E0',
													paddingLeft: 16,
													paddingRight: 16
												}}
											>
												<Image
													style={{
														borderRadius: 16,
														marginRight: 16,
														width: 32,
														height: 32,
													}}
													source={{
														uri: item.artwork_url
													}}
												/>
											</td>
											<td
												style={{
													borderBottom: '1px solid',
													borderBottomColor: '#E4E1E0',
													paddingLeft: 16,
													paddingRight: 16
												}}
											>
												<View>
													<Text>{item.name}</Text>
													<Text
														style={{
															color: '#909090',
															marginTop: 4,
															fontWeight: '600'
														}}
													>{item.email}</Text>
												</View>
											</td>
											<td
												style={{
													borderBottom: '1px solid',
													borderBottomColor: '#E4E1E0',
													paddingLeft: 16,
													paddingRight: 16
												}}
											>
												<Text>{moment.utc(item.created_at).local().format("MMM D, YYYY")}</Text>
											</td>
										</tr>
									))}
								</table>
							)}
						</View>
					</View>
					<View
						style={{
							paddingHorizontal: 12,
							flex: 1
						}}
					>
						<View
							style={{
								marginTop: 24,
								marginBottom: 16,
								flexDirection: 'row',
								justifyContent: 'space-between',
								alignItems: 'center'
							}}
						>
							<View>
								<Text
									style={{
										fontSize: 20,
										fontWeight: '500',
									}}
								>Top Studios</Text>
								<Text
									style={{
										marginTop: 16,
										color: '#6D6E6F',
										fontSize: 16
									}}
								>The Studios that are moving the platform the most!</Text>
							</View>
							<TouchableOpacity
								onPress={() => navigation.navigate('ClientsScreen')}
								style={{
									flexDirection: 'row',
									alignItems: 'center',
									borderWidth: 1,
									borderColor: '#E4E1E0',
									paddingHorizontal: 16,
									paddingVertical: 8,
									borderRadius: 6
								}}
							>
								<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M3.333 4.667a.667.667 0 0 0-.666.667v7.333a.667.667 0 0 0 .666.667h7.334a.667.667 0 0 0 .666-.667v-4a.667.667 0 0 1 1.334 0v4a2 2 0 0 1-2 2H3.333a2 2 0 0 1-2-2V5.334a2 2 0 0 1 2-2h4a.667.667 0 0 1 0 1.333h-4ZM9.333 2c0-.368.299-.666.667-.666h4c.368 0 .667.298.667.667v4a.667.667 0 0 1-1.334 0V2.667H10a.667.667 0 0 1-.667-.666Z"
										fill="#6D6E6F"
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M14.471 1.53c.26.26.26.682 0 .942L7.138 9.805a.667.667 0 0 1-.943-.942l7.334-7.334c.26-.26.682-.26.942 0Z"
										fill="#6D6E6F"
									/>
								</svg>
								<Text
									style={{
										fontWeight: '600',
										marginLeft: 8
									}}
								>See all</Text>
							</TouchableOpacity>
						</View>
						<View
							style={{
								backgroundColor: 'white',
								shadowColor: "#000",
								shadowOffset: {
									width: 0,
									height: 2,
								},
								shadowOpacity: 0.05,
								shadowRadius: 3.84,
								elevation: 5,
								borderRadius: 8
							}}
						>
							<View
								style={{
								}}
							>
								<View
									style={{
										borderBottomWidth: 2,
										borderColor: '#096730',
										alignSelf: 'flex-start',
										paddingHorizontal: 16
									}}
								>
									<Text
										style={{
											fontWeight: '600',
											fontSize: 16,
											color: '#096730',
											lineHeight: 40
										}}
									>All Studios</Text>
								</View>
							</View>
							<table
								style={{
									flex: 1,
									width: '100%',
									borderCollapse: 'collapse'
								}}
							>
								<tr
									style={{
										backgroundColor: '#F8F8F8',
										height: 48
									}}
								>
									<th></th>
									<th
										style={{
											textAlign: 'left',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Text
											style={styles.tableHeader}
										>Name</Text>
									</th>
									<th
										style={{
											textAlign: 'left',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Text
											style={styles.tableHeader}
										>Date Added</Text>
									</th>
								</tr>
								{topBookingStudios.map((item, index) => item.studio && (
									<tr
										key={index}
										style={{
											height: 64,
											cursor: 'pointer'
										}}
										onClick={() => {
											setCurrentStudio(item.studio);
											setIsStudioEditorVisible(true);
										}}
									>
										<td
											style={{
												borderBottom: '1px solid',
												borderBottomColor: '#E4E1E0',
												paddingLeft: 16,
												paddingRight: 16
											}}
										>
											<Image
												style={{
													borderRadius: 16,
													marginRight: 16,
													width: 32,
													height: 32,
												}}
												source={{
													uri: item.studio.artwork_url
												}}
											/>
										</td>
										<td
											style={{
												borderBottom: '1px solid',
												borderBottomColor: '#E4E1E0',
												paddingLeft: 16,
												paddingRight: 16
											}}
										>
											<View>
												<Text>{item.studio.name}</Text>
												<Text
													style={{
														color: '#909090',
														marginTop: 4,
														fontWeight: '600'
													}}
												>{item.studio.user && item.studio.user.email}</Text>
											</View>
										</td>
										<td
											style={{
												borderBottom: '1px solid',
												borderBottomColor: '#E4E1E0',
												paddingLeft: 16,
												paddingRight: 16
											}}
										>
											<Text>{moment.utc(item.created_at).local().format("MMM D, YYYY")}</Text>
										</td>
									</tr>
								))}
							</table>
						</View>
					</View>
				</View>
			</ScrollView>
		</View>
	);
}

const styles = StyleSheet.create({
	tableHeader: {
		fontWeight: '600',
		textAlign: 'left',
		alignSelf: 'flex-start'
	},
	tableCell: {
		borderBottom: '1px solid',
		borderBottomColor: 'gray',
		paddingLeft: 16,
		paddingRight: 16
	},
});

DashboardScreen.propTypes = {
	navigation: PropTypes.object,
};

export default connect(({auth}) => ({auth}))(DashboardScreen);