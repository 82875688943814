import React, {useEffect, useState} from 'react';
import {View, Text, ScrollView, ActivityIndicator} from "react-native";
import {TouchableOpacity} from "react-native-web";
import SortableImages from "../../components/SortableImages";
import {arrayMove} from "react-sortable-hoc";
import API from "../../helpers/Axios";
import _ from "lodash";
import {toast} from "react-toastify";

const EditPicturesScreen = ({ setIsVisible, item, type }) => {
	let images = item.images;
	const [isSubmitting, setIsSubmitting] = useState( false);
	const [itemArtworkFiles, setItemArtworkFiles] = useState(images);
	const [submitArtworkFiles, setSubmitArtworkFile] = useState([]);
	const [deleteArray, setDeleteArray] = useState([]);

	const studioArtworksSelectedHandler = (e) => {
		let newData = itemArtworkFiles;
		let array = [];
		for (let i = 0; i < e.target.files.length; i++) {
			array.push(e.target.files[i]);
			newData.push(e.target.files[i]);
		}
		setItemArtworkFiles(newData);
		setSubmitArtworkFile(array);
	}

	return (
		<View
			style={{
				flex: 1,
			}}
		>
			<View
				style={{
					flex: 1,
					justifyContent: 'center',
					alignItems: 'center',
					borderBottomWidth: 1,
					borderColor: '#E4E1E0',
					padding: 16
				}}
			>
				<Text
					style={{
						fontWeight: '500',
						fontSize: 20
					}}
				>Edit Pictures</Text>
				<TouchableOpacity
					onPress={() => setIsVisible(false)}
					style={{
						position: 'absolute',
						right: 16,
					}}
				>
					<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M18.707 5.293a1 1 0 0 1 0 1.414l-12 12a1 1 0 0 1-1.414-1.414l12-12a1 1 0 0 1 1.414 0Z"
							fill="#6D6E6F"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M5.293 5.293a1 1 0 0 1 1.414 0l12 12a1 1 0 0 1-1.414 1.414l-12-12a1 1 0 0 1 0-1.414Z"
							fill="#6D6E6F"
						/>
					</svg>
				</TouchableOpacity>
			</View>
			<View
				style={{
					padding: 32
				}}
			>
				<ScrollView
					style={{
						height: 600
					}}
				>

					<Text
						style={{
							color: '#6D6E6F',
							lineHeight: 20
						}}
					>Shoot your photos in Landscape mode, all photos will be cropped in 16:9 ratio (<strong>minimum dimensions require 1280x720</strong>).</Text>
					{itemArtworkFiles.length > 0 && (
						<View
							style={{
								marginTop: 16
							}}
						>
							<SortableImages
								items={itemArtworkFiles}
								onSortEnd={({oldIndex, newIndex}) => {
									let items = itemArtworkFiles;
									items = arrayMove(items, oldIndex, newIndex)
									setItemArtworkFiles(items);
								}}
								onRemovedEnd={(data) => {
									setDeleteArray(data)
								}}
							/>
						</View>
					)}
					<View
						style={{
							marginTop: 40,
							flex: 1,
							borderWidth: 1,
							borderColor: '#D2D2D2'
						}}
					>
						<View
							style={{
								flexDirection: 'row',
								padding: 4
							}}
						>
							{[{}, {}, {}].map((item, index) => (
								<View
									key={index}
									style={{
										width: '33.33%',
										aspectRatio: 1.77,
										padding: 4,
										opacity: .2
									}}
								>
									<View
										style={{
											flex: 1,
											backgroundColor: '#97DEB4',
										}}
									/>
								</View>
							))}
						</View>
						<View
							style={{
								marginTop: -8,
								flexDirection: 'row',
								padding: 4
							}}
						>
							{[{}, {}, {}].map((item, index) => (
								<View
									key={index}
									style={{
										width: '33.33%',
										aspectRatio: 1.77,
										padding: 4,
										opacity: .2
									}}
								>
									<View
										style={{
											flex: 1,
											backgroundColor: '#97DEB4',
										}}
									/>
								</View>
							))}
						</View>
						<View
							style={{
								position: 'absolute',
								top: 0,
								left: 0,
								right: 0,
								bottom: 0,
								alignItems: 'center',
								justifyContent: 'center'
							}}
						>
							<View
								style={{
									backgroundColor: '#3DBA71',
									height: 40,
									justifyContent: 'center',
									alignItems: 'center',
									flexDirection: 'row',
									borderRadius: 6,
									paddingHorizontal: 24
								}}
							>
								<Text
									style={{
										color: 'white',
										fontWeight: '500'
									}}
								>Upload your photos and video</Text>
								<input
									style={{
										position: 'absolute',
										top: 0,
										left: 0,
										right: 0,
										bottom: 0,
										opacity: 0,
										cursor: 'pointer'
									}}
									type="file"
									onChange={studioArtworksSelectedHandler}
									accept="image/*"
									multiple
								/>
								<Text
									style={{
										position: 'absolute',
										bottom: -24,
										lineHeight: 20,
										color: '#484848'
									}}
								>Or drag them in here</Text>
							</View>
						</View>
					</View>
				</ScrollView>
				<View
					style={{
						flexDirection: 'row',
						justifyContent: 'flex-end',
						marginTop: 32
					}}
				>
					<TouchableOpacity
						onPress={() => setIsVisible(false)}
						style={{
							width: 132,
							fontWeight: '600',
							fontSize: 16,
							paddingHorizontal: 32,
							borderWidth: 1,
							height: 48,
							borderRadius: 8,
							borderColor: '#E4E1E0',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<Text
							style={{
								fontWeight: '600',
								fontSize: 16
							}}
						>Cancel</Text>
					</TouchableOpacity>
					<TouchableOpacity
						onPress={() => {
							if(itemArtworkFiles.length) {
								if(isSubmitting) {
									return
								}
								setIsSubmitting(true);
								const data = new FormData();
								data.append('id', item.id);
								for (let i = 0; i < submitArtworkFiles.length; i++) {
									data.append('studio_artworks[]', submitArtworkFiles[i])
								}
								//data.append('remove')
								API.post('owner/images-validate', data)
									.then(res => {
										for (let i = 0; i < submitArtworkFiles.length; i++) {
											data.append('artworks[]', submitArtworkFiles[i]);
										}
										for (let i = 0; i < deleteArray.length; i++) {
											data.append('removeId[]', deleteArray[i])
										}
										API.post(type === 'studio' ? 'owner/edit-studio-artworks' : 'owner/edit-room-artworks', data)
											.then(res => {
												setIsSubmitting(false);
												setIsVisible(false);
												window.location.reload();
											}).catch (error => {

										});
									}).catch (error => {
									let files = itemArtworkFiles;
									let submitFiles = submitArtworkFiles;
									let removeIndex = [];
									let submitRemoveIndex = [];
									Object.keys(error.response.data.errors).forEach(function(key) {
										let errorId = parseInt(key.replace( /^\D+/g, ''));
										let errorIndex = itemArtworkFiles.findIndex(x => x.name === submitArtworkFiles[errorId].name);
										removeIndex.push(errorIndex);
										submitRemoveIndex.push(parseInt(key.replace( /^\D+/g, '')));
									});
									_.pullAt(files, removeIndex);
									_.pullAt(submitFiles, submitRemoveIndex);
									toast.error('Some of your images was not mark our required and have been removed automatically, please upload others.');
									setIsSubmitting(false)
								});
							} else {
								toast.error('Please upload images');
							}
						}}
						style={{
							width: 132,
							marginLeft: 16,
							fontWeight: '600',
							fontSize: 16,
							height: 48,
							borderRadius: 8,
							backgroundColor: '#19994F',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'row'
						}}
					>
						<Text
							style={{
								fontWeight: '600',
								fontSize: 16,
								color: 'white',
								marginRight: 8
							}}
						>Save</Text>
						{isSubmitting && <ActivityIndicator color={'white'}/>}
					</TouchableOpacity>
				</View>
			</View>
		</View>
	)
}
export default EditPicturesScreen;