import React  from "react";
import {
	TouchableOpacity,
	ImageBackground,
	StyleSheet,
	Text,
	View,
	StatusBar, Image,
} from "react-native";
import {connect} from "react-redux";
import {useTheme} from "@react-navigation/native";

const DownloadAppScreen = (props) => {
	const {colors, dark} = useTheme()
	console.log(props.auth);
	return (
		<View
			style={{
				position: 'absolute',
				flex: 1,
				width: '100%',
				height: '100vh',
				margin: 0,
				padding: 0,
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: '#1D1F1E'
			}}
		>

			<ImageBackground
				source={require('../../assets/images/account_type_bg.jpeg')}
				resizeMode="cover"
				style={{
					...StyleSheet.absoluteFill
				}}
			/>


			<View
				style={{
					maxWidth: 500
				}}
			>
				<View
					style={{
						marginBottom: 16
					}}
				>
					<TouchableOpacity
						onPress={() => props.navigation.goBack()}
						style={{
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M14.8625 3.725L13.3791 2.25L5.13745 10.5L13.3875 18.75L14.8625 17.275L8.08745 10.5L14.8625 3.725Z" fill="white"/>
						</svg>
						<Text
							style={{
								marginLeft: 8,
								color: colors.whiteColor
							}}
						>Back</Text>
					</TouchableOpacity>
				</View>
				<View
					style={{
						backgroundColor: dark ? '#1D1F1E' : '#f3f3f3',
						padding: 24,
						borderRadius: 8,
						justifyContent: 'center',
					}}
				>
					<Text
						style={{
							color: '#3DBA71',
							fontSize: 34,
							marginBottom: 24,
							fontWeight: '600',
						}}
					>Download Stugo app</Text>
					<Text
						style={{
							fontSize: 20,
							marginBottom: 24,
							color: '#6D6E6F'
						}}
					>For a complete studio booking experience, download the stugo app from the app stores and enjoy the best of stugo near you!</Text>
					<View
						style={{
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'space-between',
							marginTop: 24
						}}
					>
						<Image
							source={{
								uri: '/assets/icons/store/appstore.png'
							}}
							resizeMode={'contain'}
							style={{
								width: 180,
								height: 52
							}}
						/>
						<Image
							source={{
								uri: '/assets/icons/store/googleplay.png'
							}}
							resizeMode={'contain'}
							style={{
								width: 180,
								height: 52
							}}
						/>
					</View>
				</View>
			</View>
		</View>
	);
}


export default connect(({auth}) => ({auth}))(DownloadAppScreen);
