import React, {useEffect, useRef, useState} from 'react';
import {View, Text, ActivityIndicator, Modal, ScrollView, Image, TextInput} from "react-native";
import RoundTextInput from "../../components/RoundTextInput";
import {TouchableOpacity} from "react-native-web";
import API from "../../helpers/Axios";
import RoundPicker from "../../components/RoundPicker";
import {toast} from "react-toastify";
import {Popover} from "react-tiny-popover";
import moment from "moment";
import {useNavigation, useRoute} from "@react-navigation/native";

const StudioDataScreen = ({ setIsVisible, item, setitem }) => {

	const [categories, setCategories] = useState([]);

	useEffect(() => {
		API.post('categories')
			.then(res => {
				setCategories(res.data)
			}).catch (error => {
		});
	}, []);

	return (
		<View
			style={{
				flex: 1,
			}}
		>
			<View
				style={{
					justifyContent: 'center',
					alignItems: 'center',
					borderBottomWidth: 1,
					borderColor: '#E4E1E0',
					padding: 16,
					height: 64
				}}
			>
				<Text
					style={{
						fontWeight: '600',
						fontSize: 24,
						color: '#096730'
					}}
				>Studios</Text>
				<TouchableOpacity
					onPress={() => setIsVisible(false)}
					style={{
						position: 'absolute',
						right: 16,
					}}
				>
					<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M18.707 5.293a1 1 0 0 1 0 1.414l-12 12a1 1 0 0 1-1.414-1.414l12-12a1 1 0 0 1 1.414 0Z"
							fill="#6D6E6F"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M5.293 5.293a1 1 0 0 1 1.414 0l12 12a1 1 0 0 1-1.414 1.414l-12-12a1 1 0 0 1 0-1.414Z"
							fill="#6D6E6F"
						/>
					</svg>
				</TouchableOpacity>
			</View>
			<ScrollView
				style={{
					padding: 32,
					maxHeight: 'calc(100vh - 300px)',
				}}
			>
				<View
					style={{
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between'
					}}
				>
					<Text
						style={{
							fontWeight: '600',
							fontSize: 18,
							color: '#096730'
						}}
					>{item.name}</Text>
					<View
						style={{
							flexDirection: 'row'
						}}
					>
						<Text><strong>Location</strong> {item.address}</Text>
					</View>
				</View>
				<View
					style={{
						flexDirection: 'row',
						marginTop: 32,
						justifyContent: 'space-between'
					}}
				>
					<View
						style={{
							marginRight: 24,
						}}
					>
						<Text
							style={{
								fontWeight: '600',
								marginBottom: 4
							}}
						>Business Incorporation</Text>
						<Text>{item.name}</Text>
					</View>
					<View
						style={{
							marginRight: 24,
						}}
					>
						<Text
							style={{
								fontWeight: '600',
								marginBottom: 4
							}}
						>Studio Type</Text>
						{item.category && (
							<Text>{item.category.name}</Text>
						)}
					</View>
					<View
						style={{
							marginRight: 24,
						}}
					>
						<Text
							style={{
								fontWeight: '600',
								marginBottom: 4
							}}
						>Account type</Text>
						<Text>Business</Text>
					</View>
					<View
						style={{
							marginRight: 24,
						}}
					>
						<Text
							style={{
								fontWeight: '600',
								marginBottom: 4
							}}
						>Tax ID</Text>
						<Text>0018190002-133</Text>
					</View>
					<View
						style={{
							marginRight: 24,
						}}
					>
						<Text
							style={{
								fontWeight: '600',
								marginBottom: 4
							}}
						>Added in</Text>
						<Text>{moment.utc(item.created_at).local().format("MMM D, YYYY")}</Text>
					</View>
				</View>
			</ScrollView>
		</View>
	)
}
export default StudioDataScreen;