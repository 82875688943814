import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {store, persistor} from '../store/configureStore';

const GLOBAL = require('../config/Global');

const fetchClient = () => {
  const defaultOptions = {
    baseURL: GLOBAL.API_URL,
    headers: {
      'Content-Type': 'application/json',
      //'Accept': '*',
      //'Content-Type': 'application/json',
      //'X-Requested-With': 'XMLHttpRequest',
      crossdomain: true
    }
  };

  // Create instance
  let instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use(
    async (config) => {
      const token = await AsyncStorage.getItem('access_token');
      config.headers.Authorization =  token ? `Bearer ${token}` : '';
      return config;
    },function (error) {
      console.log(error);
      return Promise.reject(error);
    }
  );

  return instance;
};

export default fetchClient();
