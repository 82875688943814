import React, {useEffect, useRef, useState} from "react";
import {
	Animated,
	Easing,
	View,
} from "react-native";
import {NavigationContainer, useTheme, useRoute, useNavigation} from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import OneSignal from 'react-onesignal';
import AppStyles from "../AppStyles";
import SignupScreen from "../screens/signup/SignupScreen";
import WelcomeScreen from "../screens/WelcomeScreen";
import {connect} from "react-redux";
import {ThemeContext} from "../components/ThemeContext";
import AccountTypeScreen from "../screens/signup/AccountTypeScreen";
import DownloadAppScreen from "../screens/signup/DownloadAppScreen";
import UserInfoScreen from "../screens/signup/UserInfoScreen";
import SigninScreen from "../screens/signup/SigninScreen";
import PasswordScreen from "../screens/signup/PasswordScreen";
import DashboardScreen from "../screens/dashboard/DashboardScreen";
import BookingsScreen from "../screens/dashboard/BookingsScreen";
import DashboardSideBar from "../components/DashboardSideBar";
import EarningsScreen from "../screens/dashboard/EarningsScreen";
import MessagesScreen from "../screens/dashboard/MessagesScreen";
import RoomsScreen from "../screens/dashboard/RoomsScreen";
import SettingsScreen from "../screens/dashboard/SettingsScreen";
import HelpScreen from "../screens/dashboard/HelpScreen";
import EditStudioScreen from "../screens/modals/EditStudioScreen";
import HeaderContainer from "../components/HeaderContainer";
import NewStudioScreen from "../screens/dashboard/NewStudioScreen";
import NewRoomScreen from "../screens/dashboard/NewRoomScreen";
import {store} from "../store/configureStore";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Echo from "laravel-echo";
import Socketio from "socket.io-client";
import runOneSignal from '../helpers/OneSignal';
import ForgotPasswordScreen from "../screens/signup/ForgotPasswordScreen";
import ResetPasswordScreen from "../screens/signup/ResetPasswordScreen";
import SetNewPasswordScreen from "../screens/signup/SetNewPasswordScreen";
import StudiosScreen from "../screens/dashboard/StudiosScreen";
import NotificationsScreen from "../screens/dashboard/NotificationsScreen";
import ClientsScreen from "../screens/dashboard/ClientsScreen";
import DataScreen from "../screens/dashboard/DataScreen";
import RevenueScreen from "../screens/dashboard/RevenueScreen";
import ReportsScreen from "../screens/dashboard/ReportsScreen";
import PayoutsScreen from "../screens/dashboard/PayoutsScreen";
import Responsive from "../helpers/Reponsive";

const noTransitionConfig = () => ({
	transitionSpec: {
		duration: 0,
		timing: Animated.timing,
		easing: Easing.step0,
	},
});

const Auth = createStackNavigator();
const Dashboard = createStackNavigator();
const Root = createStackNavigator();
const OnBoarding = createStackNavigator();

const AuthStack = () => {
	return (
		<Auth.Navigator

			initialRouteName="SigninScreen"
			screenOptions={{
				headerShown: false
			}}
		>
			<Auth.Screen name="SigninScreen" component={SigninScreen} />
			<Auth.Screen name="ForgotPasswordScreen" component={ForgotPasswordScreen} />
			<Auth.Screen name="ResetPasswordScreen" component={ResetPasswordScreen} />
			<Auth.Screen name="SetNewPasswordScreen" component={SetNewPasswordScreen} />
		</Auth.Navigator>
	);
};

const DashboardStack = (navigation, route) => {
	const { isMobile } = Responsive();

	return (
		<View
			style={{
				flexDirection: 'row',
				minHeight: '100vh',
				paddingTop: 80
			}}
		>
			<HeaderContainer />
			{!isMobile && (
				<View
					style={{
						width: 220,
					}}
				>
					<DashboardSideBar />
				</View>
			)}
			<View
				style={{
					flex: 1,
					backgroundColor: '#f9f9f9',
				}}
			>
				<Dashboard.Navigator
					initialRouteName="DashboardScreen"
					screenOptions={{
						headerShown: false
					}}
				>
					<Dashboard.Screen name="DashboardScreen" component={DashboardScreen} />
					<Dashboard.Screen name="BookingsScreen" component={BookingsScreen} />
					<Dashboard.Screen name="EarningsScreen" component={EarningsScreen} />
					<Dashboard.Screen name="MessagesScreen" component={MessagesScreen} />
					<Dashboard.Screen name="RoomsScreen" component={RoomsScreen} />
					<Dashboard.Screen name="SettingsScreen" component={SettingsScreen} />
					<Dashboard.Screen name="HelpScreen" component={HelpScreen} />
					<Dashboard.Screen name="NewStudioScreen" component={NewStudioScreen} />
					<Dashboard.Screen name="NewRoomScreen" component={NewRoomScreen} />
					<Dashboard.Screen name="StudiosScreen" component={StudiosScreen} />
					<Dashboard.Screen name="NotificationsScreen" component={NotificationsScreen} />
					<Dashboard.Screen name="ClientsScreen" component={ClientsScreen} />
					<Dashboard.Screen name="DataScreen" component={DataScreen} />
					<Dashboard.Screen name="RevenueScreen" component={RevenueScreen} />
					<Dashboard.Screen name="ReportsScreen" component={ReportsScreen} />
					<Dashboard.Screen name="PayoutsScreen" component={PayoutsScreen} />
				</Dashboard.Navigator>
			</View>
		</View>
	);
};

const RootNavigator = ({auth, display, dispatch}) => {
	const navigation = useNavigation();
	const isLogged = auth.isLogged;
	const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		OneSignal.init({ appId: 'e3076dc2-3fb9-4b84-9bdf-737566e34e9b'});
		OneSignal.isPushNotificationsEnabled(function(isEnabled) {
			if (isEnabled)
				console.log("Push notifications are enabled!");
			else
				console.log("Push notifications are not enabled yet.");
		});
		OneSignal.showSlidedownPrompt();

		if(isLogged) {
			OneSignal.setExternalUserId(auth.user.id);
			OneSignal.getExternalUserId().then(function(externalUserId){
				console.log("externalUserId: ", externalUserId);
			});

			OneSignal.on('notificationDisplay', function(event) {
				console.warn('OneSignal notification displayed:', event);
			});

			OneSignal.on('permissionPromptDisplay', function () {
				console.log("The prompt displayed");
			});

			OneSignal.on('notificationDismiss', function (event) {
				let data = event.data;
				if(data.type === 'ChatMessageWasReceived') {
					navigation.navigate('MessagesScreen');
				}
				console.log('OneSignal notification dismissed:', event);
			});

			OneSignal.on('subscriptionChange', function(isSubscribed) {
				console.log("The user's subscription state is now:", isSubscribed);
			});


			const EchoServer = new Echo({
				broadcaster: 'socket.io',
				host: 'https://admin.stugo.com',
				transports:["websocket","polling","flashsocket"],
				client: Socketio,
				auth:{
					headers: {
						Accept: 'application/json',
						'Authorization': `Bearer ${auth.accessToken}`,
					},
				},
			});

			const Channel = EchoServer.private(`App.Models.User.${auth.user.id}`);

			Channel.listen('.App\\Events\\ChatMessageWasReceived', (callback) => {
				console.log('.App\\Events\\ChatMessageWasReceived', callback);

				dispatch({
					type: 'NEW_MESSAGE_WAS_RECEIVED', content: callback.message
				})
			})
		}
	}, [isLogged]);

	return (
		<Root.Navigator
			initialRouteName={AuthStack}
			screenOptions={{
				headerShown: false,
				transitionConfig: noTransitionConfig,
			}}
		>
			<Root.Group>
				{auth.isLogged ? (
				<>
					<Root.Screen name="DashboardStack" component={DashboardStack} />
				</>
				) : (
				<>
					<Root.Screen name="AuthStack" component={AuthStack} />
				</>
				)}
			</Root.Group>
			<Root.Group screenOptions={{ presentation: 'modal' }}>
				<Root.Screen name="EditStudioScreen" component={EditStudioScreen}/>
			</Root.Group>
		</Root.Navigator>
	);
};

const AppNavigator = ({auth, display, dispatch}) => {
	const [theme, setTheme] = useState( 'Light');

	const themeData = { theme, setTheme };
	const {colors, dark} = useTheme();

	const linking = {
		enabled: true,
		//prefixes: ['http://localhost:3000', 'mychat://'],
		/*config: {
			screens: {
				DashboardStack: {
					screens: {
						MessagesScreen: 'messages',
					},
				},
			},
		},*/
	};

	return (
		<ThemeContext.Provider value={themeData}>
			<NavigationContainer linking={linking} theme={AppStyles.lightColorSet}>
				<RootNavigator auth={auth} display={display} dispatch={dispatch}/>
				<ToastContainer hideProgressBar={true}/>
			</NavigationContainer>
		</ThemeContext.Provider>
	);
};

export default connect(({auth, display, message}) => ({auth, display, message}))(AppNavigator);
