import React, {useEffect, useState} from 'react';
import {View, Text, TextInput, ActivityIndicator, Image, StyleSheet} from "react-native";
import {TouchableOpacity} from "react-native-web";
import API from "../../helpers/Axios";
import AppStyles from "../../AppStyles";
import UserStatus from "../../components/UserStatus";
import RoundTextInput from "../../components/RoundTextInput";
import RoundPicker from "../../components/RoundPicker";

function Modal ({ setIsVisible, item }) {
	const [isLoading, setIsLoading] = useState(true);
	const [users, setUsers] = useState([]);
	const [usersData, setUsersData] = useState(null);
	const [groups, setGroups] = useState([]);
	const [isUsersLoading, setUsersIsLoading] = useState(true);
	const [currentUser, setCurrentUser] = useState(null);
	const [userDetailsVisible, setUserDetailsVisible] = useState(false);
	const [isEditor, setIsEditor] = useState(false);

	const [firstName, setFirstName] = useState({
		value: '',
		error: false,
		errorString: null
	});

	const [lastName, setLastName] = useState({
		value: '',
		error: false,
		errorString: null
	});

	const [email, setEmail] = useState({
		value: '',
		error: false,
		errorString: null
	});

	const [accountType, setAccountType] = useState({
		value: true,
		error: false,
		errorString: 'Please select account type'
	});

	const getUsers = (url = `admin/staffs`) => {
		API.post(url)
			.then(res => {
				setUsers(res.data.data);
				setUsersData(res.data);
				setUsersIsLoading(false);
				setIsLoading(false);
			}).catch (error => {
			console.log(error);
		});
	}

	const getGroups = (url = `admin/groups`) => {
		API.post(url)
			.then(res => {
				setGroups(res.data)
			}).catch (error => {
			console.log(error);
		});
	}

	useEffect(() => {
		getUsers();
		getGroups();
	}, []);

	return isLoading ? (<ActivityIndicator style={{flex: 1, justifyContent: 'center', alignItems: 'center', marginVertical: 64}} />) : (
		<>
			{isEditor && (
				<View
					style={{
						flex: 1,
					}}
				>
					<View
						style={{
							flex: 1,
							justifyContent: 'center',
							alignItems: 'center',
							borderBottomWidth: 1,
							borderColor: '#E4E1E0',
							padding: 16
						}}
					>
						<Text
							style={{
								fontWeight: '500',
								fontSize: 20
							}}
						>Edit account</Text>
						<TouchableOpacity
							onPress={() => setIsVisible(false)}
							style={{
								position: 'absolute',
								right: 16,
							}}
						>
							<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M18.707 5.293a1 1 0 0 1 0 1.414l-12 12a1 1 0 0 1-1.414-1.414l12-12a1 1 0 0 1 1.414 0Z"
									fill="#6D6E6F"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M5.293 5.293a1 1 0 0 1 1.414 0l12 12a1 1 0 0 1-1.414 1.414l-12-12a1 1 0 0 1 0-1.414Z"
									fill="#6D6E6F"
								/>
							</svg>
						</TouchableOpacity>
					</View>
					<View
						style={{
							padding: 32
						}}
					>
						<RoundTextInput
							placeholder={'First Name'}
							value={firstName.value}
							error={firstName.error}
							onChangeText={text => {
								setFirstName({
									value: text,
									error: false,
									errorString: firstName.errorString
								});
							}}
						/>
						<RoundTextInput
							placeholder={'Last Name'}
							value={lastName.value}
							error={lastName.error}
							onChangeText={text => {
								setLastName({
									value: text,
									error: false,
									errorString: lastName.errorString
								});
							}}
						/>
						<RoundTextInput
							placeholder={'Email'}
							value={email.value}
							error={email.error}
							onChangeText={text => {
								setEmail({
									value: text,
									error: false,
									errorString: email.errorString
								});
							}}
						/>
						<RoundTextInput
							placeholder={'Phone number'}
							value={email.value}
							error={email.error}
							onChangeText={text => {
								setEmail({
									value: text,
									error: false,
									errorString: email.errorString
								});
							}}
						/>
						<RoundPicker
							data={groups}
							placeholder={'Account type'}
							value={accountType.value}
							error={accountType.error}
							errorString={accountType.errorString}
							onValueChange={value => {
								setAccountType({
									value: value,
									error: false,
									errorString: accountType.errorString
								});
							}}
						/>
					</View>
					<View
						style={{
							flexDirection: 'row',
							justifyContent: 'flex-end',
							marginBottom: 24,
							marginRight: 24
						}}
					>
						<TouchableOpacity
							onPress={() => {

							}}
							style={{
								paddingHorizontal: 24,
								borderWidth: 1,
								borderColor: '#E4E1E0',
								fontWeight: '600',
								fontSize: 16,
								height: 48,
								borderRadius: 8,
								justifyContent: 'center',
								alignItems: 'center',
								flexDirection: 'row'
							}}
						>
							<Text
								style={{
									fontWeight: '600',
									fontSize: 16,
									marginRight: 8
								}}
							>Save</Text>
						</TouchableOpacity>
					</View>
				</View>
			)}
			{!isEditor && (
				<View
					style={{
						flex: 1,
					}}
				>
					<View
						style={{
							flex: 1,
							justifyContent: 'center',
							alignItems: 'center',
							borderBottomWidth: 1,
							borderColor: '#E4E1E0',
							padding: 16
						}}
					>
						<Text
							style={{
								fontWeight: '500',
								fontSize: 20
							}}
						>Access levels and accounts - Stugo</Text>
						<TouchableOpacity
							onPress={() => setIsVisible(false)}
							style={{
								position: 'absolute',
								right: 16,
							}}
						>
							<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M18.707 5.293a1 1 0 0 1 0 1.414l-12 12a1 1 0 0 1-1.414-1.414l12-12a1 1 0 0 1 1.414 0Z"
									fill="#6D6E6F"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M5.293 5.293a1 1 0 0 1 1.414 0l12 12a1 1 0 0 1-1.414 1.414l-12-12a1 1 0 0 1 0-1.414Z"
									fill="#6D6E6F"
								/>
							</svg>
						</TouchableOpacity>
					</View>
					<View
						style={{
							padding: 32
						}}
					>
						<View
							style={{
								borderWidth: 1,
								borderColor: '#E4E1E0',
								borderRadius: 8,
								overflow: 'hidden'
							}}
						>
							<View
								style={{
									backgroundColor: '#F8F8F8',
									paddingHorizontal: 16,
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'space-between',
									paddingVertical: 8
								}}
							>
								<select
									style={{
										height: 36,
										borderWidth: 1,
										borderColor: '#E4E1E0',
										paddingLeft: 16,
										paddingRight: 16,
										borderRadius: 6
									}}
								>
									<option disabled>All account types</option>
									{groups.map((item, index) => (
										<option value={item.id}>{item.name}</option>
									))}
								</select>
								<select
									style={{
										marginLeft: 16,
										height: 36,
										borderWidth: 1,
										borderColor: '#E4E1E0',
										paddingLeft: 16,
										paddingRight: 16,
										borderRadius: 6
									}}
								>
									<option disabled>All status</option>
									<option value={1}>Active</option>
									<option value={0}>Inactive</option>
								</select>
								<View
									style={{
										marginHorizontal: 16,
										backgroundColor: 'white',
										flex: 1,
										height: 36,
										borderWidth: 1,
										borderColor: '#E4E1E0',
										borderRadius: 6,
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'space-between',
										paddingHorizontal: 16
									}}
								>
									<svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path fillRule="evenodd" clipRule="evenodd" d="M7.33301 2.66659C4.75568 2.66659 2.66634 4.75592 2.66634 7.33325C2.66634 9.91058 4.75568 11.9999 7.33301 11.9999C9.91034 11.9999 11.9997 9.91058 11.9997 7.33325C11.9997 4.75592 9.91034 2.66659 7.33301 2.66659ZM1.33301 7.33325C1.33301 4.01954 4.0193 1.33325 7.33301 1.33325C10.6467 1.33325 13.333 4.01954 13.333 7.33325C13.333 10.647 10.6467 13.3333 7.33301 13.3333C4.0193 13.3333 1.33301 10.647 1.33301 7.33325Z" fill="#CCCCCC" />
										<path fillRule="evenodd" clipRule="evenodd" d="M10.6289 10.6286C10.8892 10.3683 11.3113 10.3683 11.5717 10.6286L14.4717 13.5286C14.732 13.789 14.732 14.2111 14.4717 14.4714C14.2113 14.7318 13.7892 14.7318 13.5289 14.4714L10.6289 11.5714C10.3685 11.3111 10.3685 10.889 10.6289 10.6286Z" fill="#CCCCCC" />
									</svg>
									<TextInput
										placeholder={'Search by name, email or account type'}
										style={{
											flex: 1,
											outline: 0,
											paddingLeft: 16,
											fontSize: 14,
											fontWeight: '600'
										}}
										value={''}
										//onChangeText={(text) => setAdditionRule(text)}
										//onSubmitEditing={() => onSubmit()}
									/>
								</View>
								<TouchableOpacity
									style={{
										borderWidth: 1,
										borderRadius: 6,
										borderColor: '#E4E1E0',
										height: 36,
										alignItems: 'center',
										justifyContent: 'center',
										paddingHorizontal: 24
									}}
								>
									<Text>Filter</Text>
								</TouchableOpacity>
							</View>
							<table
								style={{
									flex: 1,
									width: '100%',
									borderCollapse: 'collapse'
								}}
							>
								<tr
									style={{
										height: 48
									}}
								>
									<th
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											textAlign: 'left',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Text
											style={styles.tableHeader}
										>Name</Text>
									</th>
									<th
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											textAlign: 'left',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Text
											style={styles.tableHeader}
										>E-mail</Text>
									</th>
									<th
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											textAlign: 'left',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Text
											style={styles.tableHeader}
										>Contact Number</Text>
									</th>
									<th
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											textAlign: 'left',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Text
											style={styles.tableHeader}
										>Account type</Text>
									</th>
									<th
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											textAlign: 'left',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Text
											style={styles.tableHeader}
										>Status</Text>
									</th>
								</tr>
								{usersData && usersData.data.map((item, index) => (
									<tr
										key={index}
										style={{
											height: 64,
											cursor: 'pointer'
										}}
										onClick={() => {
											setCurrentUser(item);
											setFirstName({
												value: item.first_name,
												error: false,
												errorString: firstName.errorString
											});
											setLastName({
												value: item.last_name,
												error: false,
												errorString: firstName.errorString
											});
											setEmail({
												value: item.email,
												error: false,
												errorString: firstName.errorString
											});
											setIsEditor(true);
										}}
									>
										<td
											style={{
												borderBottom: '1px solid',
												borderBottomColor: '#E4E1E0',
												paddingLeft: 16,
												paddingRight: 16
											}}
										>
											<Text>{item.name}</Text>
										</td>
										<td
											style={{
												borderBottom: '1px solid',
												borderBottomColor: '#E4E1E0',
												paddingLeft: 16,
												paddingRight: 16
											}}
										>
											<Text
												style={{
													color: '#595959',
													marginTop: 4,
													fontWeight: '600'
												}}
											>{item.email}</Text>
										</td>
										<td
											style={{
												borderBottom: '1px solid',
												borderBottomColor: '#E4E1E0',
												paddingLeft: 16,
												paddingRight: 16
											}}
										>
											<Text>{item.phone && AppStyles.utils.formatPhoneNumber(item.phone.phone_number)}</Text>
										</td>
										<td
											style={{
												borderBottom: '1px solid',
												borderBottomColor: '#E4E1E0',
												paddingLeft: 16,
												paddingRight: 16
											}}
										>
											<Text
												style={{
													color: '#595959',
													fontWeight: '600'
												}}
											>{item.group && item.group.role.name}</Text>
										</td>
										<td
											style={{
												borderBottom: '1px solid',
												borderBottomColor: '#E4E1E0',
												paddingLeft: 16,
												paddingRight: 16
											}}
										>
											<UserStatus status={item.banned}/>
										</td>
									</tr>
								))}
							</table>
							{usersData && (
								<View
									style={{
										backgroundColor: '#F8F8F8',
										height: 48,
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'flex-end',
										paddingHorizontal: 24
									}}
								>
									<View
										style={{
											marginRight: 40,
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
									>
										<Text
											style={{
												fontWeight: '600',
												marginRight: 8
											}}
										>Rows per page: {usersData.per_page}</Text>
										<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M12 14 8 9h8l-4 5Z" fill="#6D6E6F" />
										</svg>
									</View>
									<Text
										style={{
											fontWeight: '600'
										}}
									>{usersData.from}- {usersData.to} </Text>
									<View
										style={{
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: 'space-between',
											width: 80,
											marginLeft: 40
										}}
									>
										<TouchableOpacity
											onPress={() => {
												if(!usersData.prev_page_url) {
													return;
												}
												setUsersIsLoading(true);
												getUsers(usersData.prev_page_url);
											}}
										>
											<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
												<path
													d="M14.8 8.106c.628-.593-.367-1.533-.995-.89L9.196 11.52a.66.66 0 0 0 0 .94l4.609 4.352c.628.593 1.623-.346.995-.94l-4.085-3.857L14.8 8.106Z"
													fill={usersData.prev_page_url ? '#6D6E6F' : '#ccc'}
												/>
											</svg>
										</TouchableOpacity>
										{isUsersLoading && (
											<ActivityIndicator color={'#096730'} />
										)}
										<TouchableOpacity
											onPress={() => {
												if(!usersData.next_page_url) {
													return;
												}
												setUsersIsLoading(true);
												getUsers(usersData.next_page_url);
											}}
										>
											<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
												<path
													d="M9.2 8.106c-.628-.593.367-1.533.995-.89l4.609 4.303a.66.66 0 0 1 0 .94l-4.609 4.352c-.628.593-1.623-.346-.995-.94l4.085-3.857L9.2 8.106Z"
													fill={usersData.next_page_url ? '#6D6E6F' : '#ccc'}
												/>
											</svg>
										</TouchableOpacity>
									</View>
								</View>
							)}
						</View>
					</View>
					<View
						style={{
							flexDirection: 'row',
							justifyContent: 'flex-end',
							marginBottom: 24,
							marginRight: 24
						}}
					>
						<TouchableOpacity
							onPress={() => {

							}}
							style={{
								paddingHorizontal: 24,
								borderWidth: 1,
								borderColor: '#E4E1E0',
								fontWeight: '600',
								fontSize: 16,
								height: 48,
								borderRadius: 8,
								justifyContent: 'center',
								alignItems: 'center',
								flexDirection: 'row'
							}}
						>
							<Text
								style={{
									fontWeight: '600',
									fontSize: 16,
									marginRight: 8
								}}
							>Add new account</Text>
						</TouchableOpacity>
					</View>
				</View>
			)}
		</>
	)
}

const styles = StyleSheet.create({
	tableHeader: {
		fontWeight: '600',
		textAlign: 'left',
		alignSelf: 'flex-start'
	},
	tableCell: {
		borderBottom: '1px solid',
		borderBottomColor: 'gray',
		paddingLeft: 16,
		paddingRight: 16
	},
});


export default Modal;