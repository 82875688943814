const types = {
    UPDATE_COORDINATE: "UPDATE_COORDINATE",
};
const initialState = {
    current: {
        latitude: null,
        longitude: null
    },
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.UPDATE_COORDINATE:
            return {
                ...state,
                current: action.coordinate
            };
        default:
            return state;
    }
};
