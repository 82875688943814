import {useEffect, useLayoutEffect, useState} from 'react';

const Responsive = () => {
  const useWindowSize = () =>{
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  const [width, height] = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if(width < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  return { isMobile, width }
};

export default Responsive;