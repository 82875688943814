import React from 'react';
import {Text, View} from 'react-native';

const StudioStatus = (props) => {
  const { status } = props;

  return (<View>
    {status === 0 && (
      <View
        style={{
          height: 26,
          borderRadius: 13,
          backgroundColor: '#FEFAEC',
          justifyContent: 'center',
          alignItems: 'center',
          width: 'fit-content',
          alignSelf: 'flex-start',
          paddingHorizontal: 8,
          flexDirection: 'row'
        }}
      >
        <View
          style={{
            width: 6,
            height: 6,
            borderRadius: 3,
            backgroundColor: '#E9C818',
            marginRight: 8
          }}
        />
        <Text
          style={{
            fontWeight: '600',
            fontSize: 12,
            color: '#725408'
          }}
        >{'Pending approval'}</Text>
      </View>
    )}
    {status === 1 && (
      <View
        style={{
          height: 26,
          borderRadius: 13,
          backgroundColor: '#C2F3D7',
          justifyContent: 'center',
          alignItems: 'center',
          width: 'fit-content',
          alignSelf: 'flex-start',
          paddingHorizontal: 8,
          flexDirection: 'row'
        }}
      >
        <View
          style={{
            width: 6,
            height: 6,
            borderRadius: 3,
            backgroundColor: '#58B17D',
            marginRight: 8
          }}
        />
        <Text
          style={{
            fontWeight: '600',
            fontSize: 12,
            color: '#096730'
          }}
        >{'Active'}</Text>
      </View>
    )}
    {status === 2 && (
      <View
        style={{
          height: 26,
          borderRadius: 13,
          backgroundColor: '#FBE8E8',
          justifyContent: 'center',
          alignItems: 'center',
          width: 'fit-content',
          alignSelf: 'flex-start',
          paddingHorizontal: 8,
          flexDirection: 'row'
        }}
      >
        <View
          style={{
            width: 6,
            height: 6,
            borderRadius: 3,
            backgroundColor: '#E55A5A',
            marginRight: 8
          }}
        />
        <Text
          style={{
            fontWeight: '600',
            fontSize: 12,
            color: '#C94545'
          }}
        >{'Not approved'}</Text>
      </View>
    )}
  </View>);
};

export default StudioStatus;