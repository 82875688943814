import React, {useState} from 'react';
import {View, Text, TextInput, ActivityIndicator} from "react-native";
import {TouchableOpacity} from "react-native-web";
import moment from "moment";
import StyleDict from "../../AppStyles";
import {useTheme} from "@react-navigation/native";
import BookingStatus from "../../components/BookingStatus";
import API from "../../helpers/Axios";

function BookingDetailsScreen ({ setIsVisible, item }) {
	const {colors, dark} = useTheme()
	const [isSubmitting, setIsSubmitting] = useState( false);
	const [data, setData] = useState(item.booking);
	const [status, setStatus] = useState(item.status);
	const [error, setError] = useState(false);

	return (
		<View
			style={{
				flex: 1,
			}}
		>
			<View
				style={{
					flex: 1,
					justifyContent: 'center',
					alignItems: 'center',
					borderBottomWidth: 1,
					borderColor: '#E4E1E0',
					padding: 16
				}}
			>
				<Text
					style={{
						fontWeight: '500',
						fontSize: 20
					}}
				>Payout details</Text>
				<TouchableOpacity
					onPress={() => setIsVisible(false)}
					style={{
						position: 'absolute',
						right: 16,
					}}
				>
					<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M18.707 5.293a1 1 0 0 1 0 1.414l-12 12a1 1 0 0 1-1.414-1.414l12-12a1 1 0 0 1 1.414 0Z"
							fill="#6D6E6F"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M5.293 5.293a1 1 0 0 1 1.414 0l12 12a1 1 0 0 1-1.414 1.414l-12-12a1 1 0 0 1 0-1.414Z"
							fill="#6D6E6F"
						/>
					</svg>
				</TouchableOpacity>
			</View>
			<View
				style={{
					padding: 32
				}}
			>
				{error && (
					<View
						style={{
							backgroundColor: '#FBE8E8',
							padding: 16,
							marginBottom: 24,
							borderRadius: 6
						}}
					>
						<Text
							style={{
								color: '#C94545'
							}}
						>Customer didn't finish link their account yet.</Text>
					</View>
				)}
				<View
					style={{
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between'
					}}
				>
					<View>
						<Text
							style={{
								fontWeight: '600',
								fontSize: 20,
								marginBottom: 8
							}}
						>{data.studio.name}</Text>
						<Text
							style={{
								fontWeight: '600',
								fontSize: 16,
								color: '#595959'
							}}
						>{data.room.name}</Text>
					</View>
					<View>
						<Text
							style={{
								fontWeight: '600',
								fontSize: 16,
								marginBottom: 8
							}}
						>Location <Text style={{fontSize: 16, fontWeight: '400', color: colors.mainSubtextColor}}>{data.studio.address}</Text></Text>
						<View
							style={{
								flexDirection: 'row',
								justifyContent: 'flex-end',
								alignItems: 'center'
							}}
						>
							<Text
								style={{
									fontWeight: '600',
									fontSize: 16,
									color: colors.mainSubtextColor,
									marginRight: 16
								}}
							>Payment status</Text>
							{status === 0 && (
								<View
									style={{
										height: 26,
										borderRadius: 13,
										backgroundColor: '#EBE8E7',
										justifyContent: 'center',
										alignItems: 'center',
										width: 'fit-content',
										alignSelf: 'flex-start',
										paddingHorizontal: 8
									}}
								>
									<Text
										style={{
											fontWeight: '600',
											fontSize: 12,
										}}
									>{'Processing'}</Text>
								</View>
							)}
							{status === 1 && (
								<View
									style={{
										height: 26,
										borderRadius: 13,
										backgroundColor: '#3DBA71',
										justifyContent: 'center',
										alignItems: 'center',
										width: 'fit-content',
										alignSelf: 'flex-start',
										paddingHorizontal: 8
									}}
								>
									<Text
										style={{
											fontWeight: '600',
											fontSize: 12,
											color: 'white',
										}}
									>{'Paid'}</Text>
								</View>
							)}
						</View>
					</View>
				</View>
				<View
					style={{
						marginTop: 32,
						borderWidth: 1,
						borderColor: '#E4E1E0',
						borderRadius: 8,
						overflow: 'hidden'
					}}
				>
					<View
						style={{
							backgroundColor: '#F8F8F8',
							paddingHorizontal: 16,
							height: 36,
							justifyContent: 'center'
						}}
					>
						<Text
							style={{
								fontWeight: '600',
								fontSize: 16
							}}
						>Booking information</Text>
					</View>
					<View
						style={{
							flexDirection: 'row',
							justifyContent: 'space-between',
							paddingVertical: 16
						}}
					>
						<View
							style={{
								flex: 1,
								paddingLeft: 16,
							}}
						>
							<Text
								style={{
									fontWeight: '600',
									fontSize: 20
								}}
							>Date & Time</Text>
							<View
								style={{
									flexDirection: 'row',
									marginTop: 16
								}}
							>
								<View
									style={{
										width: '50%'
									}}
								>
									<Text
										style={{
											color: '#3DBA71',
											fontWeight: '700',
											fontSize: 12
										}}
									>Name</Text>
									<Text
										style={{
											marginTop: 8,
											fontWeight: '600',
											color: colors.mainTextColor
										}}
									>{data.user.name}</Text>
								</View>
								<View
									style={{
										width: '50%'
									}}
								>
									<Text
										style={{
											color: '#3DBA71',
											fontWeight: '700',
											fontSize: 12
										}}
									>Booking date</Text>
									<Text
										style={{
											marginTop: 8,
											fontWeight: '600',
											color: colors.mainTextColor
										}}
									>{moment(data.start_time).format('DD MMM Y')}</Text>
								</View>
							</View>
							<View
								style={{
									flexDirection: 'row',
									marginTop: 16
								}}
							>
								<View
									style={{
										width: '50%'
									}}
								>
									<Text
										style={{
											color: '#3DBA71',
											fontWeight: '700',
											fontSize: 12
										}}
									>Session starts at</Text>
									<Text
										style={{
											marginTop: 8,
											fontWeight: '600',
											color: colors.mainTextColor
										}}
									>{moment.utc(data.start_time).local().format("hh:mm A")}</Text>
								</View>
								<View
									style={{
										width: '50%'
									}}
								>
									<Text
										style={{
											color: '#3DBA71',
											fontWeight: '700',
											fontSize: 12
										}}
									>Session ends at</Text>
									<Text
										style={{
											marginTop: 8,
											fontWeight: '600',
											color: colors.mainTextColor
										}}
									>{moment.utc(data.end_time).local().format("hh:mm A")}</Text>
								</View>
							</View>
							<View
								style={{
									flexDirection: 'row',
									marginTop: 16
								}}
							>
								<View
									style={{
										width: '50%'
									}}
								>
									<Text
										style={{
											color: '#3DBA71',
											fontWeight: '700',
											fontSize: 12
										}}
									>Session duration</Text>
									<Text
										style={{
											marginTop: 8,
											fontWeight: '600',
											color: colors.mainTextColor
										}}
									>{StyleDict.utils.diffTime(data.start_time, data.end_time)} Hours</Text>
								</View>
							</View>
						</View>
						<View
							style={{
								flex: 1,
								paddingRight: 16,

							}}
						>
							<Text
								style={{
									fontWeight: '600',
									fontSize: 20
								}}
							>Detailed Breakdown</Text>
							<View
								style={{
									marginBottom: 24
								}}
							>
								{data.booking_amenities.map((item, index) => item.amenity && (
									<View
										key={index}
										style={{
											flexDirection: 'row',
											marginTop: 16,
											justifyContent: 'space-between',
											borderBottomWidth: 1,
											borderBottomColor: dark ? '#313233' : '#E4E1E0',
											paddingBottom: 16
										}}
									>
										<Text
											style={{
												fontWeight: '600',
												fontSize: 14,
												color: colors.mainSubtextColor
											}}
										>{item.amenity.name} ({item.quantity} hours)</Text>
										{item.cost === 0 && (
											<Text
												style={{
													fontWeight: '600',
													color: colors.mainTextColor
												}}
											>Free</Text>
										)}
										{item.cost > 0 && (
											<Text
												style={{
													fontWeight: '600',
													color: colors.mainTextColor
												}}
											>+ ${item.cost}</Text>
										)}
									</View>
								))}
								{data.booking_equipments.map((item, index) => item.equipment && (
									<View
										key={index}
										style={{
											flexDirection: 'row',
											marginTop: 16,
											justifyContent: 'space-between',
											borderBottomWidth: 1,
											borderBottomColor: dark ? '#313233' : '#E4E1E0',
											paddingBottom: 16
										}}
									>
										<Text
											style={{
												fontWeight: '600',
												fontSize: 14,
												color: colors.mainSubtextColor
											}}
										>{item.equipment.name} ({item.quantity} hours)</Text>
										{item.cost === 0 && (
											<Text
												style={{
													fontWeight: '600',
													color: colors.mainTextColor
												}}
											>Free</Text>
										)}
										{item.cost > 0 && (
											<Text
												style={{
													fontWeight: '600',
													color: colors.mainTextColor
												}}
											>+ ${item.cost}</Text>
										)}
									</View>
								))}
							</View>

							{item && (() => {
								const baseCost = StyleDict.utils.diffTimeToHours(moment(data.start_time).toDate(), moment(data.end_time).toDate()) * data.room.per_session_price;
								let amenitiesCost = 0;
								data.booking_amenities.forEach(item => {
									amenitiesCost = amenitiesCost + item.cost * item.quantity
								});
								let equipmentsCost = 0;
								data.booking_equipments.forEach(item => {
									equipmentsCost = equipmentsCost + item.cost * item.quantity
								});
								const subTotal = baseCost + amenitiesCost + equipmentsCost;
								const serviceFee = subTotal * 0.03;
								const totalCost = baseCost + amenitiesCost + equipmentsCost + serviceFee;

								return (
									<>
										<View
											style={{
												justifyContent: 'space-between',
												flexDirection: 'row',
												marginBottom: 8
											}}
										>
											<Text
												style={{
													color: colors.mainTextColor
												}}
											>Subtotal</Text>
											<Text
												style={{
													color: colors.mainTextColor
												}}
											>$ {(data.cost - data.total_service_fee).toFixed(2)}</Text>
										</View>
										<View
											style={{
												justifyContent: 'space-between',
												flexDirection: 'row',
												marginBottom: 8
											}}
										>
											<Text
												style={{
													color: colors.mainTextColor
												}}
											>Service fee</Text>
											<Text
												style={{
													color: colors.mainTextColor
												}}
											>$ {data.total_service_fee}</Text>
										</View>
										<View
											style={{
												justifyContent: 'space-between',
												flexDirection: 'row',
												marginTop: 4
											}}
										>
											<Text
												style={{
													color: colors.mainTextColor,
													fontWeight: '700'
												}}
											>Total</Text>
											<Text
												style={{
													color: colors.mainTextColor,
													fontWeight: '700'
												}}
											>$ {data.cost}</Text>
										</View>
									</>
								)
							})()}
						</View>
					</View>
				</View>
				<View
					style={{
						flexDirection: 'row',
						justifyContent: 'flex-end',
						marginTop: 32
					}}
				>
					{item.status === 0 && (
						<TouchableOpacity
							onPress={() => {
								if(isSubmitting) {
									return
								}
								setIsSubmitting(true);
								API.post('admin/payouts/send', {
									id: item.id
								}).then(res => {
									setIsSubmitting(false);
									item.status = 1;
									setStatus(1);
								}).catch (error => {
									setIsSubmitting(false);
									setError(true);
								});
							}}
							style={{
								paddingHorizontal: 32,
								marginLeft: 16,
								fontWeight: '600',
								fontSize: 16,
								height: 48,
								borderRadius: 8,
								backgroundColor: '#19994F',
								justifyContent: 'center',
								alignItems: 'center'
							}}
						>
							{isSubmitting && <ActivityIndicator color={'white'}/>}
							{!isSubmitting && <Text
								style={{
									fontWeight: '600',
									fontSize: 16,
									color: 'white'
								}}
							>Pay ${item.amount}</Text>}
						</TouchableOpacity>
					)}
				</View>
			</View>
		</View>
	)
}
export default BookingDetailsScreen;
