import React, {useEffect, useState} from 'react';
import {View, Text, TextInput, ActivityIndicator} from "react-native";
import {TouchableOpacity} from "react-native-web";
import API from "../../helpers/Axios";

const Modal = ({ setIsVisible, setCurrentRoom, item, setCurrentRoomRules }) => {
	const [additionRule, setAdditionRule] = useState('');
	const [roomRules, setRoomRules] = useState(item.rules);
	const [isLoading, setIsLoading] = useState(false);
	const originalRules = item.rules;


	const onSubmit = () => {
		if(additionRule) {
			let item = {
				name: additionRule
			}
			let rules = roomRules;
			rules.push(item);
			setRoomRules(prev => ([...rules]));
			setAdditionRule('');
		}
	}

	return (
		<View
			style={{
				flex: 1,
			}}
		>
			<View
				style={{
					flex: 1,
					justifyContent: 'center',
					alignItems: 'center',
					borderBottomWidth: 1,
					borderColor: '#E4E1E0',
					padding: 16
				}}
			>
				<Text
					style={{
						fontWeight: '500',
						fontSize: 20
					}}
				>Edit rules</Text>
				<TouchableOpacity
					onPress={() => setIsVisible(false)}
					style={{
						position: 'absolute',
						right: 16,
					}}
				>
					<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M18.707 5.293a1 1 0 0 1 0 1.414l-12 12a1 1 0 0 1-1.414-1.414l12-12a1 1 0 0 1 1.414 0Z"
							fill="#6D6E6F"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M5.293 5.293a1 1 0 0 1 1.414 0l12 12a1 1 0 0 1-1.414 1.414l-12-12a1 1 0 0 1 0-1.414Z"
							fill="#6D6E6F"
						/>
					</svg>
				</TouchableOpacity>
			</View>
			<View
				style={{
					padding: 32
				}}
			>
				<View
					style={{
						marginTop: 16,
						flexDirection: 'row',
						flexWrap: 'wrap'
					}}
				>
					{roomRules.map((item, index) => (
						<View
							key={index}
							style={{
								backgroundColor: '#EBE8E7',
								paddingHorizontal: 12,
								height: 32,
								borderRadius: 16,
								justifyContent: 'center',
								marginRight: 16,
								marginBottom: 16,
								flexDirection: 'row',
								alignItems: 'center',
							}}
						>
							<Text
								style={{
									fontWeight: '600',
									marginRight: 8
								}}
							>{item.name}</Text>
							<TouchableOpacity
								onPress={() => {
									roomRules.splice(index, 1);
									setRoomRules(prev => ([...roomRules]));
								}}
							>
								<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M9.887 6.113 6.113 9.886M9.887 9.886 6.113 6.113"
										stroke="#1E1F21"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										clipRule="evenodd"
										d="M8 14v0a6 6 0 0 1-6-6v0a6 6 0 0 1 6-6v0a6 6 0 0 1 6 6v0a6 6 0 0 1-6 6Z"
										stroke="#1E1F21"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</TouchableOpacity>
						</View>
					))}
				</View>
				<View
					style={{
						maxWidth: '70%',
					}}
				>
					<View
						style={{
							flexDirection: 'row',
							borderRadius: 8,
							borderWidth: 1,
							borderColor: '#D2D2D2',
							marginTop: 16,
							height: 64,
						}}
					>
						<View
							style={{
								flex: 1,
								flexDirection: 'row',
								justifyContent: 'space-between'
							}}
						>
							<TextInput
								placeholder={'Add additional rule'}
								style={{
									flex: 1,
									outline: 0,
									paddingHorizontal: 16,
									fontSize: 16
								}}
								value={additionRule}
								onChangeText={(text) => setAdditionRule(text)}
								onSubmitEditing={() => onSubmit()}
							/>
						</View>
						<TouchableOpacity
							onPress={() => onSubmit()}
							style={{
								backgroundColor: 'rgba(61,186,113,0.2)',
								justifyContent: 'center',
								alignItems: 'center',
								paddingHorizontal: 24
							}}
						>
							<Text
								style={{
									color: '#178545',
									fontWeight: '600'
								}}
							>Add</Text>
						</TouchableOpacity>
					</View>
					<View
						style={{
							backgroundColor: '#F5F4F3',
							padding: 8,
							marginTop: 16
						}}
					>
						<Text
							style={{
								color: '#6D6E6F'
							}}
						>Additional rules are pending of approval.</Text>
					</View>
				</View>
				<View
					style={{
						flexDirection: 'row',
						justifyContent: 'flex-end',
						marginTop: 32
					}}
				>
					<TouchableOpacity
						onPress={() => {
							setIsVisible(false);
							let newData = item;
							newData.rules = originalRules;
							setCurrentRoom(newData);
							setRoomRules(originalRules);
							setIsLoading(false);
						}}
						style={{
							width: 132,
							fontWeight: '600',
							fontSize: 16,
							paddingHorizontal: 32,
							borderWidth: 1,
							height: 48,
							borderRadius: 8,
							borderColor: '#E4E1E0',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<Text
							style={{
								fontWeight: '600',
								fontSize: 16
							}}
						>Cancel</Text>
					</TouchableOpacity>
					<TouchableOpacity
						onPress={() => {
							setIsLoading(true);
							const data = new FormData();
							data.append('id', item.id);
							for (let i = 0; i < roomRules.length; i++) {
								data.append('rules[]', JSON.stringify(roomRules[i]));
							}
							API.post('owner/save-room-rules', data).then(res => {
								setRoomRules(res.data.rules);
								setCurrentRoomRules(res.data.rules);
								setIsLoading(false);
								setIsVisible(false);
							}).catch (error => {
								console.log(error);
							});
						}}
						style={{
							width: 132,
							marginLeft: 16,
							fontWeight: '600',
							fontSize: 16,
							height: 48,
							borderRadius: 8,
							backgroundColor: '#19994F',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'row'
						}}
					>
						<Text
							style={{
								fontWeight: '600',
								fontSize: 16,
								color: 'white',
								marginRight: 8
							}}
						>Save</Text>
						{isLoading && <ActivityIndicator color={'white'}/>}
					</TouchableOpacity>
				</View>
			</View>
		</View>
	)
}
export default Modal;