import React, {useEffect, useState} from 'react';
import {View, Text, ScrollView, ActivityIndicator, Image, TextInput, StyleSheet} from "react-native";
import {Switch, TouchableOpacity} from "react-native-web";
import moment from "moment";
import StyleDict from "../../AppStyles";
import {useTheme} from "@react-navigation/native";
import API from "../../helpers/Axios";
import AppStyles from "../../AppStyles";
import SendMessageContainer from "../../components/SendMessageContainer";
import BookingStatus from "../../components/BookingStatus";

function Modal ({ setIsVisible, item }) {
	const [reviews, setReviews] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isActive, setIsActive] = useState(null);

	const [currentStudio, setCurrentStudio] = useState(null);
	const [stats, setStats] = useState(null);

	const [bookings, setBookings] = useState([]);
	const [currentRoom, setCurrentRoom] = useState({id: 0});
	const [allRoomBookings, setAllRoomBookings] = useState([]);
	const [isEarningDataLoading, setIsEarningDataLoading] = useState(true);
	const [isBookingLoading, setIsBookingLoading] = useState(false);
	const [booking, setBooking] = useState(null);

	const getBookingsRoom = (roomId) => {
		setIsBookingLoading(true);
		API.get(`owner/bookings/room/${roomId}`)
			.then(res => {
				setBookings(res.data.data)
				setIsBookingLoading(false);
			}).catch (error => {
			console.log(error);
		});
	}

	const getEarningByStudio = (studioId) => {
		setIsEarningDataLoading(true);
		API.get(`admin/earnings/studio/${studioId}`)
			.then(res => {
				setCurrentStudio(res.data.studio);
				setStats(res.data.stats);
				setBookings(res.data.bookings);
				setAllRoomBookings(res.data.bookings);
				setIsEarningDataLoading(false);
				setIsLoading(false);
			}).catch (error => {
			console.log(error);
		});
	}

	useEffect(() => {
		getEarningByStudio(item.id);
		API.get(`admin/bookings/studio/${item.id}`)
			.then(res => {
				setBookings(res.data.data);
				setIsBookingLoading(false);
				setCurrentRoom({id: 0});
			}).catch (error => {
			console.log(error);
		});
	}, []);

	return isLoading ? (<ActivityIndicator style={{flex: 1, justifyContent: 'center', alignItems: 'center', marginVertical: 64}} />) : (
		<View
			style={{
				flex: 1,
			}}
		>
			<View
				style={{
					flex: 1,
					justifyContent: 'center',
					alignItems: 'center',
					borderBottomWidth: 1,
					borderColor: '#E4E1E0',
					padding: 16
				}}
			>
				<Text
					style={{
						fontWeight: '500',
						fontSize: 20
					}}
				>Studios</Text>
				<TouchableOpacity
					onPress={() => setIsVisible(false)}
					style={{
						position: 'absolute',
						right: 16,
					}}
				>
					<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M18.707 5.293a1 1 0 0 1 0 1.414l-12 12a1 1 0 0 1-1.414-1.414l12-12a1 1 0 0 1 1.414 0Z"
							fill="#6D6E6F"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M5.293 5.293a1 1 0 0 1 1.414 0l12 12a1 1 0 0 1-1.414 1.414l-12-12a1 1 0 0 1 0-1.414Z"
							fill="#6D6E6F"
						/>
					</svg>
				</TouchableOpacity>
			</View>
			<ScrollView
				style={{
					padding: 32,
					maxHeight: 'calc(100vh - 300px)',
				}}
			>
				<View
					style={{
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between'
					}}
				>
					<Text
						style={{
							fontWeight: '600',
							fontSize: 20,
							color: '#096730'
						}}
					>{item.name}</Text>
					<View
						style={{
							flexDirection: 'row'
						}}
					>
						<Text><strong>Location</strong> <Text style={{color: '#595959'}}>{item.address}</Text></Text>
					</View>
				</View>
				<View
					style={{
						marginTop: 16,
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between'
					}}
				>
					<View>
						<Text
							style={{
								fontSize: 16
							}}
						><Text style={{fontWeight: '600'}}>{reviews.average} stars</Text> ({reviews.total} reviews)</Text>
					</View>
					<View>
						{item.approved > 0 && (
							<View
								style={{
									flexDirection: 'row',
									alignItems: 'center'
								}}
							>
								<Text
									style={{
										fontWeight: '600',
										marginRight: 8
									}}
								>Status: Active</Text>
								<Switch
									onValueChange={() => {
										API.post('admin/toggle-studio', {
											id: item.id
										}).then(res => {
											setIsActive(!isActive)
										}).catch (error => {
											console.log(error);
										});
									}}
									value={isActive}
								/>
							</View>
						)}
					</View>
				</View>
				<View
					style={{
						marginTop: 40,
						flexDirection: 'row',
						justifyContent: 'space-between',
						marginHorizontal: -12
					}}
				>
					<View
						style={{
							paddingHorizontal: 12,
							flex: 1
						}}
					>
						<View
							style={{
								padding: 16,
								borderRadius: 8,
								backgroundColor: 'white',
								borderWidth: 1,
								borderColor: '#EBE8E7'
							}}
						>
							<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M6 15h1M17 7h1M10.232 12.768a2.5 2.5 0 1 1 3.536-3.536"
									stroke="#6D6E6F"
									strokeWidth={1.5}
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M8 18H6a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v4"
									stroke="#6D6E6F"
									strokeWidth={1.5}
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									clipRule="evenodd"
									d="M12.5 17h2a.5.5 0 0 1 .5.5V21h-2.5a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5ZM15.5 13h2a.5.5 0 0 1 .5.5V21h-3v-7.5a.5.5 0 0 1 .5-.5ZM18.5 15h2a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5H18v-5.5a.5.5 0 0 1 .5-.5Z"
									stroke="#6D6E6F"
									strokeWidth={1.5}
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
							<Text
								style={{
									fontWeight: '600',
									color: '#096730',
									fontSize: 34,
									marginVertical: 16
								}}
							>${stats.bookings.revenue}</Text>
							<Text
								style={{
									fontSize: 20,
									fontWeight: '600'
								}}
							>Gross Earnings</Text>
						</View>
					</View>
					<View
						style={{
							paddingHorizontal: 12,
							flex: 1
						}}
					>
						<View
							style={{
								padding: 16,
								borderRadius: 8,
								backgroundColor: 'white',
								borderWidth: 1,
								borderColor: '#EBE8E7'
							}}
						>
							<svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M12 15.9H3.9C2.85 15.9 2 15.05 2 14V4.9C2 3.85 2.85 3 3.9 3H19.1C20.15 3 21 3.85 21 4.9V10" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M13.179 7.9709C14.106 8.8979 14.106 10.4019 13.179 11.3299C12.252 12.2569 10.748 12.2569 9.82001 11.3299C8.89301 10.4029 8.89301 8.8989 9.82001 7.9709C10.748 7.0429 12.252 7.0429 13.179 7.9709" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M22 16.8001V14.0001C22 13.2261 20.432 12.6001 18.5 12.6001C16.568 12.6001 15.001 13.2271 15 14.0001V19.6001C15.001 20.3731 16.567 21.0001 18.5 21.0001C20.433 21.0001 21.999 20.3731 22 19.6001V14.0001" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M15.001 14C15.001 14.773 16.568 15.4 18.501 15.4C20.434 15.4 22 14.773 22 14" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M15.001 16.8C15.001 17.573 16.568 18.2 18.501 18.2C20.434 18.2 22.001 17.573 22.001 16.8" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M6 13H5" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M18 6H17" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
							</svg>
							<Text
								style={{
									fontWeight: '600',
									color: '#096730',
									fontSize: 34,
									marginVertical: 16
								}}
							>${stats.bookings.balance}</Text>
							<Text
								style={{
									fontSize: 20,
									fontWeight: '600'
								}}
							>Net Earnings</Text>
						</View>
					</View>
					<View
						style={{
							paddingHorizontal: 12,
							flex: 1
						}}
					>
						<TouchableOpacity
							style={{
								paddingHorizontal: 24,
								paddingVertical: 8,
								flexDirection: 'row',
								borderRadius: 8,
								backgroundColor: 'white',
								borderWidth: 1,
								borderColor: '#EBE8E7'
							}}
						>
							<svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd" d="M2 3.33333C1.63181 3.33333 1.33333 3.63181 1.33333 4V12C1.33333 12.3682 1.63181 12.6667 2 12.6667H14C14.3682 12.6667 14.6667 12.3682 14.6667 12V4C14.6667 3.63181 14.3682 3.33333 14 3.33333H2ZM0 4C0 2.89543 0.895431 2 2 2H14C15.1046 2 16 2.89543 16 4V12C16 13.1046 15.1046 14 14 14H2C0.895431 14 0 13.1046 0 12V4Z" fill="#8692A6" />
								<path fillRule="evenodd" clipRule="evenodd" d="M0 6.66667C0 6.29848 0.298477 6 0.666667 6H15.3333C15.7015 6 16 6.29848 16 6.66667C16 7.03486 15.7015 7.33333 15.3333 7.33333H0.666667C0.298477 7.33333 0 7.03486 0 6.66667Z" fill="#8692A6" />
							</svg>
							<Text
								style={{
									marginLeft: 8,
									fontWeight: '600'
								}}
							>Account Payment</Text>
						</TouchableOpacity>
					</View>
				</View>

				<View
					style={{
						marginTop: 32
					}}
				>
					<View
						style={{
							flexDirection: 'row'
						}}
					>
						<TouchableOpacity
							onPress={() => {
								setIsBookingLoading(true);
								setCurrentRoom({id: 0});
								API.get(`owner/bookings/studio/${currentStudio.id}`)
									.then(res => {
										setBookings(res.data.data);
										setIsBookingLoading(false);
									}).catch (error => {
									console.log(error);
								});
							}}
							style={{
								borderBottomWidth: 2,
								borderColor: currentRoom.id === 0 ? '#096730' : 'white',
								alignSelf: 'flex-start',
								paddingHorizontal: 16
							}}
						>
							<Text
								style={{
									fontWeight: '600',
									fontSize: 16,
									color: '#096730',
									lineHeight: 40
								}}
							>All rooms</Text>
						</TouchableOpacity>
						{currentStudio.rooms.map((item, index) => (
							<TouchableOpacity
								key={index}
								onPress={() => {
									setCurrentRoom(item);
									getBookingsRoom(item.id);
								}}
								style={{
									borderBottomWidth: 2,
									borderColor: currentRoom.id === item.id ? '#096730' : 'white',
									alignSelf: 'flex-start',
									paddingHorizontal: 16
								}}
							>
								<Text
									style={{
										fontWeight: '600',
										fontSize: 16,
										color: currentRoom.id === item.id ? 'black' : '#096730',
										lineHeight: 40
									}}
								>{item.name}</Text>
							</TouchableOpacity>
						))}
					</View>
					{isBookingLoading && (
						<View
							style={{
								height: 180,
								justifyContent: 'center',
								alignItems: 'center'
							}}
						>
							<ActivityIndicator color={'#096730'} />
						</View>
					)}
					{!isBookingLoading && (
						<table
							style={{
								flex: 1,
								width: '100%',
								borderCollapse: 'collapse'
							}}
						>
							<tr
								style={{
									backgroundColor: '#F8F8F8',
									height: 48
								}}
							>
								<th
									style={{
										textAlign: 'left',
										paddingLeft: 16,
										paddingRight: 16
									}}
								>
									<Text
										style={styles.tableHeader}
									>Name</Text>
								</th>
								<th
									style={{
										textAlign: 'left',
										paddingLeft: 16,
										paddingRight: 16
									}}
								>
									<Text
										style={styles.tableHeader}
									>Room</Text>
								</th>
								<th
									style={{
										textAlign: 'left',
										paddingLeft: 16,
										paddingRight: 16
									}}
								>
									<Text
										style={styles.tableHeader}
									>Booking info</Text>
								</th>
								<th
									style={{
										textAlign: 'left',
										paddingLeft: 16,
										paddingRight: 16
									}}
								>
									<Text
										style={styles.tableHeader}
									>Amount</Text>
								</th>
							</tr>
							{bookings.map((item, index) => (
								(item.user && item.room) && (
									<tr
										key={index}
										style={{
											height: 64,
											cursor: 'pointer'
										}}
										onClick={() => {
											setBooking(item);
											setIsVisible(true);
										}}
									>
										<td
											style={{
												borderBottom: '1px solid',
												borderBottomColor: '#E4E1E0',
												paddingLeft: 16,
												paddingRight: 16
											}}
										>
											<Text>{item.user.name}</Text>
										</td>
										<td
											style={{
												borderBottom: '1px solid',
												borderBottomColor: '#E4E1E0',
												paddingLeft: 16,
												paddingRight: 16
											}}
										>
											<Text>{item.room.name}</Text>
										</td>
										<td
											style={{
												borderBottom: '1px solid',
												borderBottomColor: '#E4E1E0',
												paddingLeft: 16,
												paddingRight: 16
											}}
										>
											<Text>{moment.utc(item.start_time).local().format("MMM D, YYYY h:mm A")}</Text>
										</td>
										<td
											style={{
												borderBottom: '1px solid',
												borderBottomColor: '#E4E1E0',
												paddingLeft: 16,
												paddingRight: 16
											}}
										>
											<Text
												style={{
													fontWeight: '600'
												}}
											>${item.cost}</Text>
										</td>
									</tr>
								)
							))}
						</table>
					)}
				</View>
				<View
					style={{
						marginTop: 32,
					}}
				>
					<View
						style={{
							flexDirection: 'row'
						}}
					>
						<TouchableOpacity
							onPress={() => {
								setCurrentRoom({id: 0});
								setBookings(allRoomBookings);
							}}
							style={{
								borderBottomWidth: 2,
								borderColor: currentRoom.id === 0 ? '#096730' : 'white',
								alignSelf: 'flex-start',
								paddingHorizontal: 16
							}}
						>
							<Text
								style={{
									fontWeight: '600',
									fontSize: 16,
									color: '#096730',
									lineHeight: 40
								}}
							>All rooms</Text>
						</TouchableOpacity>
						{currentStudio.rooms.map((item, index) => (
							<TouchableOpacity
								key={index}
								onPress={() => {
									setCurrentRoom(item);
								}}
								style={{
									borderBottomWidth: 2,
									borderColor: currentRoom.id === item.id ? '#096730' : 'white',
									alignSelf: 'flex-start',
									paddingHorizontal: 16
								}}
							>
								<Text
									style={{
										fontWeight: '600',
										fontSize: 16,
										color: currentRoom.id === item.id ? 'black' : '#096730',
										lineHeight: 40
									}}
								>{item.name}</Text>
							</TouchableOpacity>
						))}
					</View>
					<table
						style={{
							flex: 1,
							width: '100%',
							borderCollapse: 'collapse'
						}}
					>
						<tr
							style={{
								backgroundColor: '#F8F8F8',
								height: 48
							}}
						>
							<th
								style={{
									textAlign: 'left',
									paddingLeft: 16,
									paddingRight: 16
								}}
							>
								<Text
									style={styles.tableHeader}
								>Amenities</Text>
							</th>
							<th
								style={{
									textAlign: 'left',
									paddingLeft: 16,
									paddingRight: 16
								}}
							>
								<Text
									style={styles.tableHeader}
								>Last 30 days</Text>
							</th>
							<th
								style={{
									textAlign: 'left',
									paddingLeft: 16,
									paddingRight: 16
								}}
							>
								<Text
									style={styles.tableHeader}
								>Total</Text>
							</th>
						</tr>
						{bookings.map((bookingItem, bookingIndex) => (
							<>
								{bookingItem.booking_amenities.map((amenity, amenityIndex) => amenity.amenity && (
									<tr
										key={amenityIndex}
										style={{
											height: 64,
										}}
									>
										<td
											style={{
												borderBottom: '1px solid',
												borderBottomColor: '#E4E1E0',
												paddingLeft: 16,
												paddingRight: 16
											}}
										>
											<View>
												<Text>{amenity.amenity.name} ({bookingItem.room.name})</Text>
												<Text
													style={{
														color: '#909090',
														marginTop: 4,
														fontWeight: '600'
													}}
												>{bookingItem.user.name} | {bookingItem.user.email}</Text>
											</View>
										</td>
										<td
											style={{
												borderBottom: '1px solid',
												borderBottomColor: '#E4E1E0',
												paddingLeft: 16,
												paddingRight: 16
											}}
										>
											<Text>{moment(bookingItem.created_at).format("MMM D, YYYY")}</Text>
										</td>
										<td
											style={{
												borderBottom: '1px solid',
												borderBottomColor: '#E4E1E0',
												paddingLeft: 16,
												paddingRight: 16
											}}
										>
											<Text>$ {amenity.cost}</Text>
										</td>
									</tr>
								))}
							</>
						))}
						<tr
							style={{
								backgroundColor: '#F8F8F8',
								height: 48
							}}
						>
							<th
								style={{
									textAlign: 'left',
									paddingLeft: 16,
									paddingRight: 16
								}}
							>
								<Text
									style={styles.tableHeader}
								>Equipments</Text>
							</th>
							<th
								style={{
									textAlign: 'left',
									paddingLeft: 16,
									paddingRight: 16
								}}
							>
								<Text
									style={styles.tableHeader}
								>Last 30 days</Text>
							</th>
							<th
								style={{
									textAlign: 'left',
									paddingLeft: 16,
									paddingRight: 16
								}}
							>
								<Text
									style={styles.tableHeader}
								>Total</Text>
							</th>
						</tr>
						{bookings.map((bookingItem, bookingIndex) => (
							<>
								{bookingItem.booking_equipments.map((equipment, equipmentIndex) => equipment.equipment && (
									<tr
										key={equipmentIndex}
										style={{
											height: 64,
										}}
									>
										<td
											style={{
												borderBottom: '1px solid',
												borderBottomColor: '#E4E1E0',
												paddingLeft: 16,
												paddingRight: 16
											}}
										>
											<View>
												<Text>{equipment.equipment.name} ({bookingItem.room.name})</Text>
												<Text
													style={{
														color: '#909090',
														marginTop: 4,
														fontWeight: '600'
													}}
												>{bookingItem.user.name} | {bookingItem.user.email}</Text>
											</View>
										</td>
										<td
											style={{
												borderBottom: '1px solid',
												borderBottomColor: '#E4E1E0',
												paddingLeft: 16,
												paddingRight: 16
											}}
										>
											<Text>{moment(bookingItem.created_at).format("MMM D, YYYY")}</Text>
										</td>
										<td
											style={{
												borderBottom: '1px solid',
												borderBottomColor: '#E4E1E0',
												paddingLeft: 16,
												paddingRight: 16
											}}
										>
											<Text>$ {equipment.cost}</Text>
										</td>
									</tr>
								))}
							</>
						))}
					</table>
				</View>
				<SendMessageContainer item={item.user}/>
			</ScrollView>
		</View>
	)
}

const styles = StyleSheet.create({
	tableHeader: {
		fontWeight: '600',
		textAlign: 'left',
		alignSelf: 'flex-start'
	},
	tableCell: {
		borderBottom: '1px solid',
		borderBottomColor: 'gray',
		paddingLeft: 16,
		paddingRight: 16
	},
});

export default Modal;