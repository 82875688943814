import React, {useState} from 'react'
import {View, StyleSheet, Text, TextInput, Image} from 'react-native'
import { countriesArray } from '../helpers/CountryCode'

const FieldSetTextInputWithPhone = ({ value = '', placeholder = 'Placeholder', name, error = false, errorString = 'Error', onChangeText, onChangeCountryCode, ...props}) => {
  if(name === undefined) {
    name = placeholder;
  }

  const [selectorValue, setSelectorValue] = useState('us')
  const [code, setCode] = useState('1')

  const onChangeValue = (e) => {
    setSelectorValue(e.target.value.toLowerCase());
    let code = countriesArray.find(x => x.code === e.target.value).dial_code;
    setCode(code);
    onChangeCountryCode(e.target.value);
  }

  return (
    <View>
      <View
        style={{
          borderWidth: value.length > 0 ? 2 : 1,
          borderColor: error ? '#F09696' : (value.length > 0 ? '#3DBA71' : '#8692A6'),
          borderRadius: 8,
          backgroundColor: 'white',
          paddingLeft: 16,
          paddingRight: 16,
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        {value.length > 0 && (
          <View
            style={{
              position: 'absolute',
              left: 12,
              top: -9,
              height: 18,
              paddingLeft: 8,
              paddingRight: 8,
              backgroundColor: 'white'
            }}
          >
            <Text
              style={{
                lineHeight: 18,
                fontWeight: '500',
                color: error ? '#B96363' : (value.length > 0 ? '#3DBA71' : '#8692A6')
              }}
            >{name}</Text>
          </View>
        )}
        <View
          style={{
            marginRight: 16,
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <Image
            style={{
              width: 30,
              height: 22.5,
              borderRadius: 4
            }}
            source={{uri: `/assets/icons/flags/${selectorValue}.svg`}}
          />
          <Text
            style={{
              color: '#AFABAC',
              marginLeft: 16,
              fontWeight: 600,
            }}
          >+{code}</Text>
          <select
            onChange={onChangeValue}
            style={{
              width: 72,
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              opacity: 0
            }}
            name="country_code"
          >
            {countriesArray.map((item, index) => (
              <option
                key={index}
                value={item.code}
                code={item.dial_code}
              >{item.name}</option>
            ))}
          </select>
        </View>
        <TextInput
          style={{
            paddingVertical: 16,
            fontWeight: '400',
            fontSize: 16,
            color: 'black',
            outline: 'none',
          }}

          placeholder={placeholder}
          onChangeText={onChangeText}
          placeholderTextColor={'gray'}
          autoCapitalize="words"
          underlineColorAndroid="rgba(0,0,0,0)"
          value={value}
          {...props}
        />
      </View>
      {error && <Text
        style={{
          color: '#B96363',
          marginTop: 4,
          marginLeft: 16
        }}
      >{errorString}</Text>}
    </View>

  )
}

export default FieldSetTextInputWithPhone
