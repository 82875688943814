import React from "react";
import {
  Text,
  StatusBar,
  View, ImageBackground,
} from "react-native";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {SvgXml, WithLocalSvg} from "react-native-svg";
import API from "../helpers/Axios";
import {TouchableOpacity} from "react-native-web";

class WelcomeScreen extends React.Component {
  constructor(props) {
    super(props);

    StatusBar.setBarStyle('light-content', false);
  }

  componentDidMount() {
    if(this.props.auth.isLogged) {
      this.props.navigation.navigate('DashboardStack');
    }
  }

  render() {
    return (
      <ImageBackground
        source={{uri: '/assets/splash/1.jpeg'}}
        style={{
          position: 'absolute',
          flex: 1,
          width: '100%',
          height: '100vh',
          margin: 0,
          padding: 0,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#1D1F1E',
        }}
      >
        <View
          style={{
            position: 'absolute',
            backgroundColor: 'rgba(29, 31, 30, 0.4)',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0
          }}
        />
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 40
          }}
        >
          <svg width={114} height={114} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M94.112 1H19.888C9.456 1 1 9.456 1 19.888v74.224C1 104.544 9.456 113 19.888 113h74.224C104.544 113 113 104.544 113 94.112V19.888C113 9.456 104.544 1 94.112 1Z"
              fill="#212529"
            />
            <path
              d="M94.112 1.166H19.887c-10.34 0-18.722 8.382-18.722 18.721v74.225c0 10.34 8.382 18.722 18.722 18.722h74.225c10.34 0 18.722-8.382 18.722-18.722V19.888c0-10.34-8.382-18.722-18.722-18.722Z"
              stroke="#707070"
              strokeWidth={0.544}
            />
            <path
              d="M53.036 82.082c5.967-1.445 12.79-5.746 14.275-9.875-.338.746-.981 1.566-2.204 2.381-1.974 1.316-4.47 1.983-7.419 1.983a18.3 18.3 0 0 1-6.985-1.253 20.476 20.476 0 0 1-6.035-4.003 5.515 5.515 0 0 0-3.79-1.451 5.746 5.746 0 0 0-4.21 1.825 5.856 5.856 0 0 0-1.732 4.115 6.176 6.176 0 0 0 1.674 4.187c3.554 1.64 9.786 3.698 16.426 2.09ZM72.903 30.368c-2.457-.953-8.57-1.657-14.863 1.575-5.7 2.926-9.946 8.267-9.296 9.422a8.398 8.398 0 0 1 1.766-1.989c1.76-1.22 4.255-1.838 7.416-1.838 2.182.039 4.338.482 6.358 1.307 2.199.857 3.917 1.892 5.108 3.077l.09.084a4.876 4.876 0 0 0 3.298 1.19 5.707 5.707 0 0 0 4.185-1.786 5.772 5.772 0 0 0 1.7-4.076c0-1.863-.861-3.562-2.56-5.052-.194-.185-.398-.364-.602-.542-.67-.455-1.38-.848-2.122-1.173 0 0-.246-.109-.478-.199Z"
              fill="#2DC02E"
              stroke="#fff"
              strokeWidth={0.054}
            />
            <path
              d="M77.176 60.32a16.686 16.686 0 0 0-7.07-5.937c-2.796-1.298-6.412-2.437-10.725-3.377-3.937-.908-6.898-2.004-8.801-3.258-1.52-1-2.228-2.357-2.228-4.273a4.532 4.532 0 0 1 .744-2.582c1.338-2.968 3.352-6.024 9.028-8.95 6.265-3.231 12.353-2.53 14.8-1.576a13.112 13.112 0 0 1 2.589 1.372 22.814 22.814 0 0 0-7.315-4.178 29.742 29.742 0 0 0-10.188-1.745 27.04 27.04 0 0 0-10.879 2.119 18.236 18.236 0 0 0-7.851 6.275 16.432 16.432 0 0 0-2.89 9.59c0 5.208 1.758 9.37 5.226 12.369 3.258 2.817 7.895 4.855 13.754 6.05 4.402.96 7.675 2.147 9.73 3.525a5.419 5.419 0 0 1 2.567 4.727 4.108 4.108 0 0 1-.358 1.737c-1.486 4.129-8.307 8.43-14.275 9.874-6.64 1.607-12.871-.451-16.425-2.09.234.26.484.506.749.735a28.814 28.814 0 0 0 9.328 5.779c3.242 1.186 6.997 1.787 11.161 1.787 3.72.035 7.406-.717 10.815-2.207a19.49 19.49 0 0 0 8.017-6.362 16.025 16.025 0 0 0 3.031-9.576c.002-3.965-.851-7.27-2.534-9.827Z"
              fill="#fff"
            />
          </svg>
          <Text
            style={{
              marginLeft: 24,
              fontSize: 64,
              fontWeight: '600',
              marginBottom: 16,
              color: 'white'
            }}
          >Stugo</Text>
        </View>
        <View
          style={{
            padding: 48,
            backgroundColor: 'white',
            borderRadius: 8
          }}
        >
          <Text
            style={{
              fontSize: 20,
            }}
          >Already have an account? <Text onPress={() => this.props.navigation.navigate('SigninScreen')} style={{color: '#3DBA71',}}>Sign In</Text></Text>
          <Text
            style={{
              textAlign: 'center',
              marginVertical: 16
            }}
          >or</Text>
          <TouchableOpacity
            onPress={() =>  this.props.navigation.navigate('AccountTypeScreen')}
            style={{
              backgroundColor: '#3DBA71',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 4,
              height: 48
            }}
          >
            <Text
              style={{
                color: 'white',
                fontSize: 16,
                fontWeight: '600'
              }}
            >Create account</Text>
          </TouchableOpacity>
        </View>
      </ImageBackground>

    );
  }
}

WelcomeScreen.propTypes = {
  navigation: PropTypes.object,
};

export default connect(({auth}) => ({auth}))(WelcomeScreen);