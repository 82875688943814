import React, {useEffect, useMemo, useState} from "react";
import {
	Text,
	View,
	Modal, ScrollView, Switch, Image, ActivityIndicator, Button
} from "react-native";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import API from "../../helpers/Axios";
import {TouchableOpacity} from "react-native-web";
import '../../styles/modal.css';
import {toast} from "react-toastify";
import {useTheme} from "@react-navigation/native";
import FieldSetTextInput from "../../components/FieldSetTextInput";
import FieldSetTextInputWithPhone from "../../components/FieldSetTextInputWithPhone";
import {countriesArray} from "../../helpers/CountryCode";
import {store} from "../../store/configureStore";
import Responsive from "../../helpers/Reponsive";

function SettingsScreen ({navigation, route, auth, dispatch}) {
	const {colors, dark} = useTheme();
	const { isMobile } = Responsive();

	const [isLoading, setIsLoading] = useState(false);
	const [countryCode, setCountryCode] = useState(auth.user.phone_verification ? auth.user.phone_verification.country_code : 'US')

	const [firstName, setFirstName] = useState({
		value: auth.user.first_name || '',
		error: false,
		errorString: null
	});
	const [lastName, setLastName] = useState({
		value: auth.user.last_name || '',
		error: false,
		errorString: null
	});
	const [phoneNumber, setPhoneNumber] = useState({
		value: auth.user.phone_verification ? auth.user.phone_verification.phone_number : '',
		error: false,
		errorString: null
	});
	const [email, setEmail] = useState({
		value: auth.user.email || '',
		error: false,
		errorString: null
	});

	const [businessName, setBusinessName] = useState({
		value: auth.user.business_name || '',
		error: false,
		errorString: null
	});

	const [taxId, setTaxId] = useState({
		value: '',
		error: false,
		errorString: null
	});

	const [artworkFiles, setArtworkFiles] = useState(null);
	const [currentAvatar, setCurrentAvatar] = useState(auth.user.artwork_url);

	const artworksSelectedHandler = (e) => {
		setArtworkFiles(e.target.files[0]);
		setCurrentAvatar(URL.createObjectURL(e.target.files[0]));
	}

	const submitData =  () => {
		setIsLoading(true);
		const data = new FormData();
		data.append('first_name', firstName.value);
		data.append('last_name', lastName.value);
		data.append('email', email.value);
		data.append('business_name', businessName.value);
		data.append('tax_id', taxId.value);
		data.append('countryCode', countryCode);
		data.append('phoneNumber', phoneNumber.value);
		data.append('callingCode', countriesArray.find(x => x.code === countryCode).dial_code);

		artworkFiles && data.append('artwork', artworkFiles)

		API.post('owner/edit-profile', data).then(res => {
			store.dispatch({type: 'UPDATE_USER_INFO', user: res.data});
			toast.success('Saved!')
			setIsLoading(false);
			setFirstName({
				value: firstName.value,
				error: false,
				errorString: firstName.errorString
			});
			setLastName({
				value: lastName.value,
				error: false,
				errorString: lastName.errorString
			});
			setEmail({
				value: email.value,
				error: false,
				errorString: email.errorString
			});
			setPhoneNumber({
				value: phoneNumber.value,
				error: false,
				errorString: phoneNumber.errorString
			});
		}).catch (error => {
			setFirstName({
				value: firstName.value,
				error: error.response.data.errors['first_name'] !== undefined,
				errorString: error.response.data.errors['first_name'] !== undefined ? error.response.data.errors['first_name'][0] : 'Error'
			});
			setLastName({
				value: lastName.value,
				error: error.response.data.errors['last_name'] !== undefined,
				errorString: error.response.data.errors['last_name'] !== undefined ? error.response.data.errors['last_name'][0] : 'Error'
			});setIsLoading(true);
			const data = new FormData();
			data.append('first_name', firstName.value);
			data.append('last_name', lastName.value);
			data.append('email', email.value);
			data.append('business_name', businessName.value);
			data.append('tax_id', taxId.value);
			data.append('countryCode', countryCode);
			data.append('phoneNumber', phoneNumber.value);
			data.append('callingCode', countriesArray.find(x => x.code === countryCode).dial_code);

			artworkFiles && data.append('artwork', artworkFiles)

			API.post('owner/edit-profile', data).then(res => {
				store.dispatch({type: 'UPDATE_USER_INFO', user: res.data});
				toast.success('Saved!')
				setIsLoading(false);
				setFirstName({
					value: firstName.value,
					error: false,
					errorString: firstName.errorString
				});
				setLastName({
					value: lastName.value,
					error: false,
					errorString: lastName.errorString
				});
				setEmail({
					value: email.value,
					error: false,
					errorString: email.errorString
				});
				setPhoneNumber({
					value: phoneNumber.value,
					error: false,
					errorString: phoneNumber.errorString
				});
			}).catch (error => {
				setFirstName({
					value: firstName.value,
					error: error.response.data.errors['first_name'] !== undefined,
					errorString: error.response.data.errors['first_name'] !== undefined ? error.response.data.errors['first_name'][0] : 'Error'
				});
				setLastName({
					value: lastName.value,
					error: error.response.data.errors['last_name'] !== undefined,
					errorString: error.response.data.errors['last_name'] !== undefined ? error.response.data.errors['last_name'][0] : 'Error'
				});
				setEmail({
					value: email.value,
					error: error.response.data.errors['email'] !== undefined,
					errorString: error.response.data.errors['email'] !== undefined ? error.response.data.errors['email'][0] : 'Error'
				});
				setPhoneNumber({
					value: phoneNumber.value,
					error: error.response.data.errors['phoneNumber'] !== undefined,
					errorString: error.response.data.errors['phoneNumber'] !== undefined ? error.response.data.errors['phoneNumber'][0] : 'Error'
				});
				setIsLoading(false);
				console.log(error);
			});
			setEmail({
				value: email.value,
				error: error.response.data.errors['email'] !== undefined,
				errorString: error.response.data.errors['email'] !== undefined ? error.response.data.errors['email'][0] : 'Error'
			});
			setPhoneNumber({
				value: phoneNumber.value,
				error: error.response.data.errors['phoneNumber'] !== undefined,
				errorString: error.response.data.errors['phoneNumber'] !== undefined ? error.response.data.errors['phoneNumber'][0] : 'Error'
			});
			setIsLoading(false);
			console.log(error);
		});
	}

	useEffect(() => {

	}, []);

	return (
		<View
			style={{
				flex: 1
			}}
		>
			<ScrollView
				style={{
					flex: 1,
					padding: isMobile ? 24 : 40
				}}
			>
				<Text
					style={{
						fontSize: 48,
						fontWeight: '600'
					}}
				>Settings</Text>
				<Text
					style={{
						marginTop: 16,
						fontSize: 20,
						color: '#595959'
					}}
				>Edit, update & adjust any information about your profile.</Text>
				<View
					style={{
						marginTop: 40,
						backgroundColor: 'white',
						borderRadius: 6,
						padding: 24
					}}
				>
					<Text
						style={{
							fontWeight: '500',
							fontSize: 20,
							marginBottom: 40,
						}}
					>Edit Profile Information</Text>
					<View
						style={{
							maxWidth: 900,
							flexDirection: isMobile ? 'column' : 'row',
						}}
					>
						<View
							style={{
								width: isMobile ? '100%' : 220,
								marginBottom: isMobile ? 32 : 0,
							}}
						>
							<View
								style={{
									width: isMobile ? '100%' : 220,
									aspectRatio: 1,
									borderRadius: 6,
									overflow: 'hidden'
								}}
							>
								<img src={currentAvatar}/>
								<TouchableOpacity
									style={{
										left: 0,
										right: 0,
										bottom: 0,
										position: 'absolute',
										backgroundColor: 'rgba(0,0,0,.5)',
										paddingVertical: 16,
										textAlign: 'center'
									}}
								>
									<input
										style={{
											position: 'absolute',
											top: 0,
											left: 0,
											right: 0,
											bottom: 0,
											opacity: 0,
											cursor: 'pointer',
											zIndex: 1,
										}}
										type="file"
										onChange={artworksSelectedHandler}
										accept="image/*"
									/>
									<Text
										style={{
											color: '#3dba71'
										}}
									>Upload Profile Image</Text>
								</TouchableOpacity>
							</View>
						</View>
						<View
							style={{
								flex: 1,
								marginLeft: isMobile ? 0 : 24
							}}
						>
							<View
								style={{
									flexDirection: 'row'
								}}
							>
								<View
									style={{
										width: '50%'
									}}
								>
									<FieldSetTextInput
										placeholder={'First Name'}
										value={firstName.value}
										error={firstName.error}
										errorString={firstName.errorString}
										onChangeText={text => setFirstName({
											value: text,
											error: firstName.error,
											errorString: firstName.errorString
										})}
									/>
								</View>
								<View
									style={{
										marginLeft: 16,
										flex: 1
									}}
								>
									<FieldSetTextInput
										placeholder={'Last Name'}
										value={lastName.value}
										error={lastName.error}
										errorString={lastName.errorString}
										onChangeText={text => setLastName({
											value: text,
											error: lastName.error,
											errorString: lastName.errorString
										})}
									/>
								</View>
							</View>
							<View
								style={{
									marginTop: 24
								}}
							>
								<FieldSetTextInput
									placeholder={'Email'}
									value={email.value}
									error={email.error}
									errorString={email.errorString}
									onChangeText={text => setEmail({
										value: text,
										error: email.error,
										errorString: email.errorString
									})}
								/>
							</View>
							<View
								style={{
									marginTop: 24
								}}
							>
								<FieldSetTextInputWithPhone
									name={'Phone'}
									placeholder={'Phone number'}
									value={phoneNumber.value}
									error={phoneNumber.error}
									errorString={phoneNumber.errorString}
									onChangeText={text => {
										setPhoneNumber({
											value: text.replace(/[^0-9]/g, ''),
											error: false,
											errorString: phoneNumber.errorString
										});
									}}
									onChangeCountryCode={text => {
										setCountryCode(text);
									}}
								/>
							</View>
							<View
								style={{
									flexDirection: 'row',
									justifyContent: 'flex-end',
									marginTop: 32
								}}
							>
								<TouchableOpacity
									onPress={() => {
										submitData();
									}}
									style={{
										width: 132,
										marginLeft: 16,
										fontWeight: '600',
										fontSize: 16,
										height: 48,
										borderRadius: 8,
										backgroundColor: '#19994F',
										justifyContent: 'center',
										alignItems: 'center',
										flexDirection: 'row'
									}}
								>
									<Text
										style={{
											fontWeight: '600',
											fontSize: 16,
											color: 'white'
										}}
									>Save</Text>
									{isLoading && <ActivityIndicator color={'white'} style={{marginLeft: 8}}/>}
								</TouchableOpacity>
							</View>
						</View>
					</View>
				</View>
			</ScrollView>
		</View>
	);
}

SettingsScreen.propTypes = {
	navigation: PropTypes.object,
};

export default connect(({auth}) => ({auth}))(SettingsScreen);
