import React, {useEffect, useMemo, useState} from "react";
import {
  Text,
  View,
  Modal, ScrollView, ImageBackground, Image, ActivityIndicator, Button, TextInput, StyleSheet
} from "react-native";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import API from "../../helpers/Axios";
import {TouchableOpacity} from "react-native-web";
import moment from 'moment';
import useModal from '../../helpers/useModal';
import EditStudioScreen from '../../screens/modals/EditStudioScreen';
import EditPaymentScreen from '../../screens/modals/EditPaymentScreen';
import EditPicturesScreen from "../modals/EditPicturesScreen";
import {toast} from "react-toastify";
import {Popover} from "react-tiny-popover";
import {countriesArray} from "../../helpers/CountryCode";
import AppStyles from "../../AppStyles";
import StudioStatus from "../../components/StudioStatus";
import UserStatus from "../../components/UserStatus";
import UserDetailsScreen from "../modals/UserDetailsScreen";
import StudioRevenueScreen from "../modals/StudioRevenueScreen";

function RevenueScreen ({navigation, route}) {
  const [tabIndex, setTabIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isDisableLoading, setIsDisableLoading] = useState(false);
  const [studios, setStudios] = useState([]);
  const [studiosData, setStudiosData] = useState(null);
  const [isStudiosLoading, setIsStudiosLoading] = useState(true);

  const [users, setUsers] = useState([]);
  const [usersData, setUsersData] = useState(null);
  const [isUsersLoading, setUsersIsLoading] = useState(true);

  const [currentStudio, setCurrentStudio] = useState(null);
  const [menuDisplay, setMenuDisplay] = useState( false);

  const [isVisible, setIsVisible] = useState(false);
  const [isPaymentVisible, setIsPaymentVisible] = useState(false);
  const [isImageEditorVisible, setIsImageEditorVisible] = useState(false);
  const [isDisableItemVisible, setIsDisableItemVisible] = useState(false);
  const [isActive, setIsActive] = useState(null);
  const [isDeleteItemVisible, setIsDeleteItemVisible] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [revenueData, setRevenueData] = useState(null);
  const [studioRevenueData, setStudioRevenueData] = useState(null);
  const [currentQuarter, setCurrentQuarter] = useState(1);
  const [studioCurrentQuarter, setStudioCurrentQuarter] = useState(1);
  const [userDetailsVisible, setUserDetailsVisible] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    if(currentStudio) {
      setIsActive(currentStudio.active);
    }
  }, [currentStudio]);

  const getUsers = (url = `admin/users`) => {
    API.post(url)
      .then(res => {
        setUsers(res.data.data);
        setUsersData(res.data);
        setUsersIsLoading(false);
      }).catch (error => {
      console.log(error);
    });
  }

  const getStudios = (url = `admin/studios`) => {
    API.post(url)
      .then(res => {
        setStudiosData(res.data);
        setIsStudiosLoading(false);
      }).catch (error => {
      console.log(error);
    });
  }

  const getRevenue = (url = `admin/revenue`) => {
    API.post(url)
      .then(res => {
        setRevenueData(res.data.user);
        setStudioRevenueData(res.data.studio);
        setCurrentQuarter(getQuarterSpent(1, res.data.user.quarter));
        setStudioCurrentQuarter(getQuarterSpent(1, res.data.studio.quarter));
        setIsLoading(false);
      }).catch (error => {
      console.log(error);
    });
  }

  const getQuarterSpent = (quarter, data) => {
    let index = data.findIndex(item => item.quarter === quarter);
    return index >= 0 ? data[index] : {quarter: quarter, total: 0};
  }

  useEffect(() => {
    getRevenue();
    getUsers();
    getStudios();
  }, []);

  return isLoading ? (<ActivityIndicator style={{flex: 1, justifyContent: 'center', alignItems: 'center'}} />) : (
    <View
      style={{
        flex: 1
      }}
    >
      <Modal onRequestClose={() => setIsVisible(false)} transparent visible={isVisible}>
        <View
          style={{
            backgroundColor: 'white',
            borderColor: '#eee',
            borderRadius: 8,
            borderWidth: 1,
            margin: 'auto',
            minWidth: 780,
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.05,
            shadowRadius: 3.84,
            elevation: 5,
          }}
        >
          <StudioRevenueScreen
            setIsVisible={setIsVisible}
            item={currentStudio}
            setCurrentStudio={setCurrentStudio}
          />
        </View>
      </Modal>
      <Modal onRequestClose={() => setUserDetailsVisible(false)} transparent visible={userDetailsVisible}>
        <View
          style={{
            backgroundColor: 'white',
            borderColor: '#eee',
            borderRadius: 8,
            borderWidth: 1,
            margin: 'auto',
            minWidth: 780,
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.05,
            shadowRadius: 3.84,
            elevation: 5,
          }}
        >
          <UserDetailsScreen
            setIsVisible={setUserDetailsVisible}
            item={currentUser}
            setCurrentUser={setCurrentUser}
          />
        </View>
      </Modal>
      <ScrollView
        style={{
          flex: 1,
          padding: 40
        }}
      >
        <Text
          style={{
            fontSize: 48,
            fontWeight: '600'
          }}
        >Revenue overview</Text>
        <Text
          style={{
            marginTop: 16,
            fontSize: 20,
            color: '#595959'
          }}
        >Overview of Studios payments.</Text>
        <View
          style={{
            marginVertical: 40,
            flexDirection: 'row'
          }}
        >
          <TouchableOpacity
            onPress={() => {
              setTabIndex(0)
            }}
            style={{
              width: 364,
              flexDirection: 'row',
              borderWidth: 2,
              borderColor: tabIndex === 0 ? '#6EB28B' : 'white',
              paddingHorizontal: 16,
              borderRadius: 8,
              backgroundColor: 'white',
              marginRight: 24,
              overflow: 'hidden',
              alignItems: 'center',
              height: 100,
              justifyContent: 'space-between',
              boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.08)',
            }}
          >
            <svg width={55} height={36} fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M47.746 12.222a4.72 4.72 0 1 1-6.676 6.675 4.72 4.72 0 0 1 6.676-6.675M32.37 3.796a7.268 7.268 0 1 1-10.28 10.279 7.268 7.268 0 0 1 10.28-10.28M13.39 12.222a4.72 4.72 0 1 1-6.676 6.675 4.72 4.72 0 0 1 6.676-6.675M52.896 34.333v-2.557a5.832 5.832 0 0 0-5.833-5.833h-1.87M1.563 34.333v-2.557a5.832 5.832 0 0 1 5.833-5.833h1.87M39.687 34.333v-3.735a8.167 8.167 0 0 0-8.166-8.167h-8.585a8.167 8.167 0 0 0-8.166 8.167v3.735"
                stroke={tabIndex === 0 ? '#096730' : '#595959'}
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <Text
              style={{
                fontWeight: '600',
                color: tabIndex === 0 ? '#096730' : '#595959',
                fontSize: 18
              }}
            >Revenue from users</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              setTabIndex(1)
            }}
            style={{
              width: 364,
              flexDirection: 'row',
              borderWidth: 2,
              borderColor: tabIndex === 1 ? '#6EB28B' : 'white',
              paddingHorizontal: 16,
              borderRadius: 8,
              backgroundColor: 'white',
              marginRight: 24,
              overflow: 'hidden',
              alignItems: 'center',
              height: 100,
              justifyContent: 'space-between',
              boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.08)',
            }}
          >
            <svg width={57} height={56} fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M32.77 18.663V9.326a2.334 2.334 0 0 1 3.153-2.185l11.672 4.377a2.334 2.334 0 0 1 1.515 2.187v35.304"
                stroke={tabIndex === 1 ? '#096730' : '#595959'}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                clipRule="evenodd"
                d="M21.565 18.663h13.072a2.801 2.801 0 0 1 2.801 2.8V49.01H18.764V21.464a2.801 2.801 0 0 1 2.8-2.801Z"
                stroke={tabIndex === 1 ? '#096730' : '#595959'}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M25.767 18.663v-4.669a2.334 2.334 0 0 0-2.335-2.334h-9.337a2.334 2.334 0 0 0-2.334 2.334v11.672M18.764 6.991v4.669M51.444 49.009H4.758M24.6 31.968h7.002M24.6 25.666h7.002M24.6 38.271h7.002"
                stroke={tabIndex === 1 ? '#096730' : '#595959'}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.092 49.009V28a2.334 2.334 0 0 1 2.335-2.334h9.337"
                stroke="#6D6E6F"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <Text
              style={{
                fontWeight: '600',
                color: tabIndex === 1 ? '#096730' : '#595959',
                fontSize: 18
              }}
            >Revenue from studios</Text>
          </TouchableOpacity>
        </View>
        {tabIndex === 0 && (
          <>
            <View
              style={{
                marginBottom: 40,
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginHorizontal: -12
              }}
            >
              <View
                style={{
                  paddingHorizontal: 12,
                  flex: 1
                }}
              >
                <View
                  style={{
                    padding: 16,
                    borderRadius: 8,
                    backgroundColor: 'white',
                    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.08)',
                  }}
                >
                  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6 15h1M17 7h1M10.232 12.768a2.5 2.5 0 1 1 3.536-3.536"
                      stroke="#6D6E6F"
                      strokeWidth={1.5}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8 18H6a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v4"
                      stroke="#6D6E6F"
                      strokeWidth={1.5}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      clipRule="evenodd"
                      d="M12.5 17h2a.5.5 0 0 1 .5.5V21h-2.5a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5ZM15.5 13h2a.5.5 0 0 1 .5.5V21h-3v-7.5a.5.5 0 0 1 .5-.5ZM18.5 15h2a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5H18v-5.5a.5.5 0 0 1 .5-.5Z"
                      stroke="#6D6E6F"
                      strokeWidth={1.5}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <Text
                    style={{
                      fontWeight: '600',
                      color: '#096730',
                      fontSize: 34,
                      marginVertical: 16
                    }}
                  >${AppStyles.utils.moneyFormat(revenueData.spent)}</Text>
                  <Text
                    style={{
                      fontSize: 20,
                      fontWeight: '600'
                    }}
                  >Users money spent</Text>
                  <Text
                    style={{
                      marginTop: 8,
                      fontSize: 12,
                      color: '#595959'
                    }}
                  >Users money amount spent in Stugo</Text>
                </View>
              </View>
              <View
                style={{
                  paddingHorizontal: 12,
                  flex: 1
                }}
              >
                <View
                  style={{
                    padding: 16,
                    borderRadius: 8,
                    backgroundColor: 'white',
                    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.08)',
                  }}
                >
                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 15.9H3.9C2.85 15.9 2 15.05 2 14V4.9C2 3.85 2.85 3 3.9 3H19.1C20.15 3 21 3.85 21 4.9V10" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M13.179 7.9709C14.106 8.8979 14.106 10.4019 13.179 11.3299C12.252 12.2569 10.748 12.2569 9.82001 11.3299C8.89301 10.4029 8.89301 8.8989 9.82001 7.9709C10.748 7.0429 12.252 7.0429 13.179 7.9709" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M22 16.8001V14.0001C22 13.2261 20.432 12.6001 18.5 12.6001C16.568 12.6001 15.001 13.2271 15 14.0001V19.6001C15.001 20.3731 16.567 21.0001 18.5 21.0001C20.433 21.0001 21.999 20.3731 22 19.6001V14.0001" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M15.001 14C15.001 14.773 16.568 15.4 18.501 15.4C20.434 15.4 22 14.773 22 14" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M15.001 16.8C15.001 17.573 16.568 18.2 18.501 18.2C20.434 18.2 22.001 17.573 22.001 16.8" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6 13H5" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M18 6H17" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <Text
                    style={{
                      fontWeight: '600',
                      color: '#096730',
                      fontSize: 34,
                      marginVertical: 16
                    }}
                  >${AppStyles.utils.moneyFormat(revenueData.canceled)}</Text>
                  <Text
                    style={{
                      fontSize: 20,
                      fontWeight: '600'
                    }}
                  >Canceled Payments</Text>
                  <Text
                    style={{
                      marginTop: 8,
                      fontSize: 12,
                      color: '#595959'
                    }}
                  >Total canceled to users</Text>
                </View>
              </View>
              <View
                style={{
                  paddingHorizontal: 12,
                  flex: 1
                }}
              >
                <View
                  style={{
                    padding: 16,
                    borderRadius: 8,
                    backgroundColor: 'white',
                    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.08)',
                  }}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.0021 6.99801H16.0017" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M5.99756 14.0009H6.99798" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M9.99925 17.0022H5.49738C4.11609 17.0022 2.99634 15.8825 2.99634 14.5012V6.49787C2.99634 5.83455 3.25984 5.1984 3.72888 4.72936C4.19791 4.26033 4.83406 3.99683 5.49738 3.99683H17.5024C18.8837 3.99683 20.0034 5.11658 20.0034 6.49787V9.99933" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M9.73132 12.2681C8.7546 11.2914 8.7546 9.70779 9.73132 8.73107C10.708 7.75436 12.2916 7.75436 13.2683 8.73107" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M17.5025 21.0039C15.6817 21.0039 14.0401 19.907 13.3433 18.2248C12.6465 16.5426 13.0317 14.6062 14.3192 13.3187C15.6067 12.0312 17.5431 11.646 19.2253 12.3428C20.9075 13.0396 22.0044 14.6812 22.0044 16.502C22.0044 18.9883 19.9888 21.0039 17.5025 21.0039" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M17.3953 14.9846V16.7389L18.778 17.5824" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <View
                      style={{
                        flexDirection: 'row',
                      }}
                    >
                      {[
                        {
                          title: 'Q1',
                          quarter: 1,
                          action: () => {
                            setCurrentQuarter(getQuarterSpent(1, revenueData.quarter));
                          }
                        },
                        {
                          title: 'Q2',
                          quarter: 2,
                          action: () => {
                            setCurrentQuarter(getQuarterSpent(2, revenueData.quarter));
                          }
                        },
                        {
                          title: 'Q3',
                          quarter: 3,
                          action: () => {
                            setCurrentQuarter(getQuarterSpent(3, revenueData.quarter));
                          }
                        },
                        {
                          title: 'Q4',
                          quarter: 4,
                          action: () => {
                            setCurrentQuarter(getQuarterSpent(4, revenueData.quarter));
                          }
                        },
                      ].map((item, index) => (
                        <TouchableOpacity
                          key={index}
                          onPress={item.action}
                          style={{
                            backgroundColor: currentQuarter.quarter === item.quarter ? '#AFABAC' : '#F4F2F1',
                            marginLeft: 8,
                            padding: 4,
                            borderRadius: 2
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 12,
                              fontWeight: '600',
                              color: currentQuarter.quarter === item.quarter ? 'black' : '#909090'
                            }}
                          >{item.title}</Text>
                        </TouchableOpacity>
                      ))}
                    </View>
                  </View>
                  <Text
                    style={{
                      fontWeight: '600',
                      color: '#096730',
                      fontSize: 34,
                      marginVertical: 16
                    }}
                  >${AppStyles.utils.moneyFormat(currentQuarter.total)}</Text>
                  <Text
                    style={{
                      fontSize: 20,
                      fontWeight: '600'
                    }}
                  >Q{currentQuarter.quarter} Quarterly users spent</Text>
                  <Text
                    style={{
                      marginTop: 8,
                      fontSize: 12,
                      color: '#595959'
                    }}
                  >Revenue in <strong>Quarter {currentQuarter.quarter} {moment().year()}</strong></Text>
                </View>
              </View>
            </View>
            <View
              style={{
                marginBottom: 32,
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  backgroundColor: 'white',
                  flex: 1,
                  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.08)',
                  paddingVertical: 16,
                  paddingHorizontal: 16,
                  borderRadius: 8,
                  marginRight: 16
                }}
              >
                <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.837 4.999a7 7 0 1 0 0 14 7 7 0 0 0 0-14Zm-9 7a9 9 0 1 1 18 0 9 9 0 0 1-18 0Z"
                    fill="#8692A6"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.78 16.942a1 1 0 0 1 1.414 0l4.35 4.35a1 1 0 0 1-1.414 1.414l-4.35-4.35a1 1 0 0 1 0-1.414Z"
                    fill="#8692A6"
                  />
                </svg>
                <TextInput
                  placeholder={'Search for users'}
                  style={{
                    flex: 1,
                    outline: 0,
                    paddingHorizontal: 16,
                    fontSize: 16
                  }}
                  value={''}
                  //onChangeText={(text) => setAdditionRule(text)}
                  //onSubmitEditing={() => onSubmit()}
                />
              </View>
              <Popover
                isOpen={menuDisplay}
                positions={['bottom', 'right']}
                align={'end'}
                containerStyle={{
                  zIndex: 5,
                }}
                padding={10}
                reposition={false}
                onClickOutside={() => setMenuDisplay(false)}
                content={({ position, nudgedLeft, nudgedTop }) => (
                  <View
                    style={{
                      borderRadius: 8,
                      width: 240,
                      backgroundColor: 'white',
                      shadowColor: "#000",
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.25,
                      shadowRadius: 3.84,
                      elevation: 5,
                      paddingHorizontal: 16
                    }}
                  >

                  </View>
                )}
              >
                <TouchableOpacity
                  onPress={() => {
                    setMenuDisplay(!menuDisplay)
                  }}
                  style={{
                    backgroundColor: 'white',
                    padding: 16,
                    borderRadius: 8,
                    shadowColor: "#000",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.05,
                    shadowRadius: 3.84,
                    elevation: 5,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: 240
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontWeight: '600',
                        color: '#096730'
                      }}
                    >Sort by:</Text>
                    <Text
                      style={{
                        fontWeight: '600',
                        marginTop: 4
                      }}
                    >Date</Text>
                  </View>
                  <svg
                    width={16} height={16}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 492 492"
                    style={{
                      enableBackground: "new 0 0 492 492",
                    }}
                    xmlSpace="preserve"
                  >
                    <path d="M246.1 293.4 62 109.3c-5.1-5.1-11.8-7.9-19-7.9-7.2 0-14 2.8-19 7.9L7.9 125.5c-5.1 5.1-7.9 11.8-7.9 19 0 7.2 2.8 14 7.9 19L227 382.7c5.1 5.1 11.9 7.9 19.1 7.8 7.2 0 14-2.8 19.1-7.8l218.9-218.9c5.1-5.1 7.9-11.8 7.9-19 0-7.2-2.8-14-7.9-19L468 109.5c-10.5-10.5-27.6-10.5-38.1 0L246.1 293.4z" />
                  </svg>
                </TouchableOpacity>
              </Popover>
            </View>
            <View
              style={{
                backgroundColor: 'white',
                shadowColor: "#000",
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.05,
                shadowRadius: 3.84,
                elevation: 5,
                borderRadius: 8
              }}
            >
              <View
                style={{
                }}
              >
                <View
                  style={{
                    borderBottomWidth: 2,
                    borderColor: '#096730',
                    alignSelf: 'flex-start',
                    paddingHorizontal: 16
                  }}
                >
                  <Text
                    style={{
                      fontWeight: '600',
                      fontSize: 16,
                      color: '#096730',
                      lineHeight: 40
                    }}
                  >All Users</Text>
                </View>
              </View>
              <table
                style={{
                  flex: 1,
                  width: '100%',
                  borderCollapse: 'collapse'
                }}
              >
                <tr
                  style={{
                    backgroundColor: '#F8F8F8',
                    height: 48
                  }}
                >
                  <th></th>
                  <th
                    style={{
                      textAlign: 'left',
                      paddingLeft: 16,
                      paddingRight: 16
                    }}
                  >
                    <Text
                      style={styles.tableHeader}
                    >Name</Text>
                  </th>
                  <th
                    style={{
                      textAlign: 'left',
                      paddingLeft: 16,
                      paddingRight: 16
                    }}
                  >
                    <Text
                      style={styles.tableHeader}
                    >Contact Number</Text>
                  </th>
                  <th
                    style={{
                      textAlign: 'left',
                      paddingLeft: 16,
                      paddingRight: 16
                    }}
                  >
                    <Text
                      style={styles.tableHeader}
                    >Date Added</Text>
                  </th>
                  <th
                    style={{
                      textAlign: 'left',
                      paddingLeft: 16,
                      paddingRight: 16
                    }}
                  >
                    <Text
                      style={styles.tableHeader}
                    >Status</Text>
                  </th>
                  <th
                    style={{
                      textAlign: 'left',
                      paddingLeft: 16,
                      paddingRight: 16
                    }}
                  >
                  </th>
                  <th
                    style={{
                      textAlign: 'left',
                      paddingLeft: 16,
                      paddingRight: 16
                    }}
                  >
                  </th>
                </tr>
                {usersData && usersData.data.map((item, index) => (
                  <tr
                    key={index}
                    style={{
                      height: 64,
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      setCurrentUser(item);
                      setUserDetailsVisible(true);
                    }}
                  >
                    <td
                      style={{
                        borderBottom: '1px solid',
                        borderBottomColor: '#E4E1E0',
                        paddingLeft: 16,
                        paddingRight: 16
                      }}
                    >
                      <Image
                        style={{
                          borderRadius: 16,
                          marginRight: 16,
                          width: 32,
                          height: 32,
                        }}
                        source={{
                          uri: item.artwork_url
                        }}
                      />
                    </td>
                    <td
                      style={{
                        borderBottom: '1px solid',
                        borderBottomColor: '#E4E1E0',
                        paddingLeft: 16,
                        paddingRight: 16
                      }}
                    >
                      <View>
                        <Text>{item.name}</Text>
                        <Text
                          style={{
                            color: '#909090',
                            marginTop: 4,
                            fontWeight: '600'
                          }}
                        >{item.email}</Text>
                      </View>
                    </td>
                    <td
                      style={{
                        borderBottom: '1px solid',
                        borderBottomColor: '#E4E1E0',
                        paddingLeft: 16,
                        paddingRight: 16
                      }}
                    >
                      <Text>{AppStyles.utils.formatPhoneNumber(item.phone_number)}</Text>
                    </td>
                    <td
                      style={{
                        borderBottom: '1px solid',
                        borderBottomColor: '#E4E1E0',
                        paddingLeft: 16,
                        paddingRight: 16
                      }}
                    >
                      <Text>{moment.utc(item.created_at).local().format("MMM D, YYYY")}</Text>
                    </td>
                    <td
                      style={{
                        borderBottom: '1px solid',
                        borderBottomColor: '#E4E1E0',
                        paddingLeft: 16,
                        paddingRight: 16
                      }}
                    >
                      <UserStatus status={item.banned}/>
                    </td>
                    <td
                      style={{
                        borderBottom: '1px solid',
                        borderBottomColor: '#E4E1E0',
                        paddingLeft: 16,
                        paddingRight: 16
                      }}
                    >
                      <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#a)" fill="#6D6E6F">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.333 1.333A.667.667 0 0 0 6.666 2v5.588l1.702-.851a.667.667 0 0 1 .298-.07H14A.667.667 0 0 0 14.666 6V2A.667.667 0 0 0 14 1.333H7.333ZM5.919.586A2 2 0 0 1 7.333 0H14a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H8.824L6.298 9.263a.667.667 0 0 1-.965-.596V2A2 2 0 0 1 5.919.586Z"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1.252 1.92a2 2 0 0 1 1.415-.587h1a.667.667 0 1 1 0 1.334h-1A.667.667 0 0 0 2 3.333V14a.667.667 0 0 0 .667.667H10a.667.667 0 0 0 .666-.667v-4A.667.667 0 0 1 12 10v4a2 2 0 0 1-2 2H2.667a2 2 0 0 1-2-2V3.333a2 2 0 0 1 .585-1.414Z"
                          />
                          <circle cx={6.667} cy={12.667} r={0.667} />
                        </g>
                        <defs>
                          <clipPath id="a">
                            <path fill="#fff" d="M0 0h16v16H0z" />
                          </clipPath>
                        </defs>
                      </svg>
                    </td>
                    <td
                      style={{
                        borderBottom: '1px solid',
                        borderBottomColor: '#E4E1E0',
                        paddingLeft: 16,
                        paddingRight: 16
                      }}
                    >
                      <Text
                        style={{
                          fontWeight: '600',
                          color: '#595959'
                        }}
                      >View More</Text>
                    </td>
                  </tr>
                ))}
              </table>
              {usersData && (
                <View
                  style={{
                    backgroundColor: '#F8F8F8',
                    height: 48,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    paddingHorizontal: 24
                  }}
                >
                  <View
                    style={{
                      marginRight: 40,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: '600',
                        marginRight: 8
                      }}
                    >Rows per page: {usersData.per_page}</Text>
                    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 14 8 9h8l-4 5Z" fill="#6D6E6F" />
                    </svg>
                  </View>
                  <Text
                    style={{
                      fontWeight: '600'
                    }}
                  >{usersData.from}- {usersData.to} </Text>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: 80,
                      marginLeft: 40
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        if(!usersData.prev_page_url) {
                          return;
                        }
                        setUsersIsLoading(true);
                        getUsers(usersData.prev_page_url);
                      }}
                    >
                      <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M14.8 8.106c.628-.593-.367-1.533-.995-.89L9.196 11.52a.66.66 0 0 0 0 .94l4.609 4.352c.628.593 1.623-.346.995-.94l-4.085-3.857L14.8 8.106Z"
                          fill={usersData.prev_page_url ? '#6D6E6F' : '#ccc'}
                        />
                      </svg>
                    </TouchableOpacity>
                    {isUsersLoading && (
                      <ActivityIndicator color={'#096730'} />
                    )}
                    <TouchableOpacity
                      onPress={() => {
                        if(!usersData.next_page_url) {
                          return;
                        }
                        setUsersIsLoading(true);
                        getUsers(usersData.next_page_url);
                      }}
                    >
                      <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M9.2 8.106c-.628-.593.367-1.533.995-.89l4.609 4.303a.66.66 0 0 1 0 .94l-4.609 4.352c-.628.593-1.623-.346-.995-.94l4.085-3.857L9.2 8.106Z"
                          fill={usersData.next_page_url ? '#6D6E6F' : '#ccc'}
                        />
                      </svg>
                    </TouchableOpacity>
                  </View>
                </View>
              )}
            </View>
          </>
        )}
        {tabIndex === 1 && (
          <>
            <View
              style={{
                marginBottom: 40,
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginHorizontal: -12
              }}
            >
              <View
                style={{
                  paddingHorizontal: 12,
                  flex: 1
                }}
              >
                <View
                  style={{
                    padding: 16,
                    borderRadius: 8,
                    backgroundColor: 'white',
                    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.08)',
                  }}
                >
                  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6 15h1M17 7h1M10.232 12.768a2.5 2.5 0 1 1 3.536-3.536"
                      stroke="#6D6E6F"
                      strokeWidth={1.5}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8 18H6a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v4"
                      stroke="#6D6E6F"
                      strokeWidth={1.5}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      clipRule="evenodd"
                      d="M12.5 17h2a.5.5 0 0 1 .5.5V21h-2.5a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5ZM15.5 13h2a.5.5 0 0 1 .5.5V21h-3v-7.5a.5.5 0 0 1 .5-.5ZM18.5 15h2a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5H18v-5.5a.5.5 0 0 1 .5-.5Z"
                      stroke="#6D6E6F"
                      strokeWidth={1.5}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <Text
                    style={{
                      fontWeight: '600',
                      color: '#096730',
                      fontSize: 34,
                      marginVertical: 16
                    }}
                  >${AppStyles.utils.moneyFormat(studioRevenueData.released)}</Text>
                  <Text
                    style={{
                      fontSize: 20,
                      fontWeight: '600'
                    }}
                  >Released Payments</Text>
                  <Text
                    style={{
                      marginTop: 8,
                      fontSize: 12,
                      color: '#595959'
                    }}
                  >Lifetime revenue from your entire organization.</Text>
                </View>
              </View>
              <View
                style={{
                  paddingHorizontal: 12,
                  flex: 1
                }}
              >
                <View
                  style={{
                    padding: 16,
                    borderRadius: 8,
                    backgroundColor: 'white',
                    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.08)',
                  }}
                >
                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 15.9H3.9C2.85 15.9 2 15.05 2 14V4.9C2 3.85 2.85 3 3.9 3H19.1C20.15 3 21 3.85 21 4.9V10" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M13.179 7.9709C14.106 8.8979 14.106 10.4019 13.179 11.3299C12.252 12.2569 10.748 12.2569 9.82001 11.3299C8.89301 10.4029 8.89301 8.8989 9.82001 7.9709C10.748 7.0429 12.252 7.0429 13.179 7.9709" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M22 16.8001V14.0001C22 13.2261 20.432 12.6001 18.5 12.6001C16.568 12.6001 15.001 13.2271 15 14.0001V19.6001C15.001 20.3731 16.567 21.0001 18.5 21.0001C20.433 21.0001 21.999 20.3731 22 19.6001V14.0001" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M15.001 14C15.001 14.773 16.568 15.4 18.501 15.4C20.434 15.4 22 14.773 22 14" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M15.001 16.8C15.001 17.573 16.568 18.2 18.501 18.2C20.434 18.2 22.001 17.573 22.001 16.8" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6 13H5" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M18 6H17" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <Text
                    style={{
                      fontWeight: '600',
                      color: '#096730',
                      fontSize: 34,
                      marginVertical: 16
                    }}
                  >${AppStyles.utils.moneyFormat(studioRevenueData.refunded)}</Text>
                  <Text
                    style={{
                      fontSize: 20,
                      fontWeight: '600'
                    }}
                  >Refund Payments</Text>
                  <Text
                    style={{
                      marginTop: 8,
                      fontSize: 12,
                      color: '#595959'
                    }}
                  >Lifetime revenue share generated from all sources</Text>
                </View>
              </View>
              <View
                style={{
                  paddingHorizontal: 12,
                  flex: 1
                }}
              >
                <View
                  style={{
                    padding: 16,
                    borderRadius: 8,
                    backgroundColor: 'white',
                    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.08)',
                  }}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.0021 6.99801H16.0017" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M5.99756 14.0009H6.99798" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M9.99925 17.0022H5.49738C4.11609 17.0022 2.99634 15.8825 2.99634 14.5012V6.49787C2.99634 5.83455 3.25984 5.1984 3.72888 4.72936C4.19791 4.26033 4.83406 3.99683 5.49738 3.99683H17.5024C18.8837 3.99683 20.0034 5.11658 20.0034 6.49787V9.99933" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M9.73132 12.2681C8.7546 11.2914 8.7546 9.70779 9.73132 8.73107C10.708 7.75436 12.2916 7.75436 13.2683 8.73107" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M17.5025 21.0039C15.6817 21.0039 14.0401 19.907 13.3433 18.2248C12.6465 16.5426 13.0317 14.6062 14.3192 13.3187C15.6067 12.0312 17.5431 11.646 19.2253 12.3428C20.9075 13.0396 22.0044 14.6812 22.0044 16.502C22.0044 18.9883 19.9888 21.0039 17.5025 21.0039" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M17.3953 14.9846V16.7389L18.778 17.5824" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <View
                      style={{
                        flexDirection: 'row',
                      }}
                    >
                      {[
                        {
                          title: 'Q1',
                          quarter: 1,
                          action: () => {
                            setStudioCurrentQuarter(getQuarterSpent(1, studioRevenueData.quarter));
                          }
                        },
                        {
                          title: 'Q2',
                          quarter: 2,
                          action: () => {
                            setStudioCurrentQuarter(getQuarterSpent(2, studioRevenueData.quarter));
                          }
                        },
                        {
                          title: 'Q3',
                          quarter: 3,
                          action: () => {
                            setStudioCurrentQuarter(getQuarterSpent(3, studioRevenueData.quarter));
                          }
                        },
                        {
                          title: 'Q4',
                          quarter: 4,
                          action: () => {
                            setStudioCurrentQuarter(getQuarterSpent(4, studioRevenueData.quarter));
                          }
                        },
                      ].map((item, index) => (
                        <TouchableOpacity
                          onPress={item.action}
                          style={{
                            backgroundColor: studioCurrentQuarter.quarter === item.quarter ? '#AFABAC' : '#F4F2F1',
                            marginLeft: 8,
                            padding: 4,
                            borderRadius: 2
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 12,
                              fontWeight: '600',
                              color: studioCurrentQuarter.quarter === item.quarter ? 'black' : '#909090'
                            }}
                          >{item.title}</Text>
                        </TouchableOpacity>
                      ))}
                    </View>
                  </View>
                  <Text
                    style={{
                      fontWeight: '600',
                      color: '#096730',
                      fontSize: 34,
                      marginVertical: 16
                    }}
                  >${AppStyles.utils.moneyFormat(studioCurrentQuarter.total)}</Text>
                  <Text
                    style={{
                      fontSize: 20,
                      fontWeight: '600'
                    }}
                  >Q{studioCurrentQuarter.quarter} Quarterly Earnings</Text>
                  <Text
                    style={{
                      marginTop: 8,
                      fontSize: 12,
                      color: '#595959'
                    }}
                  >Revenue generated in <strong>Quarter {studioCurrentQuarter.quarter} {moment().year()}</strong></Text>
                </View>
              </View>
            </View>
            <View
              style={{
                marginBottom: 32,
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  backgroundColor: 'white',
                  flex: 1,
                  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.08)',
                  paddingVertical: 16,
                  paddingHorizontal: 16,
                  borderRadius: 8,
                  marginRight: 16
                }}
              >
                <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.837 4.999a7 7 0 1 0 0 14 7 7 0 0 0 0-14Zm-9 7a9 9 0 1 1 18 0 9 9 0 0 1-18 0Z"
                    fill="#8692A6"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.78 16.942a1 1 0 0 1 1.414 0l4.35 4.35a1 1 0 0 1-1.414 1.414l-4.35-4.35a1 1 0 0 1 0-1.414Z"
                    fill="#8692A6"
                  />
                </svg>
                <TextInput
                  placeholder={'Search for users'}
                  style={{
                    flex: 1,
                    outline: 0,
                    paddingHorizontal: 16,
                    fontSize: 16
                  }}
                  value={''}
                  //onChangeText={(text) => setAdditionRule(text)}
                  //onSubmitEditing={() => onSubmit()}
                />
              </View>
              <Popover
                isOpen={menuDisplay}
                positions={['bottom', 'right']}
                align={'end'}
                containerStyle={{
                  zIndex: 5,
                }}
                padding={10}
                reposition={false}
                onClickOutside={() => setMenuDisplay(false)}
                content={({ position, nudgedLeft, nudgedTop }) => (
                  <View
                    style={{
                      borderRadius: 8,
                      width: 240,
                      backgroundColor: 'white',
                      shadowColor: "#000",
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.25,
                      shadowRadius: 3.84,
                      elevation: 5,
                      paddingHorizontal: 16
                    }}
                  >

                  </View>
                )}
              >
                <TouchableOpacity
                  onPress={() => {
                    setMenuDisplay(!menuDisplay)
                  }}
                  style={{
                    backgroundColor: 'white',
                    padding: 16,
                    borderRadius: 8,
                    shadowColor: "#000",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.05,
                    shadowRadius: 3.84,
                    elevation: 5,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: 240
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontWeight: '600',
                        color: '#096730'
                      }}
                    >Sort by:</Text>
                    <Text
                      style={{
                        fontWeight: '600',
                        marginTop: 4
                      }}
                    >Date</Text>
                  </View>
                  <svg
                    width={16} height={16}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 492 492"
                    style={{
                      enableBackground: "new 0 0 492 492",
                    }}
                    xmlSpace="preserve"
                  >
                    <path d="M246.1 293.4 62 109.3c-5.1-5.1-11.8-7.9-19-7.9-7.2 0-14 2.8-19 7.9L7.9 125.5c-5.1 5.1-7.9 11.8-7.9 19 0 7.2 2.8 14 7.9 19L227 382.7c5.1 5.1 11.9 7.9 19.1 7.8 7.2 0 14-2.8 19.1-7.8l218.9-218.9c5.1-5.1 7.9-11.8 7.9-19 0-7.2-2.8-14-7.9-19L468 109.5c-10.5-10.5-27.6-10.5-38.1 0L246.1 293.4z" />
                  </svg>
                </TouchableOpacity>
              </Popover>
            </View>
            <View
            >
              <View
                style={{
                  backgroundColor: 'white',
                  shadowColor: "#000",
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.05,
                  shadowRadius: 3.84,
                  elevation: 5,
                  borderRadius: 8
                }}
              >
                <View
                  style={{
                  }}
                >
                  <View
                    style={{
                      borderBottomWidth: 2,
                      borderColor: '#096730',
                      alignSelf: 'flex-start',
                      paddingHorizontal: 16
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: '600',
                        fontSize: 16,
                        color: '#096730',
                        lineHeight: 40
                      }}
                    >All Studios</Text>
                  </View>
                </View>
                <table
                  style={{
                    flex: 1,
                    width: '100%',
                    borderCollapse: 'collapse'
                  }}
                >
                  <tr
                    style={{
                      backgroundColor: '#F8F8F8',
                      height: 48
                    }}
                  >
                    <th></th>
                    <th
                      style={{
                        textAlign: 'left',
                        paddingLeft: 16,
                        paddingRight: 16
                      }}
                    >
                      <Text
                        style={styles.tableHeader}
                      >Name</Text>
                    </th>
                    <th
                      style={{
                        textAlign: 'left',
                        paddingLeft: 16,
                        paddingRight: 16
                      }}
                    >
                      <Text
                        style={styles.tableHeader}
                      >Contact Number</Text>
                    </th>
                    <th
                      style={{
                        textAlign: 'left',
                        paddingLeft: 16,
                        paddingRight: 16
                      }}
                    >
                      <Text
                        style={styles.tableHeader}
                      >Date Added</Text>
                    </th>
                    <th
                      style={{
                        textAlign: 'left',
                        paddingLeft: 16,
                        paddingRight: 16
                      }}
                    >
                      <Text
                        style={styles.tableHeader}
                      >Status</Text>
                    </th>
                    <th
                      style={{
                        textAlign: 'left',
                        paddingLeft: 16,
                        paddingRight: 16
                      }}
                    >
                    </th>
                    <th
                      style={{
                        textAlign: 'left',
                        paddingLeft: 16,
                        paddingRight: 16
                      }}
                    >
                    </th>
                  </tr>
                  {studiosData && studiosData.data.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        height: 64,
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        setCurrentStudio(item);
                        setIsVisible(true);
                      }}
                    >
                      <td
                        style={{
                          borderBottom: '1px solid',
                          borderBottomColor: '#E4E1E0',
                          paddingLeft: 16,
                          paddingRight: 16
                        }}
                      >
                        <Image
                          style={{
                            borderRadius: 16,
                            marginRight: 16,
                            width: 32,
                            height: 32,
                          }}
                          source={{
                            uri: item.artwork_url
                          }}
                        />
                      </td>
                      <td
                        style={{
                          borderBottom: '1px solid',
                          borderBottomColor: '#E4E1E0',
                          paddingLeft: 16,
                          paddingRight: 16
                        }}
                      >
                        <View>
                          <Text>{item.name}</Text>
                          <Text
                            style={{
                              color: '#909090',
                              marginTop: 4,
                              fontWeight: '600'
                            }}
                          >{item.user && item.user.email}</Text>
                        </View>
                      </td>
                      <td
                        style={{
                          borderBottom: '1px solid',
                          borderBottomColor: '#E4E1E0',
                          paddingLeft: 16,
                          paddingRight: 16
                        }}
                      >
                        <Text>{item.user && AppStyles.utils.formatPhoneNumber(item.user.phone_number)}</Text>
                      </td>
                      <td
                        style={{
                          borderBottom: '1px solid',
                          borderBottomColor: '#E4E1E0',
                          paddingLeft: 16,
                          paddingRight: 16
                        }}
                      >
                        <Text>{moment.utc(item.created_at).local().format("MMM D, YYYY")}</Text>
                      </td>
                      <td
                        style={{
                          borderBottom: '1px solid',
                          borderBottomColor: '#E4E1E0',
                          paddingLeft: 16,
                          paddingRight: 16
                        }}
                      >
                        <StudioStatus status={item.approved}/>
                      </td>
                      <td
                        style={{
                          borderBottom: '1px solid',
                          borderBottomColor: '#E4E1E0',
                          paddingLeft: 16,
                          paddingRight: 16
                        }}
                      >
                        <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clipPath="url(#a)" fill="#6D6E6F">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M7.333 1.333A.667.667 0 0 0 6.666 2v5.588l1.702-.851a.667.667 0 0 1 .298-.07H14A.667.667 0 0 0 14.666 6V2A.667.667 0 0 0 14 1.333H7.333ZM5.919.586A2 2 0 0 1 7.333 0H14a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H8.824L6.298 9.263a.667.667 0 0 1-.965-.596V2A2 2 0 0 1 5.919.586Z"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M1.252 1.92a2 2 0 0 1 1.415-.587h1a.667.667 0 1 1 0 1.334h-1A.667.667 0 0 0 2 3.333V14a.667.667 0 0 0 .667.667H10a.667.667 0 0 0 .666-.667v-4A.667.667 0 0 1 12 10v4a2 2 0 0 1-2 2H2.667a2 2 0 0 1-2-2V3.333a2 2 0 0 1 .585-1.414Z"
                            />
                            <circle cx={6.667} cy={12.667} r={0.667} />
                          </g>
                          <defs>
                            <clipPath id="a">
                              <path fill="#fff" d="M0 0h16v16H0z" />
                            </clipPath>
                          </defs>
                        </svg>
                      </td>
                      <td
                        style={{
                          borderBottom: '1px solid',
                          borderBottomColor: '#E4E1E0',
                          paddingLeft: 16,
                          paddingRight: 16
                        }}
                      >
                        <Text
                          style={{
                            fontWeight: '600',
                            color: '#595959'
                          }}
                        >View More</Text>
                      </td>
                    </tr>
                  ))}
                </table>
                {studiosData && (
                  <View
                    style={{
                      backgroundColor: '#F8F8F8',
                      height: 48,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      paddingHorizontal: 24
                    }}
                  >
                    <View
                      style={{
                        marginRight: 40,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Text
                        style={{
                          fontWeight: '600',
                          marginRight: 8
                        }}
                      >Rows per page: {studiosData.per_page}</Text>
                      <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 14 8 9h8l-4 5Z" fill="#6D6E6F" />
                      </svg>
                    </View>
                    <Text
                      style={{
                        fontWeight: '600'
                      }}
                    >{studiosData.from}- {studiosData.to} </Text>
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: 80,
                        marginLeft: 40
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          if(!studiosData.prev_page_url) {
                            return;
                          }
                          setIsStudiosLoading(true);
                          getStudios(studiosData.prev_page_url);
                        }}
                      >
                        <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M14.8 8.106c.628-.593-.367-1.533-.995-.89L9.196 11.52a.66.66 0 0 0 0 .94l4.609 4.352c.628.593 1.623-.346.995-.94l-4.085-3.857L14.8 8.106Z"
                            fill={studiosData.prev_page_url ? '#6D6E6F' : '#ccc'}
                          />
                        </svg>
                      </TouchableOpacity>
                      {isStudiosLoading && (
                        <ActivityIndicator color={'#096730'} />
                      )}
                      <TouchableOpacity
                        onPress={() => {
                          if(!studiosData.next_page_url) {
                            return;
                          }
                          setIsStudiosLoading(true);
                          getStudios(studiosData.next_page_url);
                        }}
                      >
                        <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M9.2 8.106c-.628-.593.367-1.533.995-.89l4.609 4.303a.66.66 0 0 1 0 .94l-4.609 4.352c-.628.593-1.623-.346-.995-.94l4.085-3.857L9.2 8.106Z"
                            fill={studiosData.next_page_url ? '#6D6E6F' : '#ccc'}
                          />
                        </svg>
                      </TouchableOpacity>
                    </View>
                  </View>
                )}
              </View>
            </View>
          </>
        )}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  tableHeader: {
    fontWeight: '600',
    textAlign: 'left',
    alignSelf: 'flex-start'
  },
  tableCell: {
    borderBottom: '1px solid',
    borderBottomColor: 'gray',
    paddingLeft: 16,
    paddingRight: 16
  },
});

RevenueScreen.propTypes = {
  navigation: PropTypes.object,
};

export default connect(({auth}) => ({auth}))(RevenueScreen);
