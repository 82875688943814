import React, {useEffect, useState} from "react";
import {
	KeyboardAvoidingView,
	TouchableOpacity,
	ScrollView,
	StyleSheet,
	Text,
	TouchableWithoutFeedback,
	View,
	TextInput,
	Image,
	Keyboard, StatusBar, ActivityIndicator
} from "react-native";
import {connect} from "react-redux";
import API from "../../helpers/Axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {useTheme} from "@react-navigation/native";
import FieldSetTextInput from "../../components/FieldSetTextInput";
import {CheckBox} from "react-native-web";
import FieldSetPicker from "../../components/FieldSetPicker";

const UserInfoScreen = ({navigation}) => {
	const {colors, dark} = useTheme();

	const [isSubmitting, setIsSubmitting] = useState( false);
	const [term, setTerm] = useState( false);
	const [termError, setTermError] = useState( false);

	const [categories, setCategories] = useState([]);

	useEffect(() => {
		API.post('categories')
			.then(res => {
				setCategories(res.data)
			}).catch (error => {
		});
	}, []);


	const onSubmit = () => {
		let error = false;
		if(isSubmitting) {
			return;
		}
		setIsSubmitting(true);
		if(!studioName.value.length) {
			setStudioName({
				value: studioName.value,
				error: true,
				errorString: studioName.errorString
			});
			error = true;
		}
		if(!studioAddress.value.length) {
			setStudioAddress({
				value: studioAddress.value,
				error: true,
				errorString: studioAddress.errorString
			});
			error = true;
		}
		if(studioType.value === 0) {
			setStudioType({
				value: studioType.value,
				error: true,
				errorString: studioType.errorString
			});
			error = true;
		}
		if(!term) {
			error = true;
			setTermError(true)
		}
    if(error) {
			setIsSubmitting(false);
			return
		}
		navigation.navigate('SignupScreen', {
			studioName: studioName,
			studioAddress: studioAddress,
			studioType: studioType,
		});
	}

	const [studioName, setStudioName] = useState({
		value: '',
		error: false,
		errorString: 'Please enter studio name'
	});
	const [studioAddress, setStudioAddress] = useState({
		value: '',
		error: false,
		errorString: 'Please enter studio address'
	});

	const [studioType, setStudioType] = useState({
		value: 0,
		error: false,
		errorString: 'Please select studio type'
	});

	return (
		<View
			style={{
				position: 'absolute',
				flex: 1,
				width: '100%',
				height: '100vh',
				margin: 0,
				padding: 0,
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: '#1D1F1E'
			}}
		>

			<View
				style={{
					maxWidth: 400
				}}
			>
				<View
					style={{
						marginBottom: 16,
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between'
					}}
				>
					<TouchableOpacity
						onPress={() => navigation.goBack()}
						style={{
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M14.8625 3.725L13.3791 2.25L5.13745 10.5L13.3875 18.75L14.8625 17.275L8.08745 10.5L14.8625 3.725Z" fill="white"/>
						</svg>
						<Text
							style={{
								marginLeft: 8,
								color: colors.whiteColor
							}}
						>Back</Text>
					</TouchableOpacity>
					<View>
						<Text
							style={{
								color: 'white',
								fontWeight: '600',
								fontSize: 12
							}}
						>Studio info</Text>
						<Text
							style={{
								color: '#3DBA71'
							}}
						>STEP 01/03</Text>
					</View>
				</View>
				<View
					style={{
						padding: 24,
						backgroundColor: colors.mainThemeBackgroundColor,
						borderRadius: 8,
					}}
				>
					<Text
						style={{
							color: '#3DBA71',
							fontSize: 34,
							marginBottom: 24,
							fontWeight: '600',
						}}
					>Welcome to Stugo!</Text>
					<Text
						style={{
							fontSize: 20,
							marginBottom: 24,
							color: '#6D6E6F',
							lineHeight: 28
						}}
					>First step is to add your studio information to Stugo, Let’s go!</Text>
					<View>
						<FieldSetTextInput
							name={'Studio Name'}
							placeholder={'Enter your studio name'}
							value={studioName.value}
							error={studioName.error}
							errorString={studioName.errorString}
							onChangeText={text => {
								setStudioName({
									value: text,
									error: false,
									errorString: studioName.errorString
								});
							}}
						/>
					</View>
					<View
						style={{
							marginTop: 40
						}}
					>
						<FieldSetTextInput
							placeholder={'Address'}
							value={studioAddress.value}
							error={studioAddress.error}
							errorString={studioAddress.errorString}
							onChangeText={text => {
								setStudioAddress({
									value: text,
									error: false,
									errorString: studioAddress.errorString
								});
							}}
						/>
					</View>
					<View
						style={{
							marginTop: 40
						}}
					>
						<FieldSetPicker
							data={categories}
							placeholder={'Studio type'}
							value={studioType.value}
							error={studioType.error}
							errorString={studioType.errorString}
							onValueChange={value => {
								setStudioType({
									value: value,
									error: false,
									errorString: studioType.errorString
								});
							}}
						/>
					</View>
					<View
						style={{
							marginTop: 40,
							flexDirection: 'row',
							alignItems: 'center'
						}}
					>
						<CheckBox
							value={term}
							onChange={() => {
								setTerm(!term);
								setTermError(false)
							}}
						/>
						<Text
							style={{
								marginLeft: 8
							}}
						>I agree to <Text style={{fontWeight: '600', color: '#27AE60'}}>terms & conditions</Text></Text>
					</View>
					{termError && (
						<Text
							style={{
								color: '#B96363',
								marginTop: 8,
							}}
						>Please accept terms & conditions</Text>
					)}
					<TouchableOpacity
						style={{
							marginTop: 24,
							height: 50,
							width: '100%',
							borderRadius: 8,
							backgroundColor: '#3DBA71',
							justifyContent: 'center',
							alignContent: 'center',
							opacity: isSubmitting ? 0.5 : 1
						}}
						onPress={() => onSubmit()}
					>
						{isSubmitting && <ActivityIndicator color={'white'}/>}
						{!isSubmitting &&
							<Text
								style={{
									fontSize: 16,
									fontWeight: '600',
									color: 'white',
									textAlign: 'center'
								}}
							>Create my studio</Text>
						}
					</TouchableOpacity>
				</View>
			</View>
		</View>
	)

}

export default connect(({auth}) => ({auth}))(UserInfoScreen);