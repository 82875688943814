import React  from "react";
import { View } from "react-native";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/lib/integration/react';
import {store, persistor} from './store/configureStore';
import AppNavigator from "./navigations/AppNavigator";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignContent: 'center',
          backgroundColor: '#2B2B2B'
        }}
      >
      </View>} persistor={persistor}>
      <AppNavigator />
      </PersistGate>
    </Provider>
  );
}

export default App;
